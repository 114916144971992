import React from 'react'

const BiggerCloseLogo = (props) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.94" height="12.952" viewBox="0 0 12.94 12.952">
    <path id="Trazado_1645" data-name="Trazado 1645" d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,0,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,1,0,18,15.878Z" transform="translate(-5.5 -5.549)" fill={props.fill||"#f7f7f7"}/>
  </svg>
  )
}

export default BiggerCloseLogo