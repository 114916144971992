import React from 'react'

const CloseLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
  <g id="Enmascarar_grupo_81" data-name="Enmascarar grupo 81" transform="translate(-558 -172)" clip-path="url(#clip-path)">
    <g id="Grupo_1031" data-name="Grupo 1031" transform="translate(3.354 2.733)">
      <line id="Línea_98" data-name="Línea 98" x2="7.603" y2="7.603" transform="translate(556 170.621)" fill="none" stroke={props.stroke || "#fff"} stroke-linecap="round" stroke-width="2"/>
      <line id="Línea_99" data-name="Línea 99" x1="7.603" y2="7.603" transform="translate(556 170.621)" fill="none" stroke={props.stroke || "#fff"} stroke-linecap="round" stroke-width="2"/>
    </g>
  </g>
</svg>

  )
}

export default CloseLogo