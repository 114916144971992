import React from 'react'

const PPSect1Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
      <g id="Enmascarar_grupo_106" data-name="Enmascarar grupo 106" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1128" data-name="Grupo 1128" transform="translate(491.001 169.767)">
          <g id="Grupo_1117" data-name="Grupo 1117" transform="translate(0 61)">
            <g id="Grupo_1116" data-name="Grupo 1116" transform="translate(0 0)">
              <path id="Trazado_1363" data-name="Trazado 1363" d="M36.482,61H3.515A3.519,3.519,0,0,0,0,64.515V87.951a3.519,3.519,0,0,0,3.515,3.515H36.482A3.519,3.519,0,0,0,40,87.951V64.515A3.519,3.519,0,0,0,36.482,61Zm1.172,26.951a1.173,1.173,0,0,1-1.172,1.172H3.515a1.173,1.173,0,0,1-1.172-1.172V64.515a1.173,1.173,0,0,1,1.172-1.172H36.482a1.173,1.173,0,0,1,1.172,1.172Z" transform="translate(0 -61)"/>
            </g>
          </g>
          <g id="Grupo_1119" data-name="Grupo 1119" transform="translate(4.687 65.687)">
            <g id="Grupo_1118" data-name="Grupo 1118" transform="translate(0 0)">
              <path id="Trazado_1364" data-name="Trazado 1364" d="M70.624,121a10.546,10.546,0,1,0,10.546,10.546A10.616,10.616,0,0,0,70.624,121Zm0,18.749a8.29,8.29,0,0,1-5.088-1.736,5.862,5.862,0,0,1,10.162-.026A8.163,8.163,0,0,1,70.624,139.749Zm-2.344-9.374a2.344,2.344,0,1,1,2.344,2.344A2.346,2.346,0,0,1,68.281,130.374Zm9.075,5.853a8.153,8.153,0,0,0-3.237-2.734,4.687,4.687,0,1,0-6.989,0,8.153,8.153,0,0,0-3.268,2.78,8.1,8.1,0,0,1-1.518-4.728,8.242,8.242,0,0,1,16.483,0A8.156,8.156,0,0,1,77.356,136.227Z" transform="translate(-60 -121)"/>
            </g>
          </g>
          <g id="Grupo_1121" data-name="Grupo 1121" transform="translate(28.201 68.031)">
            <g id="Grupo_1120" data-name="Grupo 1120" transform="translate(0 0)">
              <path id="Trazado_1365" data-name="Trazado 1365" d="M366.937,151h-4.765a1.172,1.172,0,1,0,0,2.344h4.765a1.172,1.172,0,1,0,0-2.344Z" transform="translate(-361 -151)"/>
            </g>
          </g>
          <g id="Grupo_1123" data-name="Grupo 1123" transform="translate(28.201 72.718)">
            <g id="Grupo_1122" data-name="Grupo 1122" transform="translate(0 0)">
              <path id="Trazado_1366" data-name="Trazado 1366" d="M366.937,211h-4.765a1.172,1.172,0,1,0,0,2.344h4.765a1.172,1.172,0,1,0,0-2.344Z" transform="translate(-361 -211)"/>
            </g>
          </g>
          <g id="Grupo_1125" data-name="Grupo 1125" transform="translate(28.201 77.405)">
            <g id="Grupo_1124" data-name="Grupo 1124" transform="translate(0 0)">
              <path id="Trazado_1367" data-name="Trazado 1367" d="M366.937,271h-4.765a1.172,1.172,0,1,0,0,2.344h4.765a1.172,1.172,0,1,0,0-2.344Z" transform="translate(-361 -271)"/>
            </g>
          </g>
          <g id="Grupo_1127" data-name="Grupo 1127" transform="translate(28.201 82.092)">
            <g id="Grupo_1126" data-name="Grupo 1126" transform="translate(0 0)">
              <path id="Trazado_1368" data-name="Trazado 1368" d="M366.937,331h-4.765a1.172,1.172,0,1,0,0,2.344h4.765a1.172,1.172,0,1,0,0-2.344Z" transform="translate(-361 -331)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
    
      )
    }

export default PPSect1Logo