const Pencil = () => {
    return(
        <svg id="Grupo_809" data-name="Grupo 809" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 23.826 23.836">
            <defs>
                <clipPath id="clipPath">
                <rect id="Rectángulo_768" data-name="Rectángulo 768" width="23.826" height="23.836" fill="none"/>
                </clipPath>
            </defs>
            <g id="Grupo_808" data-name="Grupo 808" clip-path="url(#clipPath)">
                <path id="Trazado_989" data-name="Trazado 989" d="M23.826,5.639a3.562,3.562,0,0,1-1.071,2.5L11.325,19.565q-1.384,1.383-2.769,2.766a1.915,1.915,0,0,1-1.677.81q-2.868.34-5.736.685A.965.965,0,0,1,.02,23.1,2.905,2.905,0,0,1,.045,22.3c.1-.929.218-1.856.331-2.784q.193-1.589.389-3.177a1.3,1.3,0,0,1,.423-.772Q4.149,12.61,7.1,9.649q4.285-4.287,8.568-8.576a3.646,3.646,0,0,1,5.135-.031c.712.724,1.44,1.434,2.13,2.178a3.444,3.444,0,0,1,.89,2.419M2.087,21.722a1.366,1.366,0,0,0,.142,0c1.387-.156,2.772-.328,4.16-.461a1.456,1.456,0,0,0,.961-.48c1.821-1.845,3.658-3.673,5.49-5.506q2.5-2.5,5-4.994c.095-.095.053-.138-.016-.207q-1.948-1.946-3.895-3.893c-.287-.287-.288-.285-.572,0Q8.064,11.467,2.774,16.757a.546.546,0,0,0-.143.3c-.132,1.1-.253,2.2-.379,3.3-.052.451-.108.9-.164,1.365M21.875,5.582a1.677,1.677,0,0,0-.465-1.173c-.692-.7-1.386-1.389-2.1-2.061a1.573,1.573,0,0,0-1.939-.122,4.164,4.164,0,0,0-.527.472q-.849.84-1.69,1.689c-.058.058-.138.1-.031.2q2.038,2.027,4.062,4.068c.119.12.171.1.27,0q.975-.984,1.958-1.961a1.656,1.656,0,0,0,.462-1.113" transform="translate(0 0)"/>
            </g>
        </svg>
    );
}

export default Pencil;