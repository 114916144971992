import React from 'react'

const UploadCorrectLogo = () => {
  return (
    <svg id="Grupo_67" data-name="Grupo 67" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10.793" height="10.788" viewBox="0 0 10.793 10.788">
      
      <g id="Grupo_59" data-name="Grupo 59" clip-path="url(#clip-path)">
        <path id="Trazado_34" data-name="Trazado 34" d="M10.793,5.414A5.4,5.4,0,1,1,5.425,0a5.411,5.411,0,0,1,5.368,5.413m-6.3,1.155c-.049-.058-.083-.1-.122-.143q-.659-.661-1.32-1.321a.458.458,0,0,0-.436-.155.431.431,0,0,0-.344.308.442.442,0,0,0,.129.47L4.139,7.467a.452.452,0,0,0,.715,0q1.76-1.76,3.519-3.522a.454.454,0,0,0,.03-.673.447.447,0,0,0-.66.035q-.426.422-.848.847l-2.4,2.411" transform="translate(0 0)" fill="#00c67e"/>
      </g>
    </svg>
  )
}

export default UploadCorrectLogo
