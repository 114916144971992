export const msalConfig = {
    auth: {
      //clientId: "a05b95e4-a7bb-415d-a5fa-47f567e5d94c", //config Dev
      clientId: "9e95139e-ae03-4946-a569-3d6336191732", //config Prod
      authority: "https://login.microsoftonline.com/02628fa6-1dc9-48c7-a59a-a34f974631c2", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      //redirectUri: "https://dev.mdcash.mx/", //config Dev
      //redirectUri: "https://beta.mdcash.mx/", //config prod
      //redirectUri: "https://mdcash-mdcash-pre-deploy.azurewebsites.net" , //config Pre-Deploy
      redirectUri: window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2] , 
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const protectedResources = {
  apiTodoList: {
      endpoint: "http://localhost:4000/api/",
      scopes: {
          read: [ "api://0b933d9e-5edf-4881-a8da-661b3fe18c30/User.Read" ] // Api Prod
          //read: ["api://bf9b2c90-4975-46c9-a56a-1a86f537ce1b/User.Read"] //Api Dev
      }
  }
}


//export const urlBase = 'http://localhost:4000'
//export const urlBase = 'https://mdcicobackdev.azurewebsites.net'
export const urlBase = 'https://mdcicoback.azurewebsites.net'
//export const urlBase = 'https://mdcicoback-mdcicoback-pre-deploy.azurewebsites.net'
