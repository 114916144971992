import React from 'react'

const ThreeDots = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.691" height="5.797" viewBox="0 0 23.691 5.797">
      <g id="Grupo_656" data-name="Grupo 656" transform="translate(0 0)">
        <g id="Grupo_655" data-name="Grupo 655" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Trazado_913" data-name="Trazado 913" d="M107.709,5.8A2.9,2.9,0,1,1,110.6,2.9a2.9,2.9,0,0,1-2.895,2.9" transform="translate(-95.859 -0.001)" fill={props.fill || "#707070"}/>
          <path id="Trazado_914" data-name="Trazado 914" d="M5.8,2.938A2.9,2.9,0,1,1,2.943,0,2.894,2.894,0,0,1,5.8,2.938" transform="translate(0 -0.001)" fill={props.fill || "#707070"}/>
          <path id="Trazado_915" data-name="Trazado 915" d="M209.6,2.883A2.9,2.9,0,1,1,212.479,5.8,2.89,2.89,0,0,1,209.6,2.883" transform="translate(-191.706 0)" fill={props.fill || "#707070"}/>
        </g>
      </g>
    </svg>
  )
}

export default ThreeDots