
import React from 'react'

const ReporteLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_19" data-name="Enmascarar grupo 19" transform="translate(-78 -445)" clip-path="url(#clip-path)">
        <g id="Grupo_221" data-name="Grupo 221" transform="translate(84 445.972)">
          <g id="Grupo_220" data-name="Grupo 220" transform="translate(0 0.028)" clip-path="url(#clip-path-2)">
            <path id="Trazado_156" data-name="Trazado 156" d="M2.618,4.992v.361c0,.66-.005,1.321,0,1.981A1.722,1.722,0,0,0,4.481,9.182q2.907,0,5.813,0c1.374,0,2.747,0,4.121,0a1.736,1.736,0,0,0,1.877-1.871c.007-.649,0-1.3,0-1.948V5c.736,0,1.435-.015,2.134.008a.48.48,0,0,1,.468.482c.013.127.015.255.015.383q0,11.707,0,23.415a3.484,3.484,0,0,1-.017.414.491.491,0,0,1-.447.467,2.677,2.677,0,0,1-.413.025q-8.577,0-17.153,0a2.776,2.776,0,0,1-.351-.013.525.525,0,0,1-.521-.539C0,29.533,0,29.426,0,29.32Q0,17.6,0,5.873c0-.117,0-.234.008-.351A.5.5,0,0,1,.507,5c.689-.022,1.379-.007,2.111-.007M9.45,12.613h5.268c.107,0,.213,0,.319-.007.391-.037.616-.262.608-.6s-.23-.544-.638-.561c-.053,0-.107,0-.159,0H4.184a1.862,1.862,0,0,0-.35.02.544.544,0,0,0-.457.628c.037.357.263.52.741.521q2.666,0,5.331,0M9.473,20H13.5c.511,0,1.022.009,1.532,0a.577.577,0,0,0,.586-.777c-.1-.32-.363-.378-.661-.378q-5.443,0-10.887,0c-.053,0-.107,0-.159,0a.554.554,0,0,0-.524.662c.046.333.253.492.664.492q2.713,0,5.427,0m.041-4.876H6.833c-.947,0-1.894,0-2.841,0a.548.548,0,0,0-.618.573c0,.381.243.583.714.583q5.395,0,10.79,0a1.2,1.2,0,0,0,.345-.042.568.568,0,0,0,.365-.814.593.593,0,0,0-.616-.3q-2.729,0-5.459,0M6.7,22.556c-.893,0-1.787-.007-2.679,0a.579.579,0,0,0-.61.8c.114.32.38.363.673.363q2.409,0,4.817,0a6.665,6.665,0,0,0,.669-.015.512.512,0,0,0,.486-.532.529.529,0,0,0-.424-.589,1.574,1.574,0,0,0-.348-.029q-1.292,0-2.584,0" transform="translate(0 -2.29)" fill={props.fill || "#fff"}/>
            <path id="Trazado_157" data-name="Trazado 157" d="M12.629,5.755H7.807c-.642,0-.772-.131-.771-.758,0-.724,0-1.448,0-2.172a1.1,1.1,0,0,1,1.21-1.2c.511,0,1.022-.009,1.533,0a.331.331,0,0,0,.362-.228c.1-.244.237-.475.352-.715a1.05,1.05,0,0,1,.912-.659c.828-.033,1.659-.029,2.487-.007a1.154,1.154,0,0,1,.888.56.829.829,0,0,1,.14.249,1.028,1.028,0,0,0,1.33.8,5.593,5.593,0,0,1,.957-.007,1.073,1.073,0,0,1,1.1,1.086c.014.734,0,1.469,0,2.2,0,.648-.191.842-.83.843H12.629" transform="translate(-3.193 -0.027)" fill={props.fill || "#fff"}/>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default ReporteLogo
