import React from 'react'

const PlanningLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Enmascarar_grupo_83" data-name="Enmascarar grupo 83" transform="translate(-78 -586)" clip-path="url(#clip-path)">
            <g id="Grupo_1053" data-name="Grupo 1053" transform="translate(82.626 589.029)">
                <g id="Grupo_1055" data-name="Grupo 1055" clip-path="url(#clip-path-2)">
                    <g id="Grupo_1057" data-name="Grupo 1057" transform="translate(0.241 0)">
                        <g id="Grupo_1056" data-name="Grupo 1056" clip-path="url(#clip-path-3)">
                            <path id="Trazado_1229" data-name="Trazado 1229" d="M43.779,44.563h.007a.926.926,0,0,0-.029-1.851h0a.92.92,0,0,0-.91.915.933.933,0,0,0,.932.935" transform="translate(-39.157 -39.034)" fill={props.fill || "#fff"}/>
                            <path id="Trazado_1230" data-name="Trazado 1230" d="M172,203.028a.912.912,0,0,0-.91.913s0,.01,0,.015a.917.917,0,1,0,.91-.928" transform="translate(-156.351 -185.543)" fill={props.fill || "#fff"}/>
                            <path id="Trazado_1231" data-name="Trazado 1231" d="M20.257,23.167V.7a.68.68,0,0,0-.662-.7q-.033,0-.066,0H.783A.684.684,0,0,0,.007.783V11.967H0v11.2a.756.756,0,0,0,.742.769H.771q9.369-.013,18.739,0a1.021,1.021,0,0,0,.242-.02.666.666,0,0,0,.505-.751M13.495,3.754c-.117-.114-.235-.226-.345-.345a.92.92,0,0,1-.01-1.276.91.91,0,0,1,1.283-.048c.384.354.744.728,1.114,1.1.034.034.068.066.114.106.048-.045.093-.086.137-.128.349-.348.7-.7,1.048-1.046a.921.921,0,1,1,1.3,1.3l-1.046,1.05c-.043.043-.082.089-.154.167.334.319.662.633.987.947.086.083.169.17.249.259a.9.9,0,0,1,0,1.216.929.929,0,0,1-1.214.13,3.733,3.733,0,0,1-.379-.351c-.306-.3-.609-.612-.923-.929-.07.066-.134.122-.194.182-.333.332-.658.67-1,.995a.92.92,0,1,1-1.287-1.311c.358-.367.728-.728,1.086-1.091.023-.023.044-.048.084-.095ZM4.581,1.844A2.767,2.767,0,1,1,1.833,4.618,2.79,2.79,0,0,1,4.581,1.844m2.484,17.72a.912.912,0,0,1,.3.7.874.874,0,0,1-.5.8.89.89,0,0,1-.963-.073,1.643,1.643,0,0,1-.214-.191c-.355-.355-.711-.712-1.079-1.083-.122.116-.242.224-.355.338-.281.281-.552.574-.84.846a.92.92,0,0,1-1.31-1.293l.027-.027c.361-.37.728-.728,1.1-1.1.02-.02.038-.041.064-.07-.035-.038-.064-.074-.1-.106-.366-.367-.74-.728-1.094-1.1a.919.919,0,0,1,.684-1.552.942.942,0,0,1,.686.316c.369.374.741.746,1.145,1.151.2-.209.386-.406.58-.6s.4-.411.61-.609a.922.922,0,0,1,1.56.7.976.976,0,0,1-.312.658q-.505.5-1.008,1c-.044.044-.083.09-.133.146Zm5.14-4.216c-.185-.2-.357-.414-.534-.62L7.027,9.3c-.121-.146-.253-.275-.368-.422a.925.925,0,0,1,.667-1.512v0a1.067,1.067,0,0,1,.839.437l3.224,3.767q1.046,1.223,2.085,2.452a1.886,1.886,0,0,1,.213.291.885.885,0,0,1-.245,1.153.916.916,0,0,1-1.238-.117M15.788,21.3a2.767,2.767,0,0,1-2.9-2.956A2.818,2.818,0,0,1,15.6,15.647a2.784,2.784,0,0,1,2.813,2.748h0a2.767,2.767,0,0,1-2.625,2.9" transform="translate(0 0.001)" fill={props.fill || "#fff"}/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default PlanningLogo