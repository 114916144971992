import React from 'react'

const AddScenarioLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Enmascarar_grupo_89" data-name="Enmascarar grupo 89" transform="translate(-815 -294)" clip-path="url(#clip-path)">
            <g id="Grupo_1077" data-name="Grupo 1077" transform="translate(815.456 293.962)">
                <g id="Grupo_1076" data-name="Grupo 1076" clip-path="url(#clip-path-2)">
                    <path id="Trazado_1284" data-name="Trazado 1284" d="M22.518,10.39a7.619,7.619,0,0,1,3.358,1.3,7.492,7.492,0,0,1-3.1,13.549c-.2.031-.257.1-.259.294a9.995,9.995,0,0,1-.061,1.584,3.5,3.5,0,0,1-3.515,2.922c-1.952.009-3.9,0-5.855,0q-4.734,0-9.468,0A3.513,3.513,0,0,1,.1,27.3a4.064,4.064,0,0,1-.1-.925Q0,15.017,0,3.663A3.535,3.535,0,0,1,3.655.007C7.371,0,11.087.009,14.8,0a1.425,1.425,0,0,1,.988.369q3.137,2.7,6.289,5.391a1.154,1.154,0,0,1,.44.946c-.009,1.1,0,2.2,0,3.3v.381m-8.44-8.51H3.743A1.686,1.686,0,0,0,1.875,3.748q0,11.276,0,22.553a1.688,1.688,0,0,0,1.875,1.863H18.792c.083,0,.166,0,.249,0a1.64,1.64,0,0,0,1.593-1.6c.013-.342,0-.685.008-1.028.007-.189-.047-.265-.25-.3A7.5,7.5,0,0,1,17.926,11.31a7.756,7.756,0,0,1,2.694-.917V7.516H20.24c-1.7,0-3.405,0-5.107,0a.952.952,0,0,1-1.054-1.064q0-2.1,0-4.205V1.88m7.538,21.587a5.627,5.627,0,1,0-5.664-5.589,5.643,5.643,0,0,0,5.664,5.589M15.968,2.995V5.612h3.052L15.968,2.995" transform="translate(0 -0.001)"/>
                    <path id="Trazado_1285" data-name="Trazado 1285" d="M80.8,150.549c1.566,0,3.133,0,4.7,0a.92.92,0,0,1,.922.77.934.934,0,0,1-.583,1.025,1.572,1.572,0,0,1-.518.073q-4.512.006-9.024,0c-.062,0-.125,0-.187,0a.957.957,0,0,1-.936-.936.929.929,0,0,1,.96-.934c1.556-.006,3.112,0,4.668,0" transform="translate(-70.476 -141.151)"/>
                    <path id="Trazado_1286" data-name="Trazado 1286" d="M78.98,332.646c-.944,0-1.887.01-2.831,0a.906.906,0,0,1-.906-.784.916.916,0,0,1,.563-1,1.285,1.285,0,0,1,.454-.083q2.722-.008,5.444,0a.954.954,0,0,1,1.015.781.909.909,0,0,1-.549,1.009,1.554,1.554,0,0,1-.546.085c-.881.008-1.763,0-2.644,0v0" transform="translate(-70.535 -310.123)"/>
                    <path id="Trazado_1287" data-name="Trazado 1287" d="M78.917,272.567c-.892,0-1.784,0-2.675,0a.946.946,0,1,1,0-1.874q2.722,0,5.444,0a.949.949,0,0,1,.978.784.92.92,0,0,1-.585,1.018,1.487,1.487,0,0,1-.487.068c-.892.006-1.784,0-2.675,0" transform="translate(-70.478 -253.796)"/>
                    <path id="Trazado_1288" data-name="Trazado 1288" d="M78.935,210.63c.9,0,1.8,0,2.707,0a.941.941,0,1,1,0,1.873q-2.722,0-5.444,0a.94.94,0,1,1,0-1.872c.913,0,1.825,0,2.738,0" transform="translate(-70.479 -197.483)"/>
                    <path id="Trazado_1289" data-name="Trazado 1289" d="M290.343,230.362c0,.65.01,1.251,0,1.851a.921.921,0,0,1-.991.959.936.936,0,0,1-.882-.982c-.01-.488,0-.976,0-1.464,0-.11,0-.221,0-.366-.662,0-1.282.009-1.9,0a.906.906,0,0,1-.869-.677.863.863,0,0,1,.341-.987,1.356,1.356,0,0,1,.636-.2c.579-.026,1.16-.009,1.793-.009,0-.155,0-.294,0-.434,0-.478-.012-.956,0-1.432a.937.937,0,0,1,.954-.944.924.924,0,0,1,.915.945c.015.61,0,1.221,0,1.866.644,0,1.253-.005,1.862,0a.907.907,0,0,1,.879.582.882.882,0,0,1-.171.984.931.931,0,0,1-.751.309c-.59-.005-1.18,0-1.818,0" transform="translate(-267.827 -211.591)"/>
                </g>
            </g>
        </g>
    </svg>

  )
}

export default AddScenarioLogo