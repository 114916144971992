import React from 'react'

const ExclamationLogo = (props) => {
  return (
    <svg id="Grupo_1061" data-name="Grupo 1061" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" stroke={props.stroke || "#fff"} fill={props.fill || "#fff"}>
  <g id="Grupo_1060" data-name="Grupo 1060" clip-path="url(#clip-path)">
    <path id="Trazado_1141" data-name="Trazado 1141" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0m1.3,5.546-.184,13.545H13.15L12.966,5.546Zm.088,17.58a2.111,2.111,0,0,1-.723.729,2.032,2.032,0,0,1-2.468-3.188,2.03,2.03,0,0,1,2.873,0,2.019,2.019,0,0,1,.318,2.46" />
  </g>
</svg>

  )
}

export default ExclamationLogo