import { useIsAuthenticated } from '@azure/msal-react'
import React,{useState} from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Upper } from '../components'
import { useStateContext } from '../contexts/ContextProvider'
import { getToken } from '../fetch'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className='flex border-2 border-md-gray rounded-lg relative'>
      <div className='text-md-blue absolute right-2 top-1 dark:text-white dark:bg-secondary-dark-bg mt-1'>
        <Search />
      </div>
      {' '}
      <input
        placeholder='Search...'
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
        className='dark:bg-secondary-dark-bg bg-transparent font-medium pl-2 w-[100%] h-8 mt-1 focus:outline-none'
      />
    </div>
  )
}

const RulesScreen = () => {
  const {idClient,accessToken,setToken} = useStateContext()
    const navigate = useNavigate()
    const [data,setData] = useState([{
      "idRule": 5,
      "idClient": 1,
      "idCategory": 1,
      "isActive": true,
      "ruleName": 'Regla 5',
      "isInflow": null,
      "lowerBound": -100,
      "upperBound": 999999,
      "typeOfSearch": "CONTIENE",
      "searchFor": "DOM",
      "orden": 5
  }])
    const isAuthenticated = useIsAuthenticated()

  useEffect(()=>{
    if(!isAuthenticated){
      navigate('/')
      return
    }
    getToken().then((token)=>{setToken(token)})
    /*
    let headers = new Headers()

    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type",'application/json')
      fetch('https://mdcicobacktest.azurewebsites.net/getRules',{
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          "clientID": idClient
      })
      })
        .then((response) => response.json())
        .then((aux) => {
          console.log('reglas',aux)
          if(!aux.error){
            setData(aux)
          }
          
      })
      .catch((error)=>{
        console.log(error)
      })*/
  },[])

  const textNormal = 'text-md-gray font-bold text-xl'


  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'ruleName', // accessor is the "key" in the data
      },
      {
        Header:'Categoria',
        accessor:'idCategory'
      },
      {
        Header:'Regla',
        accessor:'idRule'
      },
      {
        Header:'Cliente',
        accessor:'idClient'
      },
      {
        Header:'Activa',
        accessor:'isActive'
      },
      {
        Header:'abono',
        accessor:'isInflow'
      },
      {
        Header:'inferior',
        accessor:'lowerBound'
      },
      {
        Header:'superior',
        accessor:'upperBound'
      },
      {
        Header:'orden',
        accessor:'orden'
      },
      {
        Header:'buscar',
        accessor:'searchFor'
      },
    ],
    []
  )
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })
  
  const createCellOfType = (cell,row) => {
    return <div className='px-2'>{cell.value}</div>
  }

  return (
    <div className='ml-20 mt-10 mr-10'>
      <Upper/>
      <p className='text-md-gray text-xl font-bold mt-8'>Todas las reglas</p>
      <table {...getTableProps()} class='table-fixed dark:bg-secondary-dark-bg text-md-gray dark:text-md-white rounded-md max-h-screen min-w-[100%]'>
                      <thead className='rounded-md shadow-md  sticky top-0 z-10'>
                          {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="bg-md-table-bg rounded-xl ">
                              {/*headerGroup.headers.map(column => (
                              <th
                                class='pr-10 pl-10 rounded-tl-md'
                                  {...column.getHeaderProps()}
                              >
                                  {column.render('Header')}
                              </th>
                              ))*/}
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Nombre
                              </th>
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Categoria
                                </th>
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Regla
                                </th>
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Cliente
                                </th>
                                <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                  Activa
                              </th>
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Abono
                                </th>
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Inferior
                                </th>
                                <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                                Superior
                                </th>
                              <th class='rounded-bl-xl pl-2 w-36  min-w-fit text-left text-2xl text-md-gray pb-2.5'>
                              Orden
                                </th>
                              <th class='text-left text-2xl text-md-gray rounded-r-xl pb-2.5'>
                                Buscar
                              </th>
                            </tr>
                          ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()} className='group'>
                            {row.cells.map(cell => {
                              return <td {...cell.getCellProps()}>{createCellOfType(cell,row)}</td>
                            })}
                            
                          </tr>
                        )
                        })}
                    </tbody>
                  </table>
    </div>
  )
}

export default RulesScreen