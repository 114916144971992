import React from 'react'

const DownloadIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
  <g id="Enmascarar_grupo_48" data-name="Enmascarar grupo 48" transform="translate(-642 -669)">
    <g id="Grupo_957" data-name="Grupo 957" transform="translate(645 669)">
      <g id="Grupo_509" data-name="Grupo 509" transform="translate(0 0)" clip-path="url(#clip-path-2)">
        <path id="Trazado_739" data-name="Trazado 739" d="M11.015,18.886v-.471q0-8.463,0-16.926A1.323,1.323,0,0,1,12.2,0,1.292,1.292,0,0,1,13.628,1.2a4.56,4.56,0,0,1,.01.484V18.985c.187-.174.3-.276.413-.385,1.679-1.687,3.348-3.383,5.047-5.052a1.951,1.951,0,0,1,.939-.5,1.141,1.141,0,0,1,1.244.644,1.229,1.229,0,0,1-.065,1.373,2.978,2.978,0,0,1-.381.424q-3.707,3.71-7.417,7.418a1.307,1.307,0,0,1-2.163.016q-3.817-3.787-7.628-7.581a1.284,1.284,0,0,1-.064-1.918,1.313,1.313,0,0,1,1.893.047c.117.112.23.227.345.341q2.358,2.349,4.713,4.7a5.325,5.325,0,0,1,.361.444l.14-.077" transform="translate(-0.586 0)" fill={props.fill || "#fff"}/>
        <path id="Trazado_740" data-name="Trazado 740" d="M11.74,34.745H1.556C.469,34.744,0,34.277,0,33.191,0,31.119,0,29.047,0,26.975a1.292,1.292,0,0,1,1.7-1.291A1.285,1.285,0,0,1,2.6,27.01c.007,1.543.015,3.086-.006,4.629-.006.4.094.521.508.519q8.641-.021,17.282,0c.415,0,.513-.123.508-.52-.021-1.542-.014-3.086-.006-4.629a1.3,1.3,0,1,1,2.587-.121q.024,3.285,0,6.569a1.269,1.269,0,0,1-1.415,1.287q-4.388.006-8.773,0H11.74" transform="translate(0 -4.745)" fill={props.fill || "#fff"}/>
      </g>
    </g>
  </g>
</svg>
  )
}

export default DownloadIcon