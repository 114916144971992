const IconI = ({width = 10}) => {
    let height = width;
    return(
        <svg id="Botón_Info" data-name="Botón Info" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 10">
            <g id="Enmascarar_grupo_91" data-name="Enmascarar grupo 91" transform="translate(-889 -217)" clip-path="url(#clip-path)">
                <g id="Grupo_1085" data-name="Grupo 1085" transform="translate(889 217)">
                <g id="Grupo_1085-2" data-name="Grupo 1085" clip-path="url(#clip-path-2)">
                    <path id="Trazado_1310" data-name="Trazado 1310" d="M10,5A5,5,0,1,1,5,0a5,5,0,0,1,5,5" transform="translate(0 0)" fill="#adadad"/>
                    <path id="Trazado_1311" data-name="Trazado 1311" d="M9.469,2.826a.79.79,0,0,1,.583.246.817.817,0,0,1,.243.6.807.807,0,0,1-.241.589.781.781,0,0,1-.576.243.793.793,0,0,1-.588-.248.83.83,0,0,1-.243-.6.823.823,0,0,1,.821-.823M8.823,4.947h1.292V10.1H8.823Z" transform="translate(-4.471 -1.461)" fill="#fff"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default IconI;