import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();
    return(
        <div className="w-full text-center justify-center items-center">
            <span className="text-8xl">🎉</span>
            <h2 className="text-center font-bold text-3xl mt-10">Su pago ha si efectuado, bienvenido a md cash</h2>
            <button onClick={() => navigate('/')} className='bg-purple-2 text-white p-5 rounded-md mt-10'>Ir al Inicio</button>
        </div>
    );
}

export default Success;