import React from 'react'

const MDCashLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="138.477" height="33.344" viewBox="0 0 138.477 33.344">
      <g id="Grupo_505" data-name="Grupo 505" transform="translate(0 0)">
        <path id="Trazado_727" data-name="Trazado 727" d="M18.936,15.261,0,.073V33.334H4.2V8.832L19.1,20.776,35.82,5.676V.015Z" transform="translate(0 -0.005)" fill="#3c0383"/>
        <g id="Grupo_504" data-name="Grupo 504" transform="translate(0 0)">
          <g id="Grupo_503" data-name="Grupo 503" clip-path="url(#clip-path)">
            <path id="Trazado_728" data-name="Trazado 728" d="M59.438,33.344v-4.2c8.594,0,15.586-5.593,15.586-12.468A11.266,11.266,0,0,0,70.609,7.99,17.536,17.536,0,0,0,59.438,4.2V0A21.73,21.73,0,0,1,73.281,4.748a15.375,15.375,0,0,1,5.945,11.924c0,9.194-8.877,16.671-19.788,16.671" transform="translate(-19.416 0)" fill="#3c0383"/>
            <path id="Trazado_729" data-name="Trazado 729" d="M126.637,22.277H123.2a5.276,5.276,0,0,0-.739-1.942,5.435,5.435,0,0,0-1.324-1.423,5.73,5.73,0,0,0-1.748-.883,6.906,6.906,0,0,0-2.047-.3,6.351,6.351,0,0,0-3.491.982,6.645,6.645,0,0,0-2.427,2.879,10.9,10.9,0,0,0-.887,4.633,10.954,10.954,0,0,0,.887,4.655,6.554,6.554,0,0,0,2.433,2.868,6.39,6.39,0,0,0,3.475.971,6.971,6.971,0,0,0,2.035-.292,5.747,5.747,0,0,0,1.749-.866,5.4,5.4,0,0,0,1.33-1.407,5.3,5.3,0,0,0,.755-1.914l3.441.011a9.22,9.22,0,0,1-1.065,3.062,8.471,8.471,0,0,1-2.019,2.4,9.147,9.147,0,0,1-2.807,1.561,10.372,10.372,0,0,1-3.442.552,9.859,9.859,0,0,1-5.229-1.4,9.655,9.655,0,0,1-3.612-4,13.6,13.6,0,0,1-1.318-6.211,13.53,13.53,0,0,1,1.324-6.216,9.694,9.694,0,0,1,3.618-3.994,9.837,9.837,0,0,1,5.218-1.4,10.7,10.7,0,0,1,3.359.513,9.03,9.03,0,0,1,2.807,1.5,8.357,8.357,0,0,1,2.063,2.4,9.353,9.353,0,0,1,1.1,3.238" transform="translate(-35.001 -4.776)"/>
            <path id="Trazado_730" data-name="Trazado 730" d="M143.54,40.681a6.859,6.859,0,0,1-2.913-.6,4.828,4.828,0,0,1-2.057-1.749,5,5,0,0,1-.755-2.813,4.559,4.559,0,0,1,.551-2.361,4.064,4.064,0,0,1,1.489-1.467,7.8,7.8,0,0,1,2.1-.817,21.044,21.044,0,0,1,2.361-.419q1.523-.177,2.471-.292a3.572,3.572,0,0,0,1.379-.381.93.93,0,0,0,.43-.86v-.077a2.991,2.991,0,0,0-.811-2.24,3.314,3.314,0,0,0-2.411-.794,4.2,4.2,0,0,0-2.619.734,3.961,3.961,0,0,0-1.318,1.627l-3.1-.706a5.974,5.974,0,0,1,1.616-2.5,6.577,6.577,0,0,1,2.454-1.39,9.723,9.723,0,0,1,2.924-.436,10.616,10.616,0,0,1,2.157.237,6.446,6.446,0,0,1,2.14.866,4.78,4.78,0,0,1,1.638,1.793,6.228,6.228,0,0,1,.641,3.017V40.306h-3.222V37.989h-.132a4.713,4.713,0,0,1-.96,1.257,5.1,5.1,0,0,1-1.644,1.026,6.392,6.392,0,0,1-2.4.408m.717-2.648a4.766,4.766,0,0,0,2.343-.541,3.816,3.816,0,0,0,1.489-1.417,3.661,3.661,0,0,0,.513-1.881V32.01a1.678,1.678,0,0,1-.678.325,9.181,9.181,0,0,1-1.142.254q-.639.105-1.246.187l-1.015.138a8.1,8.1,0,0,0-1.749.409,2.986,2.986,0,0,0-1.257.821,2.072,2.072,0,0,0-.469,1.417,2.1,2.1,0,0,0,.9,1.848,3.977,3.977,0,0,0,2.306.624" transform="translate(-45.019 -7.559)"/>
            <path id="Trazado_731" data-name="Trazado 731" d="M174.821,27.5l-2.99.529a3.627,3.627,0,0,0-.59-1.092,3.011,3.011,0,0,0-1.087-.85,3.918,3.918,0,0,0-1.71-.331,4.135,4.135,0,0,0-2.338.624,1.866,1.866,0,0,0-.938,1.605,1.684,1.684,0,0,0,.63,1.368,5.085,5.085,0,0,0,2.029.85l2.692.617A7.217,7.217,0,0,1,174,32.484a3.913,3.913,0,0,1,1.147,2.923,4.435,4.435,0,0,1-.877,2.709,5.811,5.811,0,0,1-2.437,1.858,9.064,9.064,0,0,1-3.613.673,8.112,8.112,0,0,1-4.643-1.219,5.108,5.108,0,0,1-2.207-3.47l3.189-.485a3.027,3.027,0,0,0,1.224,1.881,4.189,4.189,0,0,0,2.416.634,4.444,4.444,0,0,0,2.592-.679,1.986,1.986,0,0,0,.971-1.66,1.74,1.74,0,0,0-.591-1.335,3.961,3.961,0,0,0-1.8-.816L166.5,32.87a6.856,6.856,0,0,1-3.5-1.721,4.147,4.147,0,0,1-1.131-2.99,4.271,4.271,0,0,1,.838-2.625,5.526,5.526,0,0,1,2.316-1.759,8.523,8.523,0,0,1,3.387-.635,7.048,7.048,0,0,1,4.324,1.186,5.573,5.573,0,0,1,2.085,3.172" transform="translate(-52.715 -7.559)"/>
            <path id="Trazado_732" data-name="Trazado 732" d="M187.74,27.612v10.06h-3.3V15.081H187.7v8.406h.209a4.863,4.863,0,0,1,1.82-2.173,5.725,5.725,0,0,1,3.2-.806,6.242,6.242,0,0,1,3.05.718A4.9,4.9,0,0,1,198,23.36a7.742,7.742,0,0,1,.722,3.536V37.673h-3.3V27.292a4.089,4.089,0,0,0-.96-2.895,3.466,3.466,0,0,0-2.67-1.032,4.328,4.328,0,0,0-2.09.5,3.546,3.546,0,0,0-1.446,1.45,4.722,4.722,0,0,0-.524,2.3" transform="translate(-60.251 -4.927)"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MDCashLogo