import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { DinamicDropdown, Idioma, Navbar, Styles, CurrencyOptions, MessageHandler } from '../components'
import { useIsAuthenticated } from "@azure/msal-react";
import AddLogo from '../assets/AddLogo'
import { getToken } from '../fetch';
import XMark from '../assets/XMark';
import { urlBase } from '../authConfig';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import LapizLogo from '../assets/LapizLogo';
import DatePicker from 'react-datepicker';
import AddClientLogo from '../assets/AddClientLogo';
import AddUserLogo2 from '../assets/AddUserLogo2';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import UndoLogo from '../assets/UndoLogo';


const languageOptions = [
    {
        name:'Español',
        id:'es',
    },
    {
        name:'English',
        id:'en',
    },
]

const numberFormatOptions = [
    {
        name:'-1',
        id:'1',
    },
    {
        name:'(1)',
        id:'0',
    },
]

const clientInfoMap = {
    es:'Español',
    en:'English',
    '0':'(1)',
    '1':'-1',
}


const ControlCenterScreen = () => {
    const {language,setToken,accessToken,sessionID} = useStateContext()
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate()


    const [userName,setUserName] = useState('')
    const [userEmail,setUserEmail] = useState('')
    const [userNickname,setUserNickname] = useState('')
    const [userClient,setUserClient] = useState({name:Idioma.paginaControl.cliente[language]})
    const [userRS,setUserRS] = useState({name:Idioma.transacciones.configuracion.empresa[language]})

    const [userPerm1,setUserPerm1] = useState(false)
    const [userPerm2,setUserPerm2] = useState(false)
    const [userPerm3,setUserPerm3] = useState(false)
    const [userPerm4,setUserPerm4] = useState(false)
    const [userPerm5,setUserPerm5] = useState(false)

    const [selectedUserPerms,setSelectedUserPerms] = useState([])
    const [addUserPermTasks,setAddUserPermTasks] = useState([])
    const [editUserPermTasks,setEditUserPermTasks] = useState({clientAccess:{},rsAccess:{}})
    const [creatingUserAccess,setCreatingUserAccess] = useState(false)
    const [createUserAccessClientOptions,setCreateUserAccessClientOptions] = useState([])
    const [createUserAccessClient,setCreateAccessClient] = useState({name:Idioma.paginaControl.cliente[language]})
    const [createUserAccessRSOptions,setCreateUserAccessRSOptions] = useState([])
    const [createUserAccessRS,setCreateAccessRS] = useState({name:Idioma.consolidado[language]})

    const [toastList, setToastList] = useState([])


    const [rsInfo,setRSInfo] = useState([])
    const [creatingRS,setCreatingRS] = useState(false)
    const [createRSTasks,setCreateRSTasks] = useState([])
    const [updateRSTasks,setUpdateRSTasks] = useState({})
    const [addRSName,setAddRSName] = useState('')
    const [addRSAlias,setAddRSAlias] = useState('')
    const [addRSTaxID,setAddRSTaxID] = useState('')
    const [needTransitionToRS,setNeedTransitionToRS] = useState(false)

    const [clientName,setClientName] = useState('')
    const [clientIndustry,setClientIndustry] = useState('')
    const [clientLanguage,setClientLanguage] = useState({name:'Español',id:'es'})
    const [clientNumberFormat,setClientNumberFormat] = useState({name:'(1)',id:'0',})
    const [clientCurrency,setClientCurrency] = useState({name:'MXN',id:'MXN'})
    const inputRef = useRef(null)

    const [openClientModal,setOpenClientModal] = useState(false)
    const [openAddUser,setOpenAddUser] = useState(false)

    const [clientOptions,setClientOptions] = useState([])
    const [rsOptions,setRSOptions] = useState([])

    const [usersData,setUsersData] = useState([])

    const [clientData,setClientData] = useState([])

    const [openEditClientModal,setOpenEditClientModal] = useState(false)
    const [openEditUserModal,setOpenEditUserModal] = useState(false)

    const [selectedInfo,setSelectedInfo] = useState({})

    const [banksData,setBanksData] = useState([])
    const [openAddBank,setOpenAddBank] = useState(false)
    const [openEditBank,setOpenEditBank] = useState(false)
    const [addBankInfo,setAddBankInfo] = useState({name:'',image:null})

    const [uploadFile,setUploadFile] = useState(null)
    const [previewImgUrl, setPreviewimgUrl] = useState("");
    let today = new Date()
    const [dateRange,setDateRangeFilter] = useState([new Date(new Date().setDate(today.getDate() - 30)),today])
    const [startDate, endDate] = dateRange;


    const bankColumns = [
        {
            Header:Idioma.transacciones.configuracion.banco[language],
            accessor:'bank',
        },
    ]

    const columns = [
        {
            Header:Idioma.paginaControl.columnas.name[language],
            accessor:'name',
        },{
            Header:Idioma.paginaControl.columnas.accessTo[language],
            accessor:'accessTo',
        },{
            Header:Idioma.paginaControl.columnas.internoExterno[language],
            accessor:'internalExternal',
        },{
            Header:Idioma.paginaControl.columnas.fechaRegistro[language],
            accessor:'creationDate',
        },{
            Header:Idioma.paginaControl.columnas.lastAct[language],
            accessor:'lastAct',
        },{
            Header:Idioma.paginaControl.columnas.transactions[language],
            accessor:'tCount',
        },/*{
            Header:Idioma.paginaControl.columnas.email[language],
            accessor:'email',
        },{
            Header:Idioma.paginaControl.columnas.rol[language],
            accessor:'role',
        },*/
    ]

    const clientColumns = [
        {
            Header:Idioma.paginaControl.columnas.name[language],
            accessor:'client',
        },{
            Header:Idioma.paginaControl.columnas.externalUsers[language],
            accessor:'externalUsers',
        },{
            Header:Idioma.paginaControl.columnas.transactions[language],
            accessor:'transactions',
        },{
            Header:Idioma.paginaControl.columnas.sesiones[language],
            accessor:'sessions',
        },{
            Header:Idioma.paginaControl.columnas.numRS[language],
            accessor:'numRS',
        },{
            Header:Idioma.paginaControl.columnas.numAccounts[language],
            accessor:'numAccounts',
        },{
            Header:Idioma.paginaControl.columnas.fechaCreacion[language],
            accessor:'creationDate',
        },{
            Header:Idioma.paginaControl.columnas.ultimaEdicion[language],
            accessor:'lastEdition',
        },{
            Header:Idioma.paginaControl.columnas.pago[language],
            accessor:'paymentValid',
        },/*{
            Header:Idioma.paginaControl.columnas.industry[language],
            accessor:'industry',
        },{
            Header:Idioma.paginaControl.columnas.language[language],
            accessor:'language',
        },{
            Header:Idioma.paginaControl.columnas.numberFormat[language],
            accessor:'numberFormat',
        },*/
    ]

    useEffect(()=>{
        if(!isAuthenticated){
            navigate('/')
            return
        }
        getToken().then((token)=>{setToken(token)})
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        fetch(`${urlBase}/isAdmin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                logsInfo:{
                    origin:'ControlCenter',
                    sessionID:sessionID
                }
            })
        })
        .then((response) => response.json())
        .then((aux) => {
            if(aux.role!=='ADMIN'){
                navigate('/error')
            }
        });
        getClients()
        getUsersStats()
        getBanksData()
        
    },[])

    const getData = () => {
        getClients()
        getUsersStats()
    }

    const getUsersStats = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        
        fetch(`${urlBase}/getUsersAsAdmin`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                logsInfo:{
                    origin:'ControlCenter',
                    sessionID:sessionID
                }
            }),
        })
        .then((res)=>res.json())
        .then((aux)=>{  
            console.log('aux users',aux)
            setUsersData(aux)
        })
    }

    const getBanksData = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        
        fetch(`${urlBase}/getBanksAsAdmin`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                logsInfo:{
                    origin:'ControlCenter',
                    sessionID:sessionID
                }
            }),
        })
        .then((res)=>res.json())
        .then((aux)=>{  
            console.log('aux banks',aux)
            setBanksData(aux)
        })
    }

    const getClients = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            logsInfo:{
                origin:'ControlCenter',
                sessionID:sessionID
            }
        }
        if(startDate && endDate){
            let year = startDate.getFullYear().toString()
            let month = startDate.getMonth()+1
            let day = startDate.getDate()
            if(day<10){
                day = '0'+day.toString()
            }else{
                day = day.toString()
            }
            if(month<10){
                month = '0'+month.toString()
            }else{
                month = month.toString()
            }
            let fechaInicial = month+'-'+day+'-'+year
            year = endDate.getFullYear().toString()
            month = endDate.getMonth()+1
            day = endDate.getDate()
            if(day<10){
                day = '0'+day.toString()
            }else{
                day = day.toString()
            }
            if(month<10){
                month = '0'+month.toString()
            }else{
                month = month.toString()
            }
            let fechaFinal = month+'-'+day+'-'+year
            body.startDate = fechaInicial
            body.endDate =  fechaFinal
        }
        fetch(`${urlBase}/getClients`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            console.log('aux clients:',aux)
            let auxClientOptions = []
            for(let i=0;i<aux.length;i++){
                auxClientOptions[i] = {
                    name:aux[i].client,
                    id:aux[i].idClient
                }
            }
            setClientData(aux)
            setClientOptions(auxClientOptions)
            console.log('clients:' ,aux)
        });
    }

    const getRSData = (clientID) => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body={
            clientID: clientID,
        }
        fetch(`${urlBase}/getRSAsAdmin`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = [{name:Idioma.consolidado[language]}]
            for(let i=0;i<aux.length;i++){
                newData[i+1]={
                    name:aux[i].rsAlias,
                    id:aux[i].idRS
                }
            }
            setRSOptions(newData)
            setCreateUserAccessRSOptions(newData)
            setRSInfo(aux)
            setNeedTransitionToRS(aux.length>0 ? false : true)

        })
    }

    const handleAddNewUser = () => {//fix
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": userClient.id,
            'email': userEmail,
            'name':userName,
            'access1':userPerm1===true ? 1:0,
            'access2':userPerm2===true ? 1:0,
            'access3':userPerm3===true ? 1:0,
            'access4':userPerm4===true ? 1:0,
            'access5':userPerm5===true ? 1:0,
            'nickname':userNickname,
            logsInfo:{
                origin:'ControlCenter',
                sessionID:sessionID
            }
        }
        if(userRS){
            body.idRS = userRS.id
        }
        fetch(`${urlBase}/createUserAsAdmin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            
            handleCloseModal()
            getUsersStats()
        });

    }

    const handleAddNewBank = () => {
        const formData = new FormData();
        formData.append('name',addBankInfo.name)
        formData.append('bank_image',uploadFile)
 
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        fetch(`${urlBase}/createBank`, {
          method: 'POST',
          body: formData,
          headers: headers
        })
        .then((response) => response.json())
        .then((aux) => {
            getBanksData()
            handleCloseModal()
        })
    }

    const handleUpdateBank = () => {
        console.log('selectedInfo',selectedInfo)
        const formData = new FormData();
        formData.append('idBank',selectedInfo.id)
        formData.append('name',selectedInfo.name)
        formData.append('isActive',selectedInfo.isActive==true?1:0)
        formData.append('bank_image',uploadFile)
        console.log(formData)
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        fetch(`${urlBase}/updateBank`, {
          method: 'POST',
          body: formData,
          headers: headers
        })
        .then((response) => response.json())
        .then((aux) => {
            getBanksData()
            handleCloseModal()
        })
    }
  

    const handleAddNewClient = () => {//done
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        fetch(`${urlBase}/createClient`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'client':clientName,
                'industry':clientIndustry,
                'currency':clientCurrency.id,
                'language':clientLanguage.id,
                'numberFormat':clientNumberFormat.id,
                logsInfo:{
                    origin:'ControlCenter',
                    sessionID:sessionID
                }
            })
        })
        .then((response) => response.json())
        .then((aux) => {
            handleCloseModal()
        });
    }

    const handleUpdateClientImage = () => {
        const formData = new FormData();
        let upperName = selectedInfo.client.toUpperCase()
        formData.append('name',upperName)
        formData.append('bank_image',uploadFile)
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        fetch(`${urlBase}/updateClientImage`, {
            method: 'POST',
            body: formData,
            headers: headers
          })
          .then((response) => response.json())
          .then((aux) => {
  
          })
    }

    const handleUpdateClient = () => {

        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        if(uploadFile){
            handleUpdateClientImage()
        }
        
        if(createRSTasks.length>0 || Object.keys(updateRSTasks).length>0){
            fetch(`${urlBase}/updateRSAsAdmin`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    'idClient':selectedInfo.idClient,
                    'createRSTasks':createRSTasks,
                    'updateRSTasks':updateRSTasks,
                    'needTransitionToRS':needTransitionToRS,
                    logsInfo:{
                        origin:'ControlCenter',
                        sessionID:sessionID
                    }
                })
            })
            .then((response) => response.json())
            .then((aux) => {
                console.log('response',aux)
            });
        }
        
        fetch(`${urlBase}/updateClient`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'idClient':selectedInfo.idClient,
                'client':selectedInfo.client,
                'industry':selectedInfo.industry,
                'currency':selectedInfo.currency.id,
                'language':selectedInfo.language.id,
                'numberFormat':selectedInfo.numberFormat.id,
                'paymentValid':selectedInfo.paymentValid.id,
                logsInfo:{
                    origin:'ControlCenter',
                    sessionID:sessionID
                }
            })
        })
        .then((response) => response.json())
        .then((aux) => {
            getClients()
            handleCloseModal()
        });
    }

    const handleUpdateUser = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            'idUser':selectedInfo.idUser,
            'email': selectedInfo.email,
            'name':selectedInfo.name,
            'editTasks':editUserPermTasks,
            'addTasks':addUserPermTasks,
            logsInfo:{
                origin:'ControlCenter',
                sessionID:sessionID
            }
        }
        console.log('update body',body)
        fetch(`${urlBase}/updateUserAsAdmin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            
            handleCloseModal()
            getUsersStats()
        });
    }

    const getUserAccesses = (user) => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        fetch(`${urlBase}/getUserAccess`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'idUser':user.idUser,
                logsInfo:{
                    origin:'ControlCenter',
                    sessionID:sessionID
                }
            })
        })
        .then((response) => response.json())
        .then((aux) => {

            
            let auxSort = [...aux.clientPerms,...aux.rsPerms]
            auxSort.sort(function(a,b){
                let keyA = a.idClient
                let keyB = b.idClient
                if(keyA > keyB) return 1
                if(keyA < keyB) return -1
                return 0 
            })
            console.log('auxSort',auxSort)
            setSelectedUserPerms(auxSort)
            
        });
    }

    const handleUserPerm1Change = () => {
        setUserPerm1((prev)=>!prev)
    }

    const handleUserPerm2Change = () => {
        if(userPerm2===true){
            setUserPerm2(prev=>!prev)
            setUserPerm3(false)
        }else if(userPerm2===false){
            setUserPerm2(prev=>!prev)
        }
    }

    const handleUserPerm3Change = () => {
        if(userPerm3===true){
            setUserPerm3(prev=>!prev)
        }else if(userPerm3===false){
            setUserPerm3(prev=>!prev)
            setUserPerm2(true)
        }
        
    }
    const handleUserPerm4Change = () => {
        setUserPerm4((prev)=>!prev)
    }

    const handleUserPerm5Change = () => {
        setUserPerm5((prev)=>!prev)
    }


    const handleOpenNewUser = () => {
        setOpenAddUser(true)
    }

    const handleOpenNewClient = () => {
        setOpenClientModal(true)
    }

    const handleOpenNewBank = () => {
        setOpenAddBank(true)
    }

    const handleCloseModal = () => {
        setOpenAddUser(false)
        setOpenClientModal(false)
        setOpenEditClientModal(false)
        setOpenEditUserModal(false)
        setOpenAddBank(false)
        setOpenEditBank(false)
        setAddBankInfo({name:'',image:null})
        setPreviewimgUrl("")
        setUploadFile(null)
        setSelectedInfo({})
        setSelectedUserPerms([])
        setUpdateRSTasks({})
        setCreateRSTasks([])
        setEditUserPermTasks({clientAccess:{},rsAccess:{}})
        setAddUserPermTasks([])
        setUserClient({name:Idioma.paginaControl.cliente[language]})
        setUserRS({name:Idioma.transacciones.configuracion.empresa[language]})
        setUserEmail('')
        setUserName('')
        setUserNickname('')
        stopCreatingUserAccess()
        stopCreatingRS()
        setRSInfo([])
    }

    const handleUserClientChange = (value) => {
        setUserClient(value)
        getRSData(value.id)
    }

    const genCell = (row,cell) => {
        if(cell==='name' && row[cell]){
            return <td className='px-[10px] capitalize'>{row[cell].toLowerCase()}</td>
        }
        if(cell==='lastAct' && row[cell]){
            return <td className='px-[10px]'>{moment(row[cell]).locale(language).format('lll')}</td>
        }
        if(cell==='numberFormat'){
            return <td className='px-[10px] text-right'>{clientInfoMap[row[cell]]}</td>
        }
        if((cell==='transactions' || cell==='externalUsers'|| cell==='numAccounts'|| cell==='numRS' || cell==='sessions' || cell==='accessTo' || cell==='tCount')){
            return <td className='px-[10px] text-right'>{isNaN(parseInt(row[cell]).toLocaleString('en-US')) ?  '0' : parseInt(row[cell]).toLocaleString('en-US')}</td>
        }
        if(cell==='language'){
            return <td className='px-[10px] '>{clientInfoMap[row[cell]]}</td>
        }
        if((cell==='lastEdition' || cell==='creationDate') && row[cell]){
            return <td className='px-[10px]'>{moment(row[cell].slice(0,10)).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</td>
        }
        if(cell==='internalExternal'){
            return <td className='px-[10px] '>{Idioma.paginaControl.columnas[row[cell]][language]}</td>
        }
        if(cell==='paymentValid'){
            return <td className='px-[10px] '>{row[cell]===true ?Idioma.paginaControl.pagado[language]:Idioma.paginaControl.noPagado[language]}</td>
        }
        return <td className='px-[10px]'>{row[cell]}</td>
    }

    const handleStartEditingClient = (client) => {
        getRSData(client.idClient)
        setOpenEditClientModal(true)
        let aux = {...client}
        aux.language = {name:clientInfoMap[client.language],id:client.language}
        aux.currency = {name:client.currency,id:client.currency}
        aux.numberFormat = {name:clientInfoMap[client.numberFormat],id:client.numberFormat}
        aux.paymentValid = aux.paymentValid===true ? {name:Idioma.paginaControl.pagado[language],id:1} : {name:Idioma.paginaControl.noPagado[language],id:0}
        setSelectedInfo(aux)
    }

    const handleStartEditingUser = (user) => {
        setOpenEditUserModal(true)
        setSelectedInfo(user)
        getUserAccesses(user)
    }

    const handleUserNameChange = (value) => {
        setSelectedInfo({...selectedInfo,name:value})
    }

    const handleUserEmailChange = (value) => {
        setSelectedInfo({...selectedInfo,email:value})
    }

    const handleClientLanguageChange = (value) => {
        setSelectedInfo({...selectedInfo,language:value})
    }
    
    const handleClientCurrencyChange = (value) => {
        setSelectedInfo({...selectedInfo,currency:value})
    }
    
    const handleClientNumberFormatChange = (value) => {
        setSelectedInfo({...selectedInfo,numberFormat:value})
    }

    const handleClientPaymentValidChange = (value) => {
        setSelectedInfo({...selectedInfo,paymentValid:value})
    }

    const handleUserPermChange = (permNumber,access,permState) => {
        let aux = [...selectedUserPerms]
        if(access.idRS && access.idAccess){
            setEditUserPermTasks((prev)=>( {...prev,rsAccess:{...prev.rsAccess,[access.idAccess]:{...prev.rsAccess[access.idAccess],[permNumber]:permState} }} ))
        }else if(!access.idRS && access.idAccess){
            setEditUserPermTasks((prev)=>( {...prev,clientAccess:{...prev.clientAccess,[access.idAccess]:{...prev.clientAccess[access.idAccess],[permNumber]:permState} }} ))
            for(let i=0;i<aux.length && permState===true;i++){
                if(aux[i].idClient===access.idClient && aux[i].idRS){
                    handleUserPermChange(permNumber,aux[i],permState)
                }
            }
        }
        let auxIndex = selectedUserPerms.indexOf(access)
        setSelectedUserPerms((prev)=>prev.map((acc,index)=>{
                if(index===auxIndex){
                    return {...acc,[permNumber]:permState}
                }else{
                    return acc
                }
            })
        )
        if(permNumber==='access3' && permState===true && access.access2===false){
            handleUserPermChange('access2',access,true)
        }else if(permNumber==='access2' && permState===false && access.access3===true){
            handleUserPermChange('access3',access,false)
        }
    }

    const handleUserPermDelete = (access) => {
        if(!access.idAccess){
            let aux = [...addUserPermTasks]
            for(let i=0;i<aux.length;i++){
                if(access.idClient===aux[i].idClient && !access.idRS && !aux[i].idRS){
                    aux.splice(i,1)
                }else if(access.idClient===aux[i].idClient && access.idRS && aux[i].idRS && access.idRS===aux[i].idRS){
                    aux.splice(i,1)
                }
            }
            setAddUserPermTasks(aux)
        }
        else if(access.idRS){
            setEditUserPermTasks((prev)=>( {...prev,rsAccess:{...prev.rsAccess,[access.idAccess]:{...prev.rsAccess[access.idAccess],delete:true} }} ))
        }else{
            setEditUserPermTasks((prev)=>( {...prev,clientAccess:{...prev.clientAccess,[access.idAccess]:{...prev.clientAccess[access.idAccess],delete:true} }} ))
        }
        let auxIndex = selectedUserPerms.indexOf(access)
        setSelectedUserPerms((prev)=>prev.map((acc,index)=>{
            if(index===auxIndex){
                return {...acc,delete:true}
            }else{
                return acc
            }
            })
        )
    }

    const handleUserPermUndoDelete = (access) => {
        if(!access.idAccess){
            setAddUserPermTasks((prev)=>[...prev,access])
        }else if(access.idRS){
            setEditUserPermTasks((prev)=>( {...prev,rsAccess:{...prev.rsAccess,[access.idAccess]:{...prev.rsAccess[access.idAccess],delete:false} }} ))
        }else{
            setEditUserPermTasks((prev)=>( {...prev,clientAccess:{...prev.clientAccess,[access.idAccess]:{...prev.clientAccess[access.idAccess],delete:false} }} ))
        }
        let auxIndex = selectedUserPerms.indexOf(access)
        setSelectedUserPerms((prev)=>prev.map((acc,index)=>{
            if(index===auxIndex){
                return {...acc,delete:false}
            }else{
                return acc
            }
            })
        )
    }

    const startCreatingRS = () => {
        setCreatingRS(true)
    }

    const stopCreatingRS = () => {
        setCreatingRS(false)
        setAddRSAlias('')
        setAddRSName('')
        setAddRSTaxID('')
    }

    const createRS = () => {
        setCreateRSTasks((prev)=>[...prev,{rsAlias:addRSAlias,rsName:addRSName,rsTaxID:addRSTaxID}])
        setRSInfo((prev)=>[...prev,{rsName:addRSName,rsAlias:addRSAlias,rsTaxID:addRSTaxID}])
        stopCreatingRS()
    }

    const deleteRSBeforeCreation = (rs) => {
        let aux = [...createRSTasks]
        let auxIndex = createRSTasks.indexOf(rs)
        aux.splice(auxIndex,1)
        setCreateRSTasks(aux)
        let auxIndex2 = rsInfo.indexOf(rs)

        setRSInfo((prev)=>prev.map((rsDatum,index)=>{
            if(index===auxIndex2){
                return {...rsDatum,delete:true}
            }else{
                return rsDatum
            }
            })
        )
        
    }

    const undoRSDelete = (rs) => {
        setCreateRSTasks((prev)=>[...prev,rs])
        let auxIndex = rsInfo.indexOf(rs)
        setRSInfo((prev)=>prev.map((rsDatum,index)=>{
            if(index===auxIndex){
                return {...rsDatum,delete:false}
            }else{
                return rsDatum
            }
            })
        )
    }

    const handleRSChange = (rs,value,update) => {
        if(rs.idRS){
            setUpdateRSTasks((prev)=>({...prev,[rs.idRS]:{...prev[rs.idRS],[update]:value}}))
        }else{
            let auxIndex = rsInfo.indexOf(rs)
            setCreateRSTasks((prev)=>prev.map((rsDatum,index)=>{
                if(index===auxIndex){
                    return {...rsDatum,[update]:value}
                }else{
                    return rsDatum
                }
            }))
        }

    }


    const startCreatingUserAccess = () => {
        setCreateUserAccessClientOptions([...clientOptions])
        setCreatingUserAccess(true)
    }

    const stopCreatingUserAccess = () => {
        setUserPerm1(false)
        setUserPerm2(false)
        setUserPerm3(false)
        setUserPerm4(false)
        setUserPerm5(false)
        setCreateAccessClient({name:Idioma.paginaControl.cliente[language]})
        setCreateAccessRS({name:Idioma.consolidado[language]})
        setCreatingUserAccess(false)
        setCreateUserAccessRSOptions([])
    }

    const createUserAccess = () => {
        let aux = {
            idClient:createUserAccessClient.id,
            client:createUserAccessClient.name,
            access1:userPerm1,
            access2:userPerm2,
            access3:userPerm3,
            access4:userPerm4,
            access5:userPerm5,
        }
        if(createUserAccessRS.id){
            aux.idRS=createUserAccessRS.id
            aux.rsName=createUserAccessRS.name
            for(let i=0;i<selectedUserPerms.length;i++){
                if(aux.idRS===selectedUserPerms[i].idRS){
                    newToast('error', Idioma.paginaControl.errores.accesoExistente[language], `${aux.client}-${aux.rsName}`)
                    return
                }
            }
        }else{
            for(let i=0;i<selectedUserPerms.length;i++){
                if(!selectedUserPerms[i].idRS && aux.idClient===selectedUserPerms[i].idClient){
                    newToast('error', Idioma.paginaControl.errores.accesoExistente[language], `${aux.client}`)
                    return
                }
            }

        }
        setAddUserPermTasks((prev)=>[...prev,aux])
        setSelectedUserPerms((prev)=>[...prev,aux])
        stopCreatingUserAccess()
    }

    const handleStartEditingBank = (bank) => {
        let aux = {...bank}
        setSelectedInfo({
            id:aux.idBank,
            name:aux.bank,
            isActive:aux.isActive,
        })
        setOpenEditBank(true)
    }

    const handleCreateAccessClientChange = (value) => {
        setCreateAccessClient(value)
        setCreateAccessRS({name:Idioma.consolidado[language]})
        getRSData(value.id)
    }


    const handleFileChange = async (event) => {
        setUploadFile(event.target.files[0])
        setPreviewimgUrl(URL.createObjectURL(event.target.files[0]))
    }

    const uploadFiles = (event) => {
        event.preventDefault();
        inputRef.current.click()
      }

    const newToast = (type, title, message) => {
        let toastProperties = []
        toastProperties = {
          'id': toastList.length+1,
          'alertType': type,
          'alertTitle': title,
          'alertMessage': message
        }
        setToastList((prev)=>[...prev, toastProperties])
      }

    return (
        <div className=' h-[100vh] relative'>
            <div className='bg-white h-[12%] z-[99] sticky top-0 '>
                <Navbar/>
            </div>
            <MessageHandler toastList={toastList} setToastList={setToastList}/>

            {openAddUser && 
            <div className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[50%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]">
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-[52px]'><span className=' 2xl:text-h5-2xl text-h5-2xl'>{Idioma.administracion.anadirUsuarios[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.paginaControl.cliente[language]}</span>
                            <DinamicDropdown options={clientOptions} selected={userClient} setSelected={handleUserClientChange} className="w-72 text-p9-2xl px-2 py-2"/>
                        </div>
                        {rsOptions.length>1 && 
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.transacciones.configuracion.empresa[language]}</span>
                            <DinamicDropdown options={rsOptions} selected={userRS} setSelected={setUserRS} className="w-72 text-p9-2xl px-2 py-2"/>
                        </div>}
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput}  value={userName} onChange={(e)=>setUserName(e.target.value)}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.correo[language]}</span><input className={Styles.defaultInput}  value={userEmail} onChange={(e)=>setUserEmail(e.target.value)} type='email'></input>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.rol[language]}</span><input className={Styles.defaultInput} value={userNickname} onChange={(e)=>setUserNickname(e.target.value)}></input>
                        </div>
                        <div className='flex mt-[15px]'>
                            <p className=' text-h10-2xl'>{Idioma.administracion.modalEditar.permisos[language]}</p>
                        </div>
                        <div className='grid grid-cols-2 gap-x-4 mt-4'>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso1[language]}</span>
                                <div>
                                    <label for="default-toggle1" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm1} onClick={()=>{handleUserPerm1Change()}}  id="default-toggle1" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso2[language]}</span>
                                <div>
                                    <label for="default-toggle2" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm2} onClick={()=>{handleUserPerm2Change()}}  id="default-toggle2" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso3[language]}</span>
                                <div>
                                    <label for="default-toggle3" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm3} onClick={()=>{handleUserPerm3Change()}}  id="default-toggle3" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso4[language]}</span>
                                <div>
                                    <label for="default-toggle4" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm4} onClick={()=>{handleUserPerm4Change()}}  id="default-toggle4" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso5[language]}</span>
                                <div>
                                    <label for="default-toggle5" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm5} onClick={()=>{handleUserPerm5Change()}}  id="default-toggle5" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='flex justify-end items-center gap-4'>
                            <button className={Styles.saveButton} onClick={()=>handleAddNewUser()}>
                                {Idioma.administracion.anadirUsuarios[language]}
                            </button>
                            <button className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            {openEditUserModal && 
            <div className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className="bg-white w-[90%] p-[40px] z-10 absolute top-[40%] mt-20  left-[50%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]">
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-[40px]'><span className=' 2xl:text-h5-2xl text-h5-2xl'>{Idioma.administracion.modalEditar.titulo[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput}  value={selectedInfo.name} onChange={(e)=>handleUserNameChange(e.target.value)}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.correo[language]}</span><input className={Styles.defaultInput}  value={selectedInfo.email} onChange={(e)=>handleUserEmailChange(e.target.value)} type='email'></input>
                        </div>
                        <div class="h-full border-separate overflow-visible rounded-xl  border-solid flex flex-col mb-[40px]">
                            <table class="w-full table-fixed">
                                <thead class="sticky top-0 text-h11-2xl">
                                <tr className='items-end '>
                                <th><span className='flex items-end justify-start  h-[60px]'>{Idioma.paginaControl.cliente[language]}</span></th>
                                <th><span className='flex items-end justify-start  h-[60px]'>{Idioma.transacciones.configuracion.empresa[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso1[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso2[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso3[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso4[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso5[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso6[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso7[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso8[language]}</span></th>
                                <th><span className='flex items-end justify-center h-[60px]'>{Idioma.administracion.modalEditar.permiso9[language]}</span></th>
                                <th className='w-[40px]'></th>
                                </tr>
                                </thead>
                            </table>
                            
                            <div class="flex-1 overflow-y-auto max-h-52 mt-[20px] ">
                                <table class="w-full table-fixed ">
                                <tbody className=' text-p9-2xl '>
                                    {selectedUserPerms.map((userPerm)=>(
                                        <tr class="space-x-2 border-b-1 border-gray-5 h-[50px]">
                                            <td className=''>{userPerm.client}</td>
                                            <td className=''>{userPerm.rsName || Idioma.consolidado[language]}</td>
                                        <td className=''>
                                            <div className='flex justify-center'>

                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-1`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access1} onClick={()=>{handleUserPermChange('access1',userPerm,!userPerm.access1)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-1`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>

                                        </td>
                                        <td className=''>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-2`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access2} onClick={()=>{handleUserPermChange('access2',userPerm,!userPerm.access2)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-2`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                        <td>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-3`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access3}  onClick={()=>{handleUserPermChange('access3',userPerm,!userPerm.access3)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-3`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                        <td>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-4`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access4} onClick={()=>{handleUserPermChange('access4',userPerm,!userPerm.access4)}} id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-4`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                        <td>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-5`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access5}  onClick={()=>{handleUserPermChange('access5',userPerm,!userPerm.access5)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-5`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                        <td>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-6`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access6}  onClick={()=>{handleUserPermChange('access6',userPerm,!userPerm.access6)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-6`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                        <td>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-7`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access7}  onClick={()=>{handleUserPermChange('access7',userPerm,!userPerm.access7)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-7`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                        <td>
                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-8`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access8}  onClick={()=>{handleUserPermChange('access8',userPerm,!userPerm.access8)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-8`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                        </div>
                                        </td>
                                        <td>

                                        <div className='flex justify-center'>
                                            <div>
                                                <label for={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-9`} class={"inline-flex relative items-center cursor-pointer "}>
                                                    <input type="checkbox" checked={userPerm.access9}  onClick={()=>{handleUserPermChange('access9',userPerm,!userPerm.access9)}}  id={`default-toggle-${userPerm.idClient}${userPerm.idRS ? `-${userPerm.idRS}`:''}-9`} class="sr-only peer"/>
                                                    <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                </label>
                                            </div>
                                        </div>
                                        </td>
                                        {userPerm.delete===true ?
                                        <td className='w-[40px] cursor-pointer' onClick={()=>handleUserPermUndoDelete(userPerm)}>
                                            <div className='flex justify-center'>
                                        <UndoLogo/></div>
                                        </td> : 
                                        <td className='w-[40px]  cursor-pointer ' onClick={()=>handleUserPermDelete(userPerm)}>
                                            <div className='flex scale-[66%] justify-center'>

                                            <ThrashCanLogo fill={'#000'}/>
                                            </div>
                                        </td>}
                                        
                                        </tr>
                                    ))}

                                </tbody>
                                </table>
                               
                            </div>
                            <div className='flex-1 pr-[6px]'>
                            <table class="w-full table-fixed ">
                                <tbody className=' text-p9-2xl '>
                                    
                                    <tr class=" border-b-1 border-gray-5 h-[50px]">
                                    {creatingUserAccess ? <>
                                            <td className='pr-2 '>
                                                
                                                <DinamicDropdown options={createUserAccessClientOptions} selected={createUserAccessClient} setSelected={handleCreateAccessClientChange} className="w-full text-p9-2xl px-2 py-2"/>
                                            </td>
                                            {createUserAccessRSOptions.length>1 ? 
                                            <td className='pr-2 '>
                                                <DinamicDropdown options={createUserAccessRSOptions} selected={createUserAccessRS} setSelected={setCreateAccessRS} className="w-full text-p9-2xl px-2 py-2"/>
                                            </td>:<td className='pr-2'>NA</td>}

                                            <td className=''>
                                                <div className='flex justify-center'>

                                                    <div>
                                                        <label for={`default-toggle1`} class={"inline-flex relative items-center cursor-pointer "}>
                                                            <input type="checkbox" checked={userPerm1} onClick={()=>handleUserPerm1Change()}  id={`default-toggle1`} class="sr-only peer"/>
                                                            <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                        </label>
                                                    </div>
                                                </div>

                                            </td>
                                            <td className=''>
                                                <div className='flex justify-center'>
                                                    <div>
                                                        <label for={`default-toggle2`} class={"inline-flex relative items-center cursor-pointer "}>
                                                            <input type="checkbox" checked={userPerm2} onClick={()=>handleUserPerm2Change()}  id={`default-toggle2`} class="sr-only peer"/>
                                                            <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center'>
                                                    <div>
                                                        <label for={`default-toggle3`} class={"inline-flex relative items-center cursor-pointer "}>
                                                            <input type="checkbox" checked={userPerm3}  onClick={()=>handleUserPerm3Change()}  id={`default-toggle3`} class="sr-only peer"/>
                                                            <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center'>
                                                    <div>
                                                        <label for={`default-toggle4`} class={"inline-flex relative items-center cursor-pointer "}>
                                                            <input type="checkbox" checked={userPerm4} onClick={()=>handleUserPerm4Change()} id={`default-toggle4`} class="sr-only peer"/>
                                                            <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='flex justify-center'>
                                                    <div>
                                                        <label for={`default-toggle5`} class={"inline-flex relative items-center cursor-pointer "}>
                                                            <input type="checkbox" checked={userPerm5}  onClick={()=>handleUserPerm5Change()}  id={`default-toggle5`} class="sr-only peer"/>
                                                            <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            
                                            <td className='w-[40px] '>
                                                <button className='flex justify-center items-center w-full' disabled={!createUserAccessClient.id} onClick={()=>createUserAccess()}>

                                                <AddLogo fill={'#000'}/>
                                                </button>
                                            </td>
                                            </>
                                        :
                                        <td className='flex justify-start gap-x-2 h-[50px] items-center text-p8-2xl cursor-pointer' onClick={()=>{startCreatingUserAccess()}}>
                                            <AddLogo fill={"#000"}/>{Idioma.paginaControl.agregarAcceso[language]}
                                        </td>
                                    }
                                        
                                    </tr>
                                </tbody>
                                </table>
                                </div>

                            </div>
                       
                        <div className='flex justify-end items-center gap-4'>
                            <div className={Styles.saveButton} onClick={()=>handleUpdateUser()}>
                                {Idioma.transacciones.anadirTransaccionModal.guardar[language]}
                            </div>
                            <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openClientModal && 
            <div className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[50%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]">
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-12'><span className='2xl:text-h5-2xl text-[26px] font-bold'>{Idioma.administracion.anadirCliente[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput}  value={clientName} onChange={(e)=>setClientName(e.target.value)}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.industria[language]}</span><input className={Styles.defaultInput}  value={clientIndustry} onChange={(e)=>setClientIndustry(e.target.value)}></input>
                        </div>
                        
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.idioma[language]}</span>
                            <DinamicDropdown options={languageOptions} selected={clientLanguage} setSelected={setClientLanguage} className="w-72 text-p9-2xl px-2 py-2"/>
                        </div>

                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.moneda[language]}</span>
                            <DinamicDropdown options={CurrencyOptions} selected={clientCurrency} setSelected={setClientCurrency} className="w-72 text-p9-2xl px-2 py-2"/>
                            
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.negativos[language]} (1)/-1</span>
                            <DinamicDropdown options={numberFormatOptions} selected={clientNumberFormat} setSelected={setClientNumberFormat} className="w-72 text-p9-2xl px-2 py-2"/>
                            
                        </div>
                        <div className='flex justify-end items-center gap-4 mt-10'>
                            <div className={Styles.saveButton} onClick={()=>handleAddNewClient()}>
                                {Idioma.administracion.anadirCliente[language]}
                            </div>
                            <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openEditClientModal && 
            <div className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className="bg-white w-[90%] p-[40px] z-10 absolute top-[40%] mt-20  left-[50%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]">
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>
                            <div className='flex mb-12'><span className='2xl:text-h5-2xl text-[26px] font-bold'>{Idioma.paginaControl.editarCliente[language]}</span></div>
                            <div className='flex'>
                               
                                <div className='flex mb-2 items-center'>
                                    <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput + ' mr-2'}  value={selectedInfo.client} onChange={(e)=>setSelectedInfo({...selectedInfo,client:e.target.value})}></input>
                                </div>
                                <div className='flex mb-2 items-center'>
                                    <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.industria[language]}</span><input className={Styles.defaultInput+ ' mr-2'}  value={selectedInfo.industry} onChange={(e)=>setSelectedInfo({...selectedInfo,industry:e.target.value})}></input>
                                </div>
                                
                                <div className='flex mb-2 items-center'>
                                    <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.idioma[language]}</span>
                                    <DinamicDropdown options={languageOptions} selected={selectedInfo.language} setSelected={handleClientLanguageChange} className="w-72 text-p9-2xl px-2 py-2 mr-2"/>
                                </div>
                                <button onClick={uploadFiles}><AddClientLogo/></button>
                                <input ref={inputRef} style={{'display': 'none'}} type="file" accept="image/*" onChange={handleFileChange}/>
                                {previewImgUrl && (
                                    <div className="image_wrapper">
                                    <img src={previewImgUrl} className=" h-8 object-contain"/>
                                    </div>
                                )}
                            </div>
                            <div className='flex'>

                                <div className='flex mb-2 items-center'>
                                    <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.moneda[language]}</span>
                                    <DinamicDropdown options={CurrencyOptions} selected={selectedInfo.currency} setSelected={handleClientCurrencyChange} className="w-72 text-p9-2xl px-2 py-2 mr-2"/>
                                    
                                </div>
                                <div className='flex mb-2 items-center'>
                                    <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.negativos[language]} (1)/-1</span>
                                    <DinamicDropdown options={numberFormatOptions} selected={selectedInfo.numberFormat} setSelected={handleClientNumberFormatChange} className="w-72 text-p9-2xl px-2 py-2 mr-2"/>
                                    
                                </div>
                                <div className='flex mb-2 items-center'>
                                    <span className='text-p5-2xl font-bold w-36'>{Idioma.paginaControl.columnas.pago[language]}</span>
                                    <DinamicDropdown options={[{name:Idioma.paginaControl.pagado[language],id:1},{name:Idioma.paginaControl.noPagado[language],id:0}]} selected={selectedInfo.paymentValid} setSelected={handleClientPaymentValidChange} className="w-72 text-p9-2xl px-2 py-2 mr-2"/>
                                    
                                </div>
                                
                            </div>
                            <div className='flex'>
                                <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.fotoPerfil[language]}</span>

                                <img src={`https://cicofiles.blob.core.windows.net/webimages/${selectedInfo.client.toUpperCase()} ISO.svg`} className='h-8 object-contain'/>
                            </div>

                            <div class="h-full border-separate overflow-visible rounded-xl  border-solid flex flex-col mb-[40px]">
                            <table class="w-full table-fixed">
                                <thead class="sticky top-0 text-h11-2xl">
                                <tr className='items-end '>
                                <th><span className='flex items-end justify-start  h-[60px]'>{Idioma.paginaControl.columnas.name[language]}</span></th>
                                <th><span className='flex items-end justify-start  h-[60px]'>{Idioma.paginaControl.columnas.alias[language]}</span></th>
                                <th><span className='flex items-end justify-start  h-[60px]'>{Idioma.paginaControl.columnas.taxID[language]}</span></th>
                                <th className='w-[40px] '></th>
                                </tr>
                                </thead>
                            </table>
                            
                            <div class="flex-1 overflow-y-auto max-h-52 mt-[20px] ">
                                <table class="w-full table-fixed ">
                                <tbody className=' text-p9-2xl '>
                                    {rsInfo.map((rsDatum)=>(
                                        <tr class="space-x-2 border-b-1 border-gray-5 h-[50px]">
                                            <td className=''><input className={Styles.defaultInput} defaultValue={rsDatum.rsName} onBlur={(e)=>handleRSChange(rsDatum,e.target.value,'rsName')}></input></td>
                                            <td className=''><input className={Styles.defaultInput} defaultValue={rsDatum.rsAlias} onBlur={(e)=>handleRSChange(rsDatum,e.target.value,'rsAlias')}></input></td>
                                            <td className=''><input className={Styles.defaultInput} defaultValue={rsDatum.rsTaxID} onBlur={(e)=>handleRSChange(rsDatum,e.target.value,'rsTaxID')}></input></td>
                                                {!rsDatum.idRS ? <>
                                                    {rsDatum.delete===true ?
                                                        <td className='w-[40px] cursor-pointer' onClick={()=>undoRSDelete(rsDatum)}>
                                                            <div className='flex justify-center'>
                                                        <UndoLogo/></div>
                                                        </td> : 
                                                    <td className='w-[40px] cursor-pointer' onClick={()=>deleteRSBeforeCreation(rsDatum)}>
                                                        <div className='flex scale-[66%] justify-center'>
                                                        <ThrashCanLogo fill={'#000'}/>
                                                        </div>
                                                    </td>}
                                                    </>:
                                                <td className='w-[40px] '></td>}
                                            </tr>
                                    ))}

                                </tbody>
                                </table>
                               
                            </div>
                            <div className='flex-1 pr-[6px]'>
                            <table class="w-full table-fixed ">
                                <tbody className=' text-p9-2xl '>
                                    
                                    <tr class=" border-b-1 border-gray-5 h-[50px]">
                                    {creatingRS ? <>
                                            <td className=''>
                                                <input className={Styles.defaultInput} placeholder={Idioma.paginaControl.columnas.name[language]}  value={addRSName} onChange={(e)=>setAddRSName(e.target.value)}></input>
                                            </td>
                                            <td className=''>
                                                <input className={Styles.defaultInput} placeholder={Idioma.paginaControl.columnas.alias[language]}  value={addRSAlias} onChange={(e)=>setAddRSAlias(e.target.value)}></input>
                                            </td>
                                            <td className=''>
                                                <input className={Styles.defaultInput} placeholder={Idioma.paginaControl.columnas.taxID[language]}  value={addRSTaxID} onChange={(e)=>setAddRSTaxID(e.target.value)}></input>
                                            </td>
                                            
                                            <td className='w-[40px] '>
                                                <button className='flex justify-center items-center w-full ' onClick={()=>createRS()}>

                                                <AddLogo fill={'#000'}/>
                                                </button>
                                            </td>
                                            </>
                                        :
                                        <td className='flex justify-start gap-x-2 h-[50px] items-center text-p8-2xl cursor-pointer' onClick={()=>{startCreatingRS()}}>
                                            <AddLogo fill={"#000"}/>{rsInfo.length===0?Idioma.paginaControl.transicionAEmpresas[language]:Idioma.paginaControl.agregarEmpresa[language]}
                                        </td>
                                    }
                                        
                                    </tr>
                                </tbody>
                                </table>
                                </div>

                            </div>

                            <div className='flex justify-end items-center gap-4 mt-10'>
                                <div className={Styles.saveButton} onClick={()=>handleUpdateClient()}>
                                    {Idioma.transacciones.anadirTransaccionModal.guardar[language]}
                                </div>
                                <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                    {Idioma.catalogoCuentas.cancelar[language]}
                                </div>
                            </div>

                    </div>
                </div>
            </div>}
            {openAddBank && 
            <div className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[50%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]">
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-12'><span className='2xl:text-h5-2xl text-[26px] font-bold'>{Idioma.paginaControl.anadirBanco.titulo[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput}  value={addBankInfo.name} onChange={(e)=>setAddBankInfo({...addBankInfo,name:e.target.value})}></input>
                        </div>

                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.paginaControl.anadirBanco.imagen[language]}</span><input type="file" accept="image/*" onChange={handleFileChange}/>
                        </div>

                        {previewImgUrl && (
                            <div className="image_wrapper">
                            <img src={previewImgUrl} className="h-6 text-[20px] object-scale-down"/>
                            </div>
                        )}
                        
                        <div className='flex justify-end items-center gap-4 mt-10'>
                            <div className={Styles.saveButton} onClick={()=>handleAddNewBank()}>
                                {Idioma.transacciones.anadirTransaccionModal.guardar[language]}
                            </div>
                            <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openEditBank && 
            <div className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[50%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]">
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-12'><span className='2xl:text-h5-2xl text-[26px] font-bold'>{Idioma.paginaControl.anadirBanco.tituloEditar[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput} disabled value={selectedInfo.name} ></input>
                        </div>

                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.paginaControl.anadirBanco.imagen[language]}</span><input type="file" accept="image/*" onChange={handleFileChange}/>
                        </div>

                        <img src={`https://cicofiles.blob.core.windows.net/bankimages/${selectedInfo.name}.png`} className="h-6 text-[20px] object-scale-down"/>
                        {previewImgUrl && (
                            <div className="image_wrapper">
                            <img src={previewImgUrl} className="h-6 text-[20px] object-scale-down"/>
                            </div>
                        )}

                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.paginaControl.anadirBanco.activo[language]}</span>
                            <div className=''>
                                <label for={`default-toggle-bankActive`} class={" relative cursor-pointer z-[0]"}>
                                    <input type="checkbox" checked={selectedInfo.isActive} onClick={()=>setSelectedInfo({...selectedInfo,isActive:!selectedInfo.isActive})}  id={`default-toggle-bankActive`} class="sr-only peer"/>
                                    <div class="w-8 h-4 rounded-full peer bg-[#d3d3d3] peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[2px] peer-checked:after:left-[4px] after:top-[2px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white after:shadow-[1px_0px_1px_1px_rgba(0,0,0,0.1)] after:rounded-full after:h-3 after:w-3 after:transition-all after:duration-100"></div>
                                </label>
                            </div>
                        </div>
                        
                        <div className='flex justify-end items-center gap-4 mt-10'>
                            <div className={Styles.saveButton} onClick={()=>handleUpdateBank()}>
                                {Idioma.transacciones.anadirTransaccionModal.guardar[language]}
                            </div>
                            <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='mt-[30px] flex items-center h-[35px] z-[12] sticky'>
                <h2 className=' pl-[5%] mr-[16px]  text-h8-2xl'>Fecha</h2>
                <div className=' w-fit'>
                    <DatePicker
                        onChange={setDateRangeFilter}
                        locale={language}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={today}
                        placeholderText='00/00/0000'
                        selectsRange
                        fixedHeight
                        className='placeholder:text-neutral-3 w-full rounded-button pl-6 py-2 border-1 border-neutral-1'
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat={Idioma.formatoFecha[language]}
                        />
                </div>
                <button className={Styles.saveButton + ' ml-[10px]'} onClick={()=>getData()}>Aplicar</button>
                
            </div>
            <div className=' px-[5%] z-0 mt-[25px]'>
                <div className='w-full'>
                    <p className=' text-h5-2xl'>Clientes</p>
                    <div className='overflow-y-scroll max-h-96 w-fit snap-y'>
                    <table className='text-left m-2'>
                        <thead className='sticky top-0 bg-white z-[10]'>
                        
                            <tr  className=' h-[50px] bg-white'>
                                {/*<th></th>*/}
                                {clientColumns.map((col)=>(
                                    <th className='px-[10px] text-center'>{col.Header}</th>
                                ))}
                                <th className='px-[20px] text-center'><button onClick={()=>handleOpenNewClient()}><AddClientLogo/></button></th>
                            </tr>
                        </thead>
                        
                        <tbody className='gap-y-[30px] '>
                            {clientData.map((client)=>(
                                <><tr className=' bg-white shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] h-[50px] snap-center'>
                                    {/*<td className='bg-secondary rounded-full p-2'><img src={`https://cicofiles.blob.core.windows.net/webimages/${client.client.toUpperCase()} ISO.svg`} className='h-8 object-contain'/></td>*/}
                                    {clientColumns.map((col)=>(
                                        genCell(client,col.accessor)
                                    ))}
                                    <td className='px-[20px] text-center'><button onClick={()=>handleStartEditingClient(client)}><LapizLogo/></button></td>
                                </tr>
                                <tr className='h-[30px]'></tr></>
                            ))}
                        </tbody>
                    </table></div>

                    <p className=' text-h5-2xl mt-[10px]'>Usuarios</p>
                    <div className='overflow-y-scroll max-h-96 w-fit snap-y'>
                    <table className='text-left m-2'>
                        <thead className='sticky top-0 bg-white z-[10]'>
                        
                            <tr  className=' h-[50px] bg-white'>
                                {columns.map((col)=>(
                                    <th className='px-[10px]'>{col.Header}</th>
                                ))}
                                <th className='px-[20px] text-center'><button onClick={()=>handleOpenNewUser()}><AddUserLogo2/></button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData.map((user)=>(
                                <><tr className=' bg-white shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] h-[50px] snap-center'>
                                    {columns.map((col)=>(
                                        genCell(user,col.accessor)
                                    ))}
                                    <td className='px-[20px] text-center'><button onClick={()=>handleStartEditingUser(user)}><LapizLogo/></button></td>

                                </tr>
                                <tr className='h-[30px]'></tr></>
                            ))}
                        </tbody>
                    </table></div>
                    <p className=' text-h5-2xl mt-[10px]'>Bancos</p>
                    <div className='overflow-y-scroll max-h-96 w-fit snap-y'>
                    <table className='text-left m-2'>
                        <thead className='sticky top-0 bg-white z-[10]'>
                        
                            <tr  className=' h-[50px] bg-white'>
                                <th className='px-[10px]'>Imagen</th>
                                {bankColumns.map((col)=>(
                                    <th className='px-[10px]'>{col.Header}</th>
                                ))}
                                <th className='px-[20px] text-center'><button onClick={()=>handleOpenNewBank()}><AddLogo fill={"#000"}/></button></th>

                            </tr>
                        </thead>
                        <tbody>
                            {banksData.map((bank)=>(
                                <><tr className=' bg-white shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] h-[50px] snap-center'>
                                    <td className='px-[10px]'><img src={`https://cicofiles.blob.core.windows.net/bankimages/${bank.bank}.png`} className="h-6 text-[20px] object-scale-down"/></td>
                                    {bankColumns.map((col)=>(
                                        genCell(bank,col.accessor)
                                    ))}
                                    <td className='px-[20px]'><button onClick={()=>handleStartEditingBank(bank)}><LapizLogo/></button></td>
                                </tr>
                                <tr className='h-[30px]'></tr></>
                            ))}
                        </tbody>
                    </table></div>
                </div>
                
            </div>
        </div>
    )
}

export default ControlCenterScreen