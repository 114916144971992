import React from 'react'

const EditScenarioLogo = () => {
  return (
    <svg id="Grupo_1092" data-name="Grupo 1092" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Grupo_1091" data-name="Grupo 1091" clip-path="url(#clip-path)">
            <path id="Trazado_1313" data-name="Trazado 1313" d="M129.709,21.63a1.986,1.986,0,0,1-1.961-2.474c.419-1.485.991-2.926,1.473-4.394a7.367,7.367,0,0,1,1.947-2.968c3.613-3.561,7.186-7.164,10.773-10.751a3.01,3.01,0,0,1,4.544-.014q.9.9,1.805,1.805a3.007,3.007,0,0,1,0,4.5c-3.518,3.518-7.059,7.014-10.543,10.564a8.492,8.492,0,0,1-3.506,2.3c-1.358.432-2.7.912-4.053,1.362a2.739,2.739,0,0,1-.483.072M140.081,5.951l-7,7,3.257,3.25,7-7-3.255-3.248m1.556-1.6,3.322,3.338c.647-.642,1.329-1.3,1.987-1.981a.846.846,0,0,0,0-1.236q-1.044-1.081-2.127-2.125a.823.823,0,0,0-1.184-.02c-.7.672-1.363,1.375-2,2.024M129.759,19.545a38.81,38.81,0,0,0,4.987-1.811l-3.189-3.172a40.06,40.06,0,0,0-1.8,4.983" transform="translate(-119.303 -0.001)"/>
            <path id="Trazado_1314" data-name="Trazado 1314" d="M14.31,69.379c-3.011,0-6.022.009-9.033,0A5.189,5.189,0,0,1,.12,65.246a5.662,5.662,0,0,1-.114-1.2Q0,55.766,0,47.487a5.192,5.192,0,0,1,4.334-5.252,4.877,4.877,0,0,1,.878-.083c1.6-.008,14.478-.007,16.082,0a1.054,1.054,0,0,1,1.128.917,1.029,1.029,0,0,1-1.091,1.173c-1.571.007-14.413,0-15.984,0a3.124,3.124,0,0,0-3.121,2.263A3.868,3.868,0,0,0,2.1,47.539Q2.09,55.769,2.1,64a3.116,3.116,0,0,0,3.288,3.284q8.918.005,17.837,0a3.137,3.137,0,0,0,3.31-3.334c0-1.56,0-15.625,0-17.185a1.044,1.044,0,0,1,2.057-.257,1.629,1.629,0,0,1,.034.389c0,1.549,0,15.6,0,17.153a5.213,5.213,0,0,1-5.316,5.328c-3,.01-6,0-9,0" transform="translate(0 -39.382)"/>
        </g>
    </svg>
  )
}

export default EditScenarioLogo