
import React from 'react'

const resumenLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_17" data-name="Enmascarar grupo 17" transform="translate(-78 -310)" clip-path="url(#clip-path)">
        <g id="Grupo_217" data-name="Grupo 217" transform="translate(78 312)">
          <path id="Trazado_150" data-name="Trazado 150" d="M17.386,41.057h1.939v7.436H17.386ZM3.49,39.973H5.427v8.521H3.49Zm20.854-1.212h1.93v9.721h-1.93ZM10.439,36.948h1.93V48.5h-1.93ZM8.719,32.879V48.484H7.1V38.3H1.786V48.522H0v1.645H29.738V48.5H27.952V34.688H22.633V48.494H21.011V39.4H15.7v9.087H14.065v-15.6Z" transform="translate(0 -24.204)" fill={props.fill || "#fff"}/>
          <g id="Grupo_216" data-name="Grupo 216" transform="translate(0 0)">
            <g id="Grupo_215" data-name="Grupo 215" transform="translate(0 0)" clip-path="url(#clip-path-2)">
              <path id="Trazado_151" data-name="Trazado 151" d="M24.584,7.071c.893-.626,1.773-1.236,2.64-1.863a.421.421,0,0,0,.1-.345,2.6,2.6,0,1,1,3.067,2.059,2.506,2.506,0,0,1-1.8-.3.358.358,0,0,0-.465.008c-.812.584-1.64,1.145-2.448,1.734a.513.513,0,0,0-.174.394,2.591,2.591,0,0,1-4.187,2.352,2.567,2.567,0,0,1-.854-2.79.364.364,0,0,0-.1-.427c-.965-.95-1.918-1.911-2.886-2.859a.406.406,0,0,0-.344-.1,2.672,2.672,0,0,1-2.641-.2c-.958.764-1.918,1.522-2.864,2.3a.434.434,0,0,0-.071.356,2.6,2.6,0,1,1-4.3-1.26,2.56,2.56,0,0,1,2.882-.454.456.456,0,0,0,.584-.064q1.268-1.042,2.566-2.049a.392.392,0,0,0,.165-.447A2.589,2.589,0,0,1,17.5.5a2.577,2.577,0,0,1,.966,2.787.426.426,0,0,0,.12.492c.958.94,1.9,1.895,2.861,2.833a.45.45,0,0,0,.374.122,2.653,2.653,0,0,1,2.761.34M9.939,7.987a.916.916,0,0,0-.929-.911.941.941,0,0,0-.9.911.915.915,0,0,0,.921.919.9.9,0,0,0,.911-.92M30.788,4.371a.912.912,0,0,0-.927-.909.934.934,0,0,0-.9.91.923.923,0,0,0,.919.924.906.906,0,0,0,.91-.924M23.847,9.1a.917.917,0,0,0-1.833-.05.917.917,0,1,0,1.833.05M15.975,1.683a.9.9,0,0,0-.9.927.917.917,0,0,0,.9.907A.934.934,0,0,0,16.9,2.6a.914.914,0,0,0-.923-.916" transform="translate(-4.74 0)" fill={props.fill || "#fff"}/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default resumenLogo

