import { useTable, useRowSelect } from "react-table";
import { useEffect, useMemo } from "react";

const ReusableTable =({data, columns, createCellOfType, selectColumn=false, setSelectedRows, className}) => {
    const tableColumns = useMemo(() => columns, [columns]);

    const { getTableBodyProps, 
            getTableProps, 
            headerGroups, 
            prepareRow, 
            rows, 
            selectedFlatRows
        } = useTable({ 
            columns: tableColumns, 
            data
        }, 
        useRowSelect,
        
    );

    useEffect(() => {
        if(selectColumn){
            let aux = selectedFlatRows.map((x) => x)
            setSelectedRows(aux)
        }
      },[selectedFlatRows]);
  
    return (
        <table {...getTableProps()} class={'table-fixed overflow-hidden overflow-scroll min-w-[100%]'}>
        <thead className='sticky top-0 z-10'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="2xl:text-[16px] text-[12px] font-bold bg-main-bg">
                {headerGroup.headers.map(column => (
                <th
                  className={column.className}
                    {...column.getHeaderProps()}
                >
                    {column.render('Header')}
                </th>
                ))}
              </tr>
            ))}
      </thead>
      <tbody {...getTableBodyProps()}>
          {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} className='group text-[12px] '>
              {row.cells.map(cell => {
                return <td  {...cell.getCellProps()}>{createCellOfType(cell,row)}</td>
              })} 
            </tr>
          )
          })}
      </tbody>
      </table>
    )
}

export default ReusableTable