import React from 'react'

const LogOutLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_23" data-name="Enmascarar grupo 23" transform="translate(-78 -981)" clip-path="url(#clip-path)">
        <g id="Grupo_228" data-name="Grupo 228" transform="translate(79 983)">
          <g id="Grupo_139" data-name="Grupo 139" clip-path="url(#clip-path-2)">
            <path id="Trazado_55" data-name="Trazado 55" d="M19.96,10.437H15.754c-1.739,0-3.479-.016-5.218.007a2.583,2.583,0,1,0-.025,5.163c2.776.023,5.554.008,8.33.009h1.045a6.468,6.468,0,0,1,.074.67q.007,4.258,0,8.516c0,1.009-.232,1.24-1.245,1.241-5.828.005-11.657,0-17.485.01-.848,0-1.231-.357-1.23-1.2Q.009,13.037,0,1.225C0,.393.345,0,1.207,0Q10,.02,18.783,0c.664,0,1.17.179,1.172.947.01,3.112,0,6.224,0,9.486" transform="translate(0 -0.001)" fill={props.fill || "#fff"}/>
            <path id="Trazado_56" data-name="Trazado 56" d="M67.363,47.227H66.4q-6.22,0-12.441,0c-.3,0-.673.071-.893-.073a1.377,1.377,0,0,1-.634-.855c-.017-.246.35-.6.629-.759a1.956,1.956,0,0,1,.9-.073q6.22,0,12.441,0h.966l.135-.235q-1.335-1.282-2.669-2.566c-.2-.19-.491-.372-.541-.6a1.354,1.354,0,0,1,.092-1.045,1.052,1.052,0,0,1,1.019-.127q2.555,2.42,4.984,4.97a1.076,1.076,0,0,1-.052,1.045c-1.553,1.636-3.145,3.238-4.788,4.783a1.237,1.237,0,0,1-1.175-.013c-.481-.339-.328-.859.074-1.258q1.492-1.482,2.99-2.956l-.069-.233" transform="translate(-42.81 -33.319)" fill={props.fill || "#fff"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogOutLogo


