import React from 'react'
//Grupo 1375
const MDCashLogo1 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="178.757" height="33.344" viewBox="0 0 178.757 33.344">
    <g id="Grupo_1375" data-name="Grupo 1375" transform="translate(0 0)">
        <path id="Trazado_1664" data-name="Trazado 1664" d="M18.848,15.284,0,.1V33.358H4.183V8.855L19.007,20.8,35.654,5.7V.038Z" transform="translate(0 -0.028)" fill={props.fill || "#f7f7f7"}/>
        <g id="Grupo_1374" data-name="Grupo 1374" transform="translate(0 0)">
        <g id="Grupo_1373" data-name="Grupo 1373" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Trazado_1665" data-name="Trazado 1665" d="M152.316,33.344v-4.2c8.555,0,15.513-5.594,15.513-12.468a11.283,11.283,0,0,0-4.394-8.682A17.4,17.4,0,0,0,152.316,4.2V0A21.57,21.57,0,0,1,166.1,4.748a15.4,15.4,0,0,1,5.917,11.925c0,9.194-8.835,16.671-19.7,16.671" transform="translate(-112.479 0)" fill={props.fill || "#f7f7f7"}/>
            <path id="Trazado_1666" data-name="Trazado 1666" d="M534.241,42.115a4.139,4.139,0,0,0,1.748,1.514,5.24,5.24,0,0,0,2.2.487,4.49,4.49,0,0,0,1.289-.2,4.068,4.068,0,0,0,1.2-.591,3.251,3.251,0,0,0,.881-.974,2.59,2.59,0,0,0,.341-1.343,2.007,2.007,0,0,0-.683-1.642,5.942,5.942,0,0,0-1.7-.947q-1.012-.382-2.208-.734a9.018,9.018,0,0,1-2.211-.989,5.392,5.392,0,0,1-1.7-1.7,5.287,5.287,0,0,1-.682-2.878,4.942,4.942,0,0,1,1.471-3.42,6.274,6.274,0,0,1,1.96-1.273,7.3,7.3,0,0,1,2.88-.514,10,10,0,0,1,2.917.423,4.988,4.988,0,0,1,2.446,1.709l-2.05,1.867A3.4,3.4,0,0,0,541,29.729a4.284,4.284,0,0,0-1.973-.445,4.773,4.773,0,0,0-1.751.274,3.127,3.127,0,0,0-1.115.71,2.553,2.553,0,0,0-.594.935,2.844,2.844,0,0,0-.168.92,2.352,2.352,0,0,0,.682,1.815,5.3,5.3,0,0,0,1.7,1.024,20.212,20.212,0,0,0,2.208.712,9.476,9.476,0,0,1,2.208.88,5.127,5.127,0,0,1,1.7,1.525,4.5,4.5,0,0,1,.682,2.643,5.535,5.535,0,0,1-.512,2.416,5.38,5.38,0,0,1-1.382,1.817,6.122,6.122,0,0,1-2.049,1.13,7.9,7.9,0,0,1-2.526.394,9.186,9.186,0,0,1-3.392-.631,5.581,5.581,0,0,1-2.551-1.946Z" transform="translate(-392.978 -19.876)" fill={props.fill || "#f7f7f7"}/>
            <path id="Trazado_1667" data-name="Trazado 1667" d="M417.763,27.761h-.081l-9.441,19.123h3.025l6.428-13.939h.057l6.428,13.939H427.2Z" transform="translate(-301.469 -20.5)" fill={props.fill || "#f7f7f7"}/>
            <path id="Trazado_1668" data-name="Trazado 1668" d="M642.235,27.734v7.737h-8.658V27.734h-2.526V46.871h2.526v-8.9h8.658v8.9h2.525V27.734Z" transform="translate(-466.003 -20.48)" fill={props.fill || "#f7f7f7"}/>
            <path id="Trazado_1669" data-name="Trazado 1669" d="M304.235,41.811a7.48,7.48,0,0,1-5.894,2.774,7.294,7.294,0,0,1-5.3-2.055,6.913,6.913,0,0,1-2.094-5.125,7.237,7.237,0,0,1,.936-3.662,6.938,6.938,0,0,1,2.64-2.588,7.441,7.441,0,0,1,3.712-.966,7.691,7.691,0,0,1,3.369.737,7.255,7.255,0,0,1,2.621,2.166l.2.262,1.891-1.458-.185-.258a8.956,8.956,0,0,0-3.354-2.831,10.222,10.222,0,0,0-4.566-1.007,9.614,9.614,0,0,0-6.974,2.726,9.075,9.075,0,0,0-2.79,6.724,9.645,9.645,0,0,0,2.3,6.47,9.417,9.417,0,0,0,7.485,3.182,10.566,10.566,0,0,0,4.506-.944,9.183,9.183,0,0,0,3.382-2.68l.205-.262-1.89-1.441Z" transform="translate(-213.005 -20.53)" fill={props.fill || "#f7f7f7"}/>
        </g>
        </g>
    </g>
    </svg>
  )
}

export default MDCashLogo1