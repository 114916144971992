import React from 'react'

const TyCSect9Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
  <g id="Enmascarar_grupo_104" data-name="Enmascarar grupo 104" transform="translate(-491 -226)" clip-path="url(#clip-path)">
    <g id="Grupo_1114" data-name="Grupo 1114" transform="translate(491.001 226)">
      <path id="Trazado_1353" data-name="Trazado 1353" d="M33.027,4.707h-.416A6.979,6.979,0,0,0,26.016,0H6.973A6.981,6.981,0,0,0,0,6.973V21.259a6.981,6.981,0,0,0,6.973,6.973H9.181L8.144,33.91a1.172,1.172,0,0,0,1.909,1.105l3.285-2.778a6.949,6.949,0,0,0,3.048.7h5.537l8.022,6.784a1.172,1.172,0,0,0,1.909-1.105L30.819,32.94h2.208A6.981,6.981,0,0,0,40,25.967V11.68A6.981,6.981,0,0,0,33.027,4.707ZM11.486,26.31a1.172,1.172,0,0,0-.9-.421H6.973a4.635,4.635,0,0,1-4.629-4.63V6.973A4.634,4.634,0,0,1,6.973,2.344H26.016a4.623,4.623,0,0,1,4.629,4.629V21.259a4.635,4.635,0,0,1-4.629,4.63H17.647a1.172,1.172,0,0,0-.757.277l-5.853,4.949.7-3.844a1.172,1.172,0,0,0-.253-.961Zm26.17-.343A4.634,4.634,0,0,1,33.027,30.6H29.414a1.172,1.172,0,0,0-1.153,1.382l.7,3.843-5.853-4.95a1.172,1.172,0,0,0-.757-.277H16.387a4.655,4.655,0,0,1-.981-.1l2.67-2.258h7.941a6.981,6.981,0,0,0,6.973-6.973V7.051h.038a4.634,4.634,0,0,1,4.629,4.629Z"/>
      <path id="Trazado_1354" data-name="Trazado 1354" d="M106.927,99.779a1.172,1.172,0,0,0,1.172-1.172V91.547a1.172,1.172,0,1,0-2.344,0v7.061A1.172,1.172,0,0,0,106.927,99.779Z" transform="translate(-97.493 -83.314)"/>
      <path id="Trazado_1355" data-name="Trazado 1355" d="M106.915,241a1.172,1.172,0,1,0,1.172,1.172A1.185,1.185,0,0,0,106.915,241Z" transform="translate(-97.481 -222.172)"/>
      <path id="Trazado_1356" data-name="Trazado 1356" d="M287.677,99.779a1.172,1.172,0,0,0,1.172-1.172V91.547a1.172,1.172,0,0,0-2.344,0v7.061A1.172,1.172,0,0,0,287.677,99.779Z" transform="translate(-264.122 -83.314)"/>
      <path id="Trazado_1357" data-name="Trazado 1357" d="M287.665,241a1.172,1.172,0,1,0,1.172,1.172A1.185,1.185,0,0,0,287.665,241Z" transform="translate(-264.11 -222.172)"/>
      <path id="Trazado_1358" data-name="Trazado 1358" d="M167.177,95.071a1.172,1.172,0,0,0,1.172-1.172,1.182,1.182,0,1,1,1.777,1.021,3.543,3.543,0,0,0-1.767,3.033v.653a1.172,1.172,0,0,0,2.344,0v-.653a1.176,1.176,0,0,1,.608-1.011,3.526,3.526,0,1,0-5.306-3.043A1.172,1.172,0,0,0,167.177,95.071Z" transform="translate(-153.036 -83.314)"/>
      <path id="Trazado_1359" data-name="Trazado 1359" d="M197.29,241a1.172,1.172,0,1,0,1.172,1.172A1.185,1.185,0,0,0,197.29,241Z" transform="translate(-180.796 -222.172)"/>
    </g>
  </g>
</svg>

  )
}

export default TyCSect9Logo