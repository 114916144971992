
import React from 'react'

const SearchLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_22" data-name="Enmascarar grupo 22" transform="translate(-204 -11604)" clip-path="url(#clip-path)">
        <g id="Grupo_884" data-name="Grupo 884" transform="translate(204 11604)" clip-path="url(#clip-path-2)">
          <path id="Trazado_1069" data-name="Trazado 1069" d="M20.822,19.279c.1.1.185.2.278.291q3.807,3.8,7.614,7.6c.35.349.7.7,1.028,1.068a1.022,1.022,0,0,1-.077,1.473,1,1,0,0,1-1.351.041c-.294-.248-.554-.535-.827-.808q-4.019-4.013-8.035-8.03c-.1-.1-.165-.115-.282-.019a11.586,11.586,0,0,1-5.425,2.439,10.649,10.649,0,0,1-3.242.112,11.6,11.6,0,0,1-7.72-4.143,11.257,11.257,0,0,1-2.337-4.4A12.087,12.087,0,0,1,0,11.555,11.471,11.471,0,0,1,1.155,6.709,11.569,11.569,0,0,1,7.724.734a11.714,11.714,0,0,1,10.7,1.329,11.645,11.645,0,0,1,4.413,5.712A11.459,11.459,0,0,1,23.475,13a12.867,12.867,0,0,1-.694,2.889,11.439,11.439,0,0,1-1.958,3.388m.581-7.5a9.612,9.612,0,1,0-9.624,9.6,9.581,9.581,0,0,0,9.624-9.6" transform="translate(0 -0.001)" fill="#fff"/>
        </g>
      </g>
    </svg>

  )
}

export default SearchLogo

