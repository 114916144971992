import React from 'react';

const LogoSideBarSmall = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65">
            <g id="Enmascarar_grupo_98" data-name="Enmascarar grupo 98" transform="translate(22637 23572)" clip-path="url(#clip-path)">
                <g id="Grupo_1140" data-name="Grupo 1140" transform="translate(-22637 -23557.461)">
                    <path id="Trazado_1368" data-name="Trazado 1368" d="M20.691,16.673,0,.079V36.422H4.591V9.649L20.865,22.7,39.139,6.2V.015Z" transform="translate(0 -0.004)" fill="#fff" />
                    <g id="Grupo_1139" data-name="Grupo 1139" transform="translate(0 0)">
                        <g id="Grupo_1138" data-name="Grupo 1138" clip-path="url(#clip-path-2)">
                            <path id="Trazado_1369" data-name="Trazado 1369" d="M59.438,36.434V31.841c9.391,0,17.03-6.112,17.03-13.624a12.31,12.31,0,0,0-4.823-9.487A19.161,19.161,0,0,0,59.438,4.592V0A23.744,23.744,0,0,1,74.564,5.188a16.8,16.8,0,0,1,6.5,13.029c0,10.046-9.7,18.216-21.622,18.216" transform="translate(-15.707 0)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
};

export default LogoSideBarSmall