
import React from 'react'

const Triangulo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8">
      <path id="Polígono_8" data-name="Polígono 8" d="M5,0l5,8H0Z" transform="translate(10 8) rotate(180)" fill={props.fill}/>
    </svg>
  )
}

export default Triangulo