import React from 'react'

const TyCSect6Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_101" data-name="Enmascarar grupo 101" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1111" data-name="Grupo 1111" transform="translate(492 227)">
        <path id="Trazado_1344" data-name="Trazado 1344" d="M29.312,21.435a4,4,0,0,0-3.471-2.009V16.885h.367a4.772,4.772,0,1,0,0-9.543H25.84V1.1A1.1,1.1,0,0,0,24.33.08L9.845,5.873H3.3A3.307,3.307,0,0,0,0,9.177v5.873a3.307,3.307,0,0,0,3.3,3.3h.514v12.48a3.67,3.67,0,0,0,7.341,0V18.878L21.469,23l-5.451,9.491a3.4,3.4,0,0,0,2.948,5.093H32.715a3.4,3.4,0,0,0,2.948-5.093ZM25.84,9.544h.367a2.569,2.569,0,1,1,0,5.139H25.84ZM8.956,30.833a1.468,1.468,0,1,1-2.936,0V18.353H8.956Zm0-14.682H3.3a1.1,1.1,0,0,1-1.1-1.1V9.177a1.1,1.1,0,0,1,1.1-1.1H8.956Zm2.2.356V7.72l12.48-4.992V20.086a4,4,0,0,0-1.04,1ZM32.715,35.384H18.966a1.2,1.2,0,0,1-1.038-1.794l6.351-11.058a1.8,1.8,0,0,1,3.123,0L33.753,33.59a1.2,1.2,0,0,1-1.038,1.794Z"/>
        <path id="Trazado_1345" data-name="Trazado 1345" d="M455.3,150h-2.2a1.1,1.1,0,1,0,0,2.2h2.2a1.1,1.1,0,1,0,0-2.2Z" transform="translate(-418.819 -138.988)"/>
        <path id="Trazado_1346" data-name="Trazado 1346" d="M420.105,54.442a1.1,1.1,0,0,0,1.557,0l1.557-1.557a1.1,1.1,0,0,0-1.557-1.557l-1.557,1.557A1.1,1.1,0,0,0,420.105,54.442Z" transform="translate(-388.966 -47.26)"/>
        <path id="Trazado_1347" data-name="Trazado 1347" d="M420.105,228.1a1.1,1.1,0,0,0,0,1.557l1.557,1.557a1.1,1.1,0,0,0,1.557-1.557l-1.557-1.557A1.1,1.1,0,0,0,420.105,228.1Z" transform="translate(-388.966 -211.06)"/>
        <path id="Trazado_1348" data-name="Trazado 1348" d="M338.1,322a1.1,1.1,0,0,0-1.1,1.1v3.671a1.1,1.1,0,0,0,2.2,0V323.1A1.1,1.1,0,0,0,338.1,322Z" transform="translate(-312.261 -298.362)"/>
        <circle id="Elipse_69" data-name="Elipse 69" cx="1.069" cy="1.069" r="1.069" transform="translate(25.205 31.618)"/>
        </g>
    </g>
    </svg>

  )
}

export default TyCSect6Logo