import React from 'react'

const TyCSect4Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
  <g id="Enmascarar_grupo_134" data-name="Enmascarar grupo 134" transform="translate(-491 -226)" clip-path="url(#clip-path)">
    <g id="Grupo_1275" data-name="Grupo 1275" transform="translate(487.264 226.889)">
      <path id="Trazado_1558" data-name="Trazado 1558" d="M119.193,132.05H135.16a.753.753,0,1,0,0-1.506H119.193a.753.753,0,0,0,0,1.506Z" transform="translate(-104.973 -120.798)"/>
      <path id="Trazado_1559" data-name="Trazado 1559" d="M268.59,180.9h-5.2a.753.753,0,1,0,0,1.506h5.2a.753.753,0,0,0,0-1.506Z" transform="translate(-238.403 -167.393)"/>
      <path id="Trazado_1560" data-name="Trazado 1560" d="M119.193,182.4h5.5a.753.753,0,0,0,0-1.506h-5.5a.753.753,0,0,0,0,1.506Z" transform="translate(-104.973 -167.393)"/>
      <path id="Trazado_1561" data-name="Trazado 1561" d="M119.193,232.757H135.16a.753.753,0,0,0,0-1.506H119.193a.753.753,0,0,0,0,1.506Z" transform="translate(-104.973 -213.987)"/>
      <path id="Trazado_1562" data-name="Trazado 1562" d="M119.193,283.11h1.5a.753.753,0,0,0,0-1.506h-1.5a.753.753,0,0,0,0,1.506Z" transform="translate(-104.973 -260.581)"/>
      <path id="Trazado_1563" data-name="Trazado 1563" d="M195.844,281.6h-6.169a.753.753,0,1,0,0,1.506h6.169a.753.753,0,1,0,0-1.506Z" transform="translate(-170.193 -260.581)"/>
      <path id="Trazado_1564" data-name="Trazado 1564" d="M129.137,332.711a.753.753,0,0,0-.753-.753h-9.192a.753.753,0,0,0,0,1.506h9.192A.753.753,0,0,0,129.137,332.711Z" transform="translate(-104.973 -307.176)"/>
      <path id="Trazado_1565" data-name="Trazado 1565" d="M119.193,81.669h9.2a.753.753,0,1,0,0-1.506h-9.2a.753.753,0,0,0,0,1.506Z" transform="translate(-104.973 -74.179)"/>
      <path id="Trazado_1566" data-name="Trazado 1566" d="M41.108,23.558l-5.581-3.012V7.268a.751.751,0,0,0,0-.08c0-.006,0-.011,0-.017,0-.021-.006-.042-.01-.062s0-.012,0-.019-.01-.039-.016-.057l-.007-.02c-.006-.018-.013-.035-.021-.052l-.011-.023c-.008-.016-.016-.031-.024-.046l-.015-.024-.027-.04L35.366,6.8l-.031-.036-.02-.022-.038-.036L35.263,6.7,27.616.18l0,0L27.587.157l-.03-.023L27.528.116,27.5.1,27.466.081,27.437.067,27.4.052l-.03-.011L27.336.03,27.3.021,27.269.014l-.04-.006L27.2,0c-.024,0-.047,0-.071,0H11.442a2.565,2.565,0,0,0-2.56,2.564V4.085H7.561A2.566,2.566,0,0,0,5,6.648V35.659a2.566,2.566,0,0,0,2.562,2.564H34.774a.763.763,0,0,0,.088-.006,2.524,2.524,0,0,0,1.114-.425A13.256,13.256,0,0,0,40.649,32.5a14.376,14.376,0,0,0,1.825-6.838,1.98,1.98,0,0,0-1.366-2.1ZM27.881,2.383,32.73,6.515H29.35a1.47,1.47,0,0,1-1.469-1.468ZM6.5,35.659V6.648A1.059,1.059,0,0,1,7.56,5.591H8.881V31.572a2.565,2.565,0,0,0,2.56,2.564h3.736a.753.753,0,1,0,0-1.506H11.442a1.057,1.057,0,0,1-1.055-1.058V2.564a1.057,1.057,0,0,1,1.055-1.058H26.375V5.047A2.978,2.978,0,0,0,29.35,8.021h4.672V20.546l-5.557,3-.022.012a1.981,1.981,0,0,0-1.369,2.1A14.376,14.376,0,0,0,28.9,32.5c.024.044.048.087.072.131H21.877a.753.753,0,1,0,0,1.506h8a13.417,13.417,0,0,0,2.228,2.581H7.561A1.058,1.058,0,0,1,6.5,35.659Zm32.818-3.871A11.8,11.8,0,0,1,35.2,36.5a1.649,1.649,0,0,1-.428.217h0a1.573,1.573,0,0,1-.432-.217h0a11.965,11.965,0,0,1-4.118-4.712,12.873,12.873,0,0,1-1.646-6.113c0-.4.051-.468.053-.471a2.789,2.789,0,0,1,.525-.32l5.619-3.033,5.6,3.02.023.012a2.785,2.785,0,0,1,.523.32s.049.07.053.472A12.873,12.873,0,0,1,39.322,31.788Z"/>
      <path id="Trazado_1567" data-name="Trazado 1567" d="M176.877,437.085h0a.753.753,0,1,0,.757.753A.751.751,0,0,0,176.873,437.085Z" transform="translate(-158.349 -404.455)"/>
      <path id="Trazado_1568" data-name="Trazado 1568" d="M367.489,356.1l-2.622,2.692-.975-.914a.753.753,0,1,0-1.03,1.1l1.514,1.419a.753.753,0,0,0,1.054-.024l3.137-3.222a.753.753,0,1,0-1.079-1.051Z" transform="translate(-330.928 -329.307)"/>
    </g>
  </g>
</svg>

  )
}

export default TyCSect4Logo