import React from 'react'

const AddUserLogo2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
    <g id="Enmascarar_grupo_148" data-name="Enmascarar grupo 148" transform="translate(-1711 -258)" clip-path="url(#clip-path)">
        <g id="Grupo_1292" data-name="Grupo 1292" transform="translate(1710 257)">
        <path id="Trazado_1586" data-name="Trazado 1586" d="M31,16A15,15,0,1,0,16,31a14.72,14.72,0,0,0,5.55-1.1,6,6,0,0,0,8.35-8.35A14.69,14.69,0,0,0,31,16ZM16,3A13,13,0,0,1,29,16a12.79,12.79,0,0,1-.65,4A5.93,5.93,0,0,0,25,19a4.89,4.89,0,0,0-.54,0,11,11,0,0,0-3.05-2.6,7,7,0,1,0-10.82,0,11,11,0,0,0-5.27,7A13,13,0,0,1,16,3Zm-5,9a5,5,0,1,1,5,5,5,5,0,0,1-5-5ZM7.05,25.4a9,9,0,0,1,5.15-7.53,7,7,0,0,0,7.6,0,8.91,8.91,0,0,1,2.53,1.76A6,6,0,0,0,19,25a5.93,5.93,0,0,0,1,3.35,12.89,12.89,0,0,1-13-3ZM25,29a4,4,0,1,1,4-4A4,4,0,0,1,25,29Z"/>
        <path id="Trazado_1587" data-name="Trazado 1587" d="M27,23.88H26v-1a1,1,0,0,0-2,0v1H23a1,1,0,1,0,0,2h1v1a1,1,0,0,0,2,0v-1h1a1,1,0,1,0,0-2Z" transform="translate(0 0)"/>
        </g>
    </g>
    </svg>

  )
}

export default AddUserLogo2