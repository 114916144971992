import React from 'react'

const CuentasLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_20" data-name="Enmascarar grupo 20" transform="translate(-78 -515)" clip-path="url(#clip-path)">
        <g id="Grupo_223" data-name="Grupo 223" transform="translate(79 516)">
          <g id="Grupo_222" data-name="Grupo 222" clip-path="url(#clip-path-2)">
            <path id="Trazado_158" data-name="Trazado 158" d="M2.874,12.549c-.533-.11-1.178-.06-1.483-.358-.281-.275-.2-.924-.28-1.447a3.04,3.04,0,0,1-.5-.049.841.841,0,0,1-.417-.2A2.3,2.3,0,0,1,.569,8.1Q4.687,5.526,8.807,2.953C10.274,2.036,11.748,1.131,13.2.2A.941.941,0,0,1,14.357.2Q20.64,4.153,26.944,8.071A1,1,0,0,1,27.513,9.1a4.156,4.156,0,0,0,0,.422c0,1.1-.009,1.107-1.07,1.229-.085.5-.012,1.142-.3,1.455-.267.291-.918.23-1.455.335V23.206c.52.1,1.166.051,1.471.35.281.274.194.924.275,1.447.114.01.25.019.385.034.119.013.267-.006.351.058.608.457.3,1.112.311,1.682,0,.417-.35.545-.732.544h-.362q-12.622,0-25.245-.014c-.341,0-.937-.153-.975-.33a3.987,3.987,0,0,1,.033-1.7c.04-.174.573-.233.92-.359.07-.423-.009-1.083.283-1.379s.953-.233,1.473-.331ZM5.823,23.266H9.156V12.475H5.823Zm9.606-10.8H12.106V23.284h3.323Zm2.958,10.8h3.348V12.473H18.388Zm-4.6-14.854a2.277,2.277,0,1,0-2.3-2.289,2.272,2.272,0,0,0,2.3,2.289" transform="translate(0 0)" fill={props.fill || "#fff"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CuentasLogo
