import React, { useEffect, useState } from 'react'
import OpenDropdown from '../assets/OpenDropdown'
import AIChatLogo from '../assets/AIChatLogo'
import MinimizeIcon from '../assets/MinimizeIcon'
import BiggerCloseLogo from '../assets/BiggerCloseLogo'

const ChatBox = ({text, reset}) => {

    const [openQuestions, setOpenQuestions] = useState(null)
    const [openChat, setOpenChat] = useState(true)

    let questions = Object.getOwnPropertyNames(text)

    const handleExpandQuestion = (question) => {
        setOpenQuestions(question)
    }

    const handleOpenChat = () => {
        setOpenChat(!openChat)
    }

    const handleCloseChat = () => {
        reset(null)
    }

  return (
    <div className=' fixed bottom-0 right-0 z-[999] shadow-high-elevation bg-white mt-5 w-[35%]'>
        {openQuestions?
        <>
            <button onClick={()=>handleExpandQuestion(null)}>
                <div className='bg-purple-2 text-white text-left text-h8-2xl flex items-center py-6 pl-10 pr-14' >
                    <div className='rotate-90'><OpenDropdown stroke={'#9191F4'}/></div>
                    <span className='pl-5'>{openQuestions}</span>
                </div>
                <div className='text-p13-2xl max-h-[400px] overflow-y-scroll my-6 pl-14 pr-6 text-left perfect-scrollbar mr-5 whitespace-pre-line'>{text[openQuestions]}</div>
            </button>
        </>
        :
        <>
            <div className='bg-purple-2 flex justify-between'>
                <div className='text-white text-left text-h5-2xl flex items-center py-6 px-14'>
                    <AIChatLogo fill={'#e8e8ff'} fillcenter={'#5300ba'} />
                    <span className='pl-5'>AI Chat</span>
                </div>
                <div className='flex items-start pr-2'>
                    <div className='flex items-end'>
                        <button className='p-5' onClick={()=>handleOpenChat()}><MinimizeIcon stroke={"#fff"}/></button>
                        <button className='p-4' onClick={()=>handleCloseChat()}><BiggerCloseLogo stroke={"#fff"}/></button>
                    </div>
                </div>
            </div>
            {openChat && questions.map((question, index)=>(
                <button className='w-[100%]' onClick={()=>handleExpandQuestion(question)}>
                    <div className='text-b5-2xl py-6 px-14 text-left content-center items-center flex space-x-10'>
                        <span className='w-[90%]'>{question}</span>
                        <div className='-rotate-90'><OpenDropdown stroke={'#5300ba'}/></div>
                    </div>
                    {index < questions.length -1 && 
                    <hr className='w-[80%] h-[0.5px] mx-auto bg-gray-3 border-0 rounded'/>
                    }
                    
                </button>
            ))}
        </>
        }
    </div>
  )
}

export default ChatBox