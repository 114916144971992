
import React from 'react'

const CatalogoLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_21" data-name="Enmascarar grupo 21" transform="translate(-78 -586)" clip-path="url(#clip-path)">
        <g id="Grupo_485" data-name="Grupo 485" transform="translate(79 587)">
          <g id="Grupo_484" data-name="Grupo 484" clip-path="url(#clip-path-2)">
            <path id="Trazado_718" data-name="Trazado 718" d="M4.555,17.739c0-.074,0-.131,0-.188.01-.7,0-1.4.037-2.1a2.226,2.226,0,0,1,2.14-2.066c.963-.025,1.928-.009,2.892-.01h3.749V11.056c-.647,0-1.294.01-1.941,0a5.42,5.42,0,0,1-1.129-.091A1.854,1.854,0,0,1,8.854,9.24c-.009-.1-.013-.2-.013-.3q0-3.436,0-6.872A1.936,1.936,0,0,1,10.293.084a2.576,2.576,0,0,1,.658-.078Q14.378,0,17.805,0a1.938,1.938,0,0,1,1.952,1.26,2.372,2.372,0,0,1,.13.788Q19.9,5.5,19.9,8.959a2.081,2.081,0,0,1-.566,1.52,1.746,1.746,0,0,1-1.215.553c-.864.026-1.729.011-2.594.014h-.154v2.328h6.388a2.34,2.34,0,0,1,1.634.56,2.219,2.219,0,0,1,.758,1.655c.015.648,0,1.3,0,1.946v.193c.067,0,.122.008.178.008.795,0,1.589,0,2.384,0a1.954,1.954,0,0,1,1.6.731,2.032,2.032,0,0,1,.423,1.324q0,3.3,0,6.592a4.069,4.069,0,0,1-.1.987,1.833,1.833,0,0,1-1.828,1.4c-2.314.006-4.628,0-6.942.011a2.093,2.093,0,0,1-1.684-.664A1.807,1.807,0,0,1,17.7,26.97c-.012-.315-.01-.631-.011-.946q0-3.042,0-6.084a2.3,2.3,0,0,1,.221-1.156,1.8,1.8,0,0,1,1.537-1.013c.875-.041,1.752-.024,2.628-.032.04,0,.08,0,.132-.007,0-.05.012-.095.012-.139,0-.59.006-1.18.005-1.771,0-.423-.082-.506-.5-.506l-14.076,0c-.275,0-.549,0-.824,0-.2.007-.3.1-.3.29-.005.689,0,1.379,0,2.069a.393.393,0,0,0,.021.074h.446c.672,0,1.344-.012,2.016-.012a1.91,1.91,0,0,1,2.032,1.947c.027.847.015,1.694.016,2.542,0,1.455.011,2.91-.005,4.365a3.03,3.03,0,0,1-.158.993A1.733,1.733,0,0,1,9.244,28.77c-.8.041-1.612.013-2.419.015L2.268,28.8A2.265,2.265,0,0,1,.9,28.471,1.989,1.989,0,0,1,0,26.744c.006-2.332,0-4.663,0-6.995a2.428,2.428,0,0,1,.067-.555,1.859,1.859,0,0,1,1.672-1.423c.9-.038,1.81-.024,2.716-.032h.1" transform="translate(0 0)" fill={props.fill || "#fff"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CatalogoLogo

