import React from 'react'

const CloseLogo = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" stroke={props.stroke || "#fff"} fill={props.fill || "#fff"}>
  <g id="Grupo_59" data-name="Grupo 59" clip-path="url(#clip-path)">
    <path id="Trazado_34" data-name="Trazado 34" d="M30,15.055A15,15,0,1,1,15.078,0,15.044,15.044,0,0,1,30,15.055M12.486,18.268c-.138-.163-.23-.288-.338-.4Q10.315,16.033,8.479,14.2a1.273,1.273,0,0,0-1.213-.432,1.2,1.2,0,0,0-.955.857,1.23,1.23,0,0,0,.359,1.308q2.418,2.416,4.834,4.835a1.255,1.255,0,0,0,1.987.007q4.893-4.895,9.781-9.795a1.263,1.263,0,0,0,.084-1.871,1.243,1.243,0,0,0-1.835.1q-1.184,1.173-2.358,2.356c-2.2,2.212-4.407,4.425-6.678,6.706" transform="translate(0 0)"/>
  </g>
</svg>
  )
}

export default CloseLogo