import React from 'react'

const RecurrenceLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Enmascarar_grupo_32" data-name="Enmascarar grupo 32" transform="translate(-815 -294)" clip-path="url(#clip-path)">
            <path id="Trazado_1270" data-name="Trazado 1270" d="M0,13.967a13.983,13.983,0,1,1,13.976,14A13.993,13.993,0,0,1,0,13.967m26.21.857c-.331,0-.662.008-.991,0a1.5,1.5,0,0,1-.486-.076.809.809,0,0,1-.487-.907.792.792,0,0,1,.735-.683c.407-.021.817,0,1.227,0A12.2,12.2,0,0,0,14.825,1.763c0,.321,0,.642,0,.963a1.651,1.651,0,0,1-.045.435.823.823,0,0,1-.887.568.8.8,0,0,1-.735-.749c-.022-.407,0-.815,0-1.225A12.2,12.2,0,0,0,1.767,13.14c.322,0,.643-.006.963,0a1.583,1.583,0,0,1,.461.059.834.834,0,0,1-.248,1.614c-.4.017-.794,0-1.193,0a12.2,12.2,0,0,0,11.4,11.369c0-.39-.016-.78,0-1.169a.835.835,0,0,1,1.669.037c.013.385,0,.772,0,1.159A12.243,12.243,0,0,0,26.21,14.825" transform="translate(816.017 295.017)" stroke={props.fill || "#000"} fill={props.fill || "#000"}  stroke-width="0.5"/>
            <path id="Trazado_1271" data-name="Trazado 1271" d="M111.8,63.844c0-.931-.008-1.861,0-2.792a.783.783,0,1,1,1.565-.02c.009.529,0,1.058,0,1.587,0,1.15.005,2.3,0,3.449a.6.6,0,0,0,.2.476c.825.814,1.642,1.636,2.46,2.456a.781.781,0,0,1,.239.82.76.76,0,0,1-.588.551.742.742,0,0,1-.727-.217c-.977-.971-1.959-1.938-2.913-2.931a1.079,1.079,0,0,1-.23-.669c-.026-.9-.01-1.806-.01-2.709Z" transform="translate(717.419 242.326)" stroke={props.fill || "#000"} fill={props.fill || "#000"} stroke-width="0.5"/>
        </g>
    </svg>

  )
}

export default RecurrenceLogo