import React from 'react'

const PPSect4Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_116" data-name="Enmascarar grupo 116" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Transfer" transform="translate(491 226)">
        <path id="Trazado_1455" data-name="Trazado 1455" d="M15.833,0H2.5A2.5,2.5,0,0,0,0,2.5V25.833a2.5,2.5,0,0,0,2.5,2.5H15.833a2.5,2.5,0,0,0,2.5-2.5V2.5A2.5,2.5,0,0,0,15.833,0ZM1.667,5.833h15V22.5h-15Zm0-3.333A.833.833,0,0,1,2.5,1.667H15.833a.833.833,0,0,1,.833.833V4.167h-15Zm15,23.333a.833.833,0,0,1-.833.833H2.5a.833.833,0,0,1-.833-.833V24.167h15Z"/>
        <path id="Trazado_1456" data-name="Trazado 1456" d="M29,4.333A1,1,0,0,0,29.333,5c3.558,2.667,3.45,2.667,3.833,2.667a.833.833,0,0,0,.5-1.5l-1.333-1H36.5a1.667,1.667,0,0,1,1.667,1.667v2.5a.833.833,0,1,0,1.667,0v-2.5A3.333,3.333,0,0,0,36.5,3.5H32.333l1.333-1a.833.833,0,1,0-1-1.333C29.258,3.725,29,3.783,29,4.333Z" transform="translate(-4.833 -0.167)"/>
        <path id="Trazado_1457" data-name="Trazado 1457" d="M13.167,38.667a.833.833,0,1,0-1,1.333L13.5,41H9.333a1.667,1.667,0,0,1-1.667-1.667v-2.5a.833.833,0,0,0-1.667,0v2.5a3.333,3.333,0,0,0,3.333,3.333H13.5l-1.333,1a.833.833,0,0,0,1,1.333C18.083,41.317,18.075,42.35,13.167,38.667Z" transform="translate(-1 -6)"/>
        <path id="Trazado_1458" data-name="Trazado 1458" d="M9.833,10a5.833,5.833,0,1,0,5.833,5.833A5.833,5.833,0,0,0,9.833,10Zm0,10A4.167,4.167,0,1,1,14,15.833,4.167,4.167,0,0,1,9.833,20Z" transform="translate(-0.667 -1.667)"/>
        <path id="Trazado_1459" data-name="Trazado 1459" d="M11.568,14.954,9.9,16.621,9.384,16.1A.837.837,0,0,0,8.2,17.279c1.992,1.992,1.417,1.983,4.517-1.117a.834.834,0,0,0-1.15-1.208Z" transform="translate(-1.326 -2.454)"/>
        <path id="Trazado_1460" data-name="Trazado 1460" d="M41.833,14H28.5A2.5,2.5,0,0,0,26,16.5V39.833a2.5,2.5,0,0,0,2.5,2.5H41.833a2.5,2.5,0,0,0,2.5-2.5V16.5A2.5,2.5,0,0,0,41.833,14ZM27.667,19.833h15V36.5h-15Zm0-3.333a.833.833,0,0,1,.833-.833H41.833a.833.833,0,0,1,.833.833v1.667h-15Zm15,23.333a.833.833,0,0,1-.833.833H28.5a.833.833,0,0,1-.833-.833V38.167h15Z" transform="translate(-4.333 -2.333)"/>
        <path id="Trazado_1461" data-name="Trazado 1461" d="M36.5,27.53a.833.833,0,0,1,.833.833.833.833,0,0,0,.833.833c1.383,0,1.008-2.5-.833-3.183a.833.833,0,1,0-1.667,0,2.5,2.5,0,0,0,.833,4.85.833.833,0,1,1-.833.833.833.833,0,0,0-.833-.833c-1.383,0-1.008,2.5.833,3.183a.833.833,0,1,0,1.667,0A2.5,2.5,0,0,0,36.5,29.2a.833.833,0,0,1,0-1.667Z" transform="translate(-5.666 -4.197)"/>
        </g>
    </g>
    </svg>
  )
}

export default PPSect4Logo