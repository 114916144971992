import "@paybook/sync-widget/dist/widget.css";
import SyncWidget from "@paybook/sync-widget";
import { urlBase } from "../authConfig";
import { useStateContext } from "../contexts/ContextProvider";
import React,{ useState, useEffect } from "react";
import Idioma from "./Idioma";

const SyncfyWidget = ({token}) => {

    const {language} = useStateContext();

    const [syncWidget, setSyncWidget] = useState(null)


    const createWidget = (token) => {
        if (!syncWidget){
            let params = {
                token: token,
                element: ".pb-w-sync_modal-container",
                config: {
                    locale: 'es',
                    entrypoint: {
                        country: 'MX',
                        siteOrganizationType: '56cf5728784806f72b8b4568'
                    },
                    navigation: {
                        displayStatusInToast: true
                    }
                }
            };
            setSyncWidget(new SyncWidget(params))
        }
    }

    const open = () => {
        if(syncWidget){
            syncWidget.open();
            syncWidget.$on("closed", () => {
                getSyncfyAccounts()
              });
        }else{
            createWidget(token)
        }
    }
    return (
        <button className="bg-light-purple-1 main flex items-center justify-center w-[185px] 2xl:w-[230px] h-[52px] p-4 rounded-button" onClick={open} disabled={!token}>
            <h3 className="text-white text-h10-2xl 2xl:text-h7-2xl text-center">{Idioma.Syncfy.vincular[language]}</h3>
        </button>
    )
}

export default SyncfyWidget