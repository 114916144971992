import React from 'react'

const UploadErrorLogo = () => {
  return (
    <svg id="Grupo_71" data-name="Grupo 71" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10.793" height="9.594" viewBox="0 0 10.793 9.594">
      
      <g id="Grupo_70" data-name="Grupo 70" clip-path="url(#clip-path)">
        <path id="Trazado_35" data-name="Trazado 35" d="M5.383,9.593q-2.292,0-4.584,0A1.163,1.163,0,0,1,.51,9.567.686.686,0,0,1,.1,8.546c.25-.449.51-.894.766-1.34Q2.81,3.81,4.758.413A.708.708,0,0,1,5.376,0a.682.682,0,0,1,.638.382l1.1,1.92Q8.888,5.39,10.66,8.479a.764.764,0,0,1,.106.616.679.679,0,0,1-.6.492c-.067.006-.135.007-.2.007H5.383m-.7-5.306h0c0,.46,0,.92,0,1.379a.892.892,0,0,0,.02.213.712.712,0,0,0,.752.527.692.692,0,0,0,.653-.695c0-.945,0-1.89,0-2.835a.685.685,0,0,0-.014-.151.712.712,0,0,0-.79-.541.69.69,0,0,0-.621.684c0,.472,0,.945,0,1.417m-.059,3.4a.772.772,0,1,0,.786-.762.777.777,0,0,0-.786.762" transform="translate(0 0.001)" fill="#ff003f"/>
      </g>
    </svg>
  )
}

export default UploadErrorLogo