import React from 'react'

const AddClientLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Enmascarar_grupo_135" data-name="Enmascarar grupo 135" transform="translate(-1711 -258)" clip-path="url(#clip-path)">
            <g id="Grupo_1279" data-name="Grupo 1279" transform="translate(1711 258)">
            <path id="Trazado_1572" data-name="Trazado 1572" d="M366.395,363.758h-.879v-.879a.879.879,0,0,0-1.758,0v.879h-.879a.879.879,0,0,0,0,1.758h.879v.879a.879.879,0,0,0,1.758,0v-.879h.879a.879.879,0,0,0,0-1.758Z" transform="translate(-340.789 -340.789)"/>
            <path id="Trazado_1573" data-name="Trazado 1573" d="M23.848,17.7a6.114,6.114,0,0,0-2.754.652V11.832a3.517,3.517,0,0,0,1.758-3.043V6.152a.878.878,0,0,0-.111-.427L19.811.452A.879.879,0,0,0,19.043,0H3.809A.879.879,0,0,0,3.04.452L.111,5.725A.878.878,0,0,0,0,6.152V8.789a3.517,3.517,0,0,0,1.758,3.043V21.973a.879.879,0,0,0,.879.879h15.14A6.152,6.152,0,1,0,23.848,17.7ZM4.326,1.758h14.2l1.953,3.516H2.373ZM15.82,7.031V8.789a1.758,1.758,0,0,1-3.516,0V7.031Zm-5.273,0V8.789a1.758,1.758,0,0,1-3.516,0V7.031ZM1.758,8.789V7.031H5.273V8.789a1.758,1.758,0,0,1-3.516,0Zm11.426,12.3H9.668V16.406h3.516Zm6.152-1.424a6.179,6.179,0,0,0-.989,1.424H14.941V15.527a.879.879,0,0,0-.879-.879H8.789a.879.879,0,0,0-.879.879v5.566H3.516V12.3a3.507,3.507,0,0,0,2.637-1.194,3.508,3.508,0,0,0,5.273,0,3.508,3.508,0,0,0,5.273,0A3.507,3.507,0,0,0,19.336,12.3ZM17.578,8.789V7.031h3.516V8.789a1.758,1.758,0,1,1-3.516,0Zm6.27,19.453a4.395,4.395,0,1,1,4.395-4.395A4.4,4.4,0,0,1,23.848,28.242Z" transform="translate(0)"/>
            </g>
        </g>
    </svg>

  )
}

export default AddClientLogo