import React from 'react'

const UndoLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
        <g id="Enmascarar_grupo_152" data-name="Enmascarar grupo 152" transform="translate(-1801 -328)" clip-path="url(#clip-path)">
            <path id="XMLID_5_" d="M11.711,43.493a.656.656,0,0,0,0,.928,4.809,4.809,0,1,1-7.623,1.1l.468,2.354a.656.656,0,0,0,1.287-.256l-.754-3.79a.656.656,0,0,0-.771-.515l-3.79.754a.656.656,0,1,0,.256,1.287l2.129-.423a6.122,6.122,0,1,0,9.726-1.438A.656.656,0,0,0,11.711,43.493Z" transform="translate(1801 286.698)"/>
        </g>
    </svg>
  )
}

export default UndoLogo