import React from 'react'

const SettingsLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_29" data-name="Enmascarar grupo 29" transform="translate(-665 -294)" clip-path="url(#clip-path)">
        <g id="Grupo_799" data-name="Grupo 799" transform="translate(665 295.516)">
          <g id="Grupo_798" data-name="Grupo 798" transform="translate(0 0)" clip-path="url(#clip-path-2)">
            <g id="Grupo_852" data-name="Grupo 852" transform="translate(1.512)">
              <g id="Grupo_851" data-name="Grupo 851" clip-path="url(#clip-path-3)">
                <path id="Trazado_1025" data-name="Trazado 1025" d="M5.791,2.176c.345.232.707.471,1.064.717.549.377,1.094.76,1.646,1.133a.256.256,0,0,0,.207.015c.5-.2,1-.412,1.494-.631a.286.286,0,0,0,.128-.188q.292-1.528.567-3.059C10.921.03,10.972,0,11.1,0q2.39.006,4.779,0c.12,0,.176.027.2.162.179,1.027.371,2.052.554,3.079a.218.218,0,0,0,.162.186c.5.2.994.4,1.486.605A.224.224,0,0,0,18.522,4q1.311-.9,2.623-1.8c.009-.006.02-.01.01-.005l3.631,3.632c-.246.365-.51.765-.781,1.16q-.523.76-1.058,1.512a.219.219,0,0,0-.012.246c.2.488.394.981.6,1.468a.266.266,0,0,0,.175.125c.637.119,1.277.228,1.915.342.394.071.787.149,1.182.217.124.021.167.076.167.2q-.008,2.38,0,4.76c0,.147-.047.206-.193.231-1.005.178-2.008.368-3.013.546a.305.305,0,0,0-.257.224q-.269.683-.568,1.355a.28.28,0,0,0,.019.321c.591.84,1.175,1.685,1.76,2.529.029.041.056.084.076.115L21.168,24.8c-.406-.278-.831-.566-1.252-.858-.474-.328-.943-.661-1.419-.985a.261.261,0,0,0-.208-.03c-.5.2-.989.409-1.486.608a.248.248,0,0,0-.183.21c-.171.977-.351,1.953-.523,2.93-.051.288-.044.288-.337.288q-2.313,0-4.626.006c-.135,0-.209-.015-.238-.179-.177-1.022-.37-2.041-.553-3.062a.23.23,0,0,0-.17-.2c-.482-.19-.963-.385-1.436-.6a.264.264,0,0,0-.3.03Q7.2,23.828,5.96,24.684c-.06.041-.123.079-.163.105L2.148,21.2c.233-.335.454-.654.676-.972.391-.56.78-1.121,1.177-1.677a.312.312,0,0,0,.027-.364c-.213-.459-.4-.93-.593-1.4a.228.228,0,0,0-.2-.169c-1.039-.181-2.075-.374-3.114-.556C0,16.045,0,15.98,0,15.89q0-1.931,0-3.862c0-.311,0-.621,0-.932,0-.118.022-.172.16-.2,1.033-.18,2.065-.372,3.1-.567a.259.259,0,0,0,.164-.128c.214-.5.415-1,.628-1.495a.2.2,0,0,0-.015-.228c-.606-.864-1.2-1.733-1.812-2.6-.07-.1-.03-.146.035-.211Q3.618,4.318,4.979,2.96c.264-.262.535-.517.812-.784m7.7,17.08A5.778,5.778,0,1,0,13.486,7.7,5.883,5.883,0,0,0,7.7,13.507a5.82,5.82,0,0,0,5.8,5.749" transform="translate(0.001 0)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SettingsLogo
