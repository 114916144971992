import React from 'react'

const NavbarUserLogo = () => {
  return (
    <svg id="Grupo_132" data-name="Grupo 132" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="46" height="46" viewBox="0 0 46 46">
        <g id="Grupo_38" data-name="Grupo 38" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Trazado_26" data-name="Trazado 26" d="M0,22.974A23,23,0,1,1,22.984,46,23.008,23.008,0,0,1,0,22.974M22.978,43.3a20.267,20.267,0,0,0,11.13-3.309.554.554,0,0,0,.278-.7,11.656,11.656,0,0,0-22.766-.025.588.588,0,0,0,.3.743A20.336,20.336,0,0,0,22.978,43.3m7.33-23.212a7.308,7.308,0,1,0-7.289,7.324,7.325,7.325,0,0,0,7.289-7.324" transform="translate(0 -0.002)" fill="#d3d3d3"/>
        </g>
    </svg>

  )
}

export default NavbarUserLogo