
import React from 'react'

const WarningLogo = () => {
  return (
    <svg id="Grupo_797" data-name="Grupo 797" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="26.33" height="23.406" viewBox="0 0 26.33 23.406">
      <g id="Grupo_487" data-name="Grupo 487" transform="translate(0.007)">
        <g id="Grupo_486" data-name="Grupo 486" clip-path="url(#clip-path)">
          <path id="Trazado_719" data-name="Trazado 719" d="M13.136,23.4H1.954a2.838,2.838,0,0,1-.7-.063A1.673,1.673,0,0,1,.244,20.848c.61-1.1,1.243-2.179,1.868-3.269Q6.862,9.3,11.611,1.007A1.728,1.728,0,0,1,13.119,0a1.662,1.662,0,0,1,1.555.93q1.343,2.339,2.683,4.684Q21.679,13.149,26,20.682a1.862,1.862,0,0,1,.251,1.509,1.657,1.657,0,0,1-1.455,1.2c-.163.014-.329.016-.494.016H13.136M11.427,10.459h0v3.365a2.186,2.186,0,0,0,.049.519,1.737,1.737,0,0,0,1.834,1.286A1.687,1.687,0,0,0,14.9,13.935c.007-2.3,0-4.609,0-6.915a1.667,1.667,0,0,0-.034-.367,1.736,1.736,0,0,0-1.924-1.32A1.684,1.684,0,0,0,11.436,7c-.01,1.152,0,2.3,0,3.458m-.143,8.3A1.886,1.886,0,1,0,13.21,16.9a1.886,1.886,0,0,0-1.917,1.858" transform="translate(-0.007 0)" fill="#ff003f"/>
        </g>
      </g>
      <g id="Grupo_489" data-name="Grupo 489">
        <g id="Grupo_488" data-name="Grupo 488" clip-path="url(#clip-path-2)">
          <path id="Trazado_720" data-name="Trazado 720" d="M85.483,44.913h0v3.365a2.171,2.171,0,0,0,.049.519,1.736,1.736,0,0,0,1.834,1.286,1.687,1.687,0,0,0,1.592-1.694c.008-2.3,0-4.609,0-6.915a1.667,1.667,0,0,0-.034-.367A1.736,1.736,0,0,0,87,39.787a1.684,1.684,0,0,0-1.509,1.668c-.01,1.152,0,2.3,0,3.458" transform="translate(-74.056 -34.455)" fill="#fff"/>
          <path id="Trazado_721" data-name="Trazado 721" d="M84.48,128.275a1.886,1.886,0,1,0,1.917-1.858,1.886,1.886,0,0,0-1.917,1.858" transform="translate(-73.187 -109.519)" fill="#fff"/>
        </g>
      </g>
    </svg>
  )
}

export default WarningLogo
