import React from 'react'

const AddLogo = (props) => {
  return (
    <svg id="Grupo_121" data-name="Grupo 121" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.671" height="23.673" viewBox="0 0 23.671 23.673">
      <g id="Grupo_120" data-name="Grupo 120" clip-path="url(#clip-path)">
        <path id="Trazado_46" data-name="Trazado 46" d="M11.832,0A11.836,11.836,0,1,1,0,11.846,11.857,11.857,0,0,1,11.832,0m.006,2.089a9.747,9.747,0,1,0,9.745,9.761,9.759,9.759,0,0,0-9.745-9.761" transform="translate(0 0)" fill={props.fill || "#d3d3d3"}/>
        <path id="Trazado_47" data-name="Trazado 47" d="M37.238,39.31H35.731c-1.149,0-2.3,0-3.448,0a1.045,1.045,0,1,1,.014-2.087c1.549,0,3.1,0,4.647,0h.293V36.9c0-1.549,0-3.1,0-4.647a1.045,1.045,0,0,1,1.4-1.022,1.068,1.068,0,0,1,.683,1.061q0,2.3,0,4.6v.33h.306c1.541,0,3.081,0,4.622,0a1.046,1.046,0,1,1,.011,2.088c-1.549,0-3.1,0-4.647,0h-.293v.318c0,1.549,0,3.1,0,4.647a1.045,1.045,0,0,1-1.4,1.022,1.069,1.069,0,0,1-.682-1.061q0-2.3,0-4.6V39.31" transform="translate(-26.446 -26.428)" fill={props.fill || "#d3d3d3"}/>
      </g>
    </svg>
  )
}

export default AddLogo