import React from "react";

const WarningIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 35 35">
        <g id="Enmascarar_grupo_162" data-name="Enmascarar grupo 162" transform="translate(-1659 -72)" clip-path="url(#clip-path)">
            <g id="Grupo_1352" data-name="Grupo 1352" transform="translate(2.205 2)">
            <g id="Polígono_161" data-name="Polígono 161" transform="translate(1654.795 70)" fill="#ffd853">
                <path d="M 33.89438247680664 33.99999618530273 L 5.105621814727783 33.99999618530273 C 4.388951778411865 33.99999618530273 3.744331836700439 33.63110733032227 3.381261825561523 33.01320648193359 C 3.018191814422607 32.39530563354492 3.009681940078735 31.65265655517578 3.358491897583008 31.02660751342773 L 17.75286102294922 5.190527439117432 C 18.11100196838379 4.547707557678223 18.76414108276367 4.163937568664551 19.50000190734863 4.163937568664551 C 20.2358512878418 4.163937568664551 20.88899230957031 4.547707557678223 21.24713134765625 5.190527439117432 L 35.64151000976562 31.02659797668457 C 35.99032211303711 31.65265655517578 35.9818115234375 32.39530563354492 35.61874008178711 33.01320648193359 C 35.25567245483398 33.63110733032227 34.61105346679688 33.99999618530273 33.89438247680664 33.99999618530273 Z" stroke="none"/>
                <path d="M 19.49999237060547 5.163936614990234 C 19.32332229614258 5.163936614990234 18.88454246520996 5.213958740234375 18.62643241882324 5.677227020263672 L 4.232051849365234 31.51328659057617 C 3.980663299560547 31.96449661254883 4.156272888183594 32.35824584960938 4.243442535400391 32.50659942626953 C 4.330612182617188 32.65494537353516 4.589111328125 32.99999618530273 5.105621337890625 32.99999618530273 L 33.89438247680664 32.99999618530273 C 34.41088104248047 32.99999618530273 34.66939163208008 32.65495681762695 34.75655364990234 32.50660705566406 C 34.84372329711914 32.35825729370117 35.01933288574219 31.96449661254883 34.76794052124023 31.51328659057617 L 20.37356185913086 5.677227020263672 C 20.11545181274414 5.213958740234375 19.67666244506836 5.163936614990234 19.49999237060547 5.163936614990234 M 19.49999618530273 3.163936614990234 C 20.52437019348145 3.163936614990234 21.54874229431152 3.677230834960938 22.12070274353027 4.703826904296875 L 36.51508331298828 30.53988647460938 C 37.62917327880859 32.53954696655273 36.18344116210938 34.99999618530273 33.89438247680664 34.99999618530273 L 5.105621337890625 34.99999618530273 C 2.816562652587891 34.99999618530273 1.370822906494141 32.53954696655273 2.484912872314453 30.53988647460938 L 16.87929153442383 4.703826904296875 C 17.45125198364258 3.677230834960938 18.47562408447266 3.163936614990234 19.49999618530273 3.163936614990234 Z" stroke="none" fill="#000"/>
            </g>
            <g id="Grupo_1339" data-name="Grupo 1339" transform="translate(1672.155 81.952)">
                <g id="Grupo_1340" data-name="Grupo 1340" transform="translate(0 0)">
                <path id="Trazado_1623" data-name="Trazado 1623" d="M2.634,15.4a2.078,2.078,0,0,1,1.516.624,2.116,2.116,0,0,1,0,3.014,2.065,2.065,0,0,1-1.516.63,2.056,2.056,0,0,1-1.51-.624,2.138,2.138,0,0,1,0-3.02,2.056,2.056,0,0,1,1.51-.624M.912.5H4.357V13.918H.912Z" transform="translate(-0.5 -0.5)" fill="#fff"/>
                <path id="Trazado_1624" data-name="Trazado 1624" d="M2.634,15.4a2.078,2.078,0,0,1,1.516.624,2.116,2.116,0,0,1,0,3.014,2.065,2.065,0,0,1-1.516.63,2.056,2.056,0,0,1-1.51-.624,2.138,2.138,0,0,1,0-3.02A2.056,2.056,0,0,1,2.634,15.4ZM.912.5H4.357V13.918H.912Z" transform="translate(-0.5 -0.5)"/>
                </g>
            </g>
            </g>
        </g>
        </svg>
    );
}

export default WarningIcon;