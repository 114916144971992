import React,{useState,useEffect,useRef} from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect, useBlockLayout, useResizeColumns } from 'react-table'
import {matchSorter} from 'match-sorter'
import {AdjustmentsHorizontalIcon,PlusCircleIcon ,FunnelIcon} from '@heroicons/react/24/outline'
import Scissors from '../assets/Scissors'
import { ClassificationDropdown, Dropdown, FileDropdown, scrollbarWidth} from '../components';
import { FixedSizeList } from 'react-window'
import moment from 'moment'
import 'moment/locale/es'
import {AiOutlineArrowRight, AiOutlineCloseCircle, AiOutlineSave} from 'react-icons/ai'
import TransaccionesLogo from '../assets/transaccionesLogo'

import DatePicker from 'react-datepicker'
import { useStateContext } from '../contexts/ContextProvider';

import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';


import { getToken } from '../fetch';

import { urlBase } from '../authConfig';


import "react-datepicker/dist/react-datepicker.css";
import Triangulo from '../assets/Triangulo';
import PreferencesLogo from '../assets/PreferencesLogo';
import ScissorsLogo from '../assets/ScissorsLogo';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import MultiTransactionLogo from '../assets/MultiTransactionLogo';
import SearchLogo from '../assets/SearchLogo';
import SettingsLogo from '../assets/SettingsLogo'
import ClassificationExcelExport from '../components/ClassificationExcelExport'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className='flex  bg-secondary-light rounded-[7px] relative'>
      <div className='text-secondary absolute object-contain right-2 top-0 dark:text-white dark:bg-secondary-dark-bg mt-1 scale-[.8]'>
        <SearchLogo/>
      </div>
      {' '}
      <input
        placeholder='Buscar...'
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
        className='dark:bg-secondary-dark-bg bg-transparent font-medium pl-2 w-96 h-8 mt-1 focus:outline-none'
      />
    </div>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      className='dark:bg-secondary-dark-bg p-2 bg-secondary-light-2 rounded-[7px] w-[100%] text-[12px] h-8'
      placeholder='Concepto'
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}

function NotesColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      className='dark:bg-secondary-dark-bg p-2 bg-secondary-light-2 rounded-[7px] w-[95%] text-[12px] h-8'
      placeholder=''
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}
// Define a default UI for filtering
function DisabledFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div><p className=' invisible'>null</p></div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      className='dark:bg-secondary-dark-bg p-2 bg-secondary-light-2 rounded-[7px] w-[100%] text-[12px] text-filter-text h-8'
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">Todos </option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      className='flex h-8'
    >
      <input
        value={filterValue[0] || ''}
        type='number'
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]])
        }}
        placeholder='Mínimo'
        className='h-8 w-1/2 mr-[2px] dark:bg-secondary-dark-bg p-2 bg-secondary-light-2 rounded-[7px] text-[12px]'
      /><input
        value={filterValue[1] || ''}
        type='number'
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined])
        }}
        className='h-8 w-1/2 mr-0 dark:bg-secondary-dark-bg p-2 bg-secondary-light-2 rounded-[7px] text-[12px]'
        placeholder='Máximo'
      />
    </div>
  )
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? filterValues[0] : undefined
  const ed = filterValues[1] ? filterValues[1] : undefined

  if (ed || sd) {
    return rows.filter(r => {
      const cellDate = new Date(r.values[id])

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd){
        return cellDate >= sd
      } else if (ed){
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}

function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  const [start, end] = filterValue;

  return (
    <div>
      <DatePicker
        onChange={(e) => {
          const val = e;
          setFilter(e);
        }}
        startDate={start}
        endDate={end}
        selectsRange
        fixedHeight
        className='p-2 bg-secondary-light-2 rounded-[7px] w-[100%] text-[12px] h-8'
        showYearDropdown
        dropdownMode="select"
        isClearable
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}


// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'



const ClassificationOnlyViewScreen = () => {

  const {idClient,bank,setContextBank,account,setContextAccount,file,setContextFile,showState,setContextShowState,accessToken,setToken,startDate,endDate,
    dateRange,setContextSubcategoryId,contextSubcategoryId,
    setContextDateRange,userAccess,idRS,setContextRSID,contextRSID} = useStateContext()

  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  const [categories,setCategories] = useState([])
  const [categoriesDict,setCategoriesDict] = useState({})
  const [inflowCategories,setInflowCategories] = useState([])
  const [outflowCategories,setOutflowCategories] = useState([])

  const [subcategories,setSubcategories] = useState([])
  
  const [rsID,setRSID] = useState({name:'Empresa'})
  const [rsOptions,setRSOptions] = useState([])
  const [rsDict,setRSDict] = useState({})
  
  const [data,setData] = useState([])
  const [subcategoriesData,setSubcategoriesData] = useState([])
  const [indexToUpdate,setIndexToUpdate] = useState(-1)
  const isFirstRender = useRef(true);
  const [filesData,setFilesData] = useState([])


  const [openRulesModal,setOpenRulesModal] = useState(false)
  const [openPreferencesModal,setOpenPreferencesModal] = useState(false)
  const [openTransactionModal,setOpenTransactionModal] = useState(false)

  const [transactionQuantities,setTransactionQuantities] = useState([])
  const [selectedTransaction,setSelectedTransaction] = useState([])
  const [transactionId,setTransactionId] = useState(null)
  const [ruleName,setRuleName] = useState('')
  const [ruleText,setRuleText] = useState('')
  const [ruleType,setRuleType] = useState({name:'Cargo/Abono'})
  const [ruleType2,setRuleType2] = useState({name:'Concepto/Monto'})
  const [ruleType3,setRuleType3] = useState({name:'Contiene/No Contiene/Es Exacto'})
  const [bankRule,setBankRule] = useState({name:'Banco'})
  const [ruleSubcategories,setRuleSubcategories] = useState([])
  const [ruleSubcategory,setRuleSubcategory] = useState({name:'Subcategoría'})
  const [ruleCategory,setRuleCategory] = useState({name:'Categoría'})


  const [bankActive,setBankActive] = useState(true)
  const [accountActive,setAccountActive] = useState(false)
  const [rsActive,setRSActive] = useState(false)
  const [dateActive,setDateActive] = useState(true)
  const [contextActive,setContextActive] = useState(true)
  const [inflowActive,setInflowActive] = useState(true)
  const [outflowActive,setOutflowActive] = useState(true)
  const [currencyActive,setCurrencyActive] = useState(false)
  const [fxActive,setFXActive] = useState(false)
  const [accountTypeActive,setAccountTypeActive] = useState(false)
  const [notesActive,setNotesActive] = useState(false)
  const [clientSupplierActive,setClientSupplierActive] = useState(false)
  const [additionalInfoActive,setAdditionalInfoActive] = useState(false)


  const [filtersActive,setFiltersActive] = useState(false)

  const [forexValue,setForexValue] = useState(null)

  const [selectedSubcategory,setSelectedSubcategory] = useState()

  const [isLoading,setIsLoading] = useState(true)
  const [isMultiUpdateLoading,setIsMultiUpdateLoading] = useState(false)
  
  

  //const [bank,setBank] = useState({name:'Banco'});
  const [BankOptions,setBankOptions] = useState([]);
  //const [account,setAccount] = useState({name:'Cuenta de banco'});
  const [accounts,setAccounts] = useState([]);
  const [accountsDict,setAccountsDict] = useState({})
  const [info,setInfo] = useState()

  const [updateCounter,setUpdateCounter] = useState(0)
  const [showTransactionsUpdated, setShowTransactionsUpdated] = useState(false)
  const [updatedTransactions,setUpdatedTransactions] = useState(0)
  const [showTransactionsError,setShowTransactionsError] = useState(false)
  const [unaffectedTransactions,setUnaffectedTransactions] = useState(0)

  const [openDeleteTransactionsWarning,setOpenDeleteTransactionsWarning] = useState(false)

  const [openAddTransaction,setOpenAddTransaction] = useState(false)
  const [bankAddT,setBankAddT] = useState({name:'Banco'})
  const [accountsAddT,setAccountsAddT] = useState([]);
  const [accountAddT,setAccountAddT] = useState({name: 'Cuenta de banco'})
  const [dateAddT,setDateAddT] = useState(new Date());
  const [conceptAddT,setConceptAddT] = useState('')
  const [inflowAddT,setInflowAddT] = useState()
  const [outflowAddT,setOutflowAddT] = useState()
  const [categoryAddT,setCategoryAddT] = useState()
  const [splitTransactionLoading, setSplitTransactionLoading] = useState(false)
  const [addTransactionLoading, setAddTransactionLoading] = useState(false)

  const [clientOptions,setClientOptions] = useState([])
  const [supplierOptions,setSupplierOptions] = useState([])
  const [clientSupplierDict,setClientSupplierDict] = useState({})

  const AddTransactionDisabled = !(bankAddT.id && accountAddT.id && (outflowAddT || inflowAddT))


    useEffect(() => {
      if(!isAuthenticated){
        navigate('/')
        return
    }
    getToken().then((token)=>{setToken(token)})
    let headers = new Headers()

    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type",'application/json')

    if(localStorage.getItem('columnPreferences')){
      let aux = localStorage.getItem('columnPreferences')
      let aux1 = JSON.parse(aux)
      setBankActive(aux1['bankActive'])
      setAccountActive(aux1['accountActive'])
      setRSActive(aux1['rsActive'])
      setDateActive(aux1['dateActive'])
      setContextActive(aux1['contextActive'])
      setInflowActive(aux1['inflowActive'])
      setOutflowActive(aux1['outflowActive'])
      setFXActive(aux1['fxActive'])
      setAccountTypeActive(aux1['accountTypeActive'])
      setNotesActive(aux1['notesActive'])
      setClientSupplierActive(aux1['clientSupplierActive'])
      setCurrencyActive(aux1['currencyActive'])
      setAdditionalInfoActive(aux1['additionalInfoActive'])

      if(!aux1['rsActive']){
        allColumns[0].toggleHidden()
      }
      if(!aux1['bankActive']){
        allColumns[1].toggleHidden()
      }
      if(!aux1['accountActive']){
        allColumns[2].toggleHidden()
      }
      if(!aux1['dateActive']){
        allColumns[3].toggleHidden()
      }
      if(!aux1['contextActive']){
        allColumns[4].toggleHidden()
      }
      if(!aux1['inflowActive']){
        allColumns[5].toggleHidden()
      }
      if(!aux1['outflowActive']){
        allColumns[6].toggleHidden()
      }
      if(!aux1['currencyActive']){
        allColumns[9].toggleHidden()
      }
      if(!aux1['fxActive']){
        allColumns[10].toggleHidden()
      }
      if(!aux1['accountTypeActive']){
        allColumns[11].toggleHidden()
      }
      if(!aux1['clientSupplierActive']){
        allColumns[12].toggleHidden()
      }
      if(!aux1['notesActive']){
        allColumns[13].toggleHidden()
      }
      if(!aux1['additionalInfoActive']){
        allColumns[14].toggleHidden()
      }
    }else{
      allColumns[0].toggleHidden()  //Empresa
      allColumns[2].toggleHidden()  //cuenta de banco
      allColumns[9].toggleHidden()  //moneda
      allColumns[10].toggleHidden()  //fx
      allColumns[11].toggleHidden() //credito o debito
      allColumns[12].toggleHidden() //clientes proveedores
      allColumns[13].toggleHidden() //notas
      allColumns[14].toggleHidden() //informacion adicional

    }
    let body = {
      "clientID": idClient
    }
    if(idRS){
      body.idRS = idRS
    }
    fetch(`${urlBase}/getAccounts`,{
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body)
            })
              .then((response) => response.json())
              .then((aux) => {
                let newData=[]
                let aux4 = {}
                for (const property in aux) {
                    newData[property] = aux[property]
                    for(let i=0;i<aux[property].accounts.length;i++){
                      aux4[aux[property].accounts[i].accountID]=aux[property].accounts[i].accountNumber
                    }
                }
                let aux2 = Object.getOwnPropertyNames(aux);
                let aux3 = []
                for(let i=0;i<aux2.length;i++){
                    aux3[aux3.length] = {'name':aux2[i] , 'id':aux[aux2[i]].idBank}
                }
                setAccountsDict(aux4)
                setBankOptions(aux3)
                setInfo(newData)
                
                
            });

    fetch(`${urlBase}/getCategories`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        "clientID": idClient,
        "lowest": 1,
        showHidden: true,
    })
    })
      .then((response) => response.json())
      .then((aux) => {
        let catDict = {}
        let newData = []
        let inflowCategoriesData = []
        let outflowCategoriesData = []
        let moreData = []
        for(let i = 0;i<aux.length;i++){
          newData[i] = {
            'name' : aux[i].category,
            'idCategory' : aux[i].idCategory,
            'index': i
          }
          if(aux[i].flowType=='+'){
            inflowCategoriesData[i] = newData[i]
          }else {
            outflowCategoriesData[i] = newData[i]
          }
          catDict[aux[i].idCategory] = {
            "name": aux[i].category,
            "isActive" : aux[i].isActive === true
          }
          moreData[i]=[]
          for(let j = 0;j<aux[i].subcategories.length;j++){
            if(aux[i].subcategories[j].isActive){
              moreData[i][moreData[i].length] = {
                'name':aux[i].subcategories[j].category,
                'idSubCategory':aux[i].subcategories[j].idCategory,
                'idFather': aux[i].idCategory
              }
            }
            catDict[aux[i].subcategories[j].idCategory] = {
              "name": aux[i].subcategories[j].category,
              "isActive" : aux[i].subcategories[j].isActive === true,
              "idFather" : aux[i].subcategories[j].idCategoryFather
            }
          }
        }
        setInflowCategories(inflowCategoriesData)
        setOutflowCategories(outflowCategoriesData)
        setCategories(newData)
        setSubcategories(moreData)
        setCategoriesDict(catDict)
    });
    fetch(`${urlBase}/getClientSupplier`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        "clientID": idClient,
    })
    })
      .then((response) => response.json())
      .then((aux) => {
        setClientOptions(aux.clients)
        setSupplierOptions(aux.suppliers)
        let auxDict = {}
        for(let i=0;i<aux.clients.length;i++){
          auxDict[aux.clients[i].idClientSupplier] = {
            'name':aux.clients[i].clientSupplierName,
            "isActive" : aux.clients[i].isActive === true,
          }
        }
        for(let i=0;i<aux.suppliers.length;i++){
          auxDict[aux.suppliers[i].idClientSupplier] = {
            'name':aux.suppliers[i].clientSupplierName,
            "isActive" : aux.suppliers[i].isActive === true,
          }
        }
        setClientSupplierDict(auxDict)
        
    });
    fetch(`${urlBase}/getRS`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          "clientID": idClient,
        })
    })
    .then((res)=>res.json())
    .then((aux)=>{
        let newData = []
        let auxDict = {}

        for(let i=0;i<aux.length;i++){
          newData[i]={
              name:aux[i].rsAlias,
              id:aux[i].idRS
          }
          auxDict[aux[i].idRS] = {
            name:aux[i].rsAlias
          }
        }
        setRSOptions(newData)
        setRSDict(auxDict)

    })
    getFileData()
      return () => {
       
      }
    },[])
    
    useEffect(()=>{
      localStorage.setItem('columnPreferences',JSON.stringify({
        'bankActive':bankActive,
        'accountActive':accountActive,
        'rsActive':rsActive,
        'dateActive':dateActive,
        'contextActive':contextActive,
        'inflowActive':inflowActive,
        'outflowActive':outflowActive,
        'fxActive':fxActive,
        'accountTypeActive':accountTypeActive,
        'notesActive':notesActive,
        'clientSupplierActive':clientSupplierActive,
        'currencyActive':currencyActive,
        'additionalInfoActive':additionalInfoActive

      }))
    },[bankActive,accountActive,dateActive,contextActive,inflowActive,outflowActive,fxActive,accountTypeActive,notesActive,clientSupplierActive,currencyActive,additionalInfoActive])


    
    const getFileData = async () => {

      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient
      }
      if(idRS){
        body.idRS = idRS
      }
      fetch(`${urlBase}/getFiles`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
        .then((response) => response.json())
        .then((aux) => {
          let newData=[]
          for(let i=0;i<aux.length;i++){
            newData[i]={
              bank: aux[i].bank,
              account: aux[i].accountNumber,
              file: aux[i].inputLocation,
              id: aux[i].idFile,
              name: aux[i].inputName.slice(37),
              totalTransactions: aux[i].total,
              classifiedTransactions: aux[i].categorized,
              percentage: aux[i].total==0 ? 'Error' : aux[i].categorized==0 ? 0 : aux[i].categorized/aux[i].total*100 ,

            }
          }
          setFilesData(newData)
        });
    }
    

    const getTransactions = (type) => {
      setData([])
      setIsLoading(true)
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      let body = {
        "clientID": idClient,
        "showState":showState
      }
      if(type==='file' || (type==='' && file.id)){
        body.fileID = file.id
      }
      if(type==='account' || (type==='' && account.id)){
        body.accountID = account.id
      }
      if(contextSubcategoryId>0){
        body.categoryID = contextSubcategoryId
      }
      if(contextRSID.length>0){
        body.rsIDs = contextRSID
      }
      if(type==='dates' || (type==='' && startDate && endDate)){
        let year = startDate.getFullYear().toString()
        let month = startDate.getMonth()+1
        let day = startDate.getDate()
        if(day<10){
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }
        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fechaInicial = month+'-'+day+'-'+year
        year = endDate.getFullYear().toString()
        month = endDate.getMonth()+1
        day = endDate.getDate()
        if(day<10){
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }
        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fechaFinal = month+'-'+day+'-'+year
        body.startDate = fechaInicial
        body.endDate =  fechaFinal
      }
      if(type==='bank' || (type==='' && bank.id)){
        body.bankID = bank.id
      }
      if(type==='' && bank.name==='Todos'){
        body.bankBypass = true
      }
      if(idRS){
        body.idRS = idRS
      }
        fetch(`${urlBase}/getTransactions`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then((response) => response.json())
      .then((aux) => {
        let newData = []
        for(let i = 0;i<aux.length;i++){
          newData[i] = {
            'bank':aux[i].bank,
            'date':aux[i].date_d.slice(0,10),
            'id':aux[i].idTransaction,
            'context':aux[i].context,
            'reference':aux[i].references_c,
            'inflow':aux[i].inflow ,
            'outflow':aux[i].outflow,
            'category':categoriesDict[categoriesDict[aux[i].idCategory]?.idFather]?.name || '',
            'idCategory':categoriesDict[aux[i].idCategory]?.idFather || '',
            'subcategory':categoriesDict[aux[i].idCategory]?.name || '',
            'idSubCategory':aux[i].idCategory,
            'exchange':aux[i].exchange || aux[i].defaultFX,
            "updated":null,
            'accountType':aux[i].accountType==='D'?'Débito':aux[i].accountType==='C' ? 'Crédito' :'NA',
            'signature':aux[i].transactionSignature,
            'notes': aux[i].notes,
            'idAccount':accountsDict[aux[i].idAccount],
            'idClientSupplier':aux[i].idClientSupplier,
            'clientSupplier':clientSupplierDict[aux[i].idClientSupplier]?.name,
						'currency': aux[i].currency,
            'rsName': aux[i].rsName,
            'extraText': aux[i].extraText

          }
        }
        setData(newData)
        setIsLoading(false)
      });
    }

    useEffect(()=>{
      if(rsID.id && subcategories.length!==0){
        setContextFile({'name':'Seleccionar archivo'})
        setContextBank({name:'Banco'})
        setContextAccount({name: 'Cuenta de banco'})
      }
    },[rsID])

    useEffect(()=>{
      if(account.id && subcategories.length>1){
        setContextFile({'name':'Seleccionar archivo'})
        setRSID({name:'Empresa'})
        //setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }
    },[account])
    
    useEffect(()=>{
      if(subcategories.length!==0 && bank.id){
        setContextFile({'name':'Seleccionar archivo'})
        setRSID({name:'Empresa'})
        //setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }else if(bank.name==='Todos'){
        setContextFile({'name':'Seleccionar archivo'})
        setRSID({name:'Empresa'})
        //setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }
    },[bank])

    useEffect(()=>{
      if(subcategories.length!==0){
        getTransactions('')
      }
    },[showState])

    useEffect(() => {
      if(file.id && subcategories.length!==0){
        setRSID({name:'Empresa'})
        setContextBank({name:'Banco'})
        setContextAccount({name: 'Cuenta de banco'})
        setContextDateRange([null,null])
        //setData([])
        //setIsLoading(true)
        //getTransactions('')
      }
    },[file])

    useEffect(() => {
      if(startDate!=null && endDate!=null){
        setData([])
        //setContextBank({name:'Banco'})
        //setContextAccount({name: 'Cuenta de banco'})
        setContextFile({'name':'Seleccionar archivo'})
        //setIsLoading(true)
        //getTransactions('')
      }else if(!file.id){
        //getTransactions('')
      }
    },[dateRange])

    useEffect(() => {
      if(subcategories.length!==0 && Object.keys(accountsDict).length>0 && Object.keys(categoriesDict).length>0){
        setIsLoading(true)
        getTransactions('')
      }
    }, [subcategories,accountsDict,categoriesDict,clientSupplierDict,contextSubcategoryId,contextRSID])

    useEffect(()=>{
      if (indexToUpdate<0 || data.length<=0) {
        isFirstRender.current = false;
        return; // 👈️ return early if initial render
      }
     
      
    },[data])

    const handleBankChange = (value) => {
      let flag = false
      if(bank.id == value.id){
         flag = true
      }
      setContextBank(value)
      let aux = []
      for(let i = 0;i<info[value.name]?.accounts.length;i++){
        aux[i] = {
          name:info[value.name].accounts[i].accountNumber,
          id:info[value.name].accounts[i].accountID
        }
      }
      setAccounts(aux)
      if(!flag){
        setContextAccount({name:'Cuenta de banco'})
      }
    }

      const handleShowStateChange = (value) => {
        setContextShowState(value)
        setData([])
        
      }

      const handleFileChange = (value) => {
        setContextFile(value)
      }



      useEffect(()=> {
        setAllFilters([])
      },[filtersActive])

      const handleDateRangeChange = (update) => {
        setContextDateRange(update)
      }

      const createCellOfType = (cell,row) => {
        let updatedStyle = 'px-2 rounded-[7px] bg-transparent w-[100%]  border-[#B3B7C3] border-1 rounded-[7px]'
        let normalStyle = 'px-0 rounded-[7px] bg-transparent w-[100%] '
        let classifiedStyle = 'px-2 rounded-[7px] bg-transparent w-[100%]  '
        

        if(cell.column.id === 'category'){
          return <div className='pl-2 w-[100%] rounded-[7px]'>{cell.value}</div>
          //return <div><ClassificationDropdown className={ data[data.indexOf(row.original)].category ? 'min-w-[300px] ': ' text-transparent min-w-[300px] '} selected={data[data.indexOf(row.original)].category ? {name:data[data.indexOf(row.original)].category} : {name:'Categoria'}} setSelected={handleCategoryChange} options={row.original.inflow!=0 ? inflowCategories : outflowCategories}/></div>
        }
        if(cell.column.id === 'subcategory'){
          return <div className=' w-[100%] '>{cell.value}</div>
          //return <div><ClassificationDropdown className={!data[data.indexOf(row.original)].subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} disabled={row.original.idCategory ? false : true} selected={data[data.indexOf(row.original)].subcategory ? {name:data[data.indexOf(row.original)].subcategory} :{'name':'Subcategoria'}} setSelected={handleSubcategoryChange} options={row.original.idCategory ? subcategories[data[data.indexOf(row.original)].categoryIndex] : [] }/></div>
        }
        if(cell.column.id === 'inflow'){
          
          return <div className='text-right  w-[100%] tabular-nums'>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: 'code'}).slice(4) : '--'}</div>
        }
        else if(cell.column.id === 'outflow'){
          return <div className='text-right w-[100%] tabular-nums '>{cell.value ? `(${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
          }).slice(4)})` : '--' }</div>
        }
        else if(cell.column.id === 'exchange'){
          return <div className='text-right w-[100%] tabular-nums '>{cell.value ? parseFloat(cell.value).toFixed(2).toLocaleString("en-US") : '--'}</div>
        }
        else if(cell.column.id === 'context'){
          return <div className='w-[100%] truncate focus:bg-black relative '  title={cell.value}>{cell.value}</div>
        }
        else if(cell.column.id === 'date'){
          return <div className='w-[100%] '>{moment(cell.value).locale('es').format('MMM D, YYYY').replace('.','')}</div>  
        }
        else if(cell.column.id === 'selection'){
          return  <div className='w-[100%]'>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
        }
        else if(cell.column.id === 'bank'){
          return <div className='pl-1 text-left '>{cell.value}</div>
        }
        else if(cell.column.id === 'accountType'){
          return <div className='w-[100%] text-left'>{cell.value}</div>
        }else if(cell.column.id === 'idAccount'){
          return <div className='w-[100%] text-left'>{cell.value}</div>
        }else if(cell.column.id === 'extraText'){
          if(row.original.extraText){
            let json = JSON.parse(row.original.extraText)
            let aux = Object.keys(json) 
            let auxText = ``
            for(let i=0;i<aux.length;i++){
              auxText =auxText+ `${aux[i]} : ${json[aux[i]]} \n`
            } 
            return <div className='w-[100%] text-left truncate' title={auxText}>{auxText}</div>
          }else{
            return <div className=' w-[100%] text-left '>{cell.value}</div>    
          } 
          
        }
        
        return <div className=' w-[100%] text-left '>{cell.value}</div>
      }
      

      const filterTypes = React.useMemo(
        () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )

      const textNormal = 'font-bold text-left '
      const textNumber = 'font-bold text-right '
    const textCategory = 'font-bold text-left '
    
    const columns = React.useMemo(
      () => [/*
        {
          id:'index',
          Header: ' ',
          maxWidth:40,
          minWidth:20,
          width:40,
          className:'text-center',
          accessor: (row, i) => i+1,
        },*/
        {
          Header: 'Empresa',
          accessor: 'rsName',
          width:130,
          Filter: SelectColumnFilter,
          className:textNormal + '',
        },
        {
          Header: 'Banco',
          accessor: 'bank',
          Filter: SelectColumnFilter,
          className:textNormal + 'pl-1 rounded-l-[7px]',
        },
        {
          Header: 'Cuenta',
          accessor: 'idAccount',
          Filter: SelectColumnFilter,
          className:textNormal + '',
        },
        {
          Header: 'Fecha',
          accessor: 'date',
          width:120,
          sortType:'basic',
          Filter:DateRangeColumnFilter,
          filter:dateBetweenFilterFn,
          className:textNormal
        },/*
        {
          Header: 'ID',
          accessor: 'id', 
        },*/
        {
          Header: 'Concepto',
          accessor: 'context',
          width:document.documentElement.clientWidth*.15,
          className:textNormal
        },
        {
          Header: 'Abono',
          accessor: 'inflow',
          width:140,
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          className:textNumber
        },
        {
          Header: 'Cargo',
          accessor: 'outflow',
          width:140,
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          className:textNumber 
        },
        {
          Header: 'Categoría',
          accessor: 'category',
          Filter: SelectColumnFilter,
          width:document.documentElement.clientHeight*.20,
          className:textCategory + ' pl-2 '
        },
        {
          Header: 'Subcategoría',
          accessor: 'subcategory',
          width:document.documentElement.clientHeight*.30,
          Filter: SelectColumnFilter,
          className:textCategory + ' pl-2 '
        },{
          Header: 'Moneda',
          accessor: 'currency',
          Filter: SelectColumnFilter,
          className:textNormal + '',
        },
        {
          Header: 'FX',
          accessor: 'exchange',
          width:80,
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          className:textNumber  
        },{
          Header: 'Crédito/Débito',
          accessor: 'accountType',
          width:140,
          Filter: SelectColumnFilter,
          className:textNormal + ''
        },{
          Header: 'Clientes/Proveedores',
          accessor: 'clientSupplier',
          width:document.documentElement.clientHeight*.20,
          Filter: SelectColumnFilter,
          className:textCategory + ''
        },{
          Header: 'Notas',
          accessor: 'notes',
          width:140,
          Filter: NotesColumnFilter,
          className:textNormal + ' pr-4'
        },{
          Header: 'Informacion adicional',
          accessor: 'extraText',
          width:140,
          Filter: NotesColumnFilter,
          className:textNormal + ' '
        },
      ],
      []
    )
    
      const defaultColumn = React.useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
          minWidth: 10, // minWidth is only used as a limit for resizing
        width: 100, // width is used for both the flex-basis and flex-grow
        maxWidth: 500, // maxWidth is only used as a limit for resizing
        }),
        []
      )

      const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

      const sortees = React.useMemo(
        ()=> [
          {
            id:'index',
            desc:false
          }
        ],
        []
      )

      const sorteesTrue = React.useMemo(
        ()=> [
          {
            id:'index',
            desc:true
          }
        ],
        []
      )
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        setAllFilters,
        selectedFlatRows,
        allColumns,
        state,
        setSortBy,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex,pageSize, globalFilter, selectedRowIds, }
      } = useTable(
        {
          columns,
          data,
          autoResetPage: false,
          autoResetFilters: false,
          autoResetGlobalFilter: false,
          initialState: {
            pageSize:1000,
            hiddenColumns:[]
            //sortBy: sortees
          },
          defaultColumn, // Be sure to pass the defaultColumn option
          filterTypes,
          autoResetSortBy:false
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useRowSelect,
        useBlockLayout,
        useResizeColumns,
        
      )

      const RenderRow = React.useCallback(
        (rows) => ({ index, style }) => {
          const row = rows[index];
          prepareRow(row);
          return (
            <div
            {...row.getRowProps({
              style
            })}
            >
              {row.cells.map((cell) => {
                return (
                  <div><td {...cell.getCellProps()} className='w-[100%]  '>{createCellOfType(cell,row)}</td></div>
                );
              })}
            </div>
          );
        },
        [prepareRow,data]
      );


      
    
      // We don't want to render all of the rows for this example, so cap
      // it for this use case
      const firstPageRows = rows.slice(0, 100)
      const activeClass='bg-tertiary text-md-white  rounded-md px-2 py-1'
      const unactiveClass='bg-secondary-light  text-[#B4B4B4] rounded-md px-2 py-1'
        const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 
      const classified = (element) => {
        if(element.subcategory.idSubCategory){
          return true
        }else{
          return false
        }
      }

      const contextRSIDText = () => {
        let value = ''
        if(Object.keys(rsDict).length>0){
          for(let i=0;i<contextRSID.length;i++){
            value = value + rsDict[contextRSID[i]].name + '\n'
          }
        }
        return value
      }
        
      
      const breakTransactionDisabled = selectedTransaction.original ? ((selectedTransaction.original.inflow>0 ? selectedTransaction.original.inflow==getTransactionQuantityTotal() : selectedTransaction.original.outflow==getTransactionQuantityTotal() ) && transactionQuantities.every(classified) ) : false
        
  
      const breakTransactionStyle = breakTransactionDisabled ? '':'bg-secondary-light-2' 

      if(openPreferencesModal){
          document.body.classList.add('overflow-y-hidden')
      }else{
          document.body.classList.remove('overflow-y-hidden')
      }

    return (
        <div className='pl-[5%] 3xl:mt-6 mr-10 relative'>
          {openPreferencesModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
              <div onClick={()=>setOpenPreferencesModal(false)} className=' fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'>
              </div>
              <div className='bg-white px-8  z-10 absolute top-[calc(40%)] left-[35%] shadow-lg mt-10 py-6 2xl:py-[28px] 2xl:w-[27rem] w-[20rem]  rounded-[7px] -translate-x-[50%] -translate-y-[50%] '>
              <div className='w-full h-full relative'>
                <p className='2xl:text-[24px] text-[20px]  font-bold text-left flex overflow-visible'><SettingsLogo fill='#ffffff' className='overflow-visible'/> <span className='ml-4 2xl:ml-8'>Preferencias</span></p>
                
                <p className='2xl:text-[20px] text-[16px]  2xl:my-4 my-2 font-bold text-left'>Ocultar/Mostrar columnas</p>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Empresa</p>
                  <div>
                    <label for="default-toggle-RS" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[0].getToggleHiddenProps()} checked={rsActive} onClick={()=>{setRSActive((prev) => !prev)}}  id="default-toggle-RS" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Banco</p>
                  <div>
                    <label for="default-toggle-Banco" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[1].getToggleHiddenProps()} checked={bankActive} onClick={()=>{setBankActive((prev) => !prev)}}  id="default-toggle-Banco" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Cuenta de banco</p>
                  <div>
                    <label for="default-toggle-Cuenta" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[2].getToggleHiddenProps()} checked={accountActive} onClick={()=>{setAccountActive((prev) => !prev)}}  id="default-toggle-Cuenta" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Fecha</p>
                  <div>
                    <label for="default-toggle-Fecha" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[3].getToggleHiddenProps()} checked={dateActive} onClick={()=>{setDateActive((prev) => !prev)}}  id="default-toggle-Fecha" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Concepto</p>
                  <div>
                    <label for="default-toggle-Concepto" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[4].getToggleHiddenProps()} checked={contextActive} onClick={()=>{setContextActive((prev) => !prev)}}  id="default-toggle-Concepto" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Abono</p>
                  <div>
                    <label for="default-toggle-Abono" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[5].getToggleHiddenProps()} checked={inflowActive} onClick={()=>{setInflowActive((prev) => !prev)}}  id="default-toggle-Abono" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Cargo</p>
                  <div>
                    <label for="default-toggle-Cargo" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[6].getToggleHiddenProps()} checked={outflowActive} onClick={()=>{setOutflowActive((prev) => !prev)}}  id="default-toggle-Cargo" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>

                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Moneda</p>
                  <div>
                    <label for="default-toggle-Moneda" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[9].getToggleHiddenProps()} checked={currencyActive} onClick={()=>{setCurrencyActive((prev) => !prev)}}  id="default-toggle-Moneda" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>

                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>FX</p>
                  <div>
                    <label for="default-toggle-FX" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[10].getToggleHiddenProps()} checked={fxActive} onClick={()=>{setFXActive((prev) => !prev)}}  id="default-toggle-FX" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>

                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Crédito/Débito</p>
                  <div>
                    <label for="default-toggle-CD" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[11].getToggleHiddenProps()} checked={accountTypeActive} onClick={()=>{setAccountTypeActive((prev) => !prev)}}  id="default-toggle-CD" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Clientes/Proveedores</p>
                  <div>
                    <label for="default-toggle-CP" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[12].getToggleHiddenProps()} checked={clientSupplierActive} onClick={()=>{setClientSupplierActive((prev) => !prev)}}  id="default-toggle-CP" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Notas</p>
                  <div>
                    <label for="default-toggle-Notas" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[13].getToggleHiddenProps()} checked={notesActive} onClick={()=>{setNotesActive((prev) => !prev)}}  id="default-toggle-Notas" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                <div className='text-[12px] 2xl:text-[16px] font-bold flex justify-between'>
                  <p>Información adicional</p>
                  <div>
                    <label for="default-toggle-info-adicional" class={"inline-flex relative items-center cursor-pointer "}>
                        <input type="checkbox" {...allColumns[14].getToggleHiddenProps()} checked={additionalInfoActive} onClick={()=>{setAdditionalInfoActive((prev) => !prev)}}  id="default-toggle-info-adicional" class="sr-only peer"/>
                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all "></div>
                    </label>
                  </div>
                </div>
                {/*<p className='text-xl text-md-gray mt-1 font-bold text-left mb-2'>Filas</p>
                <select
                  className='w-[100%] py-2 pl-4 bg-md-table-bg rounded-md border-1 shadow-sm mb-4 text-md-gray'
                  defaultValue={'Numero de transacciones visibles'}
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 50, 100, 500].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                  </select>
                <div className='border-b-1 mt-1 w-100% border-md-divider'></div>*/}
                <div className='grid grid-cols-4 text-[12px] 2xl:text-[16px] items-center 2xl:gap-y-4 gap-y-2 2xl:mt-8 mt-3'>
                  {rsOptions.length>0 && 
                  <>
                  <p className='font-bold text-left mr-8 col-start-1'>Empresa</p>
                  <Dropdown
                          options={rsOptions}
                          selected={rsID}
                          setSelected={setRSID}
                          className={'w-full col-span-3 bg-secondary-light rounded-md'}
                  /></>}
                  <p className='font-bold text-left mr-8 col-start-1'>Banco</p>
                  <Dropdown
                          options={[{'name': 'Todos'},...BankOptions]}
                          selected={bank}
                          setSelected={handleBankChange}
                          className={'w-full col-span-3 bg-secondary-light rounded-md'}
                      />
                  <p className='font-bold text-left mr-8 col-start-1'>Cuenta</p>
                  <Dropdown
                      options={accounts}
                      selected={account}
                      setSelected={setContextAccount}
                      className={' w-full col-span-3 bg-secondary-light rounded-md '}
                  />
                  
                  <p className='font-bold text-left mr-8 col-start-1'>Archivo</p>
                  <FileDropdown //aqui va dropdown de files
                      options={filesData}
                      selected={file}
                      setSelected={handleFileChange}
                      className={'  w-full col-span-3 bg-secondary-light rounded-md'}
                  />
                  <p className='font-bold text-left mr-8 col-start-1'>Fecha</p>
                  
                  <div className='col-span-3'>
                      <DatePicker
                      onChange={(update) => {
                        handleDateRangeChange(update);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      fixedHeight
                      className='bg-secondary-light w-full rounded-[7px] border-1 pl-6 py-2 '
                      showYearDropdown
                      dropdownMode="select"
                      isClearable
                    />
                  </div>
                  <button disabled={isLoading} className={`${isLoading ? 'bg-secondary-light text-md-gray' : 'bg-[#9191F4] text-white'} rounded-md text-center  px-2 mt-1 py-2 font-bold col-span-4`} onClick={()=>getTransactions('')}>Aplicar</button>
                </div>
                <AiOutlineCloseCircle onClick={()=>setOpenPreferencesModal(false)} className='text-tertiary h-6 w-6 absolute right-0 -top-2 cursor-pointer'/>
                </div>
                {/*<div className='flex justify-center items-center mt-4'>
                  <button className='bg-md-blue text-md-white py-2 px-4 mr-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Guardar cambios</button>
                  <button className='bg-md-table-bg text-md-gray border-1 shadow-sm py-2 px-4 ml-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Cancelar</button>
                    </div>*/}
              </div>
            </div>}
         
            
          {/*<a href='/subcategories'>Subcategories settings</a>*/}
          <div className='h-[100%]  overflow-y-scroll min-h-[70vh] overflow-x-hidden'>
         
            <div className='min-h-20 max-h-28 flex justify-between'>
            <div className='flex mb-4 mt-1 h-full'>
                
              </div>
              <div className=' mr-2 w-[32rem] right-0 justify-start flex flex-col mb-12'>
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                
              </div>
            </div>
            
            <div className='flex  z-50 relative mb-1'>
              {/*<div className='ml-1 min-w-[400px] max-w-[400px]'>
                <FileDropdown //aqui va dropdown de files
                  options={filesData}
                  selected={file}
                  setSelected={handleFileChange}
                  className={' mb-2 border-gray-300 border-b-2'}
                />
              </div>*/}
                    
                    <div className='flex justify-between items-center px-2 2xl:py-2 py-0 bg-secondary-light w-full rounded-[7px] text-[14px] 2xl:text-[18px]'>
                     <div className='flex'>
                     {contextSubcategoryId>0 && 
                      <div className='items-center flex px-2 rounded-[7px] bg-secondary-light-2 2xl:text-[16px] text-[12px] '>
                        <p className='text-clip w-48'>Filtrado por: {categoriesDict[contextSubcategoryId]?.name}</p> 
                      <AiOutlineCloseCircle onClick={()=>setContextSubcategoryId()} className='text-tertiary pl-1 h-6 w-6 cursor-pointer'/>
                      </div>}
                      {contextRSID.length>0 && 
                      <div title={contextRSIDText()} className='items-center flex px-2 ml-1 rounded-[7px] bg-secondary-light-2 2xl:text-[16px] text-[12px] '>
                      <p className='text-clip w-48'>Filtrado por {contextRSID.length} empresas</p> 
                    <AiOutlineCloseCircle onClick={()=>setContextRSID([])} className='text-tertiary pl-1 h-6 w-6 cursor-pointer'/>
                    </div>}
                     </div>
                     <div className='bg-secondary-light overflow-visible flex justify-end rounded-[7px] h-8 px-4  right-2'>

                      <ClassificationExcelExport excelData={rows} fileName={'Export'}/>
                        <button onClick={()=>setOpenPreferencesModal(true)} title='Editar Preferencias' className={`hover:bg-white h-full px-1 py-1 mr-2 ml-1 rounded-[7px] flex justify-between items-center ${isLoading===false && data.length===0 && 'animate-pulsebg'}`}>
                          <span className='2xl:scale-100  scale-75'><SettingsLogo title='Preferencias' className='h-8 w-8 text-md-blue'/></span>
                        </button>
                        <div className='flex items-center justify-center '>
                            <button className={showState=='nonClassified'?activeClass:unactiveClass} onClick={()=>handleShowStateChange('nonClassified')}>No Clasificadas</button>
                            <button className={showState=='classified'?activeClass:unactiveClass} onClick={()=>handleShowStateChange('classified')}>Clasificadas</button>
                            <button className={showState=='auto'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('auto')}>Automáticas</button>
                            <button className={showState=='divided'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('divided')}>Divididas</button>
                            <button className={showState=='all'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('all')}>Todas</button>
                        </div>
                      </div>
                      
                    </div>
                    
                    
                      
                      
                </div>
                <div className=' block max-w-[100%] overflow-scroll no-scrollbar'>
                <table {...getTableProps()} className=' dark:bg-secondary-dark-bg dark:text-white rounded-[7px] w-full border-spacing-0  '>
                  <thead className=' text-center sticky top-0 z-30 h-10 rounded-[7px] text-[15px] '>
                  
                    
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()} className={' py-2 '}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()} className={column.className + ' hover:text-tertiary '}>
                          {/* Render the columns filter UI */}
                         {<div className='text-[10px] mb-2'>{column.canFilter && column.render('Filter')}</div>}
                         {column.id=='selection' ?
                           column.render('Header'):
                           column.id=='index' ? 
                           <p className='text-[16px]'>{column.Header}
                           </p> :
                           <p className={`flex items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'?' flex justify-end':''}`} onClick={()=>{
                             const desc =
                             column.isSortedDesc === true ?
                             undefined :
                             column.isSortedDesc === false ?
                             true :
                             false ;
                             if(desc===undefined){
                               setSortBy([/*...sortees*/])
                             }else if(desc===true){
                             setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
                             }else{
                               setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
                             }
                             
                             
                           }} >{column.Header}
                           <span className='ml-1'>
                           {column.isSorted
                             ? column.isSortedDesc
                               ? <Triangulo/>
                               : <div className='rotate-180'><Triangulo/></div>
                             : <></>}
                         </span></p>
                         }
                         
                         {column.id === 'context' &&
                                <div
                                {...column.getResizerProps()}
                                className={` inline-block bg-tertiary w-[5px] h-full absolute right-1 top-0 translate-x-[50%] z-1 touch-none ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                              />
                                }
                        
                       </th>
                        ))}
                        
                      </tr>
                    ))}
                    
                  </thead>
                  <tbody {...getTableBodyProps()} className='text-sm'>
                  {isLoading ? 
                  <>
                    <tr className='relative'>
                        <p className='absolute w-[100%] font-bold text-md-blue text-center mt-10 text-lg'>Buscando...</p>
                    </tr>
                  </> :
                  file.id || bank.id || (startDate!=null && endDate!=null) ? 
                    (data.length==0 && account.id ?
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] font-bold text-md-blue text-center mt-10 text-lg'>No hay transacciones por clasificar en esta cuenta</p>
                      </tr>
                    </> : 
                    data.length==0 && (startDate!=null && endDate!=null) ? 
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] font-bold text-md-blue text-center mt-10 text-lg'>No hay transacciones por clasificar entre estas fechas</p>
                      </tr>
                    </> :
                    file.id && data.length===0 && showState === 'nonClassified'?
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] font-bold text-md-blue text-center mt-10 text-lg'>Este archivo ya se ha clasificado por completo</p>
                      </tr>
                    </>
                    :
                    <></>): data.length>0 ? 
                    <></> :
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] font-bold text-md-blue text-center mt-10 text-lg'>Selecciona un archivo o cuenta de banco</p>
                      </tr>
                    </>
                    }  
                  <FixedSizeList
                  className=' no-scrollbar text-[12px] mt-8'
                      height={document.documentElement.clientHeight*.40}
                      itemCount={rows.length}
                      itemSize={40}
                    >
                      {RenderRow(rows)}
                    </FixedSizeList>
                  </tbody>
                </table>
                </div>
              </div>
              
        </div>
    )
}

export default ClassificationOnlyViewScreen