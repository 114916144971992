import React from 'react'

const TyCSect16Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_111" data-name="Enmascarar grupo 111" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1133" data-name="Grupo 1133" transform="translate(491.958 226.951)">
        <path id="Trazado_1393" data-name="Trazado 1393" d="M22.786,11.5V4.988a1.469,1.469,0,0,0-2.939,0" transform="translate(11.852 1.851)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1394" data-name="Trazado 1394" d="M19.183,22.187a7.258,7.258,0,0,1-2.208-2.316l-3.157-5.286a1.5,1.5,0,0,1,.518-2.061,1.548,1.548,0,0,1,.773-.2,1.486,1.486,0,0,1,1.29.732l1.4,2.336V5.187A1.523,1.523,0,0,1,18.891,3.7a1.481,1.481,0,0,1,1.847,1.427" transform="translate(8.025 1.934)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1395" data-name="Trazado 1395" d="M21.672,6.595a1.476,1.476,0,0,1,1.837-1.427A1.532,1.532,0,0,1,24.6,6.653V17.829a4.561,4.561,0,0,1-1.563,3.46" transform="translate(12.97 2.833)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1396" data-name="Trazado 1396" d="M20.964,11.928V4.142a1.469,1.469,0,1,0-2.939,0v7.786" transform="translate(10.735 1.332)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1397" data-name="Trazado 1397" d="M16.53,16.863V15.534a.379.379,0,0,1,.384-.384h9.324a.381.381,0,0,1,.385.384v1.613a.4.4,0,0,1-.385.337H22.393" transform="translate(9.819 8.98)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1398" data-name="Trazado 1398" d="M24.323,16.391v7.226a.711.711,0,0,1-.7.7H20.284" transform="translate(12.12 9.74)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1399" data-name="Trazado 1399" d="M11.51,2.32H5A1.469,1.469,0,0,0,5,5.259" transform="translate(1.85 1.116)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1400" data-name="Trazado 1400" d="M22.2,7.515A7.271,7.271,0,0,1,19.881,9.73L14.6,12.885a1.5,1.5,0,0,1-2.061-.518,1.545,1.545,0,0,1-.206-.773,1.49,1.49,0,0,1,.734-1.29l2.334-1.4H5.2a1.523,1.523,0,0,1-1.486-1.1A1.479,1.479,0,0,1,5.137,5.967" transform="translate(1.934 3.351)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1401" data-name="Trazado 1401" d="M6.6,3.431A1.476,1.476,0,0,1,5.177,1.594,1.534,1.534,0,0,1,6.663.5H17.839A4.558,4.558,0,0,1,21.3,2.063" transform="translate(2.832 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1402" data-name="Trazado 1402" d="M11.939,4.142H4.151a1.469,1.469,0,0,0,0,2.939h7.787" transform="translate(1.331 2.232)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1403" data-name="Trazado 1403" d="M17.5,5.4V1.588a.381.381,0,0,0-.384-.385H15.546a.381.381,0,0,0-.384.385v9.324a.379.379,0,0,0,.384.384h1.29" transform="translate(8.98 0.431)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1404" data-name="Trazado 1404" d="M16.4,1.2h7.226a.713.713,0,0,1,.706.706V5.26" transform="translate(9.739 0.429)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1405" data-name="Trazado 1405" d="M2.331,15.532v6.513a1.469,1.469,0,0,0,2.939,0" transform="translate(1.116 9.214)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1406" data-name="Trazado 1406" d="M7.525,8.855a7.258,7.258,0,0,1,2.208,2.316l3.157,5.286a1.5,1.5,0,0,1-.518,2.061,1.548,1.548,0,0,1-.773.2,1.486,1.486,0,0,1-1.29-.732L8.917,15.655V25.863a1.523,1.523,0,0,1-1.094,1.486,1.481,1.481,0,0,1-1.847-1.427" transform="translate(3.35 5.121)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1407" data-name="Trazado 1407" d="M3.442,23.549A1.476,1.476,0,0,1,1.6,24.976,1.532,1.532,0,0,1,.511,23.491V12.315a4.561,4.561,0,0,1,1.563-3.46" transform="translate(0 5.121)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1408" data-name="Trazado 1408" d="M4.153,15.589v7.786a1.469,1.469,0,0,0,2.939,0V15.589" transform="translate(2.232 9.249)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1409" data-name="Trazado 1409" d="M5.442,7.392H1.6a.4.4,0,0,0-.384.352V9.357A.389.389,0,0,0,1.6,9.74h9.324a.389.389,0,0,0,.385-.384V8.032" transform="translate(0.43 4.224)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1410" data-name="Trazado 1410" d="M1.213,10.624V3.4a.711.711,0,0,1,.7-.7H5.234" transform="translate(0.43 1.344)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1411" data-name="Trazado 1411" d="M15.523,22.8h6.511a1.469,1.469,0,0,0,0-2.939" transform="translate(9.201 11.865)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1412" data-name="Trazado 1412" d="M8.846,19.2a7.271,7.271,0,0,1,2.315-2.215l5.286-3.155a1.5,1.5,0,0,1,2.061.518,1.545,1.545,0,0,1,.206.773,1.49,1.49,0,0,1-.734,1.29l-2.334,1.4h10.2a1.523,1.523,0,0,1,1.486,1.1A1.479,1.479,0,0,1,25.9,20.744" transform="translate(5.109 8.036)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1413" data-name="Trazado 1413" d="M23.54,21.683a1.476,1.476,0,0,1,1.426,1.837,1.534,1.534,0,0,1-1.486,1.094H12.3a4.558,4.558,0,0,1-3.458-1.563" transform="translate(5.109 12.984)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1414" data-name="Trazado 1414" d="M15.579,20.975h7.787a1.469,1.469,0,0,0,0-2.939H15.579" transform="translate(9.236 10.748)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1415" data-name="Trazado 1415" d="M7.391,22.434v3.813a.381.381,0,0,0,.384.385H9.341a.381.381,0,0,0,.384-.385V16.923a.379.379,0,0,0-.384-.384H8.051" transform="translate(4.217 9.831)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        <path id="Trazado_1416" data-name="Trazado 1416" d="M10.614,24.343H3.388a.713.713,0,0,1-.706-.706V20.283" transform="translate(1.331 12.126)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.8"/>
        </g>
    </g>
    </svg>

  )
}

export default TyCSect16Logo