import React from 'react'

const TyCSect2Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_98" data-name="Enmascarar grupo 98" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1108" data-name="Grupo 1108" transform="translate(488 223)">
        <path id="Trazado_1327" data-name="Trazado 1327" d="M31.571,43.143H5.857A2.86,2.86,0,0,1,3,40.286V8.857A2.86,2.86,0,0,1,5.857,6h6.429a.952.952,0,1,1,0,1.9H5.857a.953.953,0,0,0-.952.952V40.286a.953.953,0,0,0,.952.952H31.571a.953.953,0,0,0,.952-.952V8.857a.953.953,0,0,0-.952-.952H25.143a.952.952,0,0,1,0-1.9h6.429a2.86,2.86,0,0,1,2.857,2.857V40.286a2.86,2.86,0,0,1-2.857,2.857Z" transform="translate(0 -0.143)" fill="#1d0039"/>
        <path id="Trazado_1328" data-name="Trazado 1328" d="M30.762,40.429H6.952A.952.952,0,0,1,6,39.476V9.952A.952.952,0,0,1,6.952,9H24.781a.952.952,0,0,1,0,1.9H7.9V38.524h21.9V10.9H28.466a.952.952,0,0,1,0-1.9h2.3a.952.952,0,0,1,.952.952V39.476a.952.952,0,0,1-.952.952Z" transform="translate(-0.143 -0.286)" fill="#1d0039"/>
        <path id="Trazado_1329" data-name="Trazado 1329" d="M25.031,10.619H13.186a1.906,1.906,0,0,1-1.849-2.366l.477-1.9A1.9,1.9,0,0,1,13.662,4.9h2.752a2.858,2.858,0,0,1,5.389,0h2.752A1.9,1.9,0,0,1,26.4,6.348l.476,1.9a1.9,1.9,0,0,1-1.848,2.366ZM13.662,6.81l-.477,1.9H25.031l-.475-1.9H21.013a.952.952,0,0,1-.952-.952.952.952,0,1,0-1.9,0,.952.952,0,0,1-.952.952Z" transform="translate(-0.394 0)" fill="#1d0039"/>
        <path id="Trazado_1330" data-name="Trazado 1330" d="M41.333,43.381a.952.952,0,0,1-.807-.447l-2.381-3.81A.952.952,0,0,1,38,38.619V11.952A.952.952,0,0,1,38.952,11h4.762a.952.952,0,0,1,.952.952V38.619a.952.952,0,0,1-.145.5l-2.381,3.81A.952.952,0,0,1,41.333,43.381ZM39.9,38.346l1.429,2.286,1.429-2.286V12.9H39.9Z" transform="translate(-1.667 -0.381)" fill="#1d0039"/>
        <path id="Trazado_1331" data-name="Trazado 1331" d="M43.714,12.667H38.952A.952.952,0,0,1,38,11.714V9.333a3.333,3.333,0,1,1,6.667,0v2.381a.952.952,0,0,1-.952.952Zm-3.81-1.9h2.857V9.333a1.429,1.429,0,0,0-2.857,0Z" transform="translate(-1.667 -0.143)" fill="#1d0039"/>
        <path id="Trazado_1332" data-name="Trazado 1332" d="M43.714,39.9H38.952a.952.952,0,1,1,0-1.9h4.762a.952.952,0,0,1,0,1.9Z" transform="translate(-1.667 -1.667)" fill="#1d0039"/>
        <g id="Grupo_1107" data-name="Grupo 1107" transform="translate(10.619 13.476)">
            <path id="Trazado_1333" data-name="Trazado 1333" d="M13.5,20a.949.949,0,0,1-.673-.279l-1.548-1.548a.952.952,0,1,1,1.347-1.347l.874.874,2.422-2.422a.952.952,0,1,1,1.347,1.347l-3.1,3.1A.949.949,0,0,1,13.5,20Z" transform="translate(-11 -14.048)" fill="#1d0039"/>
            <path id="Trazado_1334" data-name="Trazado 1334" d="M26.619,15.9H19.952a.952.952,0,0,1,0-1.9h6.667a.952.952,0,1,1,0,1.9Z" transform="translate(-11.381 -14)" fill="#1d0039"/>
            <path id="Trazado_1335" data-name="Trazado 1335" d="M24.714,19.9H19.952a.952.952,0,0,1,0-1.9h4.762a.952.952,0,1,1,0,1.9Z" transform="translate(-11.381 -14.19)" fill="#1d0039"/>
            <path id="Trazado_1336" data-name="Trazado 1336" d="M13.5,29a.949.949,0,0,1-.673-.279l-1.548-1.548a.952.952,0,1,1,1.347-1.347l.874.874,2.422-2.422a.952.952,0,1,1,1.347,1.347l-3.1,3.1A.949.949,0,0,1,13.5,29Z" transform="translate(-11 -14.476)" fill="#1d0039"/>
            <path id="Trazado_1337" data-name="Trazado 1337" d="M26.619,24.9H19.952a.952.952,0,0,1,0-1.9h6.667a.952.952,0,1,1,0,1.9Z" transform="translate(-11.381 -14.429)" fill="#1d0039"/>
            <path id="Trazado_1338" data-name="Trazado 1338" d="M24.714,28.9H19.952a.952.952,0,0,1,0-1.9h4.762a.952.952,0,1,1,0,1.9Z" transform="translate(-11.381 -14.619)" fill="#1d0039"/>
            <path id="Trazado_1339" data-name="Trazado 1339" d="M13.5,37a.949.949,0,0,1-.673-.279l-1.548-1.548a.952.952,0,1,1,1.347-1.347l.874.874,2.422-2.422a.952.952,0,1,1,1.347,1.347l-3.1,3.1A.949.949,0,0,1,13.5,37Z" transform="translate(-11 -14.857)" fill="#1d0039"/>
            <path id="Trazado_1340" data-name="Trazado 1340" d="M26.619,33.9H19.952a.952.952,0,1,1,0-1.9h6.667a.952.952,0,1,1,0,1.9Z" transform="translate(-11.381 -14.857)" fill="#1d0039"/>
            <path id="Trazado_1341" data-name="Trazado 1341" d="M24.714,37.9H19.952a.952.952,0,1,1,0-1.9h4.762a.952.952,0,1,1,0,1.9Z" transform="translate(-11.381 -15.048)" fill="#1d0039"/>
        </g>
        </g>
    </g>
    </svg>

  )
}

export default TyCSect2Logo