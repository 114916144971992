import { useCallback, useEffect } from 'react';
import MessageToast from './MessageToast';

const MessageHandler = ({ toastList, setToastList }) => {

    const deleteToast = useCallback(id => {
      const toastListItem = toastList.filter(e => e.id !== id);
      setToastList(toastListItem);
    }, [toastList, setToastList]);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if(toastList.length) {
          deleteToast(toastList[0].id);
        }
      }, 6000);
  
      return () => {
        clearInterval(interval);
      }
    }, [toastList, deleteToast]);
  
    return (
      <div className=' fixed bottom-5 right-5 z-[10000]'>
        {
          toastList.map((toast) => (
            <MessageToast type={toast.alertType} title={toast.alertTitle} message={toast.alertMessage} id={toast.id} close={deleteToast}/>
          ))
        }
      </div>
    )
  }

export default MessageHandler;