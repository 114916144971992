import React from 'react'

const ResetLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_96" data-name="Enmascarar grupo 96" transform="translate(-874 -224)" clip-path="url(#clip-path)">
        <g id="Grupo_1102" data-name="Grupo 1102" transform="translate(875 224)">
          <g id="Grupo_1101" data-name="Grupo 1101" clip-path="url(#clip-path-2)">
            <path id="Trazado_1322" data-name="Trazado 1322" d="M26.667,2.5V6.267a2.5,2.5,0,0,1-.733,1.767l-.1.1a.48.48,0,0,1-.35.15.835.835,0,0,1-.2-.033A9.152,9.152,0,0,0,16.45,24.185a.487.487,0,0,1,.213.4V30L10,25V18.333a2.5,2.5,0,0,0-.733-1.77L.733,8.033A2.5,2.5,0,0,1,0,6.263V0H26.667Zm-5,7.5a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,21.667,10m2.25,7.75a.8.8,0,0,1,.029,1.138l-.029.029a.8.8,0,0,1-1.138.029l-.029-.029L21.667,17.85l-1.083,1.067a.8.8,0,0,1-1.138.029l-.029-.029a.8.8,0,0,1-.029-1.138l.029-.029,1.067-1.083-1.067-1.083a.863.863,0,0,1,.713-1.457,1.582,1.582,0,0,1,.454.291l1.083,1.067,1.083-1.067A.85.85,0,0,1,24.2,15a1.036,1.036,0,0,1-.28.58L22.85,16.667Z" transform="translate(0)"/>
          </g>
        </g>
      </g>
    </svg>


  )
}

export default ResetLogo
