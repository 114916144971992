import React from 'react'

const TyCSect7Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
        <g id="Enmascarar_grupo_102" data-name="Enmascarar grupo 102" transform="translate(-491 -226)" clip-path="url(#clip-path)">
            <g id="Grupo_1112" data-name="Grupo 1112" transform="translate(491 226)">
            <path id="Trazado_1349" data-name="Trazado 1349" d="M39.657,12.3,27.7.343A1.172,1.172,0,0,0,26.875,0H13.125A1.172,1.172,0,0,0,12.3.343L.343,12.3A1.172,1.172,0,0,0,0,13.125v13.75a1.172,1.172,0,0,0,.343.829L12.3,39.657a1.172,1.172,0,0,0,.829.343h13.75a1.172,1.172,0,0,0,.829-.343L39.657,27.7A1.172,1.172,0,0,0,40,26.875V13.125A1.172,1.172,0,0,0,39.657,12.3Zm-2,14.093L26.39,37.656H13.61L2.344,26.39V13.61L13.61,2.344H26.39L37.656,13.61Z" transform="translate(0 0)"/>
            <path id="Trazado_1350" data-name="Trazado 1350" d="M135.238,67.5a3,3,0,0,0-.685.092,3.134,3.134,0,0,0-.893-1.721A3,3,0,0,0,131.488,65a3.066,3.066,0,0,0-2.954,2.591,3,3,0,0,0-2.851.781,3.126,3.126,0,0,0-.933,2.217v3.244a3,3,0,0,0-2.817.789A3.126,3.126,0,0,0,121,76.839v8.188a9.818,9.818,0,0,0,1.4,5.05h0a9.866,9.866,0,0,0,8.417,4.766h1.461a9.816,9.816,0,0,0,9.816-9.816V73.089a3.126,3.126,0,0,0-.933-2.217,3,3,0,0,0-2.857-.779,3.134,3.134,0,0,0-.893-1.721A3,3,0,0,0,135.238,67.5Zm3.794,4.843a.684.684,0,0,1,.486.2.768.768,0,0,1,.231.544V85.027a7.473,7.473,0,0,1-7.472,7.472h-1.461a7.472,7.472,0,0,1-7.473-7.472V76.839a.739.739,0,0,1,.717-.745.706.706,0,0,1,.689.7v6.25a1.15,1.15,0,0,0,.943,1.149,5.247,5.247,0,0,1,3.57,3.6,1.172,1.172,0,0,0,2.224-.741,7.714,7.714,0,0,0-4.393-4.85V70.589a.768.768,0,0,1,.231-.544.679.679,0,0,1,.486-.2.706.706,0,0,1,.689.7v9.375a1.172,1.172,0,0,0,2.344,0V68.047a.706.706,0,0,1,.689-.7.685.685,0,0,1,.486.2.768.768,0,0,1,.231.544V79.922a1.172,1.172,0,0,0,2.344,0V70.547a.706.706,0,0,1,.689-.7.694.694,0,0,1,.486.2.768.768,0,0,1,.231.544V81.8a1.172,1.172,0,0,0,2.344,0v-8.75A.706.706,0,0,1,139.033,72.344Z" transform="translate(-111.547 -59.922)"/>
            </g>
        </g>
        </svg>

  )
}

export default TyCSect7Logo