import React from 'react'

const EditIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
  <g id="Enmascarar_grupo_49" data-name="Enmascarar grupo 49" transform="translate(-645 -743)" clip-path="url(#clip-path)">
    <g id="Grupo_958" data-name="Grupo 958" transform="translate(645.012 743)">
      <g id="Grupo_790" data-name="Grupo 790" clip-path="url(#clip-path-2)">
        <path id="Trazado_959" data-name="Trazado 959" d="M0,17.152C0,13.983,0,10.814,0,7.645a3.15,3.15,0,0,1,2.523-3.18,4.826,4.826,0,0,1,1-.076q8.052-.006,16.1,0a.9.9,0,0,0,.716-.3c.988-1.024,1.992-2.033,2.988-3.048A2.883,2.883,0,0,1,27.729,1c.4.392.808.782,1.2,1.183a2.856,2.856,0,0,1,0,4.141q-1.563,1.606-3.146,3.195a.885.885,0,0,0-.278.692c.008,5.429,0,10.858.008,16.287a3.135,3.135,0,0,1-1.943,3.141,3.72,3.72,0,0,1-1.428.259c-2.642.024-5.284.012-7.926.012q-5.47,0-10.939,0a3.163,3.163,0,0,1-3.23-2.64A4.416,4.416,0,0,1,0,26.479q0-4.664,0-9.327M17.931,6.523c-.126-.008-.173-.014-.22-.014q-7.209,0-14.419,0A1.087,1.087,0,0,0,2.121,7.742q0,9.4,0,18.8A1.1,1.1,0,0,0,3.35,27.787q9.415,0,18.83,0a1.093,1.093,0,0,0,1.213-1.227q0-7.1,0-14.206c0-.1-.014-.2-.021-.3l-.105-.067a1.893,1.893,0,0,1-.2.3q-3.531,3.6-7.066,7.188a2.979,2.979,0,0,1-1.711.9c-.968.15-1.932.324-2.9.464a1.863,1.863,0,0,1-2.15-2.176c.114-.78.245-1.56.318-2.344a3.977,3.977,0,0,1,1.238-2.543c2.3-2.286,4.554-4.611,6.826-6.921.09-.091.176-.186.311-.33m4.6-1.7c-.128.163-.2.276-.291.369-3.133,3.19-6.25,6.4-9.417,9.552a3.491,3.491,0,0,0-1.245,2.495,13.3,13.3,0,0,1-.181,1.448c.836-.131,1.617-.228,2.386-.388a1.642,1.642,0,0,0,.793-.417q5.11-5.161,10.194-10.348c.073-.075.14-.155.155-.171L22.527,4.818m3.918.928c.384-.367.735-.675,1.051-1.015a.674.674,0,0,0-.006-.973c-.471-.492-.958-.968-1.457-1.431a.663.663,0,0,0-.944-.015c-.365.322-.7.675-.989.953l2.345,2.481" transform="translate(0 0)"/>
      </g>
    </g>
  </g>
</svg>
  )
}

export default EditIcon