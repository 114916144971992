import React from 'react'

const TyCSect10Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_105" data-name="Enmascarar grupo 105" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1115" data-name="Grupo 1115" transform="translate(491 226)">
        <path id="Trazado_1360" data-name="Trazado 1360" d="M36.062,265.472a4.937,4.937,0,1,0-7.02,0,7.533,7.533,0,0,0-2.766,2.559,7.532,7.532,0,0,0-2.766-2.559,4.937,4.937,0,1,0-7.02,0,7.532,7.532,0,0,0-2.766,2.559,7.533,7.533,0,0,0-2.766-2.559,4.937,4.937,0,1,0-7.02,0A7.447,7.447,0,0,0,0,272.046v3.766a1.172,1.172,0,0,0,1.172,1.172H38.828A1.172,1.172,0,0,0,40,275.811v-3.766A7.443,7.443,0,0,0,36.062,265.472ZM29.958,262a2.594,2.594,0,1,1,2.594,2.594A2.6,2.6,0,0,1,29.958,262Zm-12.552,0A2.594,2.594,0,1,1,20,264.6,2.6,2.6,0,0,1,17.406,262ZM4.854,262A2.594,2.594,0,1,1,7.448,264.6,2.6,2.6,0,0,1,4.854,262Zm-2.51,10.042a5.1,5.1,0,0,1,10.208,0v2.594H2.344Zm12.552,0a5.1,5.1,0,0,1,10.208,0v2.594H14.9Zm22.76,2.594H27.448v-2.594a5.1,5.1,0,0,1,10.208,0Zm0,0" transform="translate(0 -236.983)"/>
        <path id="Trazado_1361" data-name="Trazado 1361" d="M210.671,79.123l1.682-1.682,1.682,1.682a1.172,1.172,0,0,0,1.657-1.657l-1.682-1.682,1.682-1.682a1.172,1.172,0,0,0-1.657-1.657l-1.682,1.682-1.682-1.682a1.172,1.172,0,0,0-1.657,1.657l1.682,1.682-1.682,1.682A1.172,1.172,0,0,0,210.671,79.123Zm0,0" transform="translate(-192.352 -66.453)"/>
        <path id="Trazado_1362" data-name="Trazado 1362" d="M145.9,0a9.331,9.331,0,1,0,9.331,9.331A9.331,9.331,0,0,0,145.9,0Zm0,16.318a6.987,6.987,0,1,1,6.987-6.987A6.987,6.987,0,0,1,145.9,16.318Zm0,0" transform="translate(-125.897)"/>
        </g>
    </g>
    </svg>
  )
}

export default TyCSect10Logo