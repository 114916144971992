
import React from 'react'

const ArrowUpCatsLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14.689" height="14.689" viewBox="0 0 14.689 14.689">
      <g id="Grupo_548" data-name="Grupo 548" transform="translate(14.689 14.689) rotate(180)">
        <g id="Grupo_233" data-name="Grupo 233" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Trazado_163" data-name="Trazado 163" d="M12.655,14.689H2.034A2.04,2.04,0,0,1,0,12.655V2.034A2.04,2.04,0,0,1,2.034,0h10.62a2.04,2.04,0,0,1,2.034,2.034v10.62a2.04,2.04,0,0,1-2.034,2.034" transform="translate(0 0)" fill={props.fill || "#9191f4"}/>
          <path id="Polígono_69" data-name="Polígono 69" d="M4.5,0,9,7H0Z" transform="translate(12.105 11) rotate(180)" fill="#f7f7ff"/>
        </g>
      </g>
    </svg>
  )
}

export default ArrowUpCatsLogo