import React, { useEffect, useRef, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye,AiOutlineEyeInvisible } from 'react-icons/ai';
import { Styles, Idioma, InfoI, MessageHandler } from '../components';
import { getToken } from '../fetch';

import { urlBase } from '../authConfig';
import LapizLogo from '../assets/LapizLogo';
import PlusIcon from '../assets/PlusIcon';
import Loading from '../animations/Loading.json'
import { Player } from '@lottiefiles/react-lottie-player';
import ArrowDownCatsLogo from '../assets/ArrowDownCatsLogo';
import ArrowUpCatsLogo from '../assets/ArrowUpCatsLogo';
import ThreeDots from '../assets/ThreeDots';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import UndoLogo from '../assets/UndoLogo';

const forbiddenNames = ["bank","date","id","context","reference","inflow","outflow","category","idCategory","subcategory","idSubCategory","exchange","updated","accountType","signature","notes","idAccount","idClientSupplier","clientSupplier","currency","rsName","extraText",
            "Empresa",'Company',
            "Banco",'Bank',
            'Cuenta','Account',
            "Fecha",'Date',
            "Concepto",'Description',
            "Abono",'Inflow',
            "Cargo",'Outflow',
            "Categoría",'Category',
            "Subcategoría",'Subcategory',
            "Moneda",'Currency',
            "FX",'FX',
            "Crédito/Débito",'Credit/Debit',
            "Clientes/Proveedores",'Customers/Vendors',
            "Notas",'Notes',
            "Información adicional",'Additional information', "Subcategoría cargada"]

const SubcategoriesLuisIdea = () => {
    
    const {idClient,accessToken,setToken,language,sessionID,idRS} = useStateContext()
    const navigate = useNavigate();

    const [isAdmin,setIsAdmin] = useState(false);

    const [categories,setCategories] = useState([])
    const isAuthenticated = useIsAuthenticated();
    const [categoriesL0Opened,setCategoriesL0Opened] = useState([])
    const [categoriesL1Opened,setCategoriesL1Opened] = useState([])
    const [newCategoryL0,setNewCategoryL0] = useState('')
   
    const [newCategoryL2,setNewCategoryL2] = useState('')
    const [provisionalIdCount,setProvisionalIdCount] = useState(0)

    const [tasks,setTasks] = useState({})
    const categoriesToUpdate = Object.getOwnPropertyNames(tasks)
    const [categoriesToDelete,setCategoriesToDelete] = useState([])
    const [loading,setLoading] = useState(true)

    const [getOutWithChanges,setGetOutWithChanges] = useState(false)

    const [clientOptions,setClientOptions] = useState([])
    const [supplierOptions,setSupplierOptions] = useState([])
    const [clientsExpanded,setClientsExpanded] = useState(false)
    const [suppliersExpanded,setSuppliersExpanded] = useState(false)
    const [clientSupplierTasks,setClientSupplierTasks] = useState({})
    const clientSupplierToUpdate = Object.getOwnPropertyNames(clientSupplierTasks)

    const [extraColumnsExpanded, setExtraColumnExpanded] = useState(false)
    const [extraColumnsDict, setExtraColumnsDict]  = useState({})
    const [columnsInUseDict, setColumnsInUseDict]  = useState({})
    const [extraColumnsKeys, setExtraColumnsKeys]  = useState([])
    const [columnsTasks,setColumnsTasks] = useState({})
    const columnsToUpdate = Object.getOwnPropertyNames(columnsTasks)
    
    const updateDataDisabled = categoriesToUpdate.length==0 && clientSupplierToUpdate.length==0 && categoriesToDelete.length==0 && columnsToUpdate.length==0

    const [accountLinkedCategories,setAccountLinkedCategories] = useState({})

    const [toastList, setToastList] = useState([])

    useEffect(()=>{
        if(!isAuthenticated){
            navigate('/')
            return
        }
        getToken().then((token)=>{setToken(token)})
        getData()
     
    },[])

    useEffect(()=>{
        if(idRS){
            console.log('se intentó')
            newToast('warning', Idioma.toastsBasicos.aviso[language], Idioma.catalogoCuentas.entrarModoEmpresas[language])
        }
    },[idRS])

    const getData = async () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/getCategories`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              clientID: idClient,
              lowest: 0,
              showHidden: true,
              showCreditFinancing: true,
              getEditingInfo: true,
              forReport:true,
              logsInfo:{
                  origin:'ChartOfAccounts',
                  sessionID:sessionID
                }
            }),
          })
        .then((res) => res.json())
        .then((aux) => {
            /*let aux2 = {}
            let aux3 = {}
            for(let i=0;i<aux.length;i++){
                aux2[aux[i].idCategory]=0
                for(let j=0;j<aux[i].subcategories.length;j++){
                    aux3[aux[i].subcategories[j].idCategory]=0
                }
            }*/
            //console.log('categories aux',aux)
            setCategories(aux.categories)
            setAccountLinkedCategories(aux.notUse)
            // setCategoriesL0Opened(aux2)
            // setCategoriesL1Opened(aux3)
            setLoading(false)
        })
        .catch((err) => console.log(err));
        let body = {
            "clientID": idClient,
            logsInfo: {
              origin:'ChartOfAccounts',
            sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/getClientSupplier`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
          })
            .then((response) => response.json())
            .then((aux) => {
              setClientOptions(aux.clients)
              setSupplierOptions(aux.suppliers)
              /*let auxDict = {}
              for(let i=0;i<aux.clients.length;i++){
                auxDict[aux.clients[i].idClientSupplier] = {
                  'name':aux.clients[i].clientSupplierName,
                  "isActive" : aux.clients[i].isActive === true,
                }
              }
              for(let i=0;i<aux.suppliers.length;i++){
                auxDict[aux.suppliers[i].idClientSupplier] = {
                  'name':aux.suppliers[i].clientSupplierName,
                  "isActive" : aux.suppliers[i].isActive === true,
                }
              }
              setClientSupplierDict(auxDict)*/
              //console.log('clientSupplierDict: ',auxDict)
              
          });
        fetch(`${urlBase}/isAdmin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                logsInfo:{
                    origin:'ChartOfAccounts',
                    sessionID:sessionID
                  }
        })
        })
        .then((response) => response.json())
        .then((aux) => {
            setIsAdmin(aux.role==='ADMIN')
        });
        fetch(`${urlBase}/getColumns`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
          })
            .then((response) => response.json())
            .then((aux) => {
              let aux2 = Object.getOwnPropertyNames(aux[0]);
              setExtraColumnsDict(aux[0])
              setColumnsInUseDict(aux[1])
              setExtraColumnsKeys(aux2)
          });
    }

    const updateData = () => {
        if(categoriesToUpdate.length>0 || categoriesToDelete.length>0){
            updateCategories()
        }
        if(clientSupplierToUpdate.length>0){
            updateClientSupplier()
        }
        if(columnsToUpdate.length>0){
            updateColumns()
        }
    }

    const updateCategories = () => {
        setLoading(true)
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            tasks:tasks,
            categoriesToUpdate:categoriesToUpdate,
            categoriesToDelete:categoriesToDelete,
            logsInfo:{
                origin:'ChartOfAccounts',
                sessionID:sessionID
            }
          }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/updateCategories`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .then((aux) => {
            cancelEdition()
        })
        .catch((err) => console.log(err));
    }

    const updateClientSupplier = () => {
        setLoading(true)
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            clientSupplierTasks:clientSupplierTasks,
            clientSupplierToUpdate:clientSupplierToUpdate,
            logsInfo:{
                origin:'ChartOfAccounts',
                sessionID:sessionID
              }
          }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/updateClientSupplier`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .then((aux) => {
            cancelEdition()
        })
        .catch((err) => console.log(err));
    }

    const updateColumns = () => {
        setLoading(true)
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            columnsTasks:columnsTasks,
            columnsToUpdate:columnsToUpdate,
            logsInfo:{
                origin:'ChartOfAccounts',
                sessionID:sessionID
              }
          }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/updateColumns`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .then((aux) => {
            cancelEdition()
        })
        .catch((err) => console.log(err));
    }

    const cancelEdition = () => {
        setLoading(true)
        setTasks({})
        setCategoriesToDelete([])
        setClientSupplierTasks({})
        setColumnsTasks({})
        setProvisionalIdCount(0)
        getData()
        setGetOutWithChanges(false)
    }

    const handleExpandCategoryL0 = (id) => {
		let aux = {...categoriesL0Opened}
		aux[id] = categoriesL0Opened[id]===1 ? 0 : 1
		setCategoriesL0Opened(aux)
	}

    const handleExpandCategoryL1 = (id) => {
		let aux = {...categoriesL1Opened}
		aux[id] = categoriesL1Opened[id]===1 ? 0 : 1
		setCategoriesL1Opened(aux)
	}

    const handleCategoryL1NameChange = (value,indexL0,indexL1) => {
        if(value === categories[indexL0].subcategories[indexL1].category){
        }else{
            let aux = [...categories]
            if(aux[indexL0].subcategories.find((subcategory) => subcategory.category === value)){
                newToast('error', Idioma.transacciones.mensajeError[language], Idioma.catalogoCuentas.categoriaNombreDuplicado[language])

                return
            }
            aux[indexL0].subcategories[indexL1].category = value
            setCategories(aux)
            //let auxTasks = {...tasks, [aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:['updateName',value] }
            //auxTasks[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory] = ['updateName',value]
            setTasks((prev)=>({
                ...prev,
                [aux[indexL0].subcategories[indexL1].idCategory]:{
                    ...prev[aux[indexL0].subcategories[indexL1].idCategory],
                    'updateName':value,
                    'idCategoryFather': categories[indexL0].subcategories[indexL1].idCategoryFather
                }
            }))
        }
    }

    const handleCategoryL2NameChange = (value,indexL0,indexL1,indexL2) => {
        if(value === categories[indexL0].subcategories[indexL1].subcategories[indexL2].category){
        }else{
            let aux = [...categories]
            if(aux[indexL0].subcategories[indexL1].subcategories.find((subcategory) => subcategory.category === value)){
                newToast('error', Idioma.transacciones.mensajeError[language], Idioma.catalogoCuentas.subcategoriaNombreDuplicado[language])
                return
            }
            aux[indexL0].subcategories[indexL1].subcategories[indexL2].category = value
            setCategories(aux)
            //let auxTasks = {...tasks, [aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:['updateName',value] }
            //auxTasks[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory] = ['updateName',value]
            setTasks((prev)=>({
                ...prev,
                [aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:{
                    ...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory],
                    'updateName':value,
                    'idCategoryFather': categories[indexL0].subcategories[indexL1].subcategories[indexL2].idCategoryFather
                }
            }))
        }
    }

    const handleClientSupplierNameChange = (value,isSupplier,index) => {
        if(!isSupplier){
            if(value === clientOptions[index].clientSupplierName){
            }else{
                let aux = [...clientOptions]
                aux[index].clientSupplierName = value
                setClientOptions(aux)
                //let auxTasks = {...tasks, [aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:['updateName',value] }
                //auxTasks[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory] = ['updateName',value]
                setClientSupplierTasks((prev)=>({...prev, [aux[index].idClientSupplier]:{...prev[aux[index].idClientSupplier],'updateName':value} }))
            }
        }else{
            if(value === supplierOptions[index].clientSupplierName){
            }else{
                let aux = [...supplierOptions]
                aux[index].clientSupplierName = value
                setSupplierOptions(aux)
                //let auxTasks = {...tasks, [aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:['updateName',value] }
                //auxTasks[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory] = ['updateName',value]
                setClientSupplierTasks((prev)=>({...prev, [aux[index].idClientSupplier]:{...prev[aux[index].idClientSupplier],'updateName':value} }))
            }
        }
        
    }

    const handleColumnNameChange = (value,accessor) => {
        //console.log(value,accessor, extraColumnsDict[accessor])
        if(value === extraColumnsDict[accessor]){
        }else{
            if(forbiddenNames.includes(value)){
                newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.catalogoCuentas.nombreInvalido[language]}: ${value}`)
            }else if(extraColumnsKeys.map((key)=>extraColumnsDict[key]).includes(value)){
                newToast('error', Idioma.transacciones.mensajeError[language], `${Idioma.catalogoCuentas.nombreExistente[language]}: ${value}`)
            }else{
                let aux = {...extraColumnsDict}
                aux[accessor] = value
                setExtraColumnsDict(aux)
                let taskAux = {...columnsTasks}
                taskAux[accessor] = value
                setColumnsTasks(taskAux)
                //console.log(aux, taskAux)
            }
        }
        
    }

    const handleMoveUpdwardCategoryL0 = (indexL0) => {
        let aux = [...categories]
        let aux1 = categories[indexL0]
        let aux2 = categories[indexL0-1]
        aux[indexL0]=aux2
        aux[indexL0-1]=aux1
        setCategories(aux)
    }

    const handleMoveDownwardCategoryL0 = (indexL0) => {
        let aux = [...categories]
        let aux1 = categories[indexL0]
        let aux2 = categories[indexL0+1]
        aux[indexL0]=aux2
        aux[indexL0+1]=aux1
        setCategories(aux)
    }

    const handleMoveUpdwardCategoryL1 = (indexL0,indexL1) => {
        let aux = [...categories]
        let aux1 = categories[indexL0].subcategories[indexL1]
        let aux2 = categories[indexL0].subcategories[indexL1-1]
        let aux1Order = aux1.orderNumber
        let aux2Order = aux2.orderNumber
        aux[indexL0].subcategories[indexL1]=aux2
        aux[indexL0].subcategories[indexL1-1]=aux1
        aux[indexL0].subcategories[indexL1].orderNumber=aux1Order
        aux[indexL0].subcategories[indexL1-1].orderNumber=aux2Order
        setCategories(aux)
        setTasks((prev)=>({...prev,[aux[indexL0].subcategories[indexL1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1].orderNumber},[aux[indexL0].subcategories[indexL1-1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1-1].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1-1].orderNumber}}))
    }

    const handleMoveDownwardCategoryL1 = (indexL0,indexL1) => {
        let aux = [...categories]
        let aux1 = categories[indexL0].subcategories[indexL1]
        let aux2 = categories[indexL0].subcategories[indexL1+1]
        let aux1Order = aux1.orderNumber
        let aux2Order = aux2.orderNumber
        aux[indexL0].subcategories[indexL1]=aux2
        aux[indexL0].subcategories[indexL1+1]=aux1
        aux[indexL0].subcategories[indexL1].orderNumber=aux1Order
        aux[indexL0].subcategories[indexL1+1].orderNumber=aux2Order
        setCategories(aux)
        setTasks((prev)=>({...prev,[aux[indexL0].subcategories[indexL1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1].orderNumber},[aux[indexL0].subcategories[indexL1+1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1+1].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1+1].orderNumber}}))
    }

    const handleMoveUpdwardCategoryL2 = (indexL0,indexL1,indexL2) => {
        let aux = [...categories]
        let aux1 = categories[indexL0].subcategories[indexL1].subcategories[indexL2]
        let aux2 = categories[indexL0].subcategories[indexL1].subcategories[indexL2-1]
        let aux1Order = aux1.orderNumber
        let aux2Order = aux2.orderNumber
        aux[indexL0].subcategories[indexL1].subcategories[indexL2]=aux2
        aux[indexL0].subcategories[indexL1].subcategories[indexL2-1]=aux1
        aux[indexL0].subcategories[indexL1].subcategories[indexL2].orderNumber=aux1Order
        aux[indexL0].subcategories[indexL1].subcategories[indexL2-1].orderNumber=aux2Order
        setCategories(aux)
        setTasks((prev)=>({...prev,[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1].subcategories[indexL2].orderNumber},[aux[indexL0].subcategories[indexL1].subcategories[indexL2-1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2-1].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1].subcategories[indexL2-1].orderNumber}}))
    }

    const handleMoveDownwardCategoryL2 = (indexL0,indexL1,indexL2) => {
        let aux = [...categories]
        let aux1 = categories[indexL0].subcategories[indexL1].subcategories[indexL2]
        let aux2 = categories[indexL0].subcategories[indexL1].subcategories[indexL2+1]
        let aux1Order = aux1.orderNumber
        let aux2Order = aux2.orderNumber
        aux[indexL0].subcategories[indexL1].subcategories[indexL2]=aux2
        aux[indexL0].subcategories[indexL1].subcategories[indexL2+1]=aux1
        aux[indexL0].subcategories[indexL1].subcategories[indexL2].orderNumber=aux1Order
        aux[indexL0].subcategories[indexL1].subcategories[indexL2+1].orderNumber=aux2Order
        setCategories(aux)
        setTasks((prev)=>({...prev,[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1].subcategories[indexL2].orderNumber},[aux[indexL0].subcategories[indexL1].subcategories[indexL2+1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2+1].idCategory],'updateOrder':aux[indexL0].subcategories[indexL1].subcategories[indexL2+1].orderNumber}} ))
    }

    const handleToggleVisibilityCategoryL0 = (indexL0) => {
        let aux = [...categories]
        aux[indexL0].isActive = aux[indexL0].isActive===true ? false : true
        setCategories(aux)
    }

    const handleToggleVisibilityCategoryL1 = (indexL0,indexL1) => {
        let aux = [...categories]
        let setActive = aux[indexL0].subcategories[indexL1].isActive===true ? false : true
        aux[indexL0].subcategories[indexL1].isActive = setActive
        setCategories(aux)
        setTasks((prev)=>( {...prev,[aux[indexL0].subcategories[indexL1].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].idCategory],'updateActive':setActive}} ))
        if(!setActive){
            for(let i=0;i<aux[indexL0].subcategories[indexL1].subcategories.length;i++){
                setVisibilityFalseCategoriesL2(indexL0,indexL1,i)
            }
        }
    }

    const handleToggleVisibilityCategoryL2 = (indexL0,indexL1,indexL2) => {
        let aux = [...categories]
        let setActive = aux[indexL0].subcategories[indexL1].subcategories[indexL2].isActive===true ? false : true
        aux[indexL0].subcategories[indexL1].subcategories[indexL2].isActive = setActive
        
        setCategories(aux)
        setTasks((prev)=>( {...prev,[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory],'updateActive':setActive}} ))
        if(categories[indexL0].subcategories[indexL1].isActive === false && setActive === true){
            handleToggleVisibilityCategoryL1(indexL0,indexL1)
        }
    }

    const handleToggleVisibilityClientSupplier = (isSupplier,index) => {
        if(!isSupplier){
            let aux = [...clientOptions]
            let setActive = aux[index].isActive===true ? false : true
            aux[index].isActive = setActive
            
            setClientOptions(aux)
            setClientSupplierTasks((prev)=>( {...prev,[aux[index].idClientSupplier]:{...prev[aux[index].idClientSupplier],'updateActive':setActive}} ))
        }else{
            let aux = [...supplierOptions]
            let setActive = aux[index].isActive===true ? false : true
            aux[index].isActive = setActive
            
            setSupplierOptions(aux)
            setClientSupplierTasks((prev)=>( {...prev,[aux[index].idClientSupplier]:{...prev[aux[index].idClientSupplier],'updateActive':setActive}} ))
        }
        
    }

    const setVisibilityFalseCategoriesL2 = (indexL0,indexL1,indexL2) => {
        let aux = [...categories]
        aux[indexL0].subcategories[indexL1].subcategories[indexL2].isActive = false
        
        setCategories(aux)
        setTasks((prev)=>( {...prev,[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory]:{...prev[aux[indexL0].subcategories[indexL1].subcategories[indexL2].idCategory],'updateActive':false}} ))
    }

    const addNewCategoryL0 = () => {
        let aux = [...categories]
        let auxToAdd = {
            category : newCategoryL0,
            categoryLevel: 0,
            flowType: null,
            idCategory: 'provisional'+provisionalIdCount,
            idCategoryFather: null,
            idClient: idClient,
            isActive: true,
            orderNumber: categories.length,
            subcategories: []
        }
        aux[categories.length] = auxToAdd
        setTasks((prev)=>({...prev,['provisional'+provisionalIdCount]:{'addCategory':auxToAdd}}))

        setProvisionalIdCount((prev)=>prev+1)
        setCategories(aux)
    }

    const AddNewCategoryL1 = (props) => {
        const [newCategoryL1,setNewCategoryL1] = useState('')
        const [flowType,setFlowType] = useState(true)
        const handleAddCategory = (indexL0) => {
            let aux = [...categories]
            let auxToAdd = {
                category : newCategoryL1,
                categoryLevel: 1,
                flowType: flowType,
                idCategory: 'provisionalA'+provisionalIdCount,
                idCategoryFather: categories[indexL0].idCategory,
                idClient: idClient,
                isActive: true,
                orderNumber: categories[indexL0].subcategories.length+1,
                subcategories: []
            }
            if(aux[indexL0].subcategories.length>0 && aux[indexL0].subcategories.find((subcategory) => subcategory.category === auxToAdd.category)){
                newToast('error', Idioma.transacciones.mensajeError[language], Idioma.catalogoCuentas.categoriaNombreDuplicado[language])

                return
            }
            aux[indexL0].subcategories[aux[indexL0].subcategories.length] = auxToAdd
            setTasks((prev)=>({...prev,['provisionalA'+provisionalIdCount]:{'addCategory':auxToAdd}}))
            setProvisionalIdCount((prev)=>prev+1)
            setCategories(aux)
        }

        return (
        <tr className=''>
            <td></td>
            <td className='flex justify-between items-center'>
                <input value={newCategoryL1} onChange={(e)=>setNewCategoryL1(e.target.value)} placeholder={Idioma.catalogoCuentas.agregarCategoria[language]}></input>
                <div className='flex items-center'>
                    
                    <div className='flex mx-2'>
                        <button disabled={flowType===true} className={`font-bold rounded-full w-4 h-4 flex items-center justify-center mr-1 text-white ${flowType===true ? 'bg-purple-1' : 'bg-[#b4b4b4]'}`} title={Idioma.catalogoCuentas.flujoPositivo[language]} onClick={()=>setFlowType((prev)=>!prev)}>+</button>
                        <button disabled={flowType===false} className={`font-bold rounded-full w-4 h-4 flex items-center justify-center mx-0 text-white ${flowType===false ? 'bg-purple-1' : 'bg-[#b4b4b4]'}`} title={Idioma.catalogoCuentas.flujoNegativo[language]} onClick={()=>setFlowType((prev)=>!prev)}>-</button>
                    </div>
                    

                    <button disabled={newCategoryL1.length==0} onClick={()=>handleAddCategory(props.indexL0)} title={Idioma.catalogoCuentas.agregar[language]} className=''><PlusIcon fill={`${newCategoryL1.length==0 ? "#d3d3d3": "#9191f4"}`}/></button>
                </div>
            </td>
            <td></td>
        </tr>
        )
    }

    const deleteProvisionalCategoryL1 = (value,indexL0,indexL1) => {
        let aux = {...tasks}
        let aux2 = [...categories]
        if(categories[indexL0].subcategories[indexL1].subcategories.length>0){
            for(let i=0;i<categories[indexL0].subcategories[indexL1].subcategories.length;i++){
                delete aux[categories[indexL0].subcategories[indexL1].subcategories[i].idCategory]
            }
        }
        delete aux[value]
        delete aux2[indexL0].subcategories[indexL1]
        setCategories(aux2)
        setTasks(aux)
    }

    const AddNewCategoryL2 = (props) => {
        const [newCategoryL2,setNewCategoryL2] = useState('')
        const handleAddCategory = (indexL0,indexL1) => {
            let aux = [...categories]
            let auxToAdd = {
                category : newCategoryL2,
                categoryLevel: 2,
                flowType: categories[indexL0].subcategories[indexL1].flowType=='+' || categories[indexL0].subcategories[indexL1].flowType==true,
                idCategory: 'provisionalB'+provisionalIdCount,
                idCategoryFather: categories[indexL0].subcategories[indexL1].idCategory,
                idClient: idClient,
                isActive: true,
                orderNumber: categories[indexL0].subcategories[indexL1].subcategories.length+1,
                subcategories: []
            }
            if(aux[indexL0].subcategories[indexL1].subcategories.find((subcategory) => subcategory.category === auxToAdd.category)){
                newToast('error', Idioma.transacciones.mensajeError[language], Idioma.catalogoCuentas.subcategoriaNombreDuplicado[language])

                return
            }
            aux[indexL0].subcategories[indexL1].subcategories[aux[indexL0].subcategories[indexL1].subcategories.length] = auxToAdd
            setProvisionalIdCount((prev)=>prev+1)
            setTasks((prev)=>({...prev,['provisionalB'+provisionalIdCount]:{'addCategory':auxToAdd}}))
            setCategories(aux)
            
        }

        const handleAddPastedCategory = (indexL0,indexL1,name,sums) => {
            let aux = [...categories]
            let auxToAdd = {
                category : name,
                categoryLevel: 2,
                flowType: categories[indexL0].subcategories[indexL1].flowType=='+' || categories[indexL0].subcategories[indexL1].flowType==true,
                idCategory: 'provisionalB'+(provisionalIdCount+sums),
                idCategoryFather: categories[indexL0].subcategories[indexL1].idCategory,
                idClient: idClient,
                isActive: true,
                orderNumber: categories[indexL0].subcategories[indexL1].subcategories.length+1,
                subcategories: []
            }
            if(aux[indexL0].subcategories[indexL1].subcategories.find((subcategory) => subcategory.category === auxToAdd.category)){
                newToast('error', Idioma.transacciones.mensajeError[language], Idioma.catalogoCuentas.subcategoriaNombreDuplicado[language])
                return
            }
            aux[indexL0].subcategories[indexL1].subcategories[aux[indexL0].subcategories[indexL1].subcategories.length] = auxToAdd
            setTasks((prev)=>({...prev,['provisionalB'+(provisionalIdCount+sums)]:{'addCategory':auxToAdd}}))
            setProvisionalIdCount((prev)=>prev+1)
            setCategories(aux)
            
        }

        const handleNewCategoryChange = (index0,index1,value) => {
            let lines = value.split('\n')
            if(lines.length===1){
                setNewCategoryL2(value)
            }else if(lines.length>1){
                for(let i=0;i<lines.length-1;i++){
                    handleAddPastedCategory(index0,index1,lines[i],i)
                }
                setNewCategoryL2(lines[lines.length-1])
            }
        }

        return (
        <tr className=''>
            <td></td>
            <td></td>
            <td className='flex justify-between items-center border-light-purple-3 border-l-1 border-b-1 py-2 pl-4'>
                <textarea className=' resize-none' value={newCategoryL2} onChange={(e)=>handleNewCategoryChange(props.indexL0,props.indexL1,e.target.value)} placeholder={Idioma.catalogoCuentas.agregarSubcategoria[language]}></textarea>
                <button disabled={newCategoryL2.length==0} onClick={()=>handleAddCategory(props.indexL0,props.indexL1)} title={Idioma.catalogoCuentas.agregar[language]} className=''><PlusIcon fill={`${newCategoryL2.length==0 ? "#d3d3d3": "#9191f4"}`}/></button>
            </td>
        </tr>
        )
    }

    const AddNewClientSupplier = (props) => {
        const [newClientSupplier,setNewClientSupplier] = useState('')
        const handleAddCategory = () => {
            let aux = []
            if(!props.isSupplier){
                aux = [...clientOptions]
            }else{
                aux = [...supplierOptions]
            }
            let auxToAdd = {
                clientSupplierName : newClientSupplier,
                idClient: idClient,
                clientSupplier: props.isSupplier,
                idClientSupplier: 'provisional'+provisionalIdCount,
                isActive: true,
            }
            aux[aux.length] = auxToAdd
            setClientSupplierTasks((prev)=>({...prev,['provisional'+provisionalIdCount]:{'addClientSupplier':auxToAdd}}))
            setProvisionalIdCount((prev)=>prev+1)
            if(!props.isSupplier){
                setClientOptions(aux)
            }else{
                setSupplierOptions(aux)
            }
            
        }

        return (
        <tr className=''>
            <td></td>
            <td></td>
            <td className='flex justify-between items-center border-light-purple-3 border-l-1 border-b-1 py-2 pl-4'>
                <input value={newClientSupplier} onChange={(e)=>setNewClientSupplier(e.target.value)} placeholder={`${Idioma.catalogoCuentas.agregar[language]}  ${props.isSupplier ? Idioma.catalogoCuentas.proveedor[language]:Idioma.catalogoCuentas.cliente[language]}`}></input>
                <button disabled={newClientSupplier.length==0} onClick={()=>handleAddCategory()} title={Idioma.catalogoCuentas.agregar[language]} className=''><PlusIcon fill={`${newCategoryL2.length==0 ? "#d3d3d3": "#9191f4"}`}/></button>
            </td>
        </tr>
        )
    }

    const deleteProvisionalCategoryL2 = (value,indexL0,indexL1,indexL2) => {
        let aux = {...tasks}
        delete aux[value]
        let aux2 = [...categories]
        delete aux2[indexL0].subcategories[indexL1].subcategories[indexL2]
        setCategories(aux2)
        setTasks(aux)
    }

    const deleteCategoryL2 = (value) => {
        let aux = [...categoriesToDelete,value]
        setCategoriesToDelete(aux)
        
    }

    const notDeleteCategoryL2 = (value) => {
        let aux = [...categoriesToDelete]
        let index = aux.indexOf(value)
        if(index>-1){
            aux.splice(index,1)
        }
        setCategoriesToDelete(aux)
        
    }

    const deleteProvisionalClientSupplier = (value,isSupplier,index) => {
        let aux = {...clientSupplierTasks}
        delete aux[value]
        if(!isSupplier){
            let aux2 = [...clientOptions]
            delete aux2[index]
            setClientOptions(aux2)
        }else{
            let aux2 = [...supplierOptions]
            delete aux2[index]
            setSupplierOptions(aux2)
        }
        setTasks(aux)
    }

    const CategoryL2 = (props) => {
        const inputNameRef = useRef(null)
        const strId = ''+props.categoryL2.idCategory
        const [isEditing,setIsEditing] = useState(false)
        useEffect(()=>{
            if(isEditing){
                inputNameRef.current.focus()
            }
        },[isEditing])
        const toggleEditing = () => {
            setIsEditing((prev)=>!prev)
        }
        const callNameChange = (var1,var2,var3,var4)  => {
            handleCategoryL2NameChange(var1,var2,var3,var4)
            toggleEditing()
        }
        return (
        <>
            <td></td>
            <td></td>
            <td className='flex gap-x-[1px] pl-4 items-center justify-end w-96 border-light-purple-3 border-l-1'>
                <button  className={`mr-1 ${props.indexL2==0 && 'invisible'}`} onClick={()=>handleMoveUpdwardCategoryL2(props.indexL0,props.indexL1,props.indexL2)}><ArrowUpCatsLogo fill={"#9191f4"}/></button>
                <button  className={`mr-2 ${props.indexL2==props.categoryL1.subcategories.length-1 && 'invisible'}`} onClick={()=>handleMoveDownwardCategoryL2(props.indexL0,props.indexL1,props.indexL2)}><ArrowDownCatsLogo fill={"#9191f4"}/></button>
                
                {isEditing && !accountLinkedCategories[strId] ? 
                            <input ref={inputNameRef} className={`w-full truncate`} title={props.categoryL2.category} defaultValue={props.categoryL2.category} onBlur={(e)=>callNameChange(e.target.value,props.indexL0,props.indexL1,props.indexL2)}></input>
                            :
                            <p className={`w-full ${categoriesToDelete.includes(strId) ? ' line-through':''}` } >{props.categoryL2.category}</p>
                }
                {strId.startsWith('prov') && 
               <button className='h-[30px] scale-[.70]' onClick={()=>deleteProvisionalCategoryL2(strId,props.indexL0,props.indexL1,props.indexL2)}>
               <ThrashCanLogo fill={'000'}/>
               </button>}
               {!accountLinkedCategories[strId] && !strId.startsWith('prov') && <>{!props.categoryL2.countIDs && !categoriesToDelete.includes(strId) &&
               <button className='h-[30px] scale-[.70]' onClick={()=>deleteCategoryL2(strId,props.indexL0,props.indexL1,props.indexL2)}>
               <ThrashCanLogo fill={'000'}/>
               </button>}
               {categoriesToDelete.includes(strId) && 
               <button className='h-[30px]' onClick={()=>notDeleteCategoryL2(strId,props.indexL0,props.indexL1,props.indexL2)}>
               <UndoLogo/>
               </button>
               }
                <button className='mr-1' onClick={()=>toggleEditing()}>
                    <LapizLogo/>    
                </button>
               
                <div className=''>
                <label for={`default-toggle${props.indexL0}-${props.indexL1}-${props.indexL2}`} class={" relative cursor-pointer z-[0]"}>
                    <input type="checkbox" checked={props.categoryL2.isActive} onClick={()=>handleToggleVisibilityCategoryL2(props.indexL0,props.indexL1,props.indexL2)}  id={`default-toggle${props.indexL0}-${props.indexL1}-${props.indexL2}`} class="sr-only peer"/>
                    <div class="w-8 h-4 rounded-full peer bg-[#d3d3d3] peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[2px] peer-checked:after:left-[4px] after:top-[2px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white after:shadow-[1px_0px_1px_1px_rgba(0,0,0,0.1)] after:rounded-full after:h-3 after:w-3 after:transition-all after:duration-1000"></div>
                </label>
                </div></>}
                
                {/*props.categoryL2.isActive === true ? <AiOutlineEye className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL2(props.indexL0,props.indexL1,props.indexL2)}/> : <AiOutlineEyeInvisible className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL2(props.indexL0,props.indexL1,props.indexL2)}/>*/}
            </td>
        </>
        )
    }

    const NonEditableCategoryL2 = (props) => {
        return (
        <>
            <td></td>
            <td></td>
            <td className='flex gap-x-[1px] pl-4 items-center justify-end w-96 border-light-purple-3 border-l-1'>
                <button className={` mr-1 invisible`}><ArrowUpCatsLogo fill={"#9191f4"}/></button>
                <button className={` mr-2 invisible`}><ArrowDownCatsLogo fill={"#9191f4"}/></button>
                <p className='w-full'>{props.categoryL2.category}</p>

            </td>
        </>
        )
    }

    const ClientSupplierObject = (props) => {
        const inputNameRef = useRef(null)
        const strId = ''+props.idClientSupplier
        return (
        <>
            <td></td>
            <td></td>
            <td className='flex gap-x-[1px] pl-4 justify-end w-96 border-light-purple-3 border-l-1'>
                <input ref={inputNameRef} className={`w-full truncate ${!props.isActive && 'text-mid-gray'}`} title={props.clientSupplierName} defaultValue={props.clientSupplierName} onBlur={(e)=>handleClientSupplierNameChange(e.target.value,props.isSupplier,props.index)}></input>
                {strId.startsWith('prov') && 
               <button onClick={()=>deleteProvisionalClientSupplier(strId,props.isSupplier,props.index)}>
               <ThrashCanLogo fill={'000'}/>
               </button>}
                <button className='mr-1' onClick={()=>inputNameRef.current.focus()}>
                    <LapizLogo/>    
                </button>
               
                <div className=''>
                <label for={`default-clientSupplier-${props.isSupplier}-${props.index}`} class={" relative cursor-pointer z-[0]"}>
                    <input type="checkbox" checked={props.isActive} onClick={()=>handleToggleVisibilityClientSupplier(props.isSupplier,props.index)}  id={`default-clientSupplier-${props.isSupplier}-${props.index}`} class="sr-only peer"/>
                    <div class="w-8 h-4 rounded-full peer bg-md-white border-[#b4b4b4] peer-checked:border-light-purple-3 peer-checked:border-1 border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[2px] peer-checked:after:left-[4px] after:top-[2px] after:left-[4px] after:bg-[#b4b4b4] peer-checked:after:bg-md-white dark:after:bg-md-white after:shadow-[1px_0px_1px_1px_rgba(0,0,0,0.1)] after:rounded-full after:h-3 after:w-3 after:transition-all after:duration-1000"></div>
                </label>
                </div>
                
                {/*props.categoryL2.isActive === true ? <AiOutlineEye className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL2(props.indexL0,props.indexL1,props.indexL2)}/> : <AiOutlineEyeInvisible className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL2(props.indexL0,props.indexL1,props.indexL2)}/>*/}
            </td>
        </>
        )
    }

    const ExtraColumnObject = (props) => {
        const inputNameRef = useRef(null)
        return (
        <>
            <td></td>
            <td></td>
            <td className={`flex gap-x-[1px] pl-4 justify-end w-96 border-light-purple-3 border-l-1 ${props.index===extraColumnsKeys.length-1 ? 'border-light-purple-3 border-l-1 border-b-1 pb-2' : ''}`}>
                <input ref={inputNameRef} className={`w-full truncate`} title={props.columnName} defaultValue={props.columnName} onBlur={(e)=>handleColumnNameChange(e.target.value,props.columnAccessor)}></input>
                <button className='mr-1 flex items-center gap-x-[10px]' onClick={()=>inputNameRef.current.focus()}>
                    {columnsInUseDict[props.columnAccessor].length>0 && <InfoI popXDirection={'left'} texto={columnsInUseDict[props.columnAccessor].length + Idioma.catalogoCuentas.cuentasUtilizando[language]}/>}
                    <LapizLogo/>    
                </button>
            </td>
        </>
        )
    }

    const CategoryL1 = (props) => {
        const inputNameRef = useRef(null)
        const strId = ''+props.categoryL1.idCategory
        const [isEditing,setIsEditing] = useState(false)
        useEffect(()=>{
            if(isEditing){
                inputNameRef.current.focus()
            }
        },[isEditing])
        const toggleEditing = () => {
            setIsEditing((prev)=>!prev)
        }
        const callNameChange = (var1,var2,var3)  => {
            handleCategoryL1NameChange(var1,var2,var3)
            toggleEditing()
        }
        return (
            <>
            {
            categoriesL1Opened[props.categoryL1.idCategory] === 1 ? 
            <>
                <tr key={props.categoryL1.idCategory} className='' >
                    <td className=' '></td>
                    <td className='flex items-center gap-x-[1px] justify-end border-light-purple-3 border-b-1 border-l-1 pl-2'>
                        <button className={` mr-1 ${props.indexL1==0 && 'invisible'}`} onClick={()=>handleMoveUpdwardCategoryL1(props.indexL0,props.indexL1)}><ArrowUpCatsLogo fill={"#9191f4"}/></button>
                        <button className={` mr-2 ${props.indexL1==props.categoryL0.subcategories.length-1 && 'invisible'}`} onClick={()=>handleMoveDownwardCategoryL1(props.indexL0,props.indexL1)}><ArrowDownCatsLogo fill={"#9191f4"}/></button>
                        {isEditing ? 
                            <input ref={inputNameRef} className={` w-full bg-transparent ${!props.categoryL1.isActive && 'text-mid-gray'}`} defaultValue={props.categoryL1.category} onBlur={(e)=>callNameChange(e.target.value,props.indexL0,props.indexL1)}></input>
                            :
                            <p className='w-full'  onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}>{props.categoryL1.category}</p>
                        }
                        {strId.startsWith('prov') && 
                        <button onClick={()=>deleteProvisionalCategoryL1(strId,props.indexL0,props.indexL1)}>
                        <ThrashCanLogo fill={'000'}/>
                        </button>}
                        <span title={Idioma.catalogoCuentas.contraer[language]} className='cursor-pointer' onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}><ThreeDots/></span>
                        
                        <button className='mr-1' onClick={()=>toggleEditing()}>
                            <LapizLogo/>    
                        </button>
                        <div>
                        <label for={`default-toggle${props.indexL0}-${props.indexL1}`} class={"inline-flex relative items-center cursor-pointer z-[0]"}>
                            <input type="checkbox" checked={props.categoryL1.isActive} onClick={()=>handleToggleVisibilityCategoryL1(props.indexL0,props.indexL1)}  id={`default-toggle${props.indexL0}-${props.indexL1}`} class="sr-only peer"/>
                            <div class="w-8 h-4 rounded-full peer bg-[#d3d3d3] peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[2px] peer-checked:after:left-[4px] after:top-[2px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white after:shadow-[1px_0px_1px_1px_rgba(0,0,0,0.1)] after:rounded-full after:h-3 after:w-3 after:transition-all after:duration-1000"></div>
                        </label>
                        </div>
                        {/*props.categoryL1.isActive === true ? 
                        <AiOutlineEye className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL1(props.indexL0,props.indexL1)}/> : 
            <AiOutlineEyeInvisible className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL1(props.indexL0,props.indexL1)}/>*/}
                    
                    </td>
                    <td className=' rounded-r-[7px]'></td>
                </tr>
                {props.categoryL1.subcategories.map((categoryL2,indexL2)=>(
                    <tr key={categoryL2}>
                    <CategoryL2 categoryL2={categoryL2} indexL0={props.indexL0} indexL1={props.indexL1} indexL2={indexL2} categoryL1={props.categoryL1}/>
                    </tr>
                ))}
                <AddNewCategoryL2 indexL0={props.indexL0} indexL1={props.indexL1}/>
            </>
            :
            <>
            <tr key={props.categoryL1.idCategory} >
                <td></td>
                <td className=' flex gap-x-[1px] items-center justify-end  pl-2'>
                    <button className={` mr-1 ${props.indexL1==0 && 'invisible'}`} onClick={()=>handleMoveUpdwardCategoryL1(props.indexL0,props.indexL1)}><ArrowUpCatsLogo fill={"#9191f4"}/></button>
                    <button className={` mr-2 ${props.indexL1==props.categoryL0.subcategories.length-1 && 'invisible'}`} onClick={()=>handleMoveDownwardCategoryL1(props.indexL0,props.indexL1)}><ArrowDownCatsLogo fill={"#9191f4"}/></button>
                    {isEditing ? 
                            <input ref={inputNameRef} className={` w-full bg-transparent ${!props.categoryL1.isActive && 'text-mid-gray'}`} defaultValue={props.categoryL1.category} onBlur={(e)=>callNameChange(e.target.value,props.indexL0,props.indexL1)}></input>
                            :
                            <p className='w-full'  onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}>{props.categoryL1.category}</p>
                    }
                    {strId.startsWith('prov') && 
                    <button onClick={()=>deleteProvisionalCategoryL1(strId,props.indexL0,props.indexL1)}>
                    <ThrashCanLogo fill={'000'}/>
                    </button>}
                    
                    <span className='cursor-pointer' title={Idioma.catalogoCuentas.expandir[language]} onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}><ThreeDots/></span>
                    
                    
                    <button className='mr-1'  onClick={()=>toggleEditing()}>
                        <LapizLogo/>    
                    </button>
                    <div>
                    <label for={`default-toggle${props.indexL0}-${props.indexL1}`} class={"inline-flex relative items-center cursor-pointer z-[0]"}>
                        <input type="checkbox" checked={props.categoryL1.isActive} onClick={()=>handleToggleVisibilityCategoryL1(props.indexL0,props.indexL1)}  id={`default-toggle${props.indexL0}-${props.indexL1}`} class="sr-only peer"/>
                        <div class="w-8 h-4 rounded-full peer bg-md-white border-[#b4b4b4] peer-checked:border-light-purple-3 peer-checked:border-1 border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[2px] peer-checked:after:left-[4px] after:top-[2px] after:left-[4px] after:bg-[#b4b4b4] peer-checked:after:bg-md-white dark:after:bg-md-white after:shadow-[1px_0px_1px_1px_rgba(0,0,0,0.1)] after:rounded-full after:h-3 after:w-3 after:transition-all after:duration-1000"></div>
                    </label>
                    </div>
                    { /*props.categoryL1.isActive === true ? 
                    <AiOutlineEye className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL1(props.indexL0,props.indexL1)}/> :
                    <AiOutlineEyeInvisible className='w-[20px]' onClick={()=>handleToggleVisibilityCategoryL1(props.indexL0,props.indexL1)}/>
                    
                */}

                    
                </td>
                <td></td>
            </tr>
            </>
    }
            </>
        )
    }

    const NonEditableCategoryL1 = (props) => {
        
        return (
            <>
            {
            categoriesL1Opened[props.categoryL1.idCategory] === 1 ? 
            <>
                <tr key={props.categoryL1.idCategory} className='' >
                    <td className=' '></td>
                    <td className='flex items-center gap-x-[1px] justify-end border-light-purple-3 border-b-1 border-l-1 pl-2'  onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}>
                        <button className={` mr-1 invisible`}><ArrowUpCatsLogo fill={"#9191f4"}/></button>
                        <button className={` mr-2 invisible`}><ArrowDownCatsLogo fill={"#9191f4"}/></button>
                        <p className='w-full'>{props.categoryL1.category}</p>
                        
                        <span title={Idioma.catalogoCuentas.contraer[language]} className='cursor-pointer' onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}><ThreeDots/></span>
                    
                    </td>
                    <td className=' rounded-r-[7px]'></td>
                </tr>
                {props.categoryL1.subcategories.map((categoryL2,indexL2)=>(
                    <tr key={categoryL2}>
                    <NonEditableCategoryL2 categoryL2={categoryL2} indexL0={props.indexL0} indexL1={props.indexL1} indexL2={indexL2} categoryL1={props.categoryL1}/>
                    </tr>
                ))}
            </>
            :
            <>
            <tr key={props.categoryL1.idCategory} >
                <td></td>
                <td className=' flex gap-x-[1px] items-center justify-end  pl-2'  onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}>
                    <button className={` mr-1 invisible`}><ArrowUpCatsLogo fill={"#9191f4"}/></button>
                    <button className={` mr-2 invisible`}><ArrowDownCatsLogo fill={"#9191f4"}/></button>
                    <p className='w-full'>{props.categoryL1.category}</p>
                    <span className='cursor-pointer' title={Idioma.catalogoCuentas.expandir[language]} onClick={()=>handleExpandCategoryL1(props.categoryL1.idCategory)}><ThreeDots/></span>
                </td>
                <td></td>
            </tr>
            </>
    }
            </>
        )
    }

    const newToast = (type, title, message) => {
        let toastProperties = []
        toastProperties = {
          'id': toastList.length+1,
          'alertType': type,
          'alertTitle': title,
          'alertMessage': message
        }
        setToastList([...toastList, toastProperties])
      }

  return (

    
    <div className='pl-[5%] pr-[5%] 2xl:mt-10 2xl:mb-10 my-2 text-[14px]'>
        <MessageHandler toastList={toastList} setToastList={setToastList}/>

        {loading &&
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[100] h-screen">
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z -10 h-screen opacity-50 bg-md-gray'></div>
                    <div className="w-full h-full flex justify-center items-center pr-[15%]">
                        <Player
                            className="w-[20%]"
                            autoplay
                            loop
                            src={Loading}
                        ></Player>
                    </div>
                </div>
            
            }
        {(categoriesToUpdate.length>0 || clientSupplierToUpdate.length>0) && !getOutWithChanges && <div className="fixed cursor-pointer top-0 left-[0] bottom-0 w-[15%] z-[100] h-screen" onClick={()=>setGetOutWithChanges(true)}>
                    <div className='fixed top-0 right-0 left-[0] bottom-0 w-[15%] z -10 h-screen opacity-50 bg-transparent'></div>
                    <div className="w-full h-full flex justify-center items-center pr-[15%]">
                    </div>
                </div>}
        {getOutWithChanges && 
        <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
            <div onClick={()=>setGetOutWithChanges(false)} className={Styles.modalBackground}></div>
            <div className="w-full h-full flex justify-center items-center pr-[15%]">
            <div className={Styles.defaultModal}>
                <p className=' text-p3-2xl text-center'>{Idioma.catalogoCuentas.modalCambios.titulo[language]}<br/><span className='font-bold'>{Idioma.catalogoCuentas.modalCambios.titulo2[language]}</span></p>
                <p className='text-gray-3 text-center text-p6-2xl'>{Idioma.catalogoCuentas.modalCambios.texto[language]}</p>
                <div className='flex items-center justify-center mt-[15px]'>
                    <button onClick={()=>updateCategories()} disabled={categoriesToUpdate.length==0} className={`font-bold text-b4-2xl h-button-4 px-[15px] rounded-button ${categoriesToUpdate.length===0 ? ' bg-secondary-light-2':' bg-light-purple-3'}`}>
                        {Idioma.catalogoCuentas.guardar[language]}
                    </button>
                    <button onClick={()=>setGetOutWithChanges(false)} className='bg-purple-1 font-bold text-b4-2xl h-button-4 px-[15px] ml-2 rounded-button text-white'>
                    {Idioma.catalogoCuentas.modalCambios.mantener[language]}
                    </button>
                </div>
                </div>
            </div>
        </div>}
        <div className=' overflow-y-auto max-h-[70vh] 2xl:w-[80%]'>
        <table className='border-0 w-full'>
            <thead className='sticky top-0 bg-white z-[10]'>
                <tr className='gap-x-2'>
                    <th className='w-1/3 '><div className='py-2 mr-2 mb-1 text-center bg-light-purple-4 rounded-main sticky font-semibold top-0 '>{Idioma.catalogoCuentas.flujo[language]}</div></th>
                    <th className='w-1/3 '><div className='py-2 mx-2 mb-1 text-center bg-light-purple-3 rounded-main sticky font-semibold top-0 text-white'>{Idioma.catalogoCuentas.categoria[language]}</div></th>
                    <th className='w-1/3 '><div className='py-2 ml-2 mb-1 text-center bg-light-purple-2 rounded-main sticky font-semibold top-0 text-white'>{Idioma.catalogoCuentas.subcategoria[language]}</div></th>
                </tr>
            </thead>
            <tbody className='bg-white'>
                {categories.length>0 && 
                <>{categories.slice(0,3).map((categoryL0,indexL0)=>(
                    <>
                    {categoriesL0Opened[categoryL0.idCategory] === 1 ?
                    <>
                    <tr onClick={()=>handleExpandCategoryL0(categoryL0.idCategory)} key={categoryL0.idCategory} className={`z-[10] hover:z-[11] sticky top-9 cursor-pointer`}>
                        <td className='bg-secondary-light-2 font-regular flex justify-between py-2 pl-2 rounded-l-[7px]'>
                            <span className='flex items-center gap-x-[10px]' title={Idioma.catalogoCuentas.contraer[language]} ><InfoI popDirection={categoryL0.category===Idioma.reporte.cuentasCredito[language]} texto={ categoryL0.category===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]: categoryL0.category===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]: categoryL0.category===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:categoryL0.category===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:categoryL0.category===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/>{categoryL0.category}</span>
                        </td>
                        <td className='bg-secondary-light-2'></td>
                        <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                    </tr>
                    {categoryL0.subcategories.map((categoryL1,indexL1)=>(
                        <>
                            <CategoryL1 categoryL1={categoryL1} indexL0={indexL0} indexL1={indexL1} categoryL0={categoryL0}/>
                        </>
                    ))}

                        {!(indexL0==2 && categoryL0.subcategories.length>=2) && <AddNewCategoryL1 indexL0={indexL0}/>}    
                    </>
                    :
                    <tr onClick={()=>handleExpandCategoryL0(categoryL0.idCategory)} key={categoryL0.idCategory} className={`z-[10] hover:z-[11] sticky top-8 cursor-pointer`}>
                        <td className='font-regular justify-between rounded-l-[7px] bg-secondary-light-2 py-2 pl-2'>
                            <span className='flex items-center gap-x-[10px]' title={Idioma.catalogoCuentas.expandir[language]}><InfoI popDirection={categoryL0.category===Idioma.reporte.cuentasCredito[language]}  texto={ categoryL0.category===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]: categoryL0.category===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]: categoryL0.category===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:categoryL0.category===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:categoryL0.category===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/>{categoryL0.category}</span>
                            
                        </td>
                        <td className=' bg-secondary-light-2 '></td>
                        <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                    </tr>
                }
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>    
                    </tr></>
                ))}
                {!isAdmin && categories.slice(3).map((categoryL0,indexL0)=>(
                    <>
                    {categoriesL0Opened[categoryL0.idCategory] === 1 ?
                    <>
                    <tr onClick={()=>handleExpandCategoryL0(categoryL0.idCategory)} key={categoryL0.idCategory} className={`z-[10] hover:z-[11] sticky top-9 cursor-pointer`}>
                        <td className='bg-secondary-light-2 font-regular flex justify-between py-2 pl-2 rounded-l-[7px]'>
                            <span className='flex items-center gap-x-[10px]' title={Idioma.catalogoCuentas.contraer[language]} ><InfoI popDirection={categoryL0.category===Idioma.reporte.cuentasCredito[language]} texto={ categoryL0.category===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]: categoryL0.category===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]: categoryL0.category===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:categoryL0.category===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:categoryL0.category===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/>{categoryL0.category}</span>
                        </td>
                        <td className='bg-secondary-light-2'></td>
                        <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                    </tr>
                    {categoryL0.subcategories.map((categoryL1,indexL1)=>(
                        <>
                            <NonEditableCategoryL1 categoryL1={categoryL1} indexL0={indexL0+3} indexL1={indexL1} categoryL0={categoryL0}/>
                        </>
                    ))}
                    </>
                    :
                    <tr onClick={()=>handleExpandCategoryL0(categoryL0.idCategory)} key={categoryL0.idCategory} className={`z-[10] hover:z-[11] sticky top-8 cursor-pointer`}>
                        <td className='font-regular justify-between rounded-l-[7px] bg-secondary-light-2 py-2 pl-2'>
                            <span className='flex items-center gap-x-[10px]' title={Idioma.catalogoCuentas.expandir[language]}><InfoI popDirection={categoryL0.category===Idioma.reporte.cuentasCredito[language]}  texto={ categoryL0.category===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]: categoryL0.category===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]: categoryL0.category===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:categoryL0.category===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:categoryL0.category===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/>{categoryL0.category}</span>
                        </td>
                        <td className=' bg-secondary-light-2 '></td>
                        <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                    </tr>
                }
                
                
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>    
                    </tr></>
                ))}
                {isAdmin && categories.slice(3).map((categoryL0,indexL0)=>(
                    <>
                    {categoriesL0Opened[categoryL0.idCategory] === 1 ?
                    <>
                    <tr onClick={()=>handleExpandCategoryL0(categoryL0.idCategory)} key={categoryL0.idCategory} className={`z-[10] hover:z-[11] sticky top-9 cursor-pointer`}>
                        <td className='bg-secondary-light-2 font-regular flex justify-between py-2 pl-2 rounded-l-[7px]'>
                            <span className='flex items-center gap-x-[10px]' title={Idioma.catalogoCuentas.contraer[language]} ><InfoI popDirection={categoryL0.category===Idioma.reporte.cuentasCredito[language]} texto={ categoryL0.category===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]: categoryL0.category===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]: categoryL0.category===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:categoryL0.category===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:categoryL0.category===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/>{categoryL0.category}</span>
                        </td>
                        <td className='bg-secondary-light-2'></td>
                        <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                    </tr>
                    {categoryL0.subcategories.map((categoryL1,indexL1)=>(
                        <>
                            <CategoryL1 categoryL1={categoryL1} indexL0={indexL0+3} indexL1={indexL1} categoryL0={categoryL0}/>
                        </>
                    ))}
                        <AddNewCategoryL1 indexL0={indexL0+3}/>
                    </>
                    :
                    <tr onClick={()=>handleExpandCategoryL0(categoryL0.idCategory)} key={categoryL0.idCategory} className={`z-[10] hover:z-[11] sticky top-8 cursor-pointer`}>
                        <td className='font-regular justify-between rounded-l-[7px] bg-secondary-light-2 py-2 pl-2'>
                            <span className='flex items-center gap-x-[10px]' title={Idioma.catalogoCuentas.expandir[language]}><InfoI popDirection={categoryL0.category===Idioma.reporte.cuentasCredito[language]}  texto={ categoryL0.category===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]: categoryL0.category===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]: categoryL0.category===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:categoryL0.category===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:categoryL0.category===Idioma.catalogoCuentas.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/>{categoryL0.category}</span>
                            
                        </td>
                        <td className=' bg-secondary-light-2 '></td>
                        <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                    </tr>
                }
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>    
                    </tr></>
                ))}
                </>

                
                }
                {/*
                <tr className='font-bold'>
                    <td className='flex justify-between'>
                        <input value={newCategoryL0} onChange={(e)=>setNewCategoryL0(e.target.value)} placeholder='Agregar CategoriaL0'></input>
                        <button disabled={newCategoryL0.length==0} onClick={()=>addNewCategoryL0()} title='Agregar' className=' '><PlusIcon fill={`${newCategoryL0.length==0 ? "#d3d3d3": "#9191f4"}`}/></button>
                    </td>
                    <td></td>
                    <td></td>
                </tr>*/}
                
                <tr className='h-[15px]'>
                    <td></td>
                    <td></td>
                    <td></td>    
                </tr>
                <tr onClick={()=>setClientsExpanded((prev)=>!prev)} key={'Clients'} className='z-[10] sticky top-9 cursor-pointer'>
                    <td className='bg-secondary-light-2 font-regular flex justify-between py-2 pl-2 rounded-l-[7px]'>
                        <span className='' title={Idioma.catalogoCuentas.contraer[language]} >{Idioma.catalogoCuentas.clientes[language]}</span>
                    </td>
                    <td className='bg-secondary-light-2'></td>
                    <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                </tr> 
                {clientsExpanded && 
                <>
                    {clientOptions.map((client,index)=>(
                        <>
                            <tr key={client}>
                                <ClientSupplierObject isSupplier={0} index={index} isActive={client.isActive} clientSupplierName={client.clientSupplierName} idClientSupplier={client.idClientSupplier}/>
                            </tr>
                        </>
                    ))}
                    <AddNewClientSupplier isSupplier={0}/>
                </>
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>    
                </tr>
                <tr onClick={()=>setSuppliersExpanded((prev)=>!prev)} key={'Suppliers'} className='z-[10] sticky top-9 cursor-pointer'>
                    <td className='bg-secondary-light-2 font-regular flex justify-between py-2 pl-2 rounded-l-[7px]'>
                        <span className='' title={Idioma.catalogoCuentas.contraer[language]} >{Idioma.catalogoCuentas.proveedores[language]}</span>
                    </td>
                    <td className='bg-secondary-light-2'></td>
                    <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                </tr>
                {suppliersExpanded &&
                <>
                {supplierOptions.map((supplier,index)=>(
                    <>
                        <tr key={supplier}>
                            <ClientSupplierObject isSupplier={1} index={index} isActive={supplier.isActive} clientSupplierName={supplier.clientSupplierName} idClientSupplier={supplier.idClientSupplier}/>
                        </tr>
                    </>
                ))}
                <AddNewClientSupplier isSupplier={1}/>
                </>
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>    
                </tr>
                <tr className='h-[15px]'>
                    <td></td>
                    <td></td>
                    <td></td>    
                </tr>
                <tr onClick={()=>setExtraColumnExpanded((prev)=>!prev)} key={'Columns'} className='z-[10] sticky top-9 cursor-pointer'>
                    <td className='bg-secondary-light-2 font-regular flex justify-between py-2 pl-2 rounded-l-[7px]'>
                        <span className='' title={Idioma.catalogoCuentas.contraer[language]} >{Idioma.catalogoCuentas.columnasAdicionales[language]}</span>
                    </td>
                    <td className='bg-secondary-light-2'></td>
                    <td className='bg-secondary-light-2 rounded-r-[7px]'><div className='flex justify-end pr-2'><ThreeDots/></div></td>
                </tr> 
                {extraColumnsExpanded && 
                <>
                    {extraColumnsKeys.map((column,index)=>(
                        <>
                            <tr key={column}>
                                <ExtraColumnObject index={index} columnName={extraColumnsDict[column]} columnAccessor={column}/>
                            </tr>
                        </>
                    ))}
                </>
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>    
                </tr>
            </tbody>
        </table>
        
        </div>
        <div className='flex items-center mt-2 2xl:mt-10 gap-x-[15px]'>
            <button onClick={()=>updateData()} disabled={updateDataDisabled} className={updateDataDisabled ? Styles.saveButtonDisabled : Styles.saveButton}>
                {Idioma.catalogoCuentas.guardar[language]}
            </button>
            <button onClick={()=>cancelEdition()} className={Styles.cancelButton}>
                {Idioma.catalogoCuentas.cancelar[language]}
            </button>
        </div>
    </div>
  )
}

export default SubcategoriesLuisIdea