import React from 'react'

const Styles = {
    toggleSwitch:"2xl:w-[58px] w-[58px] 2xl:h-[28px] h-[28px] rounded-full peer bg-[#d3d3d3]  peer-checked:bg-light-purple-3 after:shadow-[1px_0px_1px_1px_rgba(0,0,0,0.1)] peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[4px] 2xl:peer-checked:after:left-[14px] peer-checked:after:left-[14px] 2xl:after:top-[4px] after:top-[4px] 2xl:after:left-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-[20px] after:h-[20px] 2xl:after:w-[20px] after:w-[20px] after:transition-all",
    defaultModal:"bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation   rounded-main -translate-x-[50%] -translate-y-[50%]",
    modalBackground: "fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]",
    modalWarningBackground: "fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.80] backdrop-brightness-[-.15] bg-gray-5 backdrop-blur-[40px]",
    modalTitle: '2xl:text-h5-2xl text-h5-2xl font-bold text-left flex items-center mb-[40px]',
    defaultInput: "bg-white text-neutral-1 placeholder:text-neutral-3 text-[12px] focus:outline-light-purple-3 accent-light-purple-3 border-1 border-neutral-1 rounded-button w-72 p-2 caret-light-purple-3",
    wideInput: "bg-white text-neutral-1 placeholder:text-neutral-3 text-[12px] focus:outline-light-purple-3 accent-light-purple-3 border-1 border-neutral-1 rounded-button w-full p-2 caret-light-purple-3",
    nonInput: "bg-white text-[12px] focus:outline-light-purple-3 accent-light-purple-3 rounded-button w-72 p-2 caret-light-purple-3",
    saveButton:'text-white cursor-pointer text-p9-2xl font-bold rounded-button px-[15px] h-button-4 bg-light-purple-3 flex items-center',
    saveButtonDisabled:' text-p9-2xl font-bold rounded-button px-[15px] h-button-4 bg-gray-6 text-neutral-3 flex items-center',
    cancelButton:'text-white cursor-pointer text-p9-2xl font-bold rounded-button px-[15px] h-button-4 bg-purple-1 flex items-center',
    modalXmark: 'absolute -right-[20px] -top-[20px] cursor-pointer',

}

export default Styles