import React from 'react'

const CloseDropdown = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="15.648" height="8.768" viewBox="0 0 15.648 8.768">
  <path id="Trazado_1072" data-name="Trazado 1072" d="M0,0,6.354,6.168,0,12.82" transform="translate(1.414 7.354) rotate(-90)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
</svg>
  )
}

export default CloseDropdown