import { ArrowRightIcon  } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <div className="w-full">
            <div className='w-full flex flex-row'>
                <div className="w-[40rem] ml-10 p-1 pl-7 pb-5 bg-md-table-bg shadow-xl border rounded-2xl">
                    <h2 className="text-md-blue text-3xl mt-12 mb-9 font-bold">CICO</h2>
                    <p className='invisible'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde eius odit sint impedit commodi amet minima facilis.</p>
                    <Link to='/upload'>
                        <button className="border-1 rounded-lg border-md-casiNegro p-1 w-72 text-left pl-6 mt-7 bg-md-table-bg text-md-gray flex flex-row space-x-32">
                            <p className='min-w-fit mt-1'>Iniciar</p>
                            <ArrowRightIcon className='w-8 text-md-blue font-extrabold' />
                        </button>
                    </Link>
                </div>
                <div className="w-[57rem] ml-10 p-1 pl-7 pb-5  shadow-xl border rounded-2xl text-md-white bg-resumen bg-cover ">
                    <h2 className='text-3xl mt-12 mb-9 font-bold'>Resumen</h2>
                    <p className='invisible'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis quia explicabo minus aliquid sed, incidunt beatae, sunt quaerat distinctio laborum inventore quisquam tempore ab possimus a? Voluptatibus voluptates perspiciatis obcaecati!</p>
                    <Link to="/summary">
                        <button className='border-1 rounded-lg border-md-gray p-1 w-72 text-left pl-6 mt-7 bg-md-table-bg text-md-gray flex flex-row space-x-32'>
                            <p className='min-w-fit mt-1'>Iniciar</p>
                            <ArrowRightIcon className='w-8 text-md-blue font-extrabold' />
                        </button>
                    </Link>
                </div>
            </div>
            <div className='w-full flex flex-row mt-7'>
                <div className='w-[57rem] ml-10 p-1 pl-7 pb-5 shadow-xl border rounded-xl text-md-white bg-reports bg-cover'>
                    <h2 className='text-3xl mt-12 mb-9 font-bold'>Reportes y documentos</h2>
                    <p className='invisible'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, tempora non? Tempora, quod! Quo, a officia? Officia iusto quasi dolores magni! Eos, earum velit. Magnam hic vero ipsa sapiente labore?</p>
                    <Link to="#">
                        <button className='border-1 rounded-lg border-md-gray p-1 w-72 text-left pl-6 mt-7 bg-md-table-bg text-md-gray flex flex-row space-x-32'>
                            <p className='min-w-fit mt-1'>Iniciar</p>
                            <ArrowRightIcon className='w-8 text-md-blue font-extrabold' />
                        </button>
                    </Link>
                </div>
                <div className='w-[40rem] ml-10 p-1 pl-7 pb-5 bg-md-table-bg shadow-xl border rounded-xl'>
                    <h2 className='text-3xl mt-12 mb-9 font-bold text-md-blue'>Reporte de saldos</h2>
                    <p className='invisible'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, tempora non? Tempora, quod! Quo, a officia? Officia iusto quasi dolores magni! Eos, earum velit. Magnam hic vero ipsa sapiente labore?</p>
                    <Link to="/balance">
                        <button className='border-1 rounded-lg border-md-gray p-1 w-72 text-left pl-6 mt-7 bg-md-table-bg text-md-gray flex flex-row space-x-32'>
                            <p className='min-w-fit mt-1'>Iniciar</p>
                            <ArrowRightIcon className='w-8 text-md-blue font-extrabold' />
                        </button>
                    </Link>
                </div>
            </div>
            <div className='w-full flex flex-row mt-7'>
                <div className='w-[40rem] ml-10 p-1 pl-7 pb-5 bg-md-table-bg shadow-xl border rounded-xl'>
                    <h2 className='text-3xl mt-12 mb-9 font-bold text-md-blue'>Cuentas bancarias</h2>
                    <p className='invisible'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, tempora non? Tempora, quod! Quo, a officia? Officia iusto quasi dolores magni! Eos, earum velit. Magnam hic vero ipsa sapiente labore?</p>
                    <Link to="/banksedition">
                        <button className='border-1 rounded-lg border-md-gray p-1 w-72 text-left pl-6 mt-7 bg-md-table-bg text-md-gray flex flex-row space-x-32'>
                            <p className='min-w-fit mt-1'>Iniciar</p>
                            <ArrowRightIcon className='w-8 text-md-blue font-extrabold' />
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HomePage;