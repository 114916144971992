import React from 'react'

const PPSect9Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Grupo_1202" data-name="Grupo 1202" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1205" data-name="Grupo 1205" transform="translate(432 226)">
        <g id="Grupo_1204" data-name="Grupo 1204" transform="translate(64)">
            <g id="Grupo_1203" data-name="Grupo 1203">
            <path id="Trazado_1477" data-name="Trazado 1477" d="M170.243,162.232a5.269,5.269,0,0,0-5.263-5.263h-.632a5.269,5.269,0,0,0-5.263,5.263V165.4h-1.109a1.053,1.053,0,0,0-1.053,1.053v9.363a1.053,1.053,0,0,0,1.053,1.053h13.376a1.053,1.053,0,0,0,1.053-1.053v-9.363a1.053,1.053,0,0,0-1.053-1.053h-1.109v-3.163Zm-9.053,0a3.161,3.161,0,0,1,3.158-3.158h.632a3.161,3.161,0,0,1,3.158,3.158V165.4h-6.947ZM170.3,167.5v7.258h-11.27V167.5H170.3Z" transform="translate(-149.664 -144.706)"/>
            <path id="Trazado_1478" data-name="Trazado 1478" d="M240.217,308.979a1.338,1.338,0,0,0-1.338,1.338,1.317,1.317,0,0,0,.285.85v1.727a1.053,1.053,0,0,0,2.105,0v-1.727a1.317,1.317,0,0,0,.285-.85A1.337,1.337,0,0,0,240.217,308.979Z" transform="translate(-225.217 -284.84)"/>
            <path id="Trazado_1479" data-name="Trazado 1479" d="M93.692,8.238,85.762.308A1.053,1.053,0,0,0,85.018,0H67.342A3.346,3.346,0,0,0,64,3.342V36.658A3.346,3.346,0,0,0,67.342,40H90.658A3.346,3.346,0,0,0,94,36.658V8.982A1.053,1.053,0,0,0,93.692,8.238ZM86.071,3.594,90.406,7.93H86.071Zm5.824,33.064a1.238,1.238,0,0,1-1.237,1.237H67.342a1.238,1.238,0,0,1-1.237-1.237V3.342a1.238,1.238,0,0,1,1.237-1.237H83.965V8.982a1.053,1.053,0,0,0,1.053,1.053h6.877V36.658Z" transform="translate(-64)"/>
            </g>
        </g>
        </g>
    </g>
    </svg>

  )
}

export default PPSect9Logo