
import React from 'react'

const ScissorsLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_27" data-name="Enmascarar grupo 27" transform="translate(-573 -296)" clip-path="url(#clip-path)">
        <g id="Grupo_472" data-name="Grupo 472" transform="translate(573.099 296.062)">
          <g id="Grupo_245" data-name="Grupo 245" transform="translate(1 0)" clip-path="url(#clip-path-2)">
            <path id="Trazado_171" data-name="Trazado 171" d="M17.377,14.983c-.421-.411-.791-.767-1.155-1.129Q10.969,8.62,5.717,3.383c-.053-.053-.109-.1-.157-.162a.741.741,0,0,1-.15-.8.711.711,0,0,1,.695-.487c.963.035,1.927.074,2.887.153a2.548,2.548,0,0,1,1.6.825q4.522,4.529,9.059,9.043c.106.106.2.229.271.319L24.44,7.7l.11.07-.292-.274a4.273,4.273,0,0,1-.942-5.034A4.4,4.4,0,0,1,28,.06,4.357,4.357,0,0,1,31.53,3.744a4.243,4.243,0,0,1-1.292,3.7q-3.252,3.244-6.5,6.487c-.337.336-.683.663-1.055,1.024.126.133.223.24.325.342,2.418,2.409,4.846,4.808,7.252,7.23a4.293,4.293,0,0,1-.565,6.646,4.359,4.359,0,0,1-5.856-.843,4.311,4.311,0,0,1,.437-5.891l.256-.237-4.517-4.545c-.1.1-.21.2-.313.3-2.936,2.92-5.893,5.82-8.795,8.775a4.047,4.047,0,0,1-2.87,1.261c-.577.023-1.154.024-1.731.038a.807.807,0,0,1-.591-1.464q5.644-5.616,11.284-11.236c.106-.106.224-.2.383-.341M27.2,6.457a2.15,2.15,0,0,0,2.157-2.1,2.141,2.141,0,1,0-4.282-.031A2.147,2.147,0,0,0,27.2,6.457m0,17.02a2.137,2.137,0,1,0,2.159,2.106A2.158,2.158,0,0,0,27.2,23.478" transform="translate(-2.785 0)" fill={props.fill || "#18002f"}/>
            <path id="Trazado_172" data-name="Trazado 172" d="M2.577,29.6c.564,0,1.129-.005,1.693,0a.78.78,0,1,1,0,1.552q-1.712.012-3.424,0a.779.779,0,1,1,0-1.552c.577-.007,1.154,0,1.731,0" transform="translate(0 -15.41)" fill={props.fill || "#18002f"}/>
            <path id="Trazado_173" data-name="Trazado 173" d="M17.13,29.6c.5,0,1-.008,1.5,0a.776.776,0,1,1,0,1.549q-1.54.017-3.079,0a.774.774,0,1,1,0-1.548c.525-.012,1.051,0,1.577,0" transform="translate(-7.687 -15.41)" fill={props.fill || "#18002f"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ScissorsLogo

