import { NavLink } from "react-router-dom";

const Upper = () => {
    const normal = 'text-2xl text-md-gray font-bold';
    const selected = 'text-2xl text-md-blue font-bold';

    return (
        <div className="flex space-x-10">
            <NavLink
                exact 
                to='/classification'
                className={({isActive}) => isActive ? selected : normal}
            >
                Clasificar
            </NavLink>
            <NavLink
                exact 
                to='/upload'
                className={({isActive}) => isActive ? selected : normal}
            >
                Subir Archivos
            </NavLink>
        </div>
    );
}

export default Upper;