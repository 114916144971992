import React from 'react'

const TyCSect1 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
        
        <g id="Enmascarar_grupo_97" data-name="Enmascarar grupo 97" transform="translate(-491 -226)" clip-path="url(#clip-path)">
            <g id="Target" transform="translate(490.668 226.001)">
            <path id="Trazado_1326" data-name="Trazado 1326" d="M31.511,8.333H34.5a.833.833,0,0,0,.589-.244l3.333-3.333a.833.833,0,0,0-.589-1.422h-2.5V.833A.833.833,0,0,0,33.909.244L30.576,3.577a.833.833,0,0,0-.244.589V7.155L28.65,8.837A14.916,14.916,0,0,0,19.5,5.042V4.167a.833.833,0,1,0-1.667,0v.876A15,15,0,0,0,3.709,19.166H2.833a.833.833,0,1,0,0,1.667h.876a14.891,14.891,0,0,0,2.846,7.979L3.03,33.853a3.907,3.907,0,1,0,6.4,4.478l3.216-4.6a14.887,14.887,0,0,0,5.183,1.227v.876a.833.833,0,0,0,1.667,0v-.876a14.887,14.887,0,0,0,5.182-1.227l3.217,4.6a3.907,3.907,0,0,0,6.4-4.478l-3.525-5.041a14.891,14.891,0,0,0,2.847-7.979H34.5a.833.833,0,1,0,0-1.667h-.876a14.916,14.916,0,0,0-3.795-9.151ZM32,4.512l1.667-1.667V4.167A.833.833,0,0,0,34.5,5H35.82L34.154,6.666H32ZM8.068,37.376A2.24,2.24,0,0,1,4.4,34.808l3.258-4.66a15.076,15.076,0,0,0,3.5,2.813Zm24.868-2.567a2.24,2.24,0,0,1-3.672,2.567l-3.086-4.414a15.076,15.076,0,0,0,3.5-2.813Zm-.979-15.642h-.791a.833.833,0,0,0,0,1.667h.791A13.331,13.331,0,0,1,19.5,33.29V32.5a.833.833,0,0,0-1.667,0v.791A13.331,13.331,0,0,1,5.375,20.832h.791a.833.833,0,1,0,0-1.667H5.375A13.331,13.331,0,0,1,17.833,6.709V7.5a.833.833,0,0,0,1.667,0V6.709a13.258,13.258,0,0,1,7.973,3.306l-2.356,2.356a10.022,10.022,0,1,0,1.178,1.178l2.356-2.356a13.258,13.258,0,0,1,3.306,7.973ZM22,20a3.333,3.333,0,1,1-3.333-3.333,3.3,3.3,0,0,1,1.683.472L18.077,19.41a.833.833,0,1,0,1.178,1.178l2.272-2.272A3.3,3.3,0,0,1,22,20Zm-.441-4.071a5.015,5.015,0,1,0,1.178,1.178l2.378-2.377a8.346,8.346,0,1,1-1.178-1.178Z"/>
            </g>
        </g>
    </svg>


  )
}

export default TyCSect1