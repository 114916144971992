import React from 'react'

const PPSect8Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
  <g id="Grupo_1175" data-name="Grupo 1175" transform="translate(-491 -226)" clip-path="url(#clip-path)">
    <g id="Grupo_1188" data-name="Grupo 1188" transform="translate(491 212.593)">
      <g id="Grupo_1177" data-name="Grupo 1177" transform="translate(0 14.543)">
        <g id="Grupo_1176" data-name="Grupo 1176">
          <path id="Trazado_1471" data-name="Trazado 1471" d="M39.282,32.8a5.429,5.429,0,0,1-.638-7.991l1.021-1.095L38.353,23a7.016,7.016,0,0,1-3.621-5.5l-.054-.578-.494-.3a13.976,13.976,0,0,0-20.657,7.717,13.973,13.973,0,1,0,13.782,18.15A13.994,13.994,0,0,0,39.629,34.2L40,33.363Zm-25.93-.572a.776.776,0,1,1-.776-.778A.778.778,0,0,1,13.352,32.231Zm.621,17.71a11.644,11.644,0,0,1-.968-23.248,14.031,14.031,0,0,0-.119,1.823c0,.21.006.418.015.626a3.106,3.106,0,1,0-.325,6.2,3.087,3.087,0,0,0,1.79-.57,14.009,14.009,0,0,0,10.529,7.583A11.637,11.637,0,0,1,13.973,49.942ZM26.86,40.161a11.644,11.644,0,1,1,5.63-21.839A9.336,9.336,0,0,0,36.1,24.305a7.761,7.761,0,0,0,1.034,9.7A11.664,11.664,0,0,1,26.86,40.161Z" transform="translate(0 -14.543)"/>
        </g>
      </g>
      <g id="Grupo_1179" data-name="Grupo 1179" transform="translate(23.987 19.123)">
        <g id="Grupo_1178" data-name="Grupo 1178">
          <path id="Trazado_1472" data-name="Trazado 1472" d="M310.143,73.168a3.105,3.105,0,1,0,3.105,3.105A3.109,3.109,0,0,0,310.143,73.168Zm0,3.881a.776.776,0,1,1,.776-.776A.777.777,0,0,1,310.143,77.049Z" transform="translate(-307.038 -73.168)"/>
        </g>
      </g>
      <g id="Grupo_1181" data-name="Grupo 1181" transform="translate(26.471 28.439)">
        <g id="Grupo_1180" data-name="Grupo 1180">
          <path id="Trazado_1473" data-name="Trazado 1473" d="M341.94,192.406a3.105,3.105,0,1,0,3.105,3.105A3.109,3.109,0,0,0,341.94,192.406Zm0,3.881a.776.776,0,1,1,.776-.776A.777.777,0,0,1,341.94,196.287Z" transform="translate(-338.835 -192.406)"/>
        </g>
      </g>
      <g id="Grupo_1183" data-name="Grupo 1183" transform="translate(17.544 27.119)">
        <g id="Grupo_1182" data-name="Grupo 1182">
          <path id="Trazado_1474" data-name="Trazado 1474" d="M227.67,175.514a3.105,3.105,0,1,0,3.105,3.105A3.109,3.109,0,0,0,227.67,175.514Zm0,3.881a.776.776,0,1,1,.776-.776A.777.777,0,0,1,227.67,179.4Z" transform="translate(-224.565 -175.514)"/>
        </g>
      </g>
      <g id="Grupo_1185" data-name="Grupo 1185" transform="translate(4.813 36.038)">
        <g id="Grupo_1184" data-name="Grupo 1184">
          <path id="Trazado_1475" data-name="Trazado 1475" d="M64.711,289.68a3.106,3.106,0,1,0,3.105,3.106A3.109,3.109,0,0,0,64.711,289.68Zm0,3.884a.778.778,0,1,1,.776-.778A.778.778,0,0,1,64.711,293.564Z" transform="translate(-61.606 -289.68)"/>
        </g>
      </g>
      <g id="Grupo_1187" data-name="Grupo 1187" transform="translate(12.576 41.397)">
        <g id="Grupo_1186" data-name="Grupo 1186">
          <path id="Trazado_1476" data-name="Trazado 1476" d="M164.077,358.275a3.106,3.106,0,1,0,3.105,3.106A3.109,3.109,0,0,0,164.077,358.275Zm0,3.884a.778.778,0,1,1,.776-.777A.778.778,0,0,1,164.077,362.159Z" transform="translate(-160.972 -358.275)"/>
        </g>
      </g>
    </g>
  </g>
</svg>

  )
}

export default PPSect8Logo