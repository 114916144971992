import React from 'react'

const FiltersLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_30" data-name="Enmascarar grupo 30" transform="translate(-715 -294)" clip-path="url(#clip-path)">
        <g id="Grupo_837" data-name="Grupo 837" transform="translate(715 298.899)">
          <g id="Grupo_836" data-name="Grupo 836" clip-path="url(#clip-path-2)">
            <path id="Trazado_1010" data-name="Trazado 1010" d="M3.114,93.853a3.84,3.84,0,0,1,3.423-2.968,3.853,3.853,0,0,1,3.993,3h.255q9.116,0,18.233,0a1.7,1.7,0,0,1,.4.039.762.762,0,0,1,.582.765.74.74,0,0,1-.607.72,2,2,0,0,1-.4.031q-9.087,0-18.173,0c-.207,0-.288.05-.347.258a3.706,3.706,0,0,1-3.843,2.749,3.751,3.751,0,0,1-3.371-2.515,1.563,1.563,0,0,1-.092-.284c-.023-.178-.114-.211-.282-.208-.668.012-1.336.009-2,0a.817.817,0,0,1-.806-.489A.766.766,0,0,1,.218,94.1a.766.766,0,0,1,.626-.258l2.044.01h.227" transform="translate(0 -76.254)"/>
            <path id="Trazado_1011" data-name="Trazado 1011" d="M9.062,49.745q-4.027,0-8.054,0a.837.837,0,0,1-.814-.4.752.752,0,0,1,.641-1.125c.259-.017.521,0,.781,0q7.834,0,15.668,0c.163,0,.2-.076.236-.2a3.77,3.77,0,0,1,7.041-.7,4.152,4.152,0,0,1,.279.725c.04.145.1.175.233.174q1.963-.007,3.927-.007c.113,0,.227,0,.34,0a.766.766,0,0,1,.733.775.749.749,0,0,1-.735.748c-.327.011-.654,0-.982,0-1.082,0-2.164,0-3.246,0-.161,0-.226.046-.269.211a3.722,3.722,0,0,1-3.629,2.814,3.77,3.77,0,0,1-3.682-2.831c-.039-.157-.1-.2-.254-.2q-4.107.006-8.214,0v.008" transform="translate(-0.076 -37.91)"/>
            <path id="Trazado_1012" data-name="Trazado 1012" d="M3.14,3.008A3.813,3.813,0,0,1,6.376.026,3.741,3.741,0,0,1,10.5,2.85c.039.14.1.169.233.169q7.664-.009,15.328-.01c1.035,0,2.07.006,3.106.009a.824.824,0,0,1,.712.338.684.684,0,0,1,.077.754.739.739,0,0,1-.67.434c-.454.007-.908-.008-1.362-.008H11.833c-.367,0-.735,0-1.1,0-.132,0-.18.048-.215.172A3.627,3.627,0,0,1,7.671,7.457,3.616,3.616,0,0,1,3.853,6.068a3.354,3.354,0,0,1-.692-1.424c-.009-.044-.114-.1-.176-.1-.581-.007-1.162,0-1.743,0-.154,0-.308.009-.461,0a.769.769,0,0,1-.74-.77A.741.741,0,0,1,.771,3.02C1.344,3,1.919,3.01,2.493,3.009H3.14" transform="translate(-0.035 0)"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FiltersLogo

