import React from 'react'

const SmallPreferencesLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20.421" height="22.274" viewBox="0 0 20.421 22.274">
      <g id="Grupo_471" data-name="Grupo 471" transform="translate(0 0)">
        <g id="Grupo_243" data-name="Grupo 243" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Trazado_168" data-name="Trazado 168" d="M20.419,4.42h-.34c-3.2,0-6.4,0-9.6-.007A.426.426,0,0,0,10,4.73a3.436,3.436,0,0,1-6.43-.079.3.3,0,0,0-.346-.235c-.973.008-1.946,0-2.92,0H0V2.494H.29c.992,0,1.983,0,2.974,0a.256.256,0,0,0,.292-.2,3.439,3.439,0,0,1,6.488-.008c.071.2.18.2.341.2q4.852,0,9.7,0h.331ZM8.331,3.458A1.531,1.531,0,1,0,6.806,4.987,1.522,1.522,0,0,0,8.331,3.458" transform="translate(0 0)"/>
          <path id="Trazado_169" data-name="Trazado 169" d="M20.429,30.1v1.919H10.135a3.586,3.586,0,0,1-1.128,1.706,3.443,3.443,0,0,1-5.422-1.443.331.331,0,0,0-.383-.257c-1.054.009-2.107,0-3.184,0V30.1H.3c.982,0,1.964,0,2.946,0a.285.285,0,0,0,.329-.221,3.436,3.436,0,0,1,6.44-.081.407.407,0,0,0,.465.3q4.8-.013,9.6-.006Zm-12.09.972A1.531,1.531,0,1,0,6.807,32.6a1.518,1.518,0,0,0,1.532-1.524" transform="translate(-0.008 -12.245)"/>
          <path id="Trazado_170" data-name="Trazado 170" d="M10.3,18.223H.017V16.3H.326q4.852,0,9.7,0a.317.317,0,0,0,.366-.245,3.441,3.441,0,0,1,6.464.012.3.3,0,0,0,.347.233c1.062-.007,2.125,0,3.21,0v1.926h-.282c-.992,0-1.983,0-2.974,0a.257.257,0,0,0-.291.2,3.443,3.443,0,0,1-6.5-.021c-.018-.051-.041-.1-.071-.174m3.335.568A1.531,1.531,0,1,0,12.1,17.274a1.523,1.523,0,0,0,1.536,1.518" transform="translate(-0.008 -6.122)"/>
        </g>
      </g>
    </svg>
  )
}

export default SmallPreferencesLogo