import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { DashboardFilters, InfoI} from '../components';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
import { useStateContext } from '../contexts/ContextProvider';
import { WaterFallData, RainfallEvaporationChart, MixLineBar, LineChartComponent, OperativesInflows, FinalBalanceCash, OperativesOutflows, TopTenInflowChart, TopTenOutflowChart } from '../components/charts/graphs';
import { 
  BarChart
} from 'echarts/charts';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {
  DatasetComponent,
  TooltipComponent,
  TitleComponent,
  GridComponent,
  TransformComponent,
  ToolboxComponent,
  LegendComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import Loading from '../animations/Loading.json'
import { Player } from '@lottiefiles/react-lottie-player';
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { getToken } from '../fetch';

import { urlBase } from '../authConfig';
import Idioma from '../components/Idioma';
import { DateFomatterDBFormat } from '../utils/DateFormater';

echarts.use([
  TitleComponent, 
  TooltipComponent, 
  CanvasRenderer, 
  GridComponent,  
  DatasetComponent, 
  TransformComponent, 
  BarChart,
  ToolboxComponent,
  LegendComponent, 
]);

registerLocale("es", es);

const SummaryScreen = () => {
  const navigate = useNavigate();
  const {idClient, accessToken,setToken,userAccess, idRS, clientRSOptions, language, numberFormat, sessionID, setCheckIsPay} = useStateContext()
  const [lineChartData,setLineChartData] = useState()
  const [openBlur, setOpenBlur] = useState(false);
  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    }
  }
  const [pieChartData,setPieChartData] = useState()

  const [showAlert,setShowAlert] = useState(false)
  const [totalsData,setTotalsData] = useState([])
  const totalBalance = useMemo(() => sumTotal(totalsData),[totalsData] )
  const isAuthenticated = useIsAuthenticated();
  const [openCashBalance, setOpenCashBalance] = useState(false);
  const [openStacked, setOpenStacked] = useState(false);
  const [categoriesDict,setCategoriesDict] = useState({})
  const [flows, setFlows] = useState({});
  const [requestParams, setRequestParams] = useState([null,null,null,null,null]);
  const [timer, setTimer] = useState(false);
  const [size, setSize] = useState(12);
  const [modalBigNumber, setModalBigNumber] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [xsize, setXSize] = useState(12);

  useEffect(()=>{
    if(!isAuthenticated){
      navigate('/')
      return
    }
    getToken().then((token)=>{setToken(token)})
    },[idClient]);



    useEffect(() => {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      let client = sessionStorage.getItem('clientName');

      const { name, email } = user;
        if(window.clarity) {
            window.clarity('identify', email, client);
        }
    }, [])

    /*
    [
      {label: }
    ]
    */

    useEffect(() => {
      if(requestParams[1] !== null){
        checkIfPay();
        getData();
        getCategories();
        getDataDown();
      }
    }, [idRS, requestParams]);

    const [aux, setAux] = useState();
    const [newAux, setNewAux] = useState();

    const getData = async() => {
      let headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append('Content-Type', 'application/json');

      let body = {
        clientID: idClient,
        startDate: requestParams[1], 
        endDate: requestParams[2],
        groupType: 3,
        categoryGroup: requestParams[0], 
        language: language, 
        logsInfo: {
          origin: 'Resumen',
          sessionID: sessionID
      }
      };

      if(idRS) {
        body.idRS = idRS.toString();
      }else if(requestParams[4]){
        body.idRS = requestParams[4].toString();
      }else {
        body.idRS = '';
      }

      const response = await fetch(`${urlBase}/chart/UppersideCharts`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      if(!response.ok) {
        throw new Error('Network response was no ok');
      }

      if(response.status === 200) {
        const aux = await response.json()

        setAux(aux);
      }
    }

    const checkIfPay  = async() => {
      let headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");

      fetch(`${urlBase}/isPay`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          clientID: idClient,
          logsInfo: {
            origin: 'Resumen',
            sessionID: sessionID
        }
        })
      }).then( (response) => response.json() )
      .then( aux => {
        setCheckIsPay(aux.paymentValid)
      } );
    }

    const getDataDown = async() => {
      let headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append('Content-Type', 'application/json');

      let body = {
        clientID: idClient,
        startDate: requestParams[1], 
        endDate: requestParams[2],
        groupType: 3, 
        categoryGroup: requestParams[0],
        language: language,
        logsInfo: {
          origin: 'Resumen',
          sessionID: sessionID
      }
      };

      if(idRS) {
        body.idRS = idRS.toString();
      }else if(requestParams[4]) {
        body.idRS = requestParams[4].toString();
      }else {
        body.idRS = '';
      }

      const response = await fetch(`${urlBase}/chart/downSideCharts`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      if(!response.ok) {
        throw new Error('Network response was no ok');
      }

      if(response.status === 200) {
        const aux = await response.json()
        
        setNewAux(aux);
      }
    }
    

    const getCategories = async() => {
      let headers = new Headers()

      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json');

      fetch(`${urlBase}/getCategories`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          "clientID": idClient,
          "lowest": 0,
          showHidden: true,
          showCreditFinancing:true,
          forReport:true,
          logsInfo: {
            origin: 'Resumen',
            sessionID: sessionID
        }
        })
      }).then((response) => response.json())
        .then((aux1) => {
          let aux = aux1.categories
          let notUse = aux1.notUse
          let catDict = {}

          let flows = {"+": [], "-" : []};

          for(let i = 0;i<aux.length;i++){
            for(let j = 0; j < aux[i].subcategories.length; j++){
              catDict[aux[i].subcategories[j].idCategory] = {
                "name": aux[i].subcategories[j].category,
                "id": aux[i].subcategories[j].idCategory,
                "isActive" : aux[i].subcategories[j].isActive === true,
              }
              for(let k = 0;k<aux[i].subcategories[j].subcategories.length;k++){
                catDict[aux[i].subcategories[j].subcategories[k].idCategory] = {
                "name": aux[i].subcategories[j].subcategories[k].category,
                "id": aux[i].subcategories[j].subcategories[k].idCategory,
                "isActive" : aux[i].subcategories[j].subcategories[k].isActive === true,
                "idFather" : aux[i].subcategories[j].subcategories[k].idCategoryFather,
                }

                if(aux[i].orderNumber === 1 && requestParams[0] === 1){
                  flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                }

                if(aux[i].orderNumber === 2 && requestParams[0] === 2){
                  flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                }

                if(aux[i].orderNumber === 3 && requestParams[0] === 3){
                  flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                }

                if(requestParams[0] === 0 && (aux[i].orderNumber === 1 || aux[i].orderNumber === 2 || aux[i].orderNumber === 3)){
                  flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                }
              }
            }
          }

          setFlows(flows);

          setCategoriesDict(catDict)

        });
    }
  
    useEffect(() => {
      checkWindowSize();
      
      window.addEventListener('resize', checkWindowSize);
      
      // Limpieza del event listener cuando el componente se desmonte
      return () => {
        window.removeEventListener('resize', checkWindowSize);
      };
    }, []);

    const checkWindowSize = () => {
      const width = window.innerWidth;
      if(width >= 1920) {
        setSize(10)
        setXSize(10)
      }else if(width < 1920 && width > 1536) {
        setSize(10);
        setXSize(10)
      }else if(width <= 1536 && width > 1367){
        setSize(7)
        setXSize(8)
      }else if(width <= 1366 && width >1281) {
        setSize(9);
        setXSize(9);
      }else if(width <= 1280 && width > 1025) {
        setSize(9);
        setXSize(9);
      }else if(width <= 1024 && width > 910){
        setSize(10);
        setXSize(10);
      }else if(width <= 910 && width > 769){
        setSize(8);
        setXSize(8);
      }else if(width <= 425 && width > 376){
        setSize(8);
        setXSize(8);
      }

    };

    let options = {};
    let options2 = {};
    let options3 = {};
    let options4 = {};
    let options5 = {};
    let options6 = {};

    if(aux && newAux) {
      options = {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        tooltip:{
          formatter: (params) => {
            let index = params.dataIndex;

            if( typeof index !== 'number' ) {
              index = aux.dayListLabels.findIndex(month => month.startsWith(index));
            }

            let month = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Indice no valido';

            let result = `${month} <br/>`;
            let amount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', 
              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(aux.realInflows[index]);
            result += `${params.marker} ${amount}`;
            return result;
          }
        },
        grid: {
          top: 15,
          bottom: 1,
          left: 30,
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: aux.dayListLabels,
          axisLabel: {
            fontSize: xsize
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: size
          }
        },
        series: [
          {
            name: 'Placeholder',
            type: 'bar',
            data: aux.inflows
          }
        ],
        color: '#C7C7FF'
      }
      options2 = {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        tooltip:{
          formatter: (params) => {
            let index = params.dataIndex;

            if( typeof index !== 'number' ) {
              index = aux.dayListLabels.findIndex(month => month.startsWith(index));
            }

            let month = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Indice no valido';

            let result = `${month} <br/>`;
            let amount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', 
              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(newAux.realDebitValues[index]);
            result += `${params.marker} ${amount}`;
            return result;
          }
        },
        grid: {
          top: 15,
          bottom: 1,
          left: 30,
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: newAux.dayListLabels,
          axisLabel: {
            fontSize: xsize
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: size
          }
        },
        series: [
          {
            name: 'Placeholder',
            type: 'line',
            data: newAux.thisDebit
          }
        ], 
        color: '#8829FF'
      };  
      options3 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let index = params[0].dataIndex;
      
            if (typeof index !== 'number') {
              index = aux.dayListLabels.findIndex(month => month.startsWith(index));
            }
      
            let month = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Indice no valido';
      
            let result = `${month} <br/>`;
            let amount;

    
            if(aux.realPositives[index] > 0 && aux.realNegatives[index] === 0 ) {
              amount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(aux.realPositives[index]);
            }else if(aux.realNegatives[index] < 0 && aux.realPositives[index] == 0) {
              amount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(aux.realNegatives[index]);
            }else {
              amount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(aux.realNegatives[index]);
            }
            result += `${amount} <br/>`;
      
            return result;
          }
        },
        grid: {
          top: 15,
          bottom: 1,
          left: 30,
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: aux.dayListLabels,
          axisLabel: {
            fontSize: xsize
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: size
          }
        },
        series: [
          {
            name: 'Positivos',
            type: 'bar', 
            stack: 'total',
            data: aux.positives,
            itemStyle: {
              color: '#C7C7FF',
            },
          },
          {
            name: 'Negativos',
            type: 'bar',
            stack: 'total',
            data: aux.negatives,
            itemStyle: {
              color: '#3C0383',
            },
          }
        ]
      };
      options4 = {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        tooltip:{
          formatter: (params) => {
            let index = params.dataIndex;

            if( typeof index !== 'number' ) {
              index = aux.dayListLabels.findIndex(month => month.startsWith(index));
            }

            let month = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Indice no valido';

            let result = `${month} <br/>`;
            let amount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', 
              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(aux.realOutflows[index]);
            result += `${params.marker} ${amount}`;
            return result;
          }
        },
        grid: {
          top: 15,
          bottom: 1,
          left: 30,
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: aux.dayListLabels,
          axisLabel: {
            fontSize: xsize
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: size
          }
        },
        series: [
          {
            name: 'Placeholder',
            type: 'bar',
            data: aux.outflows
          }
        ],
        color: '#C7C7FF'
      };
      options5 = {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        tooltip:{
          formatter: (params) => {
            let index = params.dataIndex;

            if( typeof index !== 'number' ) {
              index = aux.dayListLabels.findIndex(month => month.startsWith(index));
            }

            let month = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Indice no valido';

            let result = `${month} <br/>`;
            let amount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', 
              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(newAux.realCreditValues[index]);
            result += `${params.marker} ${amount}`;
            return result;
          }
        },
        grid: {
          top: 15,
          bottom: 1,
          left: 30,
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: newAux.dayListLabels,
          axisLabel: {
            fontSize: xsize
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: size
          }
        },
        series: [
          {
            name: 'Placeholder',
            type: 'line',
            data: newAux.thisCredits
          }
        ],
        color: '#8829FF'
      };
      options6 = {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        tooltip:{
          formatter: (params) => {
            let index = params.dataIndex;

            if( typeof index !== 'number' ) {
              index = aux.dayListLabels.findIndex(month => month.startsWith(index));
            }

            let month = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Indice no valido';

            let result = `${month} <br/>`;
            let amount = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', 
              currencySign: numberFormat === 0 ? 'accounting' : 'standard',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(newAux.realTotalValues[index]);
            result += `${params.marker} ${amount}`;
            return result;
          }
        },
        grid: {
          top: 15,
          bottom: 1,
          left: 30,
          right: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: newAux.dayListLabels,
          axisLabel: {
            fontSize: xsize
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: size
          }
        },
        series: [
          {
            name: 'Placeholder',
            type: 'line',
            data: newAux.total
          }
        ],
        color: '#8829FF'
      };
    }

    const formatDatesTitle = (date1, date2, aux=false) => {
      // Diccionario de meses en español e inglés
      const monthDict = language === 'es' ? {
        "01": 'ene', "02": 'feb', "03": 'mar', "04": 'abr', "05": 'may', "06": 'jun',
        "07": 'jul', "08": 'ago', "09": 'sep', "10": 'oct', "11": 'nov', "12": 'dic'
      } : {
        "01": 'Jan', "02": 'Feb', "03": 'Mar', "04": 'Apr', "05": 'May', "06": 'Jun',
        "07": 'Jul', "08": 'Aug', "09": 'Sep', "10": 'Oct', "11": 'Nov', "12": 'Dec'
      };
    
      // Función auxiliar para formatear una fecha individual
      const formatDate = (date) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 9);
        return language === 'es' ? `${day}/${monthDict[month]}/${year}` : `${monthDict[month]}/${day}/${year}`;
      };
    
      // Validación de las fechas
      if (!date1 || !date2) return "Invalid dates";
    
      // Formatear ambas fechas
      const formattedDate1 = formatDate(date1);
      const formattedDate2 = formatDate(date2);

      // Devolver el rango de fechas en el formato especificado

      if(aux) {
        return language === 'es' ? `al ${formattedDate2}` : `to ${formattedDate2}`;
      }else {
        return language === 'es' ? `del ${formattedDate1} al ${formattedDate2}` : `from ${formattedDate1} to ${formattedDate2}`;
      }
    };
    const handleClickChartBars = ( page, sign, arrId, catDic, centerDough, divider, upDown = true, type = 'C') => {
      navigate(page, {state: {sign, arrId, catDic, centerDough, divider, upDown, type}});
    } 

    const handleClickChartLines = ( page, data, dates, divider, type ) => {
      navigate(page, { state: { data: data, dates: dates, divider, type } });
    };

    const handleClickChartTotals = ( page, dates, data, big1, big2, big3, divider, upDown, catDic = 0, arrId = [] ) => {
      navigate(page, { state: {dates: dates, data, big1, big2, big3, divider, upDown, catDic, arrId} });
    }

    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    const data = [            
            { url: '/summary/balanceBancos', title: 'balanceBancos', value: newAux?.bigDebit, option: options2, realValueHover: newAux?.bigDebitRealValue, realValuesCharts: 1, type: 'D', chartName: 'saldoBancos', divider: newAux?.dividerSign },
            { url: '/summary/balanceCredito', title: 'saldoCredito', value: newAux?.bitCredit, option: options5, realValueHover: newAux?.bigCreditRealValue, realValuesCharts: 1, type: 'C', chartName: 'saldoTarjetasCredito', divider:newAux?.dividerSign  },
            { url: '/summary/CajaNeta', title: 'cajaNetaBancos', value: newAux?.totalCreditDebit, option: options6, realValueHover: newAux?.totalRealValue, realValuesCharts: 1, upDown: false, chartName: 'cajaNeta', divider: newAux?.dividerSign },
            { url: '/summary/entradaEfectivo', title: 'entradaEfectivo', value: aux?.entradasEfectivo, option: options, realValueHover: aux?.entradasEfectivoHover, realValuesCharts: 1, sign: '+', chartName: 'entradasEfectivo', divider: aux?.dividerSign },
            { url: '/summary/salidaEfectivo', title: 'salidaEfectivo', value: aux?.outflowCash, option: options4, realValueHover: aux?.salidasEfectivoHover, realValuesCharts: 1, sign: '-', chartName: 'salidasEfectivo', divider: aux?.dividerSign },
            { url: '/summary/FlujoCash', title: 'flujoEfectivo', value: aux?.totalCash, option: options3, realValueHover: aux?.totalCashflowHover, realValuesCharts: 1, chartName: 'flujoEfectivo', divider: aux?.dividerSign },
          ];

    const itemsToRender = userAccess.access4 == true ? data : data.slice(-3);
  return (
    <div className='pl-[5%] mr-10 max-h-16 lg:max-h-12'>
      {!aux || !newAux ? 
        <div className='fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
          <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-neutral-5'></div>
          <div className='w-full h-full flex justify-center items-center pr-[15%]'>
            <Player
              className='w-[20%]'
              autoplay
              loop
              src={Loading}
            >
            </Player>
          </div>
        </div>
        : null
      }
      <div className='flex text-[8px] md:text-[12px] 2xl:text-[16px] flex-col'>
        <DashboardFilters setRequestParams={setRequestParams} groupFilter={false} /> 
        <div className='grid grid-cols-3 mobileL:grid-cols-1 mobileL:w-full w-[95%] mobileM:grid-cols-1 mobileM:w-full mobileS:grid-cols-1 mobileS:w-full xs:w-full xxs:grid-cols-1 xxs:w-full sm:w-full md:w-full gap-x-[1px] lg:mb-[10px] md:mb-[10px] lg:w-full xs:grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-y-[10px] mt-[15px] mb-[93px]'>
          {itemsToRender.map(({ url, title, value, option, realValueHover, sign, upDown, type, chartName, divider }, index) => (
            <div key={index} className='flex'>
              <div className='relative w-full h-[350px] 3xl:h-[350px] xl:h-[280px] lg:h-[280px] md:h-[280px] shadow-high-elevation rounded-main cursor-pointer mb-5 mr-[40px] mobileL:mr-[0px] mobileM:mr-[0px] mobileS:mr-[0px]' onClick={() => handleClickChartBars(url, sign, flows[sign], categoriesDict, value, aux?.dividerSign, upDown, type)}>
                <div className='absolute inset-0 flex flex-col'>
                  <div className='ml-[30px] mt-[30px] lg:ml-[20px] lg:mt-[18.14px] lg:mb-2 md:ml-[19.86px] md:mt-[18.14px] sm:ml-[18px] sm:mt-[21px] mb-4 sm:mb-2 xxs:mb-3 mobileL:mb-3 mobileM:mb-2'>
                    <div className='flex'>
                      <h2 className='text-lg font-bold xl:text-sm lg:text-base md:text-sm sm:text-base xs:text-lg xxs:text-base mobileL:text-base mobileM:text-sm mobileS:text-xs'>{Idioma.resumen[title][language]}</h2>
                      <InfoI texto={Idioma.resumen.iconI[chartName][language]} />
                    </div>
                    <div onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                      <h3 className='text-4xl font-bold xl:text-3xl lg:text-3xl md:text-3xl sm:text-3xl xxs:text-3xl mobileL:text-3xl mobileM:text-3xl mobileS:text-2xl'>{aux ? new Intl.NumberFormat('en-US', { currency: 'USD', minimumFractionDigits: 1, maximumFractionDigits:1, style: 'currency', currencySign: numberFormat === 0 ? 'accounting' : 'standard' }).format(value) : '--'} <span className='text-4xl font-normal lg:text-h5-2xl md:text-h6-2xl mobileS:text-2xl'>{divider ?? '--'}</span></h3>
                    </div>
                  {hoveredIndex === index && (
                    <div className="absolute top-24 left-28 w-[121px] p-2 bg-slate-50 borded border-gray-300 shadow-2xl rounded-md z-20">
                      <div className='text-xl'>
                        <h2 className='text-[10px] text-center'>{DateFomatterDBFormat(requestParams[2], language)}</h2>
                        <p className='text-center text-sm font-bold'>{new Intl.NumberFormat('en-US', {
                          style: 'currency',  
                          currency: 'USD', 
                          currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          }).format(realValueHover)}
                        </p>
                      </div>
                    </div>
                  )}
                    <p className='text-[11px] text-neutral-3 xl:text-[10px] lg:text-[10px] md:text-[8px] sm:text-[10px] xs:text-[8px] xxs:text-[10px] mobileL:text-[10px] mobileM:text-[9px] mobileS:text-[8px]'>{formatDatesTitle(requestParams[1], requestParams[2])}</p>
                  </div>
                  <div className='flex-grow pb-5'>
                    <ReactEChartsCore
                      echarts={echarts}
                      option={option}
                      notMerge={true}
                      lazyUpdate={true}
                      style={{ height: '100%', width: '100%' }}
                      theme="theme_name"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    <div/>
  </div>
  )
}

const sumTotal = (totalsData) => {
  let total = 0
  for(let i=0;i<totalsData.length;i++){
    total += totalsData[i].balance
  }
  return total
}

export default SummaryScreen