
import React from 'react'

const RestoreRBinLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_46" data-name="Enmascarar grupo 46" transform="translate(-594 -809)" clip-path="url(#clip-path)">
        <g id="Grupo_946" data-name="Grupo 946" transform="translate(598.567 809.079)">
          <g id="Grupo_859" data-name="Grupo 859" clip-path="url(#clip-path-2)">
            <path fill={props.fill || "#000"} id="Trazado_1032" data-name="Trazado 1032" d="M4.386,8.767,4.35,8.69,15.739,4.061,18.5,2.917l.329-.134c.378-.154.761-.294,1.132-.46h0a.9.9,0,0,0,.49-1.169A.907.907,0,0,0,19.3.665l-.261.106L13.975,2.829c-.189-.464-.36-.9-.54-1.329l-.022-.054A2.373,2.373,0,0,0,10.3.19L6.645,1.675l-.06.025A2.372,2.372,0,0,0,5.324,4.807l.537,1.321v0L.789,8.193C.7,8.229.611,8.257.528,8.3A.905.905,0,0,0,.066,9.459a.9.9,0,0,0,1.149.488c.352-.131.7-.283,1.044-.424l.4-.164a.8.8,0,0,1,.076.092ZM7.294,3.339q1.852-.764,3.711-1.508l.036-.017a.54.54,0,0,1,.7.294c.2.444.371.9.568,1.385L7.538,5.435c-.206-.507-.436-.995-.6-1.5a.5.5,0,0,1,.354-.592" transform="translate(-0.001 0)"/>
            <path fill={props.fill || "#000"} id="Trazado_1033" data-name="Trazado 1033" d="M53.73,150.526c-.01.155-.02.3-.028.436q-.2,3.642-.4,7.282c-.141,2.594-.281,5.188-.437,7.782a2.02,2.02,0,0,1-2.032,1.875q-3.851.034-7.7,0a2.037,2.037,0,0,1-2.045-2.026q-.4-7.063-.79-14.126c-.021-.369-.036-.738-.055-1.137l-1.783.013q.253,4.563.509,9.125c.12,2.136.222,4.273.368,6.406a3.793,3.793,0,0,0,3.731,3.526q3.928.042,7.856,0a3.779,3.779,0,0,0,3.7-3.426c.132-1.517.183-3.041.271-4.562l.48-8.594c.048-.849.1-1.7.148-2.6l-1.831.021Z" transform="translate(-35.741 -139.862)"/>
            <rect fill={props.fill || "#000"} id="Rectángulo_819" data-name="Rectángulo 819" width="1.727" height="9.765" transform="translate(7.934 14.471)"/>
            <rect fill={props.fill || "#000"} id="Rectángulo_820" data-name="Rectángulo 820" width="1.721" height="9.775" transform="translate(12.826 14.46)"/>
            <path fill={props.fill || "#000"} id="Trazado_1034" data-name="Trazado 1034" d="M168.624,71.256l1.7-1.888-1.888-1.7-1.525.078,1.378,1.243c-3.581.6-7.138,4.038-7.3,4.194a.53.53,0,0,0,.741.759c.036-.035,3.322-3.209,6.5-3.863l-1.138,1.26Z" transform="translate(-149.462 -62.879)"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default RestoreRBinLogo
