const fechas = {
    'es': {
        '01': 'ene',
        '02': 'feb',
        '03': 'mar',
        '04': 'abr',
        '05': 'may',
        '06': 'jun',
        '07': 'jul',
        '08': 'ago',
        '09': 'sep',
        '10': 'oct',
        '11': 'nov',
        '12': 'dic'
    },
    'en': {
        '01': 'jan',
        '02': 'feb',
        '03': 'mar',
        '04': 'apr',
        '05': 'may',
        '06': 'jun',
        '07': 'jul',
        '08': 'aug',
        '09': 'sep',
        '10': 'oct',
        '11': 'nov',
        '12': 'dic'
    }
};

export const DateFomatterDBFormat = ( date, lang ) => {
    let year = date.substring(0, 4);
    let month = date.substring(4, 6)
    let day = date.substring(6, 8);
    return `al ${day}/${fechas[lang][month]}/${year}`;
}