import React from 'react'

const ThrashCanLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_28" data-name="Enmascarar grupo 28" transform="translate(-615 -295)" clip-path="url(#clip-path)">
        <g id="Grupo_519" data-name="Grupo 519" transform="translate(617 295)">
          <g id="Grupo_391" data-name="Grupo 391" clip-path="url(#clip-path-2)">
            <path id="Trazado_676" data-name="Trazado 676" d="M7.778,4.5c0-.6,0-1.137,0-1.675A2.786,2.786,0,0,1,10.6,0Q12.917,0,15.234,0a2.788,2.788,0,0,1,2.833,2.811c.005.548,0,1.1,0,1.686h6.422c.11,0,.221,0,.331,0a1.065,1.065,0,0,1,1.036,1.038A1.053,1.053,0,0,1,24.8,6.6c-.477.017-.956,0-1.434,0h-.417c-.059,1.047-.117,2.047-.173,3.048Q22.5,14.7,22.216,19.742c-.1,1.786-.163,3.576-.318,5.357a4.438,4.438,0,0,1-4.347,4.013q-4.614.049-9.229,0a4.455,4.455,0,0,1-4.382-4.141c-.172-2.506-.292-5.016-.432-7.524q-.3-5.359-.6-10.718a.94.94,0,0,0-.038-.13H2.359c-.441,0-.883.014-1.324-.005A1.05,1.05,0,0,1,0,5.561,1.063,1.063,0,0,1,1.015,4.5c.11-.009.221,0,.331,0H7.778ZM5,6.618c.024.507.042.971.068,1.435q.463,8.3.928,16.591a2.392,2.392,0,0,0,2.4,2.381q4.522.04,9.045,0a2.373,2.373,0,0,0,2.387-2.2c.182-3.046.347-6.093.516-9.139q.237-4.276.468-8.553c.009-.165.021-.33.033-.512ZM15.964,4.485c0-.611.018-1.186-.006-1.758a.634.634,0,0,0-.683-.629c-1.568-.011-3.137-.008-4.7,0a.583.583,0,0,0-.647.488c-.049.626-.014,1.258-.014,1.9Z" transform="translate(0 0)" fill={props.fill || "#adadad"}/>
            <rect id="Rectángulo_305" data-name="Rectángulo 305" width="2.029" height="11.469" transform="translate(9.037 11.087)" fill={props.fill || "#adadad"}/>
            <rect id="Rectángulo_306" data-name="Rectángulo 306" width="2.021" height="11.481" transform="translate(14.783 11.075)" fill={props.fill || "#adadad"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ThrashCanLogo