import React from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import ExcelExportLogo from '../assets/ExcelExportLogo'
import Idioma from './Idioma'

const DynamicExcelExport = ({excelData, fileName, disabled, title, language, columns}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF=8'
    const fileExtension = '.xlsx'
    const cols = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    const exportToExcel = async () => {
        let auxData = []

        for(let i=0;i<excelData.length;i++){
            auxData[i] = {}
            for(let j=0;j<columns.length;j++){
                auxData[i][columns[j].Header] = excelData[i].values[columns[j].accessor]
            }
/*
                [Idioma.transacciones.configuracion.empresa[language]]:excelData[i].values.rsName,
                [Idioma.transacciones.configuracion.banco[language]]:excelData[i].values.bank,
                [Idioma.transacciones.anadirTransaccionModal.cuenta[language]]:excelData[i].values.idAccount,
                [Idioma.transacciones.configuracion.fecha[language]]:new Date(excelData[i].values.date),
                [Idioma.transacciones.configuracion.concepto[language]]:excelData[i].values.context,
                [Idioma.transacciones.configuracion.abono[language]]:excelData[i].values.inflow ,
                [Idioma.transacciones.configuracion.cargo[language]]:excelData[i].values.outflow,
                [Idioma.transacciones.configuracion.categoria[language]]:excelData[i].values.category,
                [Idioma.transacciones.configuracion.subcategoria[language]]:excelData[i].values.subcategory,
                [Idioma.transacciones.configuracion.moneda[language]]: excelData[i].values.currency,
                [Idioma.transacciones.configuracion.fx[language]]:excelData[i].values.exchange,
                [Idioma.transacciones.configuracion.creditoDebito[language]]:excelData[i].values.accountType,
                [Idioma.transacciones.configuracion.clientesProvedores[language]]:excelData[i].values.clientSupplier,
                [Idioma.transacciones.configuracion.notas[language]]: excelData[i].values.notes,
                [Idioma.transacciones.configuracion.informacionAdicional[language]]:''
            */
            auxData[i][Idioma.transacciones.configuracion.fecha[language]] = new Date(excelData[i].values.date)

            //auxData[i][Idioma.transacciones.configuracion.informacionAdicional[language]] = ''

            auxData[i][Idioma.transacciones.configuracion.fecha[language]] = new Date(auxData[i][Idioma.transacciones.configuracion.fecha[language]].getTime() + auxData[i][Idioma.transacciones.configuracion.fecha[language]].getTimezoneOffset() * 60000 + 36000)
            
            /*if(excelData[i].values.extraText){
                let jsonAux = JSON.parse(excelData[i].values.extraText)
                let jsonKeys = Object.keys(jsonAux)
                for(let j=0;j<jsonKeys.length;j++){
                    auxData[i][Idioma.transacciones.configuracion.informacionAdicional[language]] = `${auxData[i][Idioma.transacciones.configuracion.informacionAdicional[language]]}${jsonKeys[j]}:${jsonAux[jsonKeys[j]]}${jsonKeys.length!==j+1 ? '\n':''}`
                }
            }*/

        }
        const ws = XLSX.utils.json_to_sheet(auxData)
        console.log('ws',ws)
        if(!ws["!rows"]) ws["!rows"] = []
        for(let i=0;i<columns.length;i++){
            for(let j=0;j<auxData.length+1;j++){
                let auxBody = {
                    font: {
                    name: 'Century Gothic',
                    sz: 8,
                  },
                }
                if(j===0){
                    auxBody.font.bold = true
                    if(!ws["!rows"][j+1]) ws["!rows"][j+1] = {hpt: 15};
                }
                if(i>=1){
                    auxBody.alignment = {
                        horizontal:'right'
                    }
                }
                if(i===5 || i===6 || i===10){
                    auxBody.numFmt = "#,##0.00_);(#,##0.00);--_)"
                }
                ws[`${cols[i]}${j+1}`].s = auxBody
            }
        }
        const wb = { Sheets: {'data': ws}, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }
   

    return (
        <>
            <button className='p-[4px] hover:bg-white' onClick={(e)=>exportToExcel(fileName)} disabled={disabled} title={title}>
                <ExcelExportLogo/>
            </button>
        </>
    )
}

export default DynamicExcelExport
