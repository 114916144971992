import React from 'react'

const TyCSect14Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_109" data-name="Enmascarar grupo 109" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1130" data-name="Grupo 1130" transform="translate(491 226)">
        <path id="Trazado_1375" data-name="Trazado 1375" d="M39.657,11.372,28.627.343A1.172,1.172,0,0,0,27.8,0H12.2a1.171,1.171,0,0,0-.829.343L.343,11.372A1.172,1.172,0,0,0,0,12.2V27.8a1.171,1.171,0,0,0,.343.829L11.373,39.657A1.172,1.172,0,0,0,12.2,40H27.8a1.171,1.171,0,0,0,.829-.343L39.657,28.627A1.172,1.172,0,0,0,40,27.8V12.2A1.172,1.172,0,0,0,39.657,11.372Zm-2,15.941L27.313,37.656H12.687L2.344,27.313V12.687L12.687,2.344H27.314L37.656,12.687Z" transform="translate(0 0)"/>
        <path id="Trazado_1376" data-name="Trazado 1376" d="M202.43,72.627h-3.945a2.734,2.734,0,0,0-2.731,2.868l.628,12.82a2.732,2.732,0,0,0,2.731,2.6H201.8a2.732,2.732,0,0,0,2.731-2.6l.628-12.82a2.734,2.734,0,0,0-2.731-2.868ZM202.192,88.2a.39.39,0,0,1-.39.371h-2.689a.39.39,0,0,1-.39-.371l-.628-12.82a.391.391,0,0,1,.39-.41h3.945a.391.391,0,0,1,.39.41Z" transform="translate(-180.458 -66.953)"/>
        <path id="Trazado_1377" data-name="Trazado 1377" d="M208.751,336.866a4,4,0,1,0,4,4A4.009,4.009,0,0,0,208.751,336.866Zm0,5.665a1.66,1.66,0,1,1,1.66-1.66A1.662,1.662,0,0,1,208.751,342.531Z" transform="translate(-188.751 -310.548)"/>
        </g>
    </g>
    </svg>
  )
}

export default TyCSect14Logo