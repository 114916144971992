import React from 'react'

const TyCSect13Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
  <g id="Enmascarar_grupo_108" data-name="Enmascarar grupo 108" transform="translate(-491 -226)" clip-path="url(#clip-path)">
    <g id="XMLID_829_" transform="translate(453.97 226)">
      <path id="XMLID_865_" d="M163.478,105.724a7.841,7.841,0,1,0,7.841,7.841A7.85,7.85,0,0,0,163.478,105.724Zm0,14.119a6.278,6.278,0,1,1,6.278-6.278A6.286,6.286,0,0,1,163.478,119.843Z" transform="translate(-106.448 -97.465)"/>
      <path id="XMLID_862_" d="M132.953,71.646A11.393,11.393,0,1,0,121.559,83.04,11.406,11.406,0,0,0,132.953,71.646Zm-11.393,9.831a9.831,9.831,0,1,1,9.831-9.831A9.842,9.842,0,0,1,121.559,81.477Z" transform="translate(-64.529 -55.546)"/>
      <path id="XMLID_861_" d="M217.385,160.821a.782.782,0,0,0-1.1,0l-2.221,2.221-2.221-2.221a.781.781,0,1,0-1.1,1.1l2.221,2.221-2.221,2.221a.781.781,0,1,0,1.1,1.1l2.221-2.221,2.221,2.221a.781.781,0,0,0,1.1-1.1l-2.221-2.221,2.221-2.221A.781.781,0,0,0,217.385,160.821Z" transform="translate(-157.029 -148.046)"/>
      <path id="XMLID_856_" d="M73.8,34.713l-4.259-7.807a3.8,3.8,0,0,0,1.044-.959,5.954,5.954,0,0,0,.842-3.231,5.25,5.25,0,0,1,1.109-3.431A6.1,6.1,0,0,0,73.78,16.1c0-1.93-1.655-3.411-2.093-4.762-.466-1.434,0-3.56-1.105-5.085a5.975,5.975,0,0,0-2.831-1.807,5.254,5.254,0,0,1-2.928-2.128A6.063,6.063,0,0,0,62.207.166c-1.769-.575-3.644.527-5.177.527s-3.405-1.1-5.177-.527c-1.812.588-2.707,2.616-3.879,3.469-1.2.876-3.376,1.081-4.495,2.619s-.64,3.65-1.105,5.085c-.439,1.351-2.093,2.832-2.093,4.762a6.094,6.094,0,0,0,1.247,3.185,5.243,5.243,0,0,1,1.109,3.431,5.954,5.954,0,0,0,.842,3.231,3.8,3.8,0,0,0,1.043.958l-4.258,7.807a.781.781,0,0,0,.87,1.133l4.976-1.21,1.677,4.838a.781.781,0,0,0,1.424.118l4.037-7.4c1.321-.081,2.655-.684,3.783-.684s2.463.6,3.782.684l4.037,7.4a.781.781,0,0,0,1.424-.118l1.677-4.838,4.976,1.21a.781.781,0,0,0,.87-1.133ZM48.684,37.294l-1.331-3.84a.781.781,0,0,0-.923-.5l-3.95.96L45.923,27.6a10.3,10.3,0,0,1,2.05.967c1.116.812,1.98,2.689,3.63,3.376Zm8.346-7.349c-1.731,0-3.506.989-4.694.6-1.247-.405-2.08-2.255-3.442-3.246s-3.391-1.23-4.151-2.275S44.387,22,43.859,20.38c-.517-1.591-2.017-2.936-2.017-4.28s1.5-2.691,2.017-4.28c.528-1.625.132-3.617.883-4.648S47.521,5.9,48.893,4.9s2.194-2.841,3.442-3.246c1.188-.386,2.961.6,4.695.6s3.5-.99,4.695-.6c1.248.405,2.078,2.253,3.442,3.246s3.391,1.23,4.151,2.275a4.81,4.81,0,0,1,.547,2.417,6.681,6.681,0,0,0,1.348,4.162,4.833,4.833,0,0,1,1,2.348,4.832,4.832,0,0,1-1,2.348,6.682,6.682,0,0,0-1.348,4.162,4.809,4.809,0,0,1-.547,2.417c-.761,1.045-2.78,1.276-4.151,2.275s-2.2,2.842-3.442,3.246c-1.189.386-2.959-.6-4.694-.6Zm10.6,3.005a.781.781,0,0,0-.923.5l-1.331,3.84-2.92-5.352c1.652-.687,2.513-2.562,3.63-3.376a10.254,10.254,0,0,1,2.05-.966l3.443,6.311Z" transform="translate(0 0)"/>
    </g>
  </g>
</svg>

  )
}

export default TyCSect13Logo