import React from 'react';
import ReactDom from 'react-dom';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import './index.css';
import App from './App';
import {ContextProvider} from './contexts/ContextProvider'


export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

ReactDom.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <ContextProvider>
                <App/>
            </ContextProvider>
        </MsalProvider>
    </React.StrictMode>
    , document.getElementById('root')
);