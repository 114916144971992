import moment from 'moment';

const fiscalWeeks = (year, week, language) => {
    const monthNumMap = {
        "Ene": 0,
        "Jan": 0,
        "Feb": 1,
        "Mar": 2,
        "Abr": 3,
        "Apr": 3,
        "May": 4,
        "Jun": 5,
        "Jul": 6,
        "Ago": 7,
        "Aug": 7,
        "Sep": 8,
        "Oct": 9,
        "Nov": 10,
        "Dic": 11,
        "Dec": 11
    };

    const momentSubstractDate = {
        'Sun': 6,
        'Mon': 0,
        'Tue': 1,
        'Wed': 2,
        'Thu': 3,
        'Fri': 4,
        'Sat': 5
    };

    let momentAux = moment().set('year', year);
    let dateAux = momentAux.isoWeek(week).toString().split(' ');
    let auxDateObj = new Date(dateAux[3], monthNumMap[dateAux[1]], dateAux[2]);
    let first = auxDateObj.getDate() - momentSubstractDate[dateAux[0]];
    let last = first + 6;
    let fetchStartDate = new Date(auxDateObj.setDate(first));
    let fetchEndDate = new Date(auxDateObj.setDate(last));


    return formatterFiscalWeeks([fetchStartDate, fetchEndDate], language);
};

const formatterFiscalWeeks = (dates, language) => {
    const months = {
        es: {
            1: 'ene',
            2: 'feb',
            3: 'mar',
            4: 'abr',
            5: 'may',
            6: 'jun',
            7: 'jul',
            8: 'ago',
            9: 'sep',
            10: 'oct',
            11: 'nov',
            12: 'dic'
        },
        en: {
            1: 'jan',
            2: 'feb',
            3: 'mar',
            4: 'apr',
            5: 'may',
            6: 'jun',
            7: 'jul',
            8: 'aug',
            9: 'sep',
            10: 'oct',
            11: 'nov',
            12: 'dec'
        }
    };

    const parsedDate = new Date(dates[0]);
    const parsedDate2 = new Date(dates[1]);


    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');


    const year2 = parsedDate2.getFullYear();
    const month2 = String(parsedDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(parsedDate2.getDate()).padStart(2, '0');

    let date;

    if (language === 'es') {
        date = `del ${day} de ${months[language][parseInt(month)]} al ${day2} de ${months[language][parseInt(month2)]} ${year2}`;
    }else {
        date = `from ${day} of ${months[language][parseInt(month)]} to ${day2} of ${months[language][parseInt(month2)]} ${year2}`;
    }


    return date;
};

export default fiscalWeeks;