import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Triangulo from '../assets/Triangulo'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const FileDropdownPreferences = ({options,selected,setSelected, className}) => {
  
  const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 


  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className={"relative mt-1 "+ className}>
            <Listbox.Button className={" relative w-full cursor-default  border-gray-300 py-2 pl-3 pr-10 text-left  focus:border-md-blue focus:outline-none focus:ring-1 focus:ring-md-blue text-md"}>
              <span className="flex items-center">
                <span className="ml-3 block truncate text-md-black-text">{selected.name }</span>
                <span className='text-md-blue font-bold absolute right-12'>{selected.percentage ? parseInt(selected.percentage) +'%' : ''}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <Triangulo fill={"#d5d5d5"} aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 max-h-56 min-w-fit  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-md-blue' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center justify-between">
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', ' ml-3 block ')}
                          >
                            {option.name}
                            
                          </span>
                          <span className='text-md-blue font-bold ml-2 mr-2'>{option.percentage ? parseInt(option.percentage) +'%' : ''}</span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-md-blue',
                              'absolute inset-y-0 -right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default FileDropdownPreferences