import React from 'react'

const TyCSect19Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
        <g id="Grupo_1137" data-name="Grupo 1137" transform="translate(-491 -226)" clip-path="url(#clip-path)">
            <g id="Grupo_1138" data-name="Grupo 1138" transform="translate(491.889 226.888)">
            <path id="Trazado_1428" data-name="Trazado 1428" d="M22.546,44.25H8.29a1.04,1.04,0,0,0,0,2.079H22.546a1.04,1.04,0,0,0,0-2.079Z" transform="translate(-2.842 -17.862)"/>
            <path id="Trazado_1429" data-name="Trazado 1429" d="M8.29,36.25a1.04,1.04,0,0,0,0,2.079H30.863a1.04,1.04,0,0,0,0-2.079Z" transform="translate(-2.842 -14.614)"/>
            <path id="Trazado_1430" data-name="Trazado 1430" d="M46.29,7.25a1.04,1.04,0,0,0-1.04,1.04v5.346a1.04,1.04,0,1,0,2.079,0V8.29a1.04,1.04,0,0,0-1.04-1.04Z" transform="translate(-18.268 -2.841)"/>
            <circle id="Elipse_70" data-name="Elipse 70" cx="1.04" cy="1.04" r="1.04" transform="translate(26.982 13.913)"/>
            <path id="Trazado_1431" data-name="Trazado 1431" d="M37.971,10.2a9.951,9.951,0,0,0-19.3-3.416H4.26a4.01,4.01,0,0,0-4.01,4.01V36.929a1.041,1.041,0,0,0,1.6.879l6.277-3.994H29.209a4.01,4.01,0,0,0,4.01-4.01V18.682A9.953,9.953,0,0,0,37.971,10.2ZM31.14,29.8a1.931,1.931,0,0,1-1.931,1.931H7.824a1.039,1.039,0,0,0-.558.163L2.329,35.039V10.795A1.931,1.931,0,0,1,4.26,8.864h13.9a10,10,0,0,0,.1,3.267H5.448a1.04,1.04,0,0,0,0,2.079H18.915a9.984,9.984,0,0,0,1.741,2.673H5.448a1.04,1.04,0,0,0,0,2.079H23.309a9.947,9.947,0,0,0,7.831.687ZM28.021,18.072A7.871,7.871,0,1,1,35.892,10.2a7.871,7.871,0,0,1-7.871,7.871Z" transform="translate(0 0)"/>
            </g>
        </g>
    </svg>

  )
}

export default TyCSect19Logo