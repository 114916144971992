import React from 'react'

const TyCSect5Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_100" data-name="Enmascarar grupo 100" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1110" data-name="Grupo 1110" transform="translate(454.031 227.055)">
        <g id="Grupo_1109" data-name="Grupo 1109" transform="translate(41.063)">
            <path id="Trazado_1343" data-name="Trazado 1343" d="M72.738,32.343a16.286,16.286,0,0,0-31.537,0,4.441,4.441,0,0,0,4.3,5.547H68.436a4.412,4.412,0,0,0,3.509-1.719A4.47,4.47,0,0,0,72.738,32.343Zm-3.132,2.013a1.471,1.471,0,0,1-1.17.574H45.5a1.492,1.492,0,0,1-1.171-.573,1.471,1.471,0,0,1-.264-1.276,13.326,13.326,0,0,1,25.8,0,1.491,1.491,0,0,1-.266,1.276Zm-12.636-16.6a8.881,8.881,0,1,0-8.881-8.881A8.891,8.891,0,0,0,56.969,17.761Zm0-14.8a5.92,5.92,0,1,1-5.92,5.92A5.927,5.927,0,0,1,56.969,2.96Z" transform="translate(-41.063)"/>
        </g>
        </g>
    </g>
    </svg>

  )
}

export default TyCSect5Logo