const formatterDates3Letters = (date, language) => {
    if(language === 'es'){
        const dateParser = date.split(" ");

        const [del, firstDay, firstDe, firstMonth, al, secondDay, secondDe, secondMonth ] = dateParser;
        
        return `${del} ${firstDay} ${firstDe} ${firstMonth} ${al} ${secondDay} ${secondDe} ${secondMonth}`;
    }
    const dateParser = date.split(" ");

    const [from, firstMonth, firstDay, to, secondMonth, secondDay] = dateParser;
    
    return `${from} ${firstMonth} ${firstDay} ${to} ${secondMonth} ${secondDay}`;
}

export default formatterDates3Letters