import React from 'react'

const AutoclassLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_31" data-name="Enmascarar grupo 31" transform="translate(-765 -294)" clip-path="url(#clip-path)">
        <g id="Grupo_922" data-name="Grupo 922" transform="translate(765 296)">
          <g id="Grupo_921" data-name="Grupo 921" clip-path="url(#clip-path-2)">
            <path id="Trazado_1069" data-name="Trazado 1069" d="M35.982,8.232V6.112a3.1,3.1,0,0,1-2.266-3.377,3.1,3.1,0,0,1,6.132-.156A3.076,3.076,0,0,1,37.615,6.1V8.24h.194c1.969,0,3.939-.043,5.906.015A4.575,4.575,0,0,1,47.2,9.96a4.827,4.827,0,0,1,1.152,2.516,6.565,6.565,0,0,1,.036.806c0,2.737-.011,5.475.007,8.212a4.9,4.9,0,0,1-4.245,4.89,5.249,5.249,0,0,1-.737.046q-6.285,0-12.57-.006a10.184,10.184,0,0,1-1.626-.069,4.611,4.611,0,0,1-2.893-1.724,4.786,4.786,0,0,1-1.091-2.471,5.868,5.868,0,0,1-.038-.7c0-2.771.007-5.542-.008-8.313A5.024,5.024,0,0,1,28.83,8.409a4.856,4.856,0,0,1,1.295-.171c1.935,0,3.87,0,5.805,0,.011,0,.022,0,.052-.008m6.653,9.719a2.349,2.349,0,1,0-.062-4.7,2.349,2.349,0,1,0,.062,4.7m-11.653,0a2.348,2.348,0,1,0-2.355-2.318,2.376,2.376,0,0,0,2.355,2.318M36.8,22.017v0c.729,0,1.458.008,2.186,0a.772.772,0,0,0,.776-.767A.8.8,0,0,0,39,20.432c-.117-.009-.235-.009-.353-.009q-1.909,0-3.818,0a2.025,2.025,0,0,0-.335.021.74.74,0,0,0-.624.562.761.761,0,0,0,.232.81.809.809,0,0,0,.59.2q1.051,0,2.1,0" transform="translate(-21.799 0)" fill={props.fill ||"#adadad"}/>
            <path id="Trazado_1070" data-name="Trazado 1070" d="M209.5,112.484v-6.808a4.134,4.134,0,0,1,1.793,3.534c-.071,1.393-.985,3.028-1.793,3.274" transform="translate(-181.296 -91.45)" fill={props.fill ||"#adadad"}/>
            <path id="Trazado_1071" data-name="Trazado 1071" d="M1.746,112.394A4.247,4.247,0,0,1,0,109.143a4.132,4.132,0,0,1,1.788-3.587v6.815l-.047.024" transform="translate(0 -91.347)" fill={props.fill ||"#adadad"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AutoclassLogo