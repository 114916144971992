import React,{ ReactDOM, useState, useEffect, useMemo, useRef, forwardRef } from 'react'

import { Dropdown,Loading,SlimDropdown,MessageToast,scrollbarWidth,ExcelExport,DropdownCheckbox, DinamicDropdown, Idioma, InfoI, Styles, PlanningExcelExport,CurrencyOptions } from '../components';

import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import DatePicker from 'react-datepicker';

import { getToken } from '../fetch';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import Upper from '../components/Upper';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid';

import { msalConfig, urlBase } from '../authConfig';

import testJson from '../assets/testJSON'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import PreferencesLogo from '../assets/PreferencesLogo';
import Triangulo from '../assets/Triangulo';

import ExcelLogo from '../assets/ExcelLogo.png'
import { use } from 'echarts/core';
import moment from 'moment'
import 'moment/locale/es'
import { FixedSizeList } from 'react-window'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect, useBlockLayout,useResizeColumns } from 'react-table'
import XMark from '../assets/XMark'
import UpdateReportLogo from '../assets/UpdateReportLogo';
import ExcelExportLogo from '../assets/ExcelExportLogo';
import DownloadIcon from '../assets/DownloadIcon';
import AddSingleTransactionLogo from '../assets/AddSingleTransactionLogo'
import TrianguloAbajoLogo from '../assets/TrianguloAbajoLogo'


function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
  }) {
	const count = preGlobalFilteredRows.length
	const [value, setValue] = React.useState(globalFilter)
	const onChange = useAsyncDebounce(value => {
	  setGlobalFilter(value || undefined)
	}, 200)
  
	return (
	  <div className='flex  bg-gray-6 rounded-[7px] relative'>
		<div className='text-secondary absolute object-contain right-2 top-0 dark:text-white dark:bg-secondary-dark-bg mt-1 scale-[.8]'>
		  <SearchLogo/>
		</div>
		{' '}
		<input
		  placeholder='Buscar...'
		  value={value || ""}
		  onChange={e => {
			setValue(e.target.value);
			onChange(e.target.value);
		  }}
		  style={{
			border: '0',
		  }}
		  className='dark:bg-secondary-dark-bg bg-transparent font-medium pl-2 w-96 h-8 mt-1 focus:outline-none 2xl:text-[20px] text-[16px]'
		/>
	  </div>
	)
  }
  
  // Define a default UI for filtering
  function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
  }) {
	const {language} = useStateContext()
	const count = preFilteredRows.length
  
	return (
		<div className='pr-4'>
			<input
		value={filterValue || ''}
		className=' p-2 bg-white rounded-button w-[calc(100%)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
		placeholder={Idioma.transacciones.configuracion.concepto[language]}
		onChange={e => {
		  setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
		}}
	  />
		</div>
	)
  }
  
  function NotesColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
  }) {
	const count = preFilteredRows.length
  
	return (
		<div className='pr-4'>
			<input
		value={filterValue || ''}
		className='p-2 bg-white rounded-button w-[calc(100%)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
		placeholder=''
		onChange={e => {
		  setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
		}}
	  />
		</div>
	)
  }
  // Define a default UI for filtering
  function DisabledFilter({
	column: { filterValue, preFilteredRows, setFilter },
  }) {
	const count = preFilteredRows.length
  
	return (
	  <div><p className=' invisible'>null</p></div>
	)
  }
  
  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id },
  }) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const {language} = useStateContext()
	const options = React.useMemo(() => {
	  const options = new Set()
	  options.add(Idioma.filtros.todos[language])
	  preFilteredRows.forEach(row => {
		options.add(row.values[id])
	  })
	  return [...options.values()]
	}, [id, preFilteredRows])
  
	// Render a multi-select box
	return (
		<DinamicDropdown
					options={options.map((option, i) => ({id: i, name: option}))}
					selected={{name: filterValue==null ? Idioma.filtros.todos[language] : filterValue} || {id: 0, name: options[0]}}
					className={'text-p9-2xl w-button-4 h-button-4'}
					setSelected={(e) => e.name===Idioma.filtros.todos[language] ? setFilter("") : setFilter(e.name)}
				/>
	  
	)
  }
  
  function SliderColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id },
  }) {
	// Calculate the min and max
	// using the preFilteredRows
  
	const [min, max] = React.useMemo(() => {
	  let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
	  let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
	  preFilteredRows.forEach(row => {
		min = Math.min(row.values[id], min)
		max = Math.max(row.values[id], max)
	  })
	  return [min, max]
	}, [id, preFilteredRows])
  
	return (
	  <>
		<input
		  type="range"
		  min={min}
		  max={max}
		  value={filterValue || min}
		  onChange={e => {
			setFilter(parseInt(e.target.value, 10))
		  }}
		/>
		<button onClick={() => setFilter(undefined)}>Off</button>
	  </>
	)
  }
  
  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({
	column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
	const {language} = useStateContext()
	const [min, max] = React.useMemo(() => {
	  let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
	  let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
	  preFilteredRows.forEach(row => {
		min = Math.min(row.values[id], min)
		max = Math.max(row.values[id], max)
	  })
	  return [min, max]
	}, [id, preFilteredRows])
  
	return (
	  <div
		className='flex h-8 w-[95%]'
	  >
		
		<input
		  value={filterValue[0] || ''}
		  type='number'
		  onChange={e => {
			const val = e.target.value
			setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]])
		  }}
		  placeholder={Idioma.filtros.min[language]}
		  className='w-1/2 mr-2 p-2 bg-white rounded-button 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
		/><input
		  value={filterValue[1] || ''}
		  type='number'
		  onChange={e => {
			const val = e.target.value
			setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined])
		  }}
		  className='w-1/2 mr-2 p-2 bg-white rounded-button 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
		  placeholder={Idioma.filtros.max[language]}
		/>
	  </div>
	)
  }
  
  function dateBetweenFilterFn(rows, id, filterValues) {
	const sd = filterValues[0] ? filterValues[0] : undefined
	const ed = filterValues[1] ? filterValues[1] : undefined
  
	if (ed || sd) {
	  return rows.filter(r => {
		let dateArr = r.values[id].split('-')
		const cellDate = new Date(dateArr[0],dateArr[1]-1,dateArr[2])
		
		if (ed && sd) {
		  return cellDate >= sd && cellDate <= ed
		} else if (sd){
		  return cellDate >= sd
		} else if (ed){
		  return cellDate <= ed
		}
	  })
	} else {
	  return rows
	}
  }
  
  function DateRangeColumnFilter({
	column: { filterValue = [], preFilteredRows, setFilter, id }
  }) {
	const {language} = useStateContext()
	const [min, max] = React.useMemo(() => {
	  let min = preFilteredRows.length
		? new Date(preFilteredRows[0].values[id])
		: new Date(0);
	  let max = preFilteredRows.length
		? new Date(preFilteredRows[0].values[id])
		: new Date(0);
  
	  preFilteredRows.forEach((row) => {
		const rowDate = new Date(row.values[id]);
  
		min = rowDate <= min ? rowDate : min;
		max = rowDate >= max ? rowDate : max;
	  });
  
	  return [min, max];
	}, [id, preFilteredRows]);
  
	const [start, end] = filterValue;
  
	return (
	  <div className='w-[100%] text-[10px] 2xl:text-[14px] pr-4'>
		<DatePicker
		  onChange={(e) => {
			const val = e;
			setFilter(e);
		  }}
		  startDate={start}
		  endDate={end}
		  selectsRange
		  fixedHeight
		  locale={language}
		  className={'h-button-4 focus:outline-none cursor-default border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 rounded-button text-p9-2xl text-center'} 
		  showYearDropdown
		  dropdownMode="select"
		  isClearable
		/>
	  </div>
	);
  }
  
  function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  
  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val
  
  // Define a custom filter filter function!
  function filterGreaterThan(rows, id, filterValue) {
	return rows.filter(row => {
	  const rowValue = row.values[id]
	  return rowValue >= filterValue
	})
  }
  
  
  // This is an autoRemove method on the filter function that
  // when given the new filter value and returns true, the filter
  // will be automatically removed. Normally this is just an undefined
  // check, but here, we want to remove the filter if it's not a number
  filterGreaterThan.autoRemove = val => typeof val !== 'number'
  

const monthMap = {
	"Ene":"enero",
	"Feb":"febrero",
	"Mar":"marzo",
	"Abr":"abril",
	"May":"mayo",
	"Jun":"junio",
	"Jul":"julio",
	"Ago":"agosto",
	"Sep":"septiembre",
	"Oct":"octubre",
	"Nov":"noviembre",
	"Dic":"diciembre",
}

const monthNumMap = {
	"Ene":0,
	"Jan":0,
	"Feb":1,
	"Mar":2,
	"Abr":3,
	"Apr":3,
	"May":4,
	"Jun":5,
	"Jul":6,
	"Ago":7,
	"Aug":7,
	"Sep":8,
	"Oct":9,
	"Nov":10,
	"Dic":11,
	"Dec":11
}

const momentSubstractDate = {
	'Sun': 6,
	'Mon': 0,
	'Tue': 1,
	'Wed': 2,
	'Thu': 3,
	'Fri': 4,
	'Sat': 5,
}


const ReportPlanning = () => {
    const {idClient,idRS, clientRSOptions, accessToken,language,setToken,setContextBank,setContextAccount,setContextFile,setContextShowState,setContextDateRange,setContextSubcategoryId,contextSubcategoryId,userAccess,setContextRSID, mainCurrency, numberFormat, sessionID} = useStateContext()
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    const [data,setData] = useState([])
	const [typeAccount, setTypeAccount] = useState({name: Idioma.filtros.tipo[language], id: ''});
	const [newTypeAccount, setNewTypeAccount] = useState([]);
    const [bank,setBank] = useState({name:Idioma.transacciones.configuracion.banco[language], id: ''});
    const [BankOptions,setBankOptions] = useState([]);
    const [account,setAccount] = useState({name:Idioma.transacciones.configuracion.cuentaBanco[language], id: ''});
    const [accounts,setAccounts] = useState([{name:Idioma.transacciones.configuracion.cuentaBanco[language], id: ''}]);
    const [info,setInfo] = useState([])
	const [catalogo, setCatalogo] = useState({name: 'Catalogo L2', id: 2});
	const [catalogos, setCatalogos] = useState([{name: 'Catalogo L0', id: 0}, {name: 'Catalogo L1', id: 1}, {name: 'Catalogo L2', id:2}]);
	const [proyectionStartIndex, setProyectionStartIndex] = useState();
	const [fileURL,setFileURL] = useState(null);
	const [fileLoading,setFileLoading] = useState(false);
	const [optionTime, setOptionTime] = useState(1);
	const [selectedForex, setSelectedForex] = useState({name: Idioma.transacciones.configuracion.moneda[language], id: ''});
	const [forexOptions, setForexOptions] = useState([]);
	const [jsonReport,setJsonReport] = useState()
	const [highlightRow,setHighlightRow] = useState(-1);
	const [ errorSign, setErrorSign ] = useState(false);
	const [ yesAccount, setYesAccount ] = useState(false);
	const [currentFx, setCurrentFx] = useState(0);
	const [cashFlowLegend,setCashFlowLegend] = useState(`${Idioma.filtros.todasCuentas[language]} - MXN`)
	const [categoriesL1,setCategoriesL1] = useState([])
	const [categoriesL0,setCategoriesL0] = useState([])
	const [conciliationsCat, setConciliationCat] = useState([])
	const [noBank, SetNoBank] = useState(true)
	const [errorMessage, setErrorMessage] = useState()
	const [openLineageModal,setOpenLineageModal] = useState(false)
	const [lineageData,setLineageData] = useState([])
	const [accountsDict,setAccountsDict] = useState({})
	const [banksDict,setBanksDict] = useState({})
	const [categories,setCategories] = useState([])
	const [categoriesDict,setCategoriesDict] = useState({})
	const [excelLineageData,setExcelLineageData] = useState({})
	const [redirectFiltersData,setRedirectFiltersData] = useState()
	const [openCredit,setOpenCredit] = useState(false)
	const [filters, setFilters] = useState()
	const [dataModal, setDataModal] = useState([]);
	const [idRString, setIdRString] = useState('');
	const [RSID, setRSID] = useState({name:Idioma.filtros.empresa[language]})
	const [idRsOptions, setIdRsOptions] = useState([])
	const [showTransactionsUpdated, setShowTransactionsUpdated] = useState(false)
	const [needUpdate, setNeedUpdate] = useState(false)
	const [showNeedUpdate, setShowNeedUpdate] = useState(false)
    const [updatedTransactions,setUpdatedTransactions] = useState(0)
	const [openAddTransaction,setOpenAddTransaction] = useState(false)
	const [dateAddT,setDateAddT] = useState(new Date());
    const [conceptAddT,setConceptAddT] = useState('')
    const [inflowAddT,setInflowAddT] = useState()
    const [outflowAddT,setOutflowAddT] = useState()
    const [categoryAddT,setCategoryAddT] = useState()
    const [currencyAddT,setCurrencyAddT] = useState({name:mainCurrency,id:mainCurrency})
    const [paidWithAddT,setPaidWithAddT] = useState({name:Idioma.transacciones.debito[language],id:'D'})
    const [scenarioAddT,setScenarioAddT] = useState({name:Idioma.planeacion.seleccionaEscenario[language]})
    const [exchangeAddT,setExchangeAddT] = useState()
    const [clientSupplierAddT,setClientSupplierAddT] = useState({name:Idioma.planeacion.seleccionaClienteProveedor[language]})
	const [recurrenceOptions,setRecurrenceOptions] = useState([])
	const [addTransactionLoading, setAddTransactionLoading] = useState(false)

	const [clientOptions,setClientOptions] = useState([])
    const [supplierOptions,setSupplierOptions] = useState([])
    const [clientSupplierOptions,setClientSupplierOptions] = useState([])
    const [clientSupplierDict,setClientSupplierDict] = useState({})

	const [recurrence,setRecurrence] = useState({name:Idioma.planeacion.seleccionaRecurrencia[language]})
    const [recurrenceEndDate,setRecurrenceEndDate] = useState(new Date())
    const recurrenceEndDateLimit = new Date()
    const [recurrenceEndDateMinLimit,setRecurrenceEndDateMinLimit] = useState()

    const [recurrenceRepeats,setRecurrenceRepeats] = useState()
    const [recurrenceRepeatsLimit,setRecurrenceRepeatsLimit] = useState()

	const [loadingReport,setLoadingReport] = useState(false)

	const creditDebitOptions = [
		{
			name:Idioma.transacciones.credito[language],
			id:'C'
		},
		{
			name:Idioma.transacciones.debito[language],
			id:'D'
		},
	]

	useEffect(() => {
		if(filters!==undefined && filters!==null){
			if(JSON.parse(filters).length>5){
				setDateFilter([new Date(Date.parse(JSON.parse(filters)[5][0])), new Date(Date.parse(JSON.parse(filters)[5][1]))])
			}
			setPreFilter(JSON.parse(filters)[0])
			setPlanningDate(new Date(JSON.parse(filters)[1]))
			setGroupBy(JSON.parse(filters)[2])
			setScenario(JSON.parse(filters)[3])
			if(JSON.parse(filters)[4].length!==0){
				setRSID(JSON.parse(filters)[4])
			}
			sessionStorage.setItem('planningFilters', filters)
		}
	}, [filters])

	useEffect(()=>{
		if(jsonReport!==undefined){
			let aux = jsonReport.categoryL0
			let aux2 = {}
			let auxCategoriesL0 = {}
			let count=0
			for(let i=0;i<aux.length;i++){
				auxCategoriesL0[jsonReport.categoryL0[i]]=0
				for(let j=0;j<jsonReport.categoryL1[aux[i]]?.length;j++){
					aux2[jsonReport.categoryL1[aux[i]][j]]=0
					count++
				}
			}
			setCategoriesL0(auxCategoriesL0)
			setCategoriesL1(aux2)
			setConciliationCat(jsonReport.categoryL0.slice(3).filter((element) => jsonReport.categoryL0Valid.includes(element)))
			setProyectionStartIndex(jsonReport.date_to_use.map(date => date).indexOf(jsonReport.proyection_cut_date[1]))
			
			{/*setCashFlowLegend(`${bank.name === Idioma.transacciones.configuracion.banco[language] || bank.name === Idioma.filtros.todos[language] ? Idioma.filtros.todasCuentas[language] : bank.name} ${account.name === Idioma.transacciones.configuracion.cuentaBanco[language] || account.name === Idioma.filtros.todos[language] ? '' : account.name} - ${jsonReport.report_fx[0]} ${typeAccount.name === Idioma.filtros.todos[language] ? '': `- ${typeAccount.name}`}`)
		sessionStorage.setItem('cashFlowLegend',cashFlowLegend)*/}
			
			sessionStorage.setItem('jsonPlanningReport'+idRS,JSON.stringify(jsonReport))
		}

	},[jsonReport])

	const handleExpandCategoryL0 = (datum) => {
		let aux = {...categoriesL0}
		aux[datum] = categoriesL0[datum]===0 ? 1 : 0
		setCategoriesL0(aux)
	}

	const handleExpandCategoryL1 = (datum) => {
		let aux = {...categoriesL1}
		aux[datum] = categoriesL1[datum]===0 ? 1 : 0
		setCategoriesL1(aux)
	}

    //eliminar input de clientID, debe estar automatizado y tomar esa informacion de la base de datos
    const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 

	const [preFilterOptions,setPreFilterOptions] = useState([{name: Idioma.filtros.LTM[language], id: 0}, {name: Idioma.filtros.esteAnio[language], id: 1}, {name: Idioma.filtros.anioAnterior[language], id: 2}, {name: Idioma.filtros.ultimosTresMeses[language], id: 9}, {name: Idioma.filtros.esteMes[language], id: 5}, {name: Idioma.filtros.mesAnterior[language], id: 6}, {name: Idioma.filtros.estaSemana[language], id: 7}, {name: Idioma.filtros.semanaAnterior[language], id: 8}])
	const [preFilter,setPreFilter] = useState({name: Idioma.filtros.personalizado[language], id: 10})
	const [groupByOptions, setGroupByOptions] = useState([{name: Idioma.filtros.anio[language], id: 'Y',  value: 364}, {name: Idioma.filtros.trimestre[language], id: 'Q', value: 91}, {name: Idioma.filtros.mes[language], id: 'M', value: 28}, {name: Idioma.filtros.semana[language], id: 'W', value: 7}, {name: Idioma.filtros.dia[language], id: 'D', value: 1}])
	const [groupBy, setGroupBy] = useState({name: Idioma.filtros.mes[language], id: 'M', value: 28})
	const [scenarioOptions, setScenarioOptions] = useState([])
	const [scenario, setScenario] = useState({name: Idioma.planeacion.escenario[language]})

	const groupByDynamic = () => {
		if (getDateRange()[0]<7 && getDateRange()[0]!==null){
			setGroupBy({name: Idioma.filtros.dia[language], id: 'D', value: 1})
			setGroupByOptions([{name: Idioma.filtros.dia[language], id: 'D', value: 1}])
		} else if (getDateRange()[0]<28){
			if(groupBy.value>7){
				setGroupBy({name: Idioma.filtros.semana[language], id: 'W', value: 7})
			}
			setGroupByOptions([{name: Idioma.filtros.semana[language], id: 'W', value: 7}, {name: Idioma.filtros.dia[language], id: 'D', value: 1}])
		} else if (getDateRange()[0]<91){
			if(groupBy.value>28){
				setGroupBy({name: Idioma.filtros.mes[language], id: 'M', value: 28})
			}
			setGroupByOptions([{name: Idioma.filtros.mes[language], id: 'M', value: 28}, {name: Idioma.filtros.semana[language], id: 'W', value: 7}, {name: Idioma.filtros.dia[language], id: 'D', value: 1}])
		} else if (getDateRange()[0]<364){
			if(groupBy.value>91){
				setGroupBy({name: Idioma.filtros.mes[language], id: 'M', value: 28})
			}
			setGroupByOptions([{name: Idioma.filtros.trimestre[language], id: 'Q', value: 91}, {name: Idioma.filtros.mes[language], id: 'M', value: 28}, {name: Idioma.filtros.semana[language], id: 'W', value: 7}])
		} else {
			if(groupBy.value<28){
				setGroupBy({name: Idioma.filtros.mes[language], id: 'M', value: 28})
			  }
			setGroupByOptions([{name: Idioma.filtros.anio[language], id: 'Y',  value: 364}, {name: Idioma.filtros.trimestre[language], id: 'Q', value: 91}, {name: Idioma.filtros.mes[language], id: 'M', value: 28}])
		}
	}

	const dateToString = (day, month, year) => {
		if(day<10){
		day = '0'+day.toString()
		}else{
			day = day.toString()
		}
		if(month<10){
			month = '0'+month.toString()
		}else{
			month = month.toString()
		}
		let fecha = (year+month+day);
		return fecha
	}

	const getDateRange = () => {
		if (startDate==null || endDate==null){
		return [null, [null, null]]
		} else {
		let diff = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
		let year = (startDate.getFullYear()).toString()
		let month = startDate.getMonth()+1
		let day = startDate.getDate()
		let year2 = (endDate.getFullYear()).toString()
		let month2 = endDate.getMonth()+1
		let day2 = endDate.getDate()
		return [diff, [dateToString(day, month, year),dateToString(day2, month2, year2)]]
		}
	}

	const getLTM = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth() - 12)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth()  + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth()+1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]])//Fecha final LTM
	}

	const getLY = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(0)
		newStartDate.setDate(1)
		newStartDate.setFullYear(newStartDate.getFullYear()-1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setMonth(0)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Year
	}

	const getTY = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(0)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setFullYear(newEndDate.getFullYear()+1)
		newEndDate.setMonth(0)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Year
	}

	const getL3M = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth()-3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Quarter
	}

	/*const getLQTR = () => {
		let newStartDate = new Date()
		newStartDate.setMonth((Math.floor(newStartDate.getMonth()/3)-1)*3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setMonth(Math.floor(newEndDate.getMonth()/3)*3)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Quarter
	}

	const getTQTR = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(Math.floor(newStartDate.getMonth()/3)*3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(newEndDate.getDate()-1)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Quarter
	}*/

	const getLM = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth()-1)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Month
	}

	const getTM = () => {
		let newStartDate = new Date()
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Month
	}

	const getLW = () => {
		let newStartDate = new Date();
		//newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
		newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7 - 7) //Lunes pasado
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate =  new Date()
		//newEndDate.setTime(newEndDate.getTime() - 1 * 24 * 60 * 60 * 1000) //7 dias
		newEndDate.setDate(newEndDate.getDate() - (newEndDate.getDay() + 6) % 7 - 1) //Lunes pasado
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final Last Week
	}

	const getTW = () => {
		let newStartDate = new Date();
		//newStartDate.setTime(newStartDate.getTime() - 8 * 24 * 60 * 60 * 1000) //7 dias
		newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() + 6) % 7) //Lunes pasado
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate =  new Date()
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]]) //Fecha final This Week
	}

	const getProjection = (endDate) => {
		let newDate = new Date(endDate);
		newDate.setMonth(newDate.getMonth()-3)
		newDate.setDate(1)
		if(newDate<startDate){
			newDate=startDate
		}
		let year = (newDate.getFullYear()).toString()
		let month = newDate.getMonth() + 1
		let day = newDate.getDate()
		return([newDate,dateToString(day, month, year)])
	}

	const getSixMonths = () => {
		let newStartDate = new Date()
		newStartDate.setMonth(newStartDate.getMonth()-3)
		newStartDate.setDate(1)
		let year = (newStartDate.getFullYear()).toString()
		let month = newStartDate.getMonth() + 1
		let day = newStartDate.getDate()
		let newEndDate = new Date()
		newEndDate.setMonth(newEndDate.getMonth()+4)
		newEndDate.setDate(0)
		let year2 = newEndDate.getFullYear().toString()
		let month2 = newEndDate.getMonth() + 1
		let day2 = newEndDate.getDate()
		return([[newStartDate, newEndDate],[dateToString(day, month, year),dateToString(day2, month2, year2)]])
	}

	const [ltmFilter,setLtmFilter] = useState(getLTM());
	const [l3mFilter, setL3mFilter] = useState(getL3M())
	const [lyFilter,setLyFilter] = useState(getLY());
	//const [lqtrFilter,setLqtrFilter] = useState(getLQTR());
	const [lmFilter,setLmFilter] = useState(getLM());
	const [lwFilter,setLwFilter] = useState(getLW());
	const [tyFilter,setTyFilter] = useState(getTY());
	//const [tqtrFilter,setTqtrFilter] = useState(getTQTR());
	const [tmFilter,setTmFilter] = useState(getTM());
	const [twFilter,setTwFilter] = useState(getTW());
	const [dateFilter,setDateFilter] = useState(getSixMonths()[0]);
	const [startDate, endDate] = dateFilter;
	const [planningDate, setPlanningDate] = useState(getProjection(endDate)[0])
	
	const handleDateChange = (event) => {
		setDateFilter(event)
	}

	useEffect(() => {
		if(preFilter.id!=10){
			if (preFilter.id==0) {
				setDateFilter(ltmFilter[0])
			} else if (preFilter.id==1) {
				setDateFilter(tyFilter[0])
			} else if (preFilter.id==2) {
				setDateFilter(lyFilter[0])
			} else if (preFilter.id==5) {
				setDateFilter(tmFilter[0])
			} else if (preFilter.id==6) {
				setDateFilter(lmFilter[0])
			} else if (preFilter.id==7) {
				setDateFilter(twFilter[0])
			} else if (preFilter.id==8) {
				setDateFilter(lwFilter[0])
			} else if (preFilter.id==9) {
				setDateFilter(l3mFilter[0])
			}
		}
	},[preFilter])

	useEffect(() => {
		groupByDynamic()

		if(dateFilter!=ltmFilter[0] && dateFilter!=lyFilter[0] /*&& dateFilter!=lqtrFilter[0]*/ && dateFilter!=lmFilter[0] && dateFilter!=lwFilter[0] && dateFilter!=tyFilter[0]/* && dateFilter!=tqtrFilter[0]*/ && dateFilter!=tmFilter[0] && dateFilter!=twFilter[0] && dateFilter!=l3mFilter[0]){
			setPreFilter({name: Idioma.filtros.personalizado[language], id: 10})
		}
		if(endDate<planningDate || startDate>planningDate){
			setPlanningDate(getProjection(endDate)[0])
		}
	},[dateFilter])

	const [requestParamas, setRequestParams] = useState([getDateRange()[1][0], getDateRange()[1][1], dateToString(planningDate.getDate(), planningDate.getMonth()+1, planningDate.getFullYear()), groupBy.id]);

	useEffect(() => {
		setRequestParams([getDateRange()[1][0], getDateRange()[1][1],dateToString(planningDate.getDate(), planningDate.getMonth()+1, planningDate.getFullYear()),groupBy.id])
	},[dateFilter, planningDate ,groupBy, scenario])

	const updateTransactionConcept = async (index,concept) => {
        let headers = new Headers()
  
          const bearer = `Bearer ${accessToken}`;
          headers.append("Authorization", bearer);
          headers.append("Content-Type",'application/json')

		  let body ={
			"clientID": idClient,
			"transactions": [data[index].id],
			"concept": concept,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}

		if(idRS){
			body.idRS=idRS
		}
      
    
          if(index>=0){
              fetch(`${urlBase}/planning/updateTransaction`,{
                  method: 'POST',
                  headers: headers,
                  body: JSON.stringify(body)
              })
              .then((response) => response.json())
              .then((aux) => {
				setShowTransactionsUpdated(aux.updates.length>=1)
				setUpdatedTransactions(aux.updates.length)
				setNeedUpdate(true)
				if(aux.unaffected.length >= 1){
					setErrorMessage(Idioma.transacciones.transNoActualizadas[language])
				}
              })
              .catch(error=>{
                alert('Ha ocurrido un error')
              })
          }
      }
      const updateTransactionNotes = async (index,notes) => {
        let headers = new Headers()
  
          const bearer = `Bearer ${accessToken}`;
          headers.append("Authorization", bearer);
          headers.append("Content-Type",'application/json')

		  let body = {
			"clientID": idClient,
			"transactions": [data[index].id],
			"notes":notes,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}

		if(idRS){
			body.idRS=idRS
		}
   
    
          if(index>=0){
              fetch(`${urlBase}/planning/updateTransaction`,{
                  method: 'POST',
                  headers: headers,
                  body: JSON.stringify(body)
              })
              .then((response) => response.json())
              .then((aux) => {
				setShowTransactionsUpdated(aux.updates.length>=1)
				setUpdatedTransactions(aux.updates.length)
				setNeedUpdate(true)
				if(aux.unaffected.length >= 1){
					setErrorMessage(Idioma.transacciones.transNoActualizadas[language])
				}
              })
              .catch(error=>{
                alert('Ha ocurrido un error')
              })
          }
      }

	  //Funcion para actualizar el monto de una transaccion
      const updateTransactionAmount = async (index,inflow,outflow) => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

		let body = {
			"clientID": idClient,
			"transactionID": data[index].id,
			"inflow": inflow,
			"outflow": outflow,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}

		if(idRS){
			body.idRS=idRS
		}
  
    
        if(index>=0){
          fetch(`${urlBase}/planning/updateSingleTransaction`,{
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body)
          })
          .then((response) => response.json())
          .then((aux) => {
  
          setShowTransactionsUpdated(aux.updates.length>=1)
          setUpdatedTransactions(aux.updates.length)
		  setNeedUpdate(true)
          if(aux.unaffected.length >= 1){
			setErrorMessage(Idioma.transacciones.transNoActualizadas[language])
		}
          
          })
          .catch(error=>{
            alert('Ha ocurrido un error')
          })
        }
      }

    //Funcion para actualizar la fecha de una transaccion
    const updateTransactionDate = async (index,date) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

		let body = {
			"clientID": idClient,
			"transactionID": data[index].id,
			"date":date,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}

		if(idRS){
			body.idRS=idRS
		}

        if(index>=0){
            fetch(`${urlBase}/planning/updateSingleTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

				setShowTransactionsUpdated(aux.updates.length>=1)
				setUpdatedTransactions(aux.updates.length)
				setNeedUpdate(true)
				if(aux.unaffected.length >= 1){
					setErrorMessage(Idioma.transacciones.transNoActualizadas[language])
				}
            
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }

    //Funcion para actualizar la fecha de una transaccion
    const updateTransactionCurrency = async (index,currency) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

		let body = {
			"clientID": idClient,
			"transactionID": data[index].id,
			"currency":currency,
			'mainCurrency':mainCurrency,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}

		if(idRS){
			body.idRS=idRS
		}


        if(index>=0){
            fetch(`${urlBase}/planning/updateSingleTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

				setShowTransactionsUpdated(aux.updates.length>=1)
				setUpdatedTransactions(aux.updates.length)
				setNeedUpdate(true)
				if(aux.unaffected.length >= 1){
					setErrorMessage(Idioma.transacciones.transNoActualizadas[language])
				}
            
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }
  

    //Funcion para actualizar la fecha de una transaccion
    const updateTransactionPaidWith = async (index,paidWith) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

		let body = {
			"clientID": idClient,
			"transactionID": data[index].id,
			"paidWith":paidWith,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}

		if(idRS){
			body.idRS=idRS
		}


        if(index>=0){
            fetch(`${urlBase}/planning/updateSingleTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
			
				setShowTransactionsUpdated(aux.updates.length>=1)
				setUpdatedTransactions(aux.updates.length)
				setErrorSign(aux.unaffected.length >= 1)
				setErrorMessage(Idioma.transacciones.transNoActualizadas[language])
				setNeedUpdate(true)
            
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }

	const getScenarios = () => {
		let headers = new Headers()
		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')
		let body = {
			"clientID": idClient,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		  }
		if(idRS){
			body.idRS = idRS
		}else if (RSID.id){
			body.rsID = RSID.id
		}
		fetch(`${urlBase}/planning/getScenarios`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = []
            for(let i=0;i<aux.length;i++){
              newData[i]={
                  name:aux[i].name,
                  id:aux[i].idScenario
              }
            }
            setScenarioOptions(newData)
			if(newData.length===0){
				setScenario({name: Idioma.planeacion.escenario[language]})
				setErrorMessage(Idioma.planeacion.scenarioError[language])
			}
        })
	}

	const genReport = () => {
		setLoadingReport(true)
		setNeedUpdate(false)
		setErrorSign(false)
		setJsonReport()
		let body = {
			"env": msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? '1' : '3',
			"clientId": idClient,
			"startDate":  requestParamas[0],
			"endDate":  requestParamas[1],
			"groupBy":  requestParamas[3],
			"fx":  mainCurrency,
			"lan": language,
			"proyectionCutDate": requestParamas[2],
			"scenarioId": scenario.id,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
			}
		if(idRS){
			body.idRS = idRS
			body.rsID = [idRS]
		}else if (RSID.id){
			body.idRS = ''
			body.rsID = [RSID.id]
		}else{
			body.idRS = ''
			body.rsID = []
		}
		let headers = new Headers()
		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')
		//setCurrentFx(selectedForex.id);
		fetch(`${msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? 'https://cico-functions-dev.azurewebsites.net/api/FlowTableGenerator' : 'https://cico-functions.azurewebsites.net/api/FlowTableGenerator'}`,{
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body)
		})
		/*.then(function(response) {
			let res = response.text();
			setTimeout(() => {
				setOpenPreferencesModal(false);
			}, 1000);
			return res;
		})
		.then(function(html){
			html=html.replace(/""/gi,'"'); //metodo replace temporal hasta que arreglen la funcion de backend
			html=html.replace(/''/gi,"'");
			setData(html);
		})*/
		.then(res => res.json())
		.then(res =>  {
			setLoadingReport(false)
			if('error' in res === false){
				if(preFilter.id===10){
					setFilters(JSON.stringify([preFilter, planningDate, groupBy, scenario, RSID, [startDate, endDate]]))
				}else{
					setFilters(JSON.stringify([preFilter, planningDate, groupBy, scenario, RSID]))
				}
				setFileURL(null)
				setCurrentFx(res.report_fx[1]);
				setJsonReport(res);
				setTimeout(() => {
				}, 500);
				setErrorSign(false)
			}else{
				setErrorMessage(res['error'])
			}
			return res
		} )
		.catch(function(err) {
			console.log('Failed to fetch page: ',err);
		});
	}
	const genFile = () => {
		setFileLoading(true)
		let body = {
			"env": msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? '1' : '3',
			"clientId": idClient,
			"startDate":  requestParamas[0],
			"endDate":  requestParamas[1],
			"groupBy":  requestParamas[3],
			"fx":  mainCurrency,
			"lan": language,
			"proyectionCutDate": requestParamas[2],
			"scenarioId": scenario.id,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
			}

			if(idRS){
				body.idRS = idRS
				body.rsID = []
			}else if (RSID.id){
				body.idRS = ''
				body.rsID = [RSID.id]
			}else{
				body.idRS = ''
				body.rsID = []
			}
		let headers = new Headers()
		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')
		fetch(`${msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? 'https://cico-functions-dev.azurewebsites.net/api/FlowTableExport' : 'https://cico-functions.azurewebsites.net/api/FlowTableExport'}`,{
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body)
		})
		.then((response)=>response.json())
		.then((response)=>{
			if('error' in response===false){
				setFileURL(response.url)
				setFileLoading(false)
				setErrorSign(false)
			}else{
				setErrorMessage(response['error'])
			}
		})
		.catch((error)=>{
			setFileLoading(false)
		})
	}

	const savedReport = () => {
		let filtersAux =  sessionStorage.getItem('planningFilters')
		setFilters(filtersAux)
		let cashFlowLegendAux = sessionStorage.getItem('cashFlowLegend')
		setCashFlowLegend(cashFlowLegendAux)
		let reportAux = sessionStorage.getItem('jsonPlanningReport'+idRS)
		reportAux = JSON.parse(reportAux)
		setJsonReport(reportAux)
	}

	useEffect(()=>{
		
        if(!isAuthenticated){
            navigate('/')
            return
        }
		getToken().then((token)=>{setToken(token)})

		let headers = new Headers()

		const bearer = `Bearer ${accessToken}`;
		headers.append("Authorization", bearer);
		headers.append("Content-Type",'application/json')
		let body = {
			"clientID": idClient,
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
		}
		if(idRS){
			body.idRS=idRS
		}
		fetch(`${urlBase}/getRS`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
			let newRSOptions = []
			newRSOptions[0] = Idioma.filtros.empresa[language]
			aux.map((rs) => {
				newRSOptions[rs.idRS]=rs.rsName
			})
			setIdRsOptions(newRSOptions)
			if(!idRS && newRSOptions.length>0 && !RSID.id){
				getScenarios()
			}
        })
		fetch(`${urlBase}/getAccounts`,{
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body)
		})
		.then((response) => response.json())
		.then((aux) => {
			let newData=[]
			let aux4 = {}
			let aux5 = {}
			for (const property in aux) {
				newData[property] = aux[property]
				aux5[aux[property].idBank] = {'name':property}
				for(let i=0;i<aux[property].accounts.length;i++){
				  aux4[aux[property].accounts[i].accountID]=aux[property].accounts[i].accountNumber
				}
			}
			let aux2 = Object.getOwnPropertyNames(aux);
			let aux3 = []
			aux3[0] = {'name':Idioma.filtros.todos[language], 'id':''}
			for(let i=1;i<aux2.length+1;i++){
				aux3[i] = {'name':aux2[i-1] , 'id':aux[aux2[i-1]].idBank}
			}
			setAccountsDict(aux4)
			setBanksDict(aux5)
			setBankOptions(aux3)
			setInfo(newData)
		});
		fetch(`${urlBase}/getCategories`,{
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
			  "clientID": idClient,
			  "lowest": 1,
			  showHidden: true,
			  showCreditFinancing:true,
			  forReport:true,
			  logsInfo: {
				origin:'Report planning',
				sessionID:sessionID 
			  },
		  })
		  })
			.then((response) => response.json())
			.then((aux1) => {
			  let aux = aux1.categories
			  let notUse = aux1.notUse
			  let catDict = {}
			  for(let i = 0;i<aux.length;i++){
				catDict[aux[i].category] = {
				  "id": aux[i].idCategory,
				  "isActive" : aux[i].isActive === true,
				  "notUse" : notUse[aux[i].idCategory] === true
				}
				for(let j = 0;j<aux[i].subcategories.length;j++){
				  catDict[`${aux[i].category}${aux[i].subcategories[j].category}`] = {
					"id": aux[i].subcategories[j].idCategory,
					"isActive" : aux[i].subcategories[j].isActive === true,
					"idFather" : aux[i].subcategories[j].idCategoryFather,
					"notUse" : notUse[aux[i].subcategories[j].idCategory] === true
				  }
				}
			  }
			  
			  setCategoriesDict(catDict)
		  });
		  fetch(`${urlBase}/getClientSupplier`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              "clientID": idClient,
			  logsInfo: {
				origin:'Report planning',
				sessionID:sessionID 
			  },
          })
          })
            .then((response) => response.json())
            .then((aux) => {
              setClientOptions(aux.clients)
              setSupplierOptions(aux.suppliers)
              let aux1 = []
              let auxDict = {}
              for(let i=0;i<aux.clients.length;i++){
                aux1[aux1.length] = {
                    'name':aux.clients[i].clientSupplierName,
                    'id':aux.clients[i].idClientSupplier,
                }
                auxDict[aux.clients[i].idClientSupplier] = {
                  'name':aux.clients[i].clientSupplierName,
                  "isActive" : aux.clients[i].isActive === true,
                }
              }
              for(let i=0;i<aux.suppliers.length;i++){
                aux1[aux1.length] = {
                    'name':aux.suppliers[i].clientSupplierName,
                    'id':aux.suppliers[i].idClientSupplier,
                }
                auxDict[aux.suppliers[i].idClientSupplier] = {
                  'name':aux.suppliers[i].clientSupplierName,
                  "isActive" : aux.suppliers[i].isActive === true,
                }
              }
              setClientSupplierOptions(aux1)
              setClientSupplierDict(auxDict)
              
          });
		window.clarity("set", "report", "reportEnter")
    },[]);

	useEffect(() => {
		/*if(((scenarioOptions.length>0 && !scenario.id) || (!scenarioOptions.find(item => item.id === scenario.id) && scenarioOptions.length>0))){
			setScenario(scenarioOptions[0])
		}else */if(sessionStorage.getItem('jsonPlanningReport'+idRS)){
			savedReport()
		}/*else{
			if(scenarioOptions.length>0 && scenario.id){
				genReport()
			} 
		}*/
	},[scenarioOptions, scenario])

	useEffect(() => {
		if (typeof errorMessage!=="undefined"){
			setErrorSign(true)
		}
	},[errorMessage])

	useEffect(() => {
		if (errorSign===false){
			setErrorMessage(undefined)
			if(sessionStorage.getItem('jsonPlanningReport'+idRS)){
				savedReport()
			}else{
				setFilters(null)
			}
		}
	},[errorSign])

	const lineageDateToString = (day, month, year) => {
		if(day<10){
			day = '0'+day.toString()
		}else{
			day = day.toString()
		}
		if(month<10){
			month = '0'+month.toString()
		}else{
			month = month.toString()
		}
		return month+'-'+day+'-'+year
	}

	const setLineage = (data,datum,info,e,indexDate,realData,planningData) => {
		setLineageData({
			flow:data,
			category:datum,
			subcategory:info,
			event: e,
			indexDate: indexDate,
			realData: realData,
			planningData: planningData
		})
		if(userAccess.access2 && (e.ctrlKey || e.detail>1) && Object.keys(categoriesDict).length>0){
			let showState='classified'
			let headers = new Headers()
	
			const bearer = `Bearer ${accessToken}`;
			headers.append("Authorization", bearer);
			headers.append("Content-Type",'application/json')
			let body = {
				"clientID": idClient,
				"showState":showState,
				"currency": mainCurrency,
				logsInfo: {
				  origin:'Report planning',
				  sessionID:sessionID 
				},
			}

			body.categoryID = categoriesDict[`${datum}${info}`].id
			setCategoryAddT({name: info, id: categoriesDict[`${datum}${info}`].id})
			if(jsonReport.input_list.account_id){
				body.accountID = jsonReport.input_list.account_id
			}else if(jsonReport.input_list.bank_id){
				body.bankID = jsonReport.input_list.bank_id
			}
			body.idScenario=scenario.id
			if(jsonReport.date_other_type==='M'){//calcular fechas
				let fetchStartDate = new Date('20'+jsonReport.date_to_use[indexDate].slice(4),monthNumMap[jsonReport.date_to_use[indexDate].slice(0,3)],1)
				let fetchEndDate = new Date('20'+jsonReport.date_to_use[indexDate].slice(4),monthNumMap[jsonReport.date_to_use[indexDate].slice(0,3)]+1,0)
				let fetchPlanningStartDate = false
				if(indexDate===0){
					fetchStartDate=new Date(jsonReport.input_list.start_date.slice(0,4),jsonReport.input_list.start_date.slice(4,6)-1,jsonReport.input_list.start_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}else if(indexDate===jsonReport.date_to_use.length-1){
					fetchEndDate=new Date(jsonReport.input_list.end_date.slice(0,4),jsonReport.input_list.end_date.slice(4,6)-1,jsonReport.input_list.end_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}
				if(jsonReport.date_to_use[indexDate]===jsonReport.proyection_cut_date[1]){
					fetchPlanningStartDate=new Date(jsonReport.proyection_cut_date[0].slice(0,4),jsonReport.proyection_cut_date[0].slice(4,6)-1,jsonReport.proyection_cut_date[0].slice(6,8))
				}
				
				let fechaInicial = lineageDateToString(fetchStartDate.getDate(),fetchStartDate.getMonth()+1,fetchStartDate.getFullYear().toString())
				let fechaFinal = lineageDateToString(fetchEndDate.getDate(),fetchEndDate.getMonth()+1,fetchEndDate.getFullYear().toString())
				body.startDate = fechaInicial
				body.endDate =  fechaFinal
				if(fetchPlanningStartDate){
					body.startPlanning = lineageDateToString(fetchPlanningStartDate.getDate(),fetchPlanningStartDate.getMonth()+1,fetchPlanningStartDate.getFullYear().toString())
				}
				setRedirectFiltersData([fetchStartDate,fetchEndDate])
				setDateAddT(fetchStartDate)
			}else if(jsonReport.date_other_type==='Q'){
				let quarter = jsonReport.date_to_use[indexDate].slice(1,2)
				let iniQ =(quarter-1)*3
				let endQ =(quarter-1)*3+2
				let fetchStartDate = new Date('20'+jsonReport.date_to_use[indexDate].slice(4),iniQ,1)
				let fetchEndDate = new Date('20'+jsonReport.date_to_use[indexDate].slice(4),endQ+1,0)
				let fetchPlanningStartDate = false
				if(indexDate===0){
					fetchStartDate=new Date(jsonReport.input_list.start_date.slice(0,4),jsonReport.input_list.start_date.slice(4,6)-1,jsonReport.input_list.start_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}else if(indexDate===jsonReport.date_to_use.length-1){
					fetchEndDate=new Date(jsonReport.input_list.end_date.slice(0,4),jsonReport.input_list.end_date.slice(4,6)-1,jsonReport.input_list.end_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}
				if(jsonReport.date_to_use[indexDate]===jsonReport.proyection_cut_date[1]){
					fetchPlanningStartDate=new Date(jsonReport.proyection_cut_date[0].slice(0,4),jsonReport.proyection_cut_date[0].slice(4,6)-1,jsonReport.proyection_cut_date[0].slice(6,8))
				}
				let fechaInicial = lineageDateToString(fetchStartDate.getDate(),fetchStartDate.getMonth()+1,fetchStartDate.getFullYear().toString())
				let fechaFinal = lineageDateToString(fetchEndDate.getDate(),fetchEndDate.getMonth()+1,fetchEndDate.getFullYear().toString())
				body.startDate = fechaInicial
				body.endDate =  fechaFinal
				if(fetchPlanningStartDate){
					body.startPlanning = lineageDateToString(fetchPlanningStartDate.getDate(),fetchPlanningStartDate.getMonth()+1,fetchPlanningStartDate.getFullYear().toString())
				}
				setRedirectFiltersData([fetchStartDate,fetchEndDate])
				setDateAddT(fetchStartDate)
			}else if(jsonReport.date_other_type==='Y'){
				let fetchStartDate = new Date('20'+jsonReport.date_to_use[indexDate],0,1)
				let fetchEndDate = new Date('20'+jsonReport.date_to_use[indexDate],12,0)
				let fetchPlanningStartDate = false
				if(indexDate===0){
					fetchStartDate=new Date(jsonReport.input_list.start_date.slice(0,4),jsonReport.input_list.start_date.slice(4,6)-1,jsonReport.input_list.start_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}else if(indexDate===jsonReport.date_to_use.length-1){
					fetchEndDate=new Date(jsonReport.input_list.end_date.slice(0,4),jsonReport.input_list.end_date.slice(4,6)-1,jsonReport.input_list.end_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}
				if(jsonReport.date_to_use[indexDate]===jsonReport.proyection_cut_date[1]){
					fetchPlanningStartDate=new Date(jsonReport.proyection_cut_date[0].slice(0,4),jsonReport.proyection_cut_date[0].slice(4,6)-1,jsonReport.proyection_cut_date[0].slice(6,8))
				}
				let fechaInicial = lineageDateToString(fetchStartDate.getDate(),fetchStartDate.getMonth()+1,fetchStartDate.getFullYear().toString())
				let fechaFinal = lineageDateToString(fetchEndDate.getDate(),fetchEndDate.getMonth()+1,fetchEndDate.getFullYear().toString())
				body.startDate = fechaInicial
				body.endDate =  fechaFinal
				if(fetchPlanningStartDate){
					body.startPlanning = lineageDateToString(fetchPlanningStartDate.getDate(),fetchPlanningStartDate.getMonth()+1,fetchPlanningStartDate.getFullYear().toString())
				}
				setRedirectFiltersData([fetchStartDate,fetchEndDate])
				setDateAddT(fetchStartDate)
			}else if(jsonReport.date_other_type==='W'){
				let dateSplit = jsonReport.date_to_use[indexDate].split(',')
				let daySplit = dateSplit[0].split(' ')
				/*let momentAux = moment().set('year',`20${jsonReport.date_to_use[indexDate].slice(-2)}`)
				let dateAux = momentAux.isoWeek(dateSplit[0].slice(1)).toString().split(' ')*/
				let auxDateObj = new Date(`20${dateSplit[1].replace(' ','')}`,monthNumMap[daySplit[0]],daySplit[1])
				let first = auxDateObj.getDate()// - momentSubstractDate[dateAux[0]]
				let last = first + 6
				let fetchStartDate = new Date(auxDateObj.setDate(first))
				let fetchEndDate = new Date(auxDateObj.setDate(last))
				let fetchPlanningStartDate = false
				if(first<0){
					fetchEndDate.setMonth(fetchEndDate.getMonth()+1)
				}
				if(indexDate===0){
					fetchStartDate=new Date(jsonReport.input_list.start_date.slice(0,4),jsonReport.input_list.start_date.slice(4,6)-1,jsonReport.input_list.start_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}else if(indexDate===jsonReport.date_to_use.length-1){
					fetchEndDate=new Date(jsonReport.input_list.end_date.slice(0,4),jsonReport.input_list.end_date.slice(4,6)-1,jsonReport.input_list.end_date.slice(6,8)) 											//cambiar por propiedad de jsonReport
				}
				if(jsonReport.date_to_use[indexDate]===jsonReport.proyection_cut_date[1]){
					fetchPlanningStartDate=new Date(jsonReport.proyection_cut_date[0].slice(0,4),jsonReport.proyection_cut_date[0].slice(4,6)-1,jsonReport.proyection_cut_date[0].slice(6,8))
				}
				let fechaInicial = lineageDateToString(fetchStartDate.getDate(),fetchStartDate.getMonth()+1,fetchStartDate.getFullYear().toString())
				let fechaFinal = lineageDateToString(fetchEndDate.getDate(),fetchEndDate.getMonth()+1,fetchEndDate.getFullYear().toString())
				body.startDate = fechaInicial
				body.endDate =  fechaFinal
				if(fetchPlanningStartDate){
					body.startPlanning = lineageDateToString(fetchPlanningStartDate.getDate(),fetchPlanningStartDate.getMonth()+1,fetchPlanningStartDate.getFullYear().toString())
				}
				setRedirectFiltersData([fetchStartDate,fetchEndDate])
				setDateAddT(fetchStartDate)
			}else if(jsonReport.date_other_type==='D'){
				let aux = jsonReport.date_to_use[indexDate].split(' ')
				let aux2 = aux[1].split(',')
				let fetchDate = new Date('20'+jsonReport.date_to_use[indexDate].slice(-2),monthNumMap[jsonReport.date_to_use[indexDate].slice(0,3)],aux2[0])
				let year = fetchDate.getFullYear().toString()
				let month = fetchDate.getMonth()+1
				let day = fetchDate.getDate()
				if(day<10){
					day = '0'+day.toString()
				}else{
					day = day.toString()
				}
				if(month<10){
					month = '0'+month.toString()
				}else{
					month = month.toString()
				}
				let fechaInicial = month+'-'+day+'-'+year
				body.startDate = fechaInicial
				body.endDate =  fechaInicial
				setRedirectFiltersData([fetchDate,fetchDate])
				setDateAddT(fetchDate)
			}
			if(idRS){
				body.idRS=idRS
			}else if(RSID.id){
				body.rsID = [RSID.id]
			}
			
			let newData = []
			let newExcelData = []



			fetch(`${urlBase}/getLineageTransactions`,{
				method: 'POST',
				headers: headers,
				body: JSON.stringify(body)
			})
			.then((response) => response.json())
			.then((aux) => {
				if(realData && body.endDate!=body.startPlanning){
					for(let i = 0;i<aux.length;i++){
						newData[i] = {
							'type':Idioma.planeacion.real[language],
							'bank':aux[i].bank,
							'date':aux[i].date_d.slice(0,10),
							'id':aux[i].idTransaction,
							'context':aux[i].context,
							'reference':aux[i].references_c,
							'inflow':aux[i].inflow ,
							'outflow':aux[i].outflow,
							'category':datum,
							'subcategory':info,
							'exchange':aux[i].exchange || aux[i].defaultFX ,
							"updated":null,
							'accountType':aux[i].accountType==='D' ? Idioma.transacciones.debito[language]:aux[i].accountType==='C' ? Idioma.transacciones.credito[language]:'NA',
							'signature':aux[i].transactionSignature,
							'notes': aux[i].notes,
							'idAccount':accountsDict[aux[i].idAccount],
							'currency': aux[i].currency,
							'rsName':aux[i].rsName,
							'extraText': aux[i].extraText
						}
						newExcelData[i] = {
							[Idioma.filtros.tipo[language]]:Idioma.planeacion.real[language],
							[Idioma.transacciones.configuracion.empresa[language]]:aux[i].rsName,
							[Idioma.transacciones.configuracion.banco[language]]:aux[i].bank,
							[Idioma.transacciones.anadirTransaccionModal.cuenta[language]]:accountsDict[aux[i].idAccount],
							[Idioma.transacciones.configuracion.fecha[language]]:new Date(aux[i].date_d.slice(0,10)),
							[Idioma.transacciones.configuracion.concepto[language]]:aux[i].context,
							[Idioma.transacciones.configuracion.abono[language]]:aux[i].inflow ,
							[Idioma.transacciones.configuracion.cargo[language]]:aux[i].outflow,
							[Idioma.transacciones.configuracion.categoria[language]]:datum,
							[Idioma.transacciones.configuracion.subcategoria[language]]:info,
							[Idioma.transacciones.configuracion.moneda[language]]: aux[i].currency,
							[Idioma.transacciones.configuracion.fx[language]]:aux[i].exchange || aux[i].defaultFX ,
							[Idioma.transacciones.configuracion.creditoDebito[language]]:aux[i].accountType==='D' ? Idioma.transacciones.debito[language]:aux[i].accountType==='C' ? Idioma.transacciones.credito[language]:'NA',
							[Idioma.transacciones.configuracion.notas[language]]: aux[i].notes,
							[Idioma.transacciones.configuracion.informacionAdicional[language]]:''
						}
						newExcelData[i][Idioma.transacciones.configuracion.fecha[language]] = new Date(newExcelData[i][Idioma.transacciones.configuracion.fecha[language]].getTime() + newExcelData[i][Idioma.transacciones.configuracion.fecha[language]].getTimezoneOffset() * 60000 + 36000)
						
						let jsonAux = JSON.parse(aux[i].extraText || "{}")
						let jsonKeys = Object.keys(jsonAux)
						for(let j=0;j<jsonKeys.length;j++){
							newExcelData[i][Idioma.transacciones.configuracion.informacionAdicional[language]] = `${newExcelData[i][Idioma.transacciones.configuracion.informacionAdicional[language]]}${jsonKeys[j]}:${jsonAux[jsonKeys[j]]}${jsonKeys.length!==j+1 ? '\n':''}`
						}
					}
				}
				return [newData, newExcelData]
			}).then((newDataAux) =>
				{fetch(`${urlBase}/planning/getLineageTransactions`,{
					method: 'POST',
					headers: headers,
					body: JSON.stringify(body)
				})
				.then((response) => response.json())
				.then((aux) => {
					if(planningData){
						let lengthAux = newDataAux[0].length
						for(let i = 0;i<aux.length;i++){
							newDataAux[0][lengthAux+i] = {
								'type':Idioma.planeacion.planeacion[language],
								'scenario':aux[i].idScenario,
								'date':aux[i].date_d.slice(0,10),
								'id':aux[i].idTransaction,
								'context':aux[i].context,
								'reference':aux[i].references_c,
								'inflow':aux[i].inflow ,
								'outflow':aux[i].outflow,
								'category':datum,
								'subcategory':info,
								'exchange':aux[i].exchange || aux[i].defaultFX ,
								"updated":null,
								'paidWith':aux[i].paidWith==='D' ? Idioma.transacciones.debito[language]:aux[i].paidWith==='C' ? Idioma.transacciones.credito[language]:'NA',
								'signature':aux[i].transactionSignature,
								'notes': aux[i].notes,
								'currency': aux[i].currency,
								'rsName':aux[i].rsName,
								'extraText': aux[i].extraText
							}
							newDataAux[1][lengthAux+i] = {
								[Idioma.filtros.tipo[language]]:Idioma.planeacion.planeacion[language],
								[Idioma.transacciones.configuracion.empresa[language]]:aux[i].rsName,
								[Idioma.transacciones.configuracion.banco[language]]:'N/A',
								[Idioma.transacciones.anadirTransaccionModal.cuenta[language]]:'N/A',
								[Idioma.transacciones.configuracion.fecha[language]]:new Date(aux[i].date_d.slice(0,10)),
								[Idioma.transacciones.configuracion.concepto[language]]:aux[i].context,
								[Idioma.transacciones.configuracion.abono[language]]:aux[i].inflow ,
								[Idioma.transacciones.configuracion.cargo[language]]:aux[i].outflow,
								[Idioma.transacciones.configuracion.categoria[language]]:datum,
								[Idioma.transacciones.configuracion.subcategoria[language]]:info,
								[Idioma.transacciones.configuracion.moneda[language]]: aux[i].currency,
								[Idioma.transacciones.configuracion.fx[language]]:aux[i].exchange || aux[i].defaultFX ,
								[Idioma.transacciones.configuracion.creditoDebito[language]]:aux[i].paidWith==='D' ? Idioma.transacciones.debito[language]:aux[i].paidWith==='C' ? Idioma.transacciones.credito[language]:'NA',
								[Idioma.transacciones.configuracion.notas[language]]: aux[i].notes,
								[Idioma.transacciones.configuracion.informacionAdicional[language]]:''
							}
							newDataAux[1][lengthAux+i][Idioma.transacciones.configuracion.fecha[language]] = new Date(newDataAux[1][lengthAux+i][Idioma.transacciones.configuracion.fecha[language]].getTime() + newDataAux[1][lengthAux+i][Idioma.transacciones.configuracion.fecha[language]].getTimezoneOffset() * 60000 + 36000)

							let jsonAux = JSON.parse(aux[i].extraText || "{}")
							let jsonKeys = Object.keys(jsonAux)
							for(let j=0;j<jsonKeys.length;j++){
								newDataAux[1][lengthAux+i][Idioma.transacciones.configuracion.informacionAdicional[language]] = `${newDataAux[1][lengthAux+i][Idioma.transacciones.configuracion.informacionAdicional[language]]}${jsonKeys[j]}:${jsonAux[jsonKeys[j]]}${jsonKeys.length!==j+1 ? '\n':''}`
							}
						}
					}
					
						setData(newDataAux[0])
						setExcelLineageData(newDataAux[1])
				})}
			)
			setOpenLineageModal(true)
		}
	}

	const closeLineageModal = () => {
		setData([])
		setOpenLineageModal(false)
		if(needUpdate){
			setShowNeedUpdate(true)
			genReport()
		}
	}

	const genLineageCell = (cell,row) => {
        const [isEditingInflow,setIsEditingInflow] = useState(false)
        const [isEditingOutflow,setIsEditingOutflow] = useState(false)
        const inputInflowRef = useRef(null)
        const inputOutflowRef = useRef(null)

        const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
            <span onClick={onClick} ref={ref}>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</span>
          ));

        const toggleEditingInflow = () => {
        setIsEditingInflow((prev)=>!prev)
        setIsEditingOutflow(false)
        }

        useEffect(()=>{
        if(isEditingInflow){
            inputInflowRef.current.focus()
        }
        },[isEditingInflow])

        const toggleEditingOutflow = () => {
        setIsEditingOutflow((prev)=>!prev)
        setIsEditingInflow(false)
        }

        useEffect(()=>{
        if(isEditingOutflow){
            inputOutflowRef.current.focus()
        }
        },[isEditingOutflow])

        const handleInflowChange = async (event) =>{
        if(cell.row.original.inflow != event.target.value){
            let index = data.indexOf(cell.row.original)
            setData(current =>
            current.map(obj => {
                if(obj.id === cell.row.original.id){
                return{ ...obj,inflow:event.target.value,outflow:0}
                }
                return obj;
            }),
            )
            let resp = await updateTransactionAmount(index,event.target.value,0)
        }
        toggleEditingInflow()
        }

        const handleOutflowChange = async (event) =>{
        if(cell.row.original.outflow != event.target.value){
            let index = data.indexOf(cell.row.original)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,inflow:0,outflow:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionAmount(index,0,event.target.value)
        }
        toggleEditingOutflow()
        }
        
        const handleDateChange = async (value) =>{
            let year = value.getFullYear().toString()
            let month= value.getMonth()+1
            let day = value.getDate()
            if(day<10){1
                day = '0'+day.toString()
            }else{
                day = day.toString()
            }
    
            if(month<10){
                month = '0'+month.toString()
            }else{
                month = month.toString()
            }
            let fecha = year+'-'+month+'-'+day
            if(cell.row.original.date != fecha){
                let index = data.indexOf(cell.row.original)
                setData(current =>
                    current.map(obj => {
                    if(obj.id === cell.row.original.id){
                        return{ ...obj,date:fecha}
                    }
                    return obj;
                    }),
                )
                let resp = await updateTransactionDate(index,fecha)
            }
        }
        
        

        const handleConceptChange = async (event) =>{
            let index = data.indexOf(cell.row.original)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,context:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionConcept(index,event.target.value)
        }

        const handleNotesChange = async (event) =>{
            let index = data.indexOf(cell.row.original)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,notes:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionNotes(index,event.target.value)
        }

        const handleExchangeChange = async (event) =>{
            let index = data.indexOf(cell.row.original)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,exchange:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionExchange(index,event.target.value)
        }
    
        const handleSubcategoryChange = async (event) => {
            let index = data.indexOf(cell.row.original)
            setData(current=> 
                current.map(obj => {
                if (obj.id === cell.row.original.id) {
                    return {...obj,category:event.target.selectedOptions[0].outerText,idSubCategory:event.target.value,subcategory:event.target.selectedOptions[0].label}
                }
                return obj;
                }),
            )
            let resp = await updateTransaction(index,event.target.value)
        }

        const handleClientSupplierChange = async (event) => {
            let index = data.indexOf(cell.row.original)
            setData(current=> 
                current.map(obj => {
                if (obj.id === cell.row.original.id) {
                    return {...obj,idClientSupplier:event.target.value,clientSupplier:event.target.selectedOptions[0].label}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionClientSupplier(index,event.target.value)
        }

        const handleCurrencyChange = async (value) => {
            let index = data.indexOf(cell.row.original)
            if(mainCurrency===value.name){
                setData(current=> 
                    current.map(obj => {
                    if (obj.id === cell.row.original.id) {
                        return {...obj,currency:value.name,exchange:null}
                    }
                    return obj;
                    }),
                )
            }else{
                setData(current=> 
                    current.map(obj => {
                    if (obj.id === cell.row.original.id) {
                        return {...obj,currency:value.name}
                    }
                    return obj;
                    }),
                )
            }
            
            let resp = await updateTransactionCurrency(index,value.id)
        }

        const handlePaidWithChange = async (value) => {
            let index = data.indexOf(cell.row.original)
            setData(current=> 
                current.map(obj => {
                if (obj.id === cell.row.original.id) {
                    return {...obj,paidWith:value.name}
                }
                return obj;
                }),
            )
            
            let resp = await updateTransactionPaidWith(index,value.id)
        }

        let updatedStyle = 'px-2 rounded-button bg-transparent w-[100%]  border-neutral-1 border-1  appearance-none relative'
        let normalStyle = 'px-0 rounded-button bg-transparent w-[100%] appearance-none relative'
        let classifiedStyle = 'px-2 rounded-button bg-transparent w-[100%] appearance-none relative'

        if(cell.column.id === 'category'){
            return <div className='pl-4 w-[100%]'>{cell.value}</div>
        }
        if(cell.column.id === 'subcategory' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] flex relative'>
            <select className={!row.original.subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} value={categoriesDict[row.original.idSubCategory]?.isActive ? row.original.idSubCategory : ""} onChange={(event)=>handleSubcategoryChange(event)}>
            <option value={cell.row.original.idSubCategory} selected='true' disabled>{cell.value}</option>
            {row.original.inflow!=0 ? inflowCategories.map(category=>{
                return (
                <optgroup label={category.name}>
                    {subcategories[category.index].map(subcategory => {
                    return (
                        <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                    )
                    })}
                </optgroup>
                )
            }) :
            outflowCategories.map(category=>{
                return (
                <optgroup label={category.name}>
                    {subcategories[category.index].map(subcategory => {
                    return (
                        <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                    )
                    })}
                </optgroup>
                )
            }) }
            </select><span className='absolute flex items-center right-[15px] top-[2px] pointer-events-none'><TrianguloAbajoLogo/></span></div>
        //return <div><ClassificationDropdown className={!data[data.indexOf(row.original)].subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} disabled={row.original.idCategory ? false : true} selected={data[data.indexOf(row.original)].subcategory ? {name:data[data.indexOf(row.original)].subcategory} :{'name':'Subcategoria'}} setSelected={handleSubcategoryChange} options={row.original.idCategory ? subcategories[data[data.indexOf(row.original)].categoryIndex] : [] }/></div>
        }
        
        if(cell.column.id === 'clientSupplier' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] flex relative'>
            <select className={!row.original.idClientSupplier ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} value={clientSupplierDict[row.original.idClientSupplier]?.isActive ? row.original.idClientSupplier : ""} onChange={(event)=>handleClientSupplierChange(event)}>
            <option value={cell.row.original.idClientSupplier} selected='true' disabled>{cell.value}</option>
            <optgroup label={'Clientes'}>
                {clientOptions.map(client=>{
                return (
                <option value={client.idClientSupplier} label={client.clientSupplierName}>{client.clientSupplierName}</option>
                )})
                }
            </optgroup>
            <optgroup label={'Proveedores'}>
                {supplierOptions.map(supplier=>{
                return(
                <option value={supplier.idClientSupplier} label={supplier.clientSupplierName}>{supplier.clientSupplierName}</option>
                )})
                }
            </optgroup>
            
            </select><span className='absolute flex items-center right-[15px] top-[2px] pointer-events-none'><TrianguloAbajoLogo/></span></div>
        }
        if(cell.column.id === 'inflow'&& userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
        return <div className='text-right relative w-[100%] tabular-nums pr-2'>
				
				{isEditingInflow ?
				<div className='bg-white rounded-[7px] w-full'>
				<input ref={inputInflowRef} onBlur={(e)=>handleInflowChange(e)} defaultValue={Math.round(cell.value * 100) / 100} className='text-right w-[100%]' type='number'>
				</input>
				</div>
				:
				<span onClick={()=>toggleEditingInflow()}>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				style: "currency",
				currency: "USD",
				currencyDisplay: 'code'}).slice(4) : '--'}</span>
				}
            </div>/*
            return <div className='text-right relative w-[100%] tabular-nums'>
            <span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: 'code'}).slice(4) : '--'}</span>
            </div>*/
        }
        if(cell.column.id === 'inflow'){ 
                return <div className='text-right relative w-[100%] tabular-nums'>
                <span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                currencyDisplay: 'code'}).slice(4) : '--'}</span>
                </div>
            }
        if(cell.column.id === 'outflow' && userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
        return <div className='text-right relative overflow-visible w-[100%] tabular-nums pr-2'>
            {isEditingOutflow ? 
            <div className='bg-white rounded-[7px] shadow-xl border-1 w-[100%]'>
            <input ref={inputOutflowRef} onBlur={(e)=>handleOutflowChange(e)} defaultValue={Math.round(cell.value * 100) / 100} className='text-right w-[100%]' type='number'>
            </input>
            </div>
            :
            <span onClick={()=>toggleEditingOutflow()}>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
            }</div>
        /*
        return <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
            <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
            </div>*/

        }
        if(cell.column.id === 'outflow'){
            return <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
                <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                currencySign: "accounting",
                currencyDisplay: 'code'
                }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
                </div>
    
            }
        if(cell.column.id === 'exchange' && userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
        return <div className='pl-2 w-[100%] truncate focus:bg-black relative '  title={cell.value}>
            
            <input className='w-full h-full appearance-none bg-transparent group text-right' placeholder='--' disabled={row.original.currency===mainCurrency} defaultValue={cell.value} onBlur={(e)=>handleExchangeChange(e)}></input>
            
            {false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
            </div>
        }
        if(cell.column.id === 'exchange'){
        return <div className='pl-2 text-right w-[100%] tabular-nums'>{cell.value ? parseFloat(cell.value).toFixed(2).toLocaleString("en-US") : '--'}</div>
        }
        if(cell.column.id === 'context' && userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
        return <div className='pl-2 w-[100%] truncate relative '  title={cell.value}>
            <input className='w-full h-full appearance-none bg-transparent group' defaultValue={cell.value} onBlur={(e)=>handleConceptChange(e)}></input>
            
            {false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
            </div>
        }
        if(cell.column.id === 'context'){
        return <div className='pl-2 w-[100%] truncate relative '  title={cell.value}>{cell.value}</div>
        }
        if(cell.column.id === 'date'){
            let date = new Date(cell.value)
            date = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
        return  cell.row.original.type === Idioma.planeacion.planeacion[language] ? 
			<div className=' w-[100%] '>
				<DatePicker
				className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
				selected={date} 
				locale={language}
				onChange={(date) => handleDateChange(date)}
				fixedHeight
				customInput={<ExampleCustomInput/>}
				dateFormat={Idioma.formatoFecha[language]}
				/>
				
            </div>  
			:
			<div className='w-[100%]'>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div> 
        }
        if(cell.column.id === 'paidWith' && userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
            return <div className=' w-[100%] text-left '>
                <DinamicDropdown
                    options={creditDebitOptions}
                    selected={{name:cell.value}}
                    setSelected={handlePaidWithChange}
                    tableDropdown={true}
					className={' w-[80%] pl-2'}
                />
            </div>
        }
        if(cell.column.id === 'paidWith'){
        return <div className='pl-2 w-[100%] text-left'>{cell.value}</div>
        }
        if(cell.column.id === 'notes' && userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
        return <div className='pl-2 w-[100%] truncate focus:bg-black relative '  title={cell.value}>
				<input className='w-full h-full appearance-none bg-transparent group' defaultValue={cell.value} onBlur={(e)=>handleNotesChange(e)}></input>
				
				{false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
			</div>
			
        }
		if(cell.column.id === 'notes'){
			return <div className=' w-[100%]' title={cell.value}>
						{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
					</div>
		}
        if(cell.column.id === 'extraText'){
            if(row.original.extraText){
                let json = JSON.parse(row.original.extraText)
                let aux = Object.keys(json) 
                let auxText = ``
                for(let i=0;i<aux.length;i++){
                auxText =auxText+ `${aux[i]} : ${json[aux[i]]} \n`
                } 
                return <div className='w-[100%] text-left truncate' title={auxText}>{auxText}</div>
            }else{
                return <div className=' w-[100%] text-left '>{cell.value}</div>    
            } 
        
        }
        if(cell.column.id === 'currency' && userAccess && userAccess.access3 && cell.row.original.type === Idioma.planeacion.planeacion[language]){
            return <DinamicDropdown
			options={CurrencyOptions}
			selected={{name:cell.value,id:cell.value}}
			setSelected={handleCurrencyChange}
			tableDropdown={true}
			className={' w-[80%] pl-2'}
		/>
        }
		if(cell.column.id === 'currency'){
			<div className=' w-[100%]' title={cell.value}>
				{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
			</div>
		}
        return <div className=' w-[100%] text-left pl-2'>{cell.value}</div>
        
    }

	
	const filterTypes = React.useMemo(
        () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )

	const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
	const defaultColumn = React.useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
          minWidth: 10, // minWidth is only used as a limit for resizing
        width: 120, // width is used for both the flex-basis and flex-grow
        maxWidth: 1800, // maxWidth is only used as a limit for resizing
        }),
        []
    )
	
	const columns = React.useMemo(
        () => [
			...(!idRS && dataModal.length>0
				? [{
						Header: Idioma.transacciones.configuracion.empresa[language],
						accessor: 'rsName',
						Filter: SelectColumnFilter,
					}]
				: [] ),
			{
				Header: Idioma.filtros.tipo[language],
				accessor: 'type',
				Filter: SelectColumnFilter,
			},
			{
				Header: Idioma.transacciones.configuracion.banco[language],
				accessor: 'bank',
				Filter: SelectColumnFilter,
			},
			{
				Header: Idioma.transacciones.configuracion.cuentaDrop[language],
				accessor: 'idAccount',
				Filter: SelectColumnFilter,
			},
			{
				Header: Idioma.transacciones.configuracion.fecha[language],
				accessor: 'date',
				sortType:'basic',
				Filter:DateRangeColumnFilter,
				filter:dateBetweenFilterFn,
				className: 'text-left',
			},
			{
				Header: Idioma.transacciones.configuracion.concepto[language],
				accessor: 'context',
				className: 'text-left',
				width:300
			},
			{
				Header: Idioma.transacciones.configuracion.abono[language],
				className: 'text-right',
				Filter: NumberRangeColumnFilter,
				filter: 'between',
				accessor: 'inflow',
			},
			{
				Header: Idioma.transacciones.configuracion.cargo[language],
				className: 'text-right',
				Filter: NumberRangeColumnFilter,
				filter: 'between',
				accessor: 'outflow',
			},{
				Header: Idioma.transacciones.configuracion.moneda[language],
				accessor: 'currency',
				Filter: SelectColumnFilter,
			},
			{
				Header: Idioma.transacciones.configuracion.fx[language],
				accessor: 'exchange',		
				Filter: NumberRangeColumnFilter,
				filter: 'between',
			},{
				Header: Idioma.transacciones.configuracion.notas[language],
				accessor: 'notes',

				Filter: NotesColumnFilter,
			},{
				Header: Idioma.transacciones.configuracion.informacionAdicional[language],
				accessor: 'extraText',
				Filter: NotesColumnFilter,
			}
        ]
		,
        []
      )

	const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        setAllFilters,
        selectedFlatRows,
        allColumns,
        state,
        setSortBy,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
          autoResetPage: false,
          autoResetGlobalFilter: false,
          defaultColumn, // Be sure to pass the defaultColumn option
		  filterTypes,
          autoResetSortBy:false
        },
		useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useBlockLayout,
        useResizeColumns,
      )

	  const RenderRow = React.useCallback(
        (rows) => ({ index, style }) => {
          const row = rows[index];
          prepareRow(row);
          return (
            <div
            {...row.getRowProps({
              style
            })}
            >
              {row.cells.map((cell) => {
                return (
                  <div><td {...cell.getCellProps()} className='w-[100%] '>{genLineageCell(cell,row)}</td></div>
                );
              })}
            </div>
          );
        },
        [prepareRow,data]
      );

	/*const redirectLineage = () => {
		
		if(jsonReport.input_list.bank_id !==null){
			setContextBank({name:banksDict[jsonReport.input_list.bank_id].name,id:jsonReport.input_list.bank_id})
		}else{
			setContextBank({name:Idioma.transacciones.configuracion.banco[language]})
		}
		if(jsonReport.input_list.account_id !== null){

			setContextAccount({name:accountsDict[jsonReport.input_list.account_id],id:jsonReport.input_list.account_id})
		}else{
			setContextAccount({name: Idioma.transacciones.configuracion.bancoDrop[language]})
		}
		if(jsonReport.input_list.rsId !== null && !idRS){

			setContextRSID(jsonReport.input_list.rsId)
		}else{
			setContextRSID([])
		}
		setContextFile({name:Idioma.transacciones.configuracion.archivoDrop[language],percentage:''})
		setContextShowState('classified')
		setContextDateRange(redirectFiltersData)
		setContextSubcategoryId(categoriesDict[`${lineageData.category}${lineageData.subcategory}`].id)
		navigate('/classification')
	}*/

	const AddTransactionDisabled = conceptAddT.length===0 || !(outflowAddT || inflowAddT) || !(scenarioAddT.id) || !(recurrence.id && (recurrence.id=='none' || recurrenceRepeats>0 || recurrenceEndDate))

	const handleChangeRecurrenceAddT = (value) => {
        setRecurrence(value)
        if(value.id==='daily'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24)))
            let aux = moment(dateAddT).add(1,'days').format('YYYY-MM-DD')
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        if(value.id==='weeklyOnDD'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24*7)))
            let aux = moment(dateAddT).add(1,'week').format('YYYY-MM-DD')
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        if(value.id==='monthlyOnNDD'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24*30)))
            let n = parseInt((selectedTransaction?.original?.date?.split('-')[2]-1)/7+1)
            let aux = moment(dateAddT).add(4,'week').format('YYYY-MM-DD')
            if(n!==parseInt((aux.split('-')[2]-1)/7+1)){
                aux = moment(aux).add(1,'week').format('YYYY-MM-DD')
            }
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        setRecurrenceRepeatsLimit()
    }

    const handleChangeRecurrenceEndDate = (value) => {
        setRecurrenceEndDate(value)
        setRecurrenceRepeats('')
    }

	const handleDateAddTChange = (date) => {
        setDateAddT(date)
        let aux = date
        aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
        setRecurrenceEndDateMinLimit(aux)
		setRecurrence({
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        })
        let aux1 = [{
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.diario[language],
            id:'daily'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(aux).locale(language).format('dddd')}`,
            id:'weeklyOnDD'
        }]

        if(parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)<=4){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums[`${parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)}s`][language]} ${moment(aux).locale(language).format('dddd')}`,
                id:'monthlyOnNDD'
            }
        }
        if(moment(aux).month()!==moment(aux).add(1,'week').month()){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums.ultimo[language]} ${moment(aux).locale(language).format('dddd')}`, 
                id:'monthlyLastDD'
            }
        }
        setRecurrenceOptions(aux1)
    }

    const handleValueAddTChange = (inflow,outflow) => {
        setInflowAddT(inflow)
        setOutflowAddT(outflow)
    }

	const handleOpenAddTransaction = () => {
        setOpenAddTransaction(true)
        let aux = new Date()
        aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
        setRecurrenceEndDateMinLimit(aux)
        setRecurrenceEndDate(aux)
		setRecurrence({
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        })
        let aux1 = [{
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.diario[language],
            id:'daily'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(aux).locale(language).format('dddd')}`,
            id:'weeklyOnDD'
        }]

        if(parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)<=4){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums[`${parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)}s`][language]} ${moment(aux).locale(language).format('dddd')}`,
                id:'monthlyOnNDD'
            }
        }
        if(moment(aux).month()!==moment(aux).add(1,'week').month()){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums.ultimo[language]} ${moment(aux).locale(language).format('dddd')}`, 
                id:'monthlyLastDD'
            }
        }
        setRecurrenceOptions(aux1)
		setScenarioAddT(scenario)
    }

    const handleCloseAddT = () => {
        setOpenAddTransaction(false)
        setDateAddT(new Date())
        setConceptAddT('')
        setInflowAddT()
        setOutflowAddT()
        setCurrencyAddT({name:mainCurrency,id:mainCurrency})
        setPaidWithAddT({name:Idioma.transacciones.debito[language],id:'D'})
        setScenarioAddT({name:Idioma.planeacion.seleccionaEscenario[language]})
        setExchangeAddT()
        setClientSupplierAddT({name:Idioma.planeacion.seleccionaClienteProveedor[language]})
        setRecurrenceEndDateMinLimit()
        setRecurrenceRepeats()
        setRecurrenceRepeatsLimit()
        
    }

	const AddSingleTransaction = () => {
        
        setAddTransactionLoading(true)
        let year = dateAddT.getFullYear().toString()
        let month= dateAddT.getMonth()+1
        let day = dateAddT.getDate()
        if(day<10){1
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }

        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fecha = year+'-'+month+'-'+day
        let datesArray=[]
        if(recurrence.id && recurrence.id!='none'){
            datesArray=CalculateRecurrence(fecha)
        }

        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "date": fecha,
            "context": conceptAddT,
            "inflow":   parseFloat(inflowAddT) || 0,
            "outflow":  parseFloat(outflowAddT) || 0,
            "idCategory": String(categoryAddT.id),
            "idScenario":scenarioAddT.id,
            "currency":currencyAddT.id,
            "paidWith":paidWithAddT.id,
            "recurrence":[],
			logsInfo: {
			  origin:'Report planning',
			  sessionID:sessionID 
			},
        }
        if(clientSupplierAddT.id){
            body.idClientSupplier=clientSupplierAddT.id
        }
        if(datesArray.length>0){
            body.recurrence=datesArray
        }

        fetch(`${urlBase}/planning/addTransaction`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setAddTransactionLoading(false)
		setLineage(lineageData.flow,
			lineageData.category,
			lineageData.subcategory,
			lineageData.event,
			lineageData.indexDate,
			lineageData.realData,
			lineageData.planningData)
        handleCloseAddT()
        })
        .catch(error=>{
        alert('Ha ocurrido un error')
        })
    }

	useEffect( () => {
		if(filters!==undefined && idRsOptions.length>1){
		  let data = [];
		  let ids = Object.keys(idRsOptions)
		  let names = Object.values(idRsOptions)

		  for( let i = 0; i < ids.length; i++ ) {
			data.push({name: names[i], id: Number(ids[i])})
		  }
  
		  setDataModal(data)
		}
	}, [idRsOptions] );

	useEffect( () => {
		if(idRsOptions.length>1 || idRS){
			if(RSID.id || scenarioOptions.length===0 || idRS){
				getScenarios()
			}
		}
	}, [RSID] );

    return (
        <div className='ml-[5%] mt-6 mr-10'>
			{openAddTransaction && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[2000] h-screen'>
                <div onClick={()=>handleCloseAddT()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><AddSingleTransactionLogo className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.transacciones.anadirTransaccionModal.tituloModal[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    {/*<p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cuenta[language]}</p>
                    <Dropdown
                        options={accountsAddT}
                        selected={accountAddT}
                        setSelected={setAccountAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />*/}
					<p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <Dropdown
                        options={scenarioOptions}
                        selected={scenarioAddT}
                        setSelected={setScenarioAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-3 '}
						disabled={true}
                    />
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.fecha[language]}</p>
                    <div className='col-span-2'>
                      <DatePicker 
                      className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
                      selected={dateAddT} 
                      locale={language}
                      onChange={(date) => handleDateAddTChange(date)}
                      fixedHeight
                      dateFormat={Idioma.formatoFecha[language]}
					  minDate={redirectFiltersData[0]}
					  maxDate={redirectFiltersData[1]}
                      />
                    </div>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.concepto[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' value={conceptAddT} onChange={(e)=>setConceptAddT(e.target.value)} placeholder={Idioma.transacciones.anadirTransaccionModal.concepto[language]}></input>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.abono[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={inflowAddT} onChange={(e)=>handleValueAddTChange(e.target.value,'')} placeholder='--'></input>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cargo[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={outflowAddT} onChange={(e)=>handleValueAddTChange('',e.target.value)} placeholder='--'></input>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.subCategoria[language]}</p>
                    <div className='col-span-2 relative flex'>
					<Dropdown
                        options={[{name: lineageData.subcategory, id: lineageData.category}]}
                        selected={categoryAddT}
                        setSelected={setCategoryAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-3 '}
						disabled={true}
                    />
                    </div>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.moneda[language]}</p>
                    <Dropdown
                        options={CurrencyOptions}
                        selected={currencyAddT}
                        setSelected={setCurrencyAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    {currencyAddT.id!==mainCurrency && 
                    <>
                        <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.fx[language]}</p>
                        <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={exchangeAddT} onChange={(e)=>setExchangeAddT(e.target.value)} placeholder='--'></input>
                    </>}
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.creditoDebito[language]}</p>
                    <Dropdown
                        options={creditDebitOptions}
                        selected={paidWithAddT}
                        setSelected={setPaidWithAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.clientesProvedores[language]}</p>
                    <Dropdown
                        options={[{name:Idioma.planeacion.ninguno[language]},...clientSupplierOptions]}
                        selected={clientSupplierAddT}
                        setSelected={setClientSupplierAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1'}
                        optionsMaxH='max-h-40'
                    />
                    {/*<p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.recurrencia[language]}</p>
                    <Dropdown
                        options={recurrenceOptions}
                        selected={recurrence}
                        setSelected={handleChangeRecurrenceAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
					/>*/}
                    
                    <p className={`col-span-3 ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>{Idioma.planeacion.opcionesRecurrencia.termina[language]}</p>
                    <p className={`text-[12px] 2xl:text-p8-2xl ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>{Idioma.planeacion.opcionesRecurrencia.cuandoTermina[language]}</p>
                    <div className={`col-span-2 ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>
                      <DatePicker 
                      className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
                      selected={recurrenceEndDate} 
                      locale={language}
                      onChange={(date) => handleChangeRecurrenceEndDate(date)}
                      fixedHeight
                      maxDate={recurrenceEndDateLimit.setFullYear(recurrenceEndDateLimit.getFullYear() + 2 )}
                      minDate={recurrenceEndDateMinLimit}
                      placeholderText={Idioma.transacciones.configuracion.fecha[language]}
                      dateFormat={Idioma.formatoFecha[language]}
                      />
                    </div>
                    <p className={`text-[12px] 2xl:text-p8-2xl ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>{Idioma.planeacion.opcionesRecurrencia.despuesDe[language]}(max {recurrenceRepeatsLimit})</p>
                    <input key={'recurrenceRepeats'} className={`col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2 ${recurrence.id && recurrence.id!='none'?'':'hidden'}`} type='number' min='0' max={recurrenceRepeatsLimit} value={recurrenceRepeats} onBlur={()=>checkRecurrenceRepeat()} onChange={(e)=>handleChangeRecurrenceRepeats(e.target.value)} placeholder={Idioma.planeacion.opcionesRecurrencia.repeticiones[language]}></input>
                    
                  </div>
                  
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddTransactionDisabled || addTransactionLoading ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddTransactionDisabled  || addTransactionLoading} onClick={()=>{AddSingleTransaction()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseAddT()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseAddT()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
			{openLineageModal && 
				<div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
				<div onClick={()=>closeLineageModal()} className=' fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'>
				</div>
				<div className='bg-white px-8  z-10 absolute top-[calc(40%)] left-[35%] shadow-lg mt-10 py-6 2xl:py-[28px] w-[70%] text-16 rounded-[7px] thin-scrollbar overflow-x-scroll -translate-x-[40%] -translate-y-[50%] '>
				<p>{data.length} {Idioma.reporte.subcatLineage[language]} {lineageData.subcategory}</p>
				<table {...getTableProps()} className="table">
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} className={' py-2 '}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()} className={column.className + '  '}>
								 {<div className='text-[10px] mb-2'>{column.canFilter && column.render('Filter')}</div>}
								{
								<p className={`flex pr-2 text-[12px] 2xl:text-[16px] items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':''}`} onClick={()=>{
									const desc =
									column.isSortedDesc === true ?
									undefined :
									column.isSortedDesc === false ?
									true :
									false ;
									if(desc===undefined){
									  setSortBy([])
									}else if(desc===true){
									setSortBy([{id:column.id,desc:desc}])
									}else{
									  setSortBy([{id:column.id,desc:desc}])
									}
									
									
								  }} >{column.Header}
								  <span className='ml-1'>
								  {column.isSorted
									? column.isSortedDesc
									  ? <Triangulo/>
									  : <div className='rotate-180'><Triangulo/></div>
									: <></>}
									
									
								</span></p>
								}
								<div
                                {...column.getResizerProps()}
                                className={` inline-block bg-neutral-1 w-[4px] h-[32px] absolute right-1 top-0 translate-x-[50%] z-1 touch-none ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                              />
							</th>
							))}
							
						</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()} className='text-p9-2xl'>
						<FixedSizeList
						height={document.documentElement.clientHeight*.30}
						itemCount={rows.length}
						itemSize={35}
						width={totalColumnsWidth+scrollBarSize}
						>
						{RenderRow(rows)}
						</FixedSizeList>
					</tbody>
					</table>
					<div className='flex gap-4 items-center'>
						<PlanningExcelExport excelData={excelLineageData} fileName={'datos'}/>
						{userAccess && userAccess.access3 && !categoriesDict[`${lineageData.category}${lineageData.subcategory}`].notUse &&
							<button onClick={()=>handleOpenAddTransaction()} title={Idioma.transacciones.anadirTransaccionModal.tituloModal[language]} className={` p-[4px] rounded-button flex justify-center items-center scale-100`}>
								<AddSingleTransactionLogo fill={'#000'}/>
							</button>
						}
					</div>
					{/*!categoriesDict[`${lineageData.category}${lineageData.subcategory}`].notUse && <button className='rounded-button text-center text-white p-2 bg-light-purple-3 px-2 ml-2 text-h13-2xl 2xl:text-h11-2xl' onClick={()=>redirectLineage()}>
						<span className='flex gap-x-2 items-center'>{Idioma.resumen.graficasArriba.abrirTransacciones[language]}</span>
					</button>*/}
				</div>
			</div>}
			<div className='flex gap-2 2xl:gap-4 mb-2'>
			{!idRS && dataModal.length>1 && dataModal.length<5 &&
            <DinamicDropdown
				options={dataModal}
				selected={RSID}
				className={'text-p11-2xl w-[80px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
				setSelected={setRSID}
			/>
			}
				<DinamicDropdown
					options={scenarioOptions}
					selected={scenario}
					className={'text-p11-2xl w-[120px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
					setSelected={setScenario}
				/>
				{/*<DinamicDropdown
					options={preFilterOptions}
					selected={preFilter}
					className={'text-p11-2xl w-[120px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
					setSelected={setPreFilter}
				/>*/}
				<div className='col-span-2 w-button-3 2xl:w-button-2 z-50'>
				<DatePicker 
					className={' h-button-4 2xl:h-button-3 focus:outline-none cursor-default border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 rounded-button text-p11-2xl 2xl:text-p9-2xl text-center'} 
					onChange={(date) => handleDateChange(date)} 
					selectsRange startDate={startDate} 
					endDate={endDate}
					dateFormat={Idioma.formatoFecha[language]}
					locale={language}
					showYearDropdown
					fixedHeight
				/>
				</div>
				<h3 className='py-1 mt-2 text-center text-p11-2xl 2xl:text-p9-2xl whitespace-nowrap'>{Idioma.filtros.agrupar[language]}</h3>
				<DinamicDropdown
					options={groupByOptions}
					selected={groupBy}
					className={'text-p11-2xl w-[80px] h-button-4 2xl:text-p9-2xl 2xl:w-button-3 2xl:h-button-3'}
					setSelected={setGroupBy}
				/>
				<h3 className='py-1 mt-2 text-center text-p11-2xl 2xl:text-p9-2xl whitespace-nowrap'>{Idioma.filtros.proyeccion[language]}</h3>
				<div className='col-span-2 w-button-4 z-50'>
				<DatePicker 
					className={' h-button-4 2xl:h-button-3 focus:outline-none cursor-default border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 rounded-button text-p11-2xl 2xl:text-p9-2xl text-center'} 
					onChange={setPlanningDate} 
					selected={planningDate}
					dateFormat={Idioma.formatoFecha[language]}
					locale={language}
					showYearDropdown
					fixedHeight
					minDate={startDate}
					maxDate={endDate}
				/>
				</div>
				{ fileURL ? <a href={fileURL} title={Idioma.reporte.exportarExcel[language]} className='rounded-md hover:bg-md-white flex cursor-pointer items-center p-1 text-[16px] top-[2rem]'><DownloadIcon fill='#00C67E'/></a> : fileLoading ? <div className='flex items-center'><Loading/></div> : <button className={idRsOptions.length===0 || scenario.id ? '':'invisible'} disabled={idRsOptions.length===0 || scenario.id ? false : true} onClick={genFile} title={Idioma.reporte.exportarExcel[language]}><ExcelExportLogo/></button>}

				<button className={``} disabled={ (idRsOptions.length===0 || scenario.id) && startDate != null && startDate != undefined && endDate != null && endDate != undefined ? false : true} onClick={genReport}><UpdateReportLogo fill={(idRsOptions.length===0 || scenario.id) && startDate != null && startDate != undefined && endDate != null && endDate != undefined ? '#00C67E':'#DBDBDB'} stroke={(idRsOptions.length===0 || scenario.id) && startDate != null && startDate != undefined && endDate != null && endDate != undefined ? '#00C67E':'#DBDBDB'}/></button>
			</div>
			<div className=' mb-2 flex justify-between'>
			<div></div>
				<div className='flex justify-end text-white'>
					{userAccess && userAccess.access3 && <div onClick={()=>navigate("/planning/comparison")} className='bg-purple-2 cursor-pointer flex items-center justify-center rounded-button text-[12px] 2xl:text-b3-2xl h-button-3 w-[192px]'>
					<p className=''>{Idioma.planeacion.comparacion[language]}</p>
					</div>}
					{userAccess && userAccess.access3 && <div onClick={()=>navigate("/planning/classification")} className='ml-5 bg-light-purple-3 cursor-pointer flex items-center justify-center rounded-button text-[12px] 2xl:text-b3-2xl h-button-3 w-[192px]'>
					<p className=''>{Idioma.planeacion.modalEditar.titulo[language]}</p>
					</div>}
				</div>
			</div>
			
			{errorSign||!loadingReport  ? null:<div className='mt-4'><Loading/></div>}
            {/*<div className='mt-2'  dangerouslySetInnerHTML={{ __html: data ? data : null }} />*/}
			{jsonReport && <div className={`mt-4 pb-0.5 max-h-[74vh] perfect-scrollbar overflow-scroll`}>
					<table onMouseLeave={()=>setHighlightRow(-1)} className=' mr-4 pr-1'>
						<thead>
						<tr className='z-[10] sticky top-0 h-[40px]'>
							<th onMouseEnter={()=>setHighlightRow(-1)} className='px-3.5 text-left min-w-[200px] 2xl:min-w-[300px] sticky left-0 bg-light-purple-5 rounded-l-button text-h13-2xl whitespace-nowrap'>
								{`${Idioma.reporte.fxExpresado[language]}${mainCurrency}`}
							</th>
							{jsonReport.date_to_use.map((data,index)=> (
								<th onMouseEnter={()=>setHighlightRow(index)} className={`px-3.5 text-right min-w-[80px] 2xl:min-w-[120px] text-p10-2xl 2xl:text-p9-2xl whitespace-nowrap ${index==highlightRow ? ' bg-light-purple-4 ':'bg-light-purple-5'}`}>
									{data}
								</th>
							))}
							<th onMouseEnter={()=>setHighlightRow(jsonReport.date_to_use.length)} className={`px-3.5 text-right min-w-[80px] 2xl:min-w-[120px] rounded-r-button bg-light-purple-5 text-p9-2xl`}>
								{Idioma.reporte.total[language]}
							</th>
						</tr>
						</thead>
					<tbody>
						<tr className='h-4'>
							<td onMouseEnter={()=>setHighlightRow(-1)}>
								{' '}
							</td>
							{jsonReport.date_other.map((date,indexTotalL0)=>(
								<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}>
									{' '}
								</td>
							))}
							<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)}>
								{' '}
							</td>
						</tr>
						{jsonReport.categoryL0.slice(0,3).map((data,indexL0)=>(
							<>
							<tr className={`bg-gray-6 text-black sticky top-[40px] z-10 hover:z-[11]`}>
								<td onMouseEnter={()=>setHighlightRow(-1)} className={`sticky left-0 text-h13-2xl 2xl:text-h11-2xl flex items-center `}>
								<span className='mx-2'><InfoI popDirection={false} texto={data===Idioma.reporte.flujoOperativo[language]?Idioma.reporte.infoFlujoOperativo[language]:data===Idioma.reporte.flujoInversion[language]?Idioma.reporte.infoFlujoInversion[language]:data===Idioma.reporte.flujoFinanciamiento[language]?Idioma.reporte.infoFlujoFinanciamiento[language]:null}/></span>{data}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right text-p10-2xl`}>
										{/*(!parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])).toLocaleString('en-US')*/}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums text-h13-2xl`}>
									{/*!parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])).toLocaleString('en-US')*/}
								</td>
							</tr>
							{jsonReport.categoryL1[data]?.map((datum,indexL1)=>(
								<>

								{categoriesL1[datum]===0 ? 
								<>
								<tr className='text-left hover:bg-light-purple-6 group'>
									<td onMouseEnter={()=>setHighlightRow(-1)} onClick={()=>handleExpandCategoryL1(datum)} className='sticky cursor-pointer left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
									<span className='mx-2'>+</span> <span className='mx-2 invisible'>-</span>{datum} 
										
									</td>
									{jsonReport.date_other.map((totalData,indexDate)=>(
									<td onMouseEnter={()=>setHighlightRow(indexDate)} className={` text-p10-2xl tabular-nums text-right ${indexDate==highlightRow ? ' bg-light-purple-6 ':''}  ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`} >
										{!parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
									))}
									<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums text-h13-2xl `}>
									{!parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`]) ? '--' :Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
								</tr>
								</>
								:<><tr className='  text-left hover:bg-light-purple-6 group'>
								<td onMouseEnter={()=>setHighlightRow(-1)} onClick={()=>handleExpandCategoryL1(datum)} className='sticky cursor-pointer left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
									<span className='mx-2 invisible'>+</span> <span className='mx-2'>-</span>{datum} 
								</td>
								{jsonReport.date_other.map((totalData,indexDate)=>(
									<td onMouseEnter={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''}  ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`} >
										{!parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums text-h13-2xl`}>
									{!parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`]) ? '--' :Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])).toLocaleString('en-US')}
								</td>
							</tr>
							{jsonReport.categoryL2[datum]?.map((info,indexL2)=>(
								
								<tr className=' text-left hover:bg-light-purple-6 group'>
									<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky pl-7 left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl' >
									<span className=' invisible mx-2'>+</span> <span className='mx-2 invisible'>-</span>{info}
									</td>
									{jsonReport.date_other.map((date_other_n,indexDate)=>(
										jsonReport["no_lineage "].includes(info) ?
										<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
											{!Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`])) ? '--' : Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])).toLocaleString('en-US')}
										</td>
										:
										<td onClick={(e)=>setLineage(data,datum,info,e,indexDate, (indexDate<=proyectionStartIndex), (indexDate>=proyectionStartIndex))} onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right ${userAccess.access2 && 'cursor-zoom-in'} text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
											{!Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`])) ? '--' : Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])).toLocaleString('en-US')}
										</td>
									))}
									<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` text-h13-2xl tabular-nums text-right`}>
									
										{!parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx])).toLocaleString('en-US')}
									</td>
								</tr>
							))}
							{/*<tr className='text-left  group'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='  pl-2 sticky left-0  bg-gray-6 text-[14px]'>
									Total {datum.toLowerCase()}
								</td>
								{jsonReport.date_other.map((totalData,indexDate)=>(
									<td onMouseEnter={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right   ${indexDate==highlightRow ? 'bg-gray-6 ':''}`} >
										{!parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` :Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums    bg-gray-6`}>
									{!parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`]) ? '--' :Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])).toLocaleString('en-US')}
								</td>
							</tr>*/}
							<tr className='h-4'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky bg-light-purple-6 left-0'>
								{' '}
							</td>
							
							{jsonReport.date_other.map((date,indexTotalL0)=>(
								<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
									{' '}
								</td>
							))}
							<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
								{' '}
							</td>
							
						</tr>
						</>}
								
								</>//cierra l1
							))}
							

							<tr className=' '>
								<td className=' text-left left-0 bg-gray-6 text-h13-2xl 2xl:text-h11-2xl sticky' > {/*Total L0*/}
								<span className='ml-6'>{Idioma.reporte.total[language]} {data}</span>
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums bg-gray-6 text-h13-2xl text-right ${indexTotalL0>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
										{!parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` :Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])).toLocaleString('en-US')}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums text-h13-2xl bg-gray-6`}>
									{!parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])).toLocaleString('en-US')}
								</td>
							</tr>
							<tr className='h-4'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className=' left-0 bg-light-purple-6 sticky'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
							</tr>
							{indexL0 === 1 &&
							<>
							<tr className={`sticky top-[40px] z-10 hover:z-[11]`}>
								<td className='text-left sticky left-0 bg-gray-6 text-h13-2xl 2xl:text-h11-2xl flex items-center'>
								<span className='mx-2'><InfoI popDirection={false} texto={Idioma.reporte.infoFlujoAntesFinanciamiento[language]}/></span>{Idioma.reporte.flujoAntesFinanciamiento[language]}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=> (
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right text-p10-2xl bg-gray-6 ${indexTotalL0>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
										{!parseFloat(jsonReport.cash_flow_before_financing[`${jsonReport.date_year[indexTotalL0]}${date}Flujo antes de financiamiento`]) ? '--' : Math.round(parseFloat(jsonReport.cash_flow_before_financing[`${jsonReport.date_year[indexTotalL0]}${date}Flujo antes de financiamiento`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.cash_flow_before_financing[`${jsonReport.date_year[indexTotalL0]}${date}Flujo antes de financiamiento`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.cash_flow_before_financing[`${jsonReport.date_year[indexTotalL0]}${date}Flujo antes de financiamiento`][currentFx]))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(jsonReport.cash_flow_before_financing[`${jsonReport.date_year[indexTotalL0]}${date}Flujo antes de financiamiento`][currentFx])).toLocaleString('en-US')}
									</td>		
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right  tabular-nums text-h13-2xl bg-gray-6`}>
									{!parseFloat(jsonReport.cash_flow_before_financing[`cash_flow_before_financing_total`]) ? '--' : Math.round(parseFloat(jsonReport.cash_flow_before_financing[`cash_flow_before_financing_total`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.cash_flow_before_financing[`cash_flow_before_financing_total`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.cash_flow_before_financing[`cash_flow_before_financing_total`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.cash_flow_before_financing[`cash_flow_before_financing_total`][currentFx])).toLocaleString('en-US')}
								</td>
							</tr>
							<tr className='h-4'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 bg-light-purple-6'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
							</tr>

							</>
							}
							</>
						))}
						
						{jsonReport.currency_egl && 
						<tr className='text-left group bg-gray-6 '>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 bg-gray-6 text-h13-2xl 2xl:text-h11-2xl  flex items-center z-10'>
							<span className='mx-2'><InfoI popDirection={false} texto={Idioma.reporte.infoPerdidaUtilidad[language]}/></span>{Idioma.reporte.perdidaUtilidad[language]}{jsonReport.report_fx[0]==='MXN' ? '' : 'en MXN'}
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` text-p10-2xl tabular-nums text-right ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.currency_egl[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.currency_egl[`${jsonReport.date_year[indexDate]}${initBal}`])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.currency_egl[`${jsonReport.date_year[indexDate]}${initBal}`])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.currency_egl[`${jsonReport.date_year[indexDate]}${initBal}`])).toLocaleString('en-US'))==='0' ? '--' : Math.round(parseFloat(jsonReport.currency_egl[`${jsonReport.date_year[indexDate]}${initBal}`])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` text-h13-2xl tabular-nums text-right`}>
								{!parseFloat(jsonReport.currency_egl_horizontal_total[0]) ? '--' : Math.round(parseFloat(jsonReport.currency_egl_horizontal_total[0])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.currency_egl_horizontal_total[0])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.currency_egl_horizontal_total[0]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.currency_egl_horizontal_total[0])).toLocaleString('en-US')}
							</td>
						</tr>}
						<tr className='h-4'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className=' bg-light-purple-6 sticky left-0'>
								{' '}
							</td>
							{jsonReport.date_other.map((date,indexTotalL0)=>(
								<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
									{' '}
								</td>
							))}
							<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
								{' '}
							</td>
						</tr>
						
						<tr className='bg-gray-6 text-black sticky top-[18px] '>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 text-h13-2xl 2xl:text-h11-2xl flex items-center'>
								<span className='ml-6'>{Idioma.reporte.balanceEfectivo[language]}</span>
								</td>
								{jsonReport.date_other.map((i,indexVoid)=>(
									<td onMouseEnter={()=>setHighlightRow(indexVoid)}>{' '}</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_to_use.length)}>
									{' '}
								</td>
						</tr>
						<tr className='text-left hover:bg-light-purple-6 group '>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
								<span className='ml-11'>{Idioma.reporte.balanceInicial[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-p10-2xl text-right ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.initial_balance[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.initial_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.initial_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.initial_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.initial_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` tabular-nums text-h13-2xl text-right`}>
								{!parseFloat(jsonReport.initial_balance_horizontal_total[currentFx]) ? '--' : Math.round(parseFloat(jsonReport.initial_balance_horizontal_total[currentFx][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.initial_balance_horizontal_total[currentFx][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.initial_balance_horizontal_total[currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.initial_balance_horizontal_total[currentFx])).toLocaleString('en-US')}
							</td>
						</tr>
						<tr className='text-left hover:bg-light-purple-6 group text-p10-2xl 2xl:text-p8-2xl '>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
								<span className='ml-11'>{Idioma.reporte.flujoNeto[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.net_cash_flow[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' :  Math.round(parseFloat(jsonReport.net_cash_flow[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${ Math.round(parseFloat(jsonReport.net_cash_flow[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.net_cash_flow[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.net_cash_flow[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` tabular-nums text-right text-h13-2xl`}>
								{!parseFloat(jsonReport.net_cash_flow_horizontal_total[currentFx]) ? '--' : Math.round(parseFloat(jsonReport.net_cash_flow_horizontal_total[currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.net_cash_flow_horizontal_total[currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.net_cash_flow_horizontal_total[currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.net_cash_flow_horizontal_total[currentFx])).toLocaleString('en-US')}
							</td>
						</tr>
						<tr className='text-left hover:bg-light-purple-6 group '>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
								<span className='ml-11'>{Idioma.reporte.balanceFinal[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.final_balance[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.final_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.final_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.final_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.final_balance[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` tabular-nums text-right text-h13-2xl`}>
								{!parseFloat(jsonReport.final_balance_horizontal_total[currentFx]) ? '--' : Math.round(parseFloat(jsonReport.final_balance_horizontal_total[currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.final_balance_horizontal_total[currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.final_balance_horizontal_total[currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.final_balance_horizontal_total[currentFx])).toLocaleString('en-US')}
							</td>
						</tr>
						<tr className='h-8'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className=' bg-light-purple-6 sticky left-0'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
							</tr>
						{/*<tr className='h-4 bg-[#E6E6FF]'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 text-[16px] font-bold'>
								Conciliación
							</td>
							{jsonReport.date_other.map((date,indexTotalL0)=>(
								<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right`}>
									{' '}
								</td>
							))}
							<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums  `}>
								{' '}
							</td>
						</tr>
						<tr className='h-4'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className=' bg-light-purple-6 sticky left-0'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-gray-5 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
								</tr>*/}

						{conciliationsCat.map((data)=>(
							<>
							<tr className='bg-gray-6 text-black sticky top-[40px]'>
									<td onClick={()=>handleExpandCategoryL0(data)} onMouseEnter={()=>setHighlightRow(-1)} className=' bg-gray-6  sticky left-0 text-h13-2xl 2xl:text-h11-2xl flex items-center'>
									<span className='mx-2'><InfoI texto={data===Idioma.reporte.MovimientoEntreCuentas[language]?Idioma.reporte.infoMovimientosCuentas[language]:data===Idioma.reporte.cuentasCredito[language]?Idioma.reporte.infoCuentasCredito[language]:null}/></span>{data}
									</td>
									{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums bg-gray-6 text-right text-p10-2xl ${indexTotalL0>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
										{!parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL0_date_total[`${jsonReport.date_year[indexTotalL0]}${date}${data}`][currentFx])).toLocaleString('en-US')}
									</td>
									))}
									<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right text-h13-2xl tabular-nums`}>
										{!parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL0_horizontal_total[`${data}`][currentFx])).toLocaleString('en-US')}
									</td>
								</tr>
							{jsonReport.categoryL1[data]?.map((datum,indexL1)=>(
								<>
								{categoriesL1[datum]===0 ? 
								<>
								<tr className='text-left hover:bg-light-purple-6 group'>
									<td onMouseEnter={()=>setHighlightRow(-1)} onClick={()=>handleExpandCategoryL1(datum)} className=' sticky cursor-pointer left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl flex items-center'>
									<span className='mx-2'>+</span> <span className='mx-2 invisible'>-</span>{datum}{datum===Idioma.reporte.ingresoCredito[language]?<span className='mx-2'><InfoI texto={Idioma.reporte.infoIngresosCredito[language]}/></span>:null}{datum===Idioma.reporte.egresoCredito[language]?<span  className='mx-2'><InfoI texto={Idioma.reporte.infoEgresosCredito[language]}/></span>:null}
									</td>
									{jsonReport.date_other.map((totalData,indexDate)=>(
									<td onMouseEnter={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`} >
										{!parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
									))}
									<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums text-h13-2xl`}>
									{!parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`]) ? '--' :Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
								</tr>
								</>
								:<><tr className='  text-left hover:bg-light-purple-6  group'>
								<td onMouseEnter={()=>setHighlightRow(-1)} onClick={()=>handleExpandCategoryL1(datum)} className=' cursor-pointer sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl flex items-center'>
								<span className='mx-2 invisible'>+</span> <span className='mx-2'>-</span>{datum}{datum===Idioma.reporte.ingresoCredito[language]?<span className='mx-2'><InfoI texto={Idioma.reporte.infoIngresosCredito[language]}/></span>:null}{datum===Idioma.reporte.egresoCredito[language]?<span  className='mx-2'><InfoI texto={Idioma.reporte.infoEgresosCredito[language]}/></span>:null}
								</td>
								{jsonReport.date_other.map((totalData,indexDate)=>(
									<td onMouseEnter={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? 'bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`} >
										{!parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.abs(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums text-h13-2xl`}>
									{!parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`]) ? '--' :Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])).toLocaleString('en-US')}
								</td>
							</tr>
							{jsonReport.categoryL2[datum]?.map((info,indexL2)=>(
								<tr className=' text-left hover:bg-light-purple-6 group'>
									<td onMouseEnter={()=>setHighlightRow(-1)} className='pl-7 sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl' >
									<span className=' invisible mx-2'>+</span> <span className='mx-2 invisible'>-</span>{info}
									</td>
									{jsonReport.date_other.map((date_other_n,indexDate)=>(
										jsonReport["no_lineage "].includes(info) ?
										<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
											{!Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`])) ? '--' : Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])).toLocaleString('en-US')}
										</td>
										:
										<td onClick={(e)=>setLineage(data,datum,info,e,indexDate, (indexDate<=proyectionStartIndex), (indexDate>=proyectionStartIndex))} onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right ${userAccess.access2 && 'cursor-zoom-in'} text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
											{!Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`])) ? '--' : Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])) <0 ? `${numberFormat===0 ?'(':''}${Math.round((parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))*(numberFormat===0?-1:1)).toLocaleString("en-US")}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.transaction_value[`${jsonReport.date_year[indexDate]}${date_other_n}${data}${datum}${info}`][currentFx])).toLocaleString('en-US')}
										</td>
									))}
									<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` text-h13-2xl tabular-nums text-right`}>
										{!parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.categoryL2_horizontal_total[`${data}${datum}${info}`][currentFx])).toLocaleString('en-US')}
									</td>
								</tr>
							))}
							{/*<tr className='text-left hover:bg-gray-5 group'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='  pl-2 sticky left-0 group-hover:bg-gray-5 bg-light-purple-6 text-[14px]' >
									Total {datum.toLowerCase()}
								</td>
								{jsonReport.date_other.map((totalData,indexDate)=>(
									<td onMouseEnter={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right   ${indexDate==highlightRow ? 'bg-gray-6 ':''}`} >
										{!parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`]) ? '--' : Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` :Math.round(parseFloat(jsonReport.categoryL1_date_total[`${jsonReport.date_year[indexDate]}${totalData}${data}${datum}`][currentFx])).toLocaleString('en-US')}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums    bg-gray-6`}>
									{!parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`]) ? '--' :Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(parseFloat(jsonReport.categoryL1_horizontal_total[`${data}${datum}`][currentFx])).toLocaleString('en-US')}
								</td>
								</tr>*/}
							<tr className='h-2'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className=' bg-light-purple-6 sticky left-0'>
								{' '}
							</td>
							{jsonReport.date_other.map((date,indexTotalL0)=>(
								<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
									{' '}
								</td>
							))}
							<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
								{' '}
							</td>
						</tr>
						</>}
								
								</>//cierra l1
							))}

							<tr className='h-4'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className=' bg-light-purple-6 sticky left-0'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right   ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
							</tr>
							</>
						))}
						{jsonReport.new_accounts_after_start_date && 
						<>
							<tr className='text-left group bg-gray-6'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 bg-gray-6 text-h13-2xl 2xl:text-h11-2xl'>
								<span className='ml-6'>{Idioma.reporte.cuentasDebito[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` text-p10-2xl tabular-nums text-right ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.new_accounts_after_start_date[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date[`${jsonReport.date_year[indexDate]}${initBal}`])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.new_accounts_after_start_date[`${jsonReport.date_year[indexDate]}${initBal}`])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.new_accounts_after_start_date[`${jsonReport.date_year[indexDate]}${initBal}`]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date[`${jsonReport.date_year[indexDate]}${initBal}`])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` text-h13-2xl tabular-nums text-right`}>
								{!parseFloat(jsonReport.new_accounts_after_start_date_horizontal_total[0]) ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date_horizontal_total[0])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.new_accounts_after_start_date_horizontal_total[0])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.new_accounts_after_start_date_horizontal_total[0]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date_horizontal_total[0])).toLocaleString('en-US')}
							</td>
						</tr>
						<tr className='h-4'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 bg-light-purple-6'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''} ${indexTotalL0>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
							</tr>
						</>
						}
						{jsonReport.new_accounts_after_start_date_credit && 
						<>
							<tr className='text-left group bg-gray-6'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 bg-gray-6 text-h13-2xl 2xl:text-h11-2xl'>
								<span className='ml-6'>{Idioma.reporte.cuentasCredito[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` text-p10-2xl tabular-nums text-right ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.new_accounts_after_start_date_credit[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date_credit[`${jsonReport.date_year[indexDate]}${initBal}`])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.new_accounts_after_start_date_credit[`${jsonReport.date_year[indexDate]}${initBal}`])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.new_accounts_after_start_date_credit[`${jsonReport.date_year[indexDate]}${initBal}`]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date_credit[`${jsonReport.date_year[indexDate]}${initBal}`])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` text-h13-2xl tabular-nums text-right`}>
								{!parseFloat(jsonReport.new_accounts_after_start_date_credit_horizontal_total[0]) ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date_credit_horizontal_total[0])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.new_accounts_after_start_date_credit_horizontal_total[0])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.new_accounts_after_start_date_credit_horizontal_total[0]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.new_accounts_after_start_date_credit_horizontal_total[0])).toLocaleString('en-US')}
							</td>
						</tr>
						<tr className='h-4'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 bg-light-purple-6'>
									{' '}
								</td>
								{jsonReport.date_other.map((date,indexTotalL0)=>(
									<td onMouseEnter={()=>setHighlightRow(indexTotalL0)} className={` tabular-nums text-right ${indexTotalL0==highlightRow ? ' bg-light-purple-6 ':''}`}>
										{' '}
									</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_other.length)} className={`text-right tabular-nums`}>
									{' '}
								</td>
							</tr>
						</>
						}
						{jsonReport.initial_balance_credit && 
						<tr className='bg-gray-6 text-black sticky top-[18px]  cursor-pointer'>
								<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 text-h13-2xl 2xl:text-h11-2xl' onClick={()=>setOpenCredit(false)}>
								<span className='ml-6'>{Idioma.reporte.saldoCredito[language]}</span>
								</td>
								{jsonReport.date_other.map((i,indexVoid)=>(
									<td onMouseEnter={()=>setHighlightRow(indexVoid)}>{' '}</td>
								))}
								<td onMouseEnter={()=>setHighlightRow(jsonReport.date_to_use.length)}>
									{' '}
								</td>
						</tr>}
						{jsonReport.initial_balance_credit && 
						<tr className='text-left hover:bg-light-purple-6 group '>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
								<span className='ml-11'>{Idioma.reporte.saldoInicialCredito[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.initial_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.initial_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.initial_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.initial_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.initial_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` tabular-nums text-right text-h13-2xl`}>
								{!parseFloat(jsonReport.initial_balance_credit_horizontal_total[currentFx]) ? '--' : Math.round(parseFloat(jsonReport.initial_balance_credit_horizontal_total[currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.initial_balance_credit_horizontal_total[currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.initial_balance_credit_horizontal_total[currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.initial_balance_credit_horizontal_total[currentFx])).toLocaleString('en-US')}
							</td>
						</tr>}
						{jsonReport.net_cash_flow_credit &&
						<tr className='text-left hover:light-purple-6 group'>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
								<span className='ml-11'>{Idioma.reporte.movimientosCredito[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.net_cash_flow_credit[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' :  Math.round(parseFloat(jsonReport.net_cash_flow_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${ Math.round(parseFloat(jsonReport.net_cash_flow_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}` : Math.round(Math.abs(parseFloat(jsonReport.net_cash_flow_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.net_cash_flow_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` tabular-nums text-right text-h13-2xl`}>
								{!parseFloat(jsonReport.net_cash_flow_credit_horizontal_total[currentFx]) ? '--' : Math.round(parseFloat(jsonReport.net_cash_flow_credit_horizontal_total[currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.net_cash_flow_credit_horizontal_total[currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.net_cash_flow_credit_horizontal_total[currentFx]))).toLocaleString('en-US')==='0' ? '--' :  Math.round(parseFloat(jsonReport.net_cash_flow_credit_horizontal_total[currentFx])).toLocaleString('en-US')}
							</td>
						</tr>}
						{jsonReport.final_balance_credit &&
						<tr className='text-left hover:bg-light-purple-6 group '>
							<td onMouseEnter={()=>setHighlightRow(-1)} className='sticky left-0 group-hover:bg-light-purple-4 bg-light-purple-6 text-p10-2xl 2xl:text-p8-2xl'>
								<span className='ml-11'>{Idioma.reporte.saldoFinalCredito[language]}</span>
							</td>
							{jsonReport.date_other.map((initBal,indexDate)=> (
								<td onMouseOver={()=>setHighlightRow(indexDate)} className={` tabular-nums text-right text-p10-2xl ${indexDate==highlightRow ? ' bg-light-purple-6 ':''} ${indexDate>=proyectionStartIndex ? ' text-light-purple-2 ':''}`}>
									{!parseFloat(jsonReport.final_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`]) ? '--' : Math.round(parseFloat(jsonReport.final_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.final_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.final_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.final_balance_credit[`${jsonReport.date_year[indexDate]}${initBal}`][currentFx])).toLocaleString('en-US')}
								</td>
							))}
							<td onMouseOver={()=>setHighlightRow(jsonReport.date_other.length)} className={` tabular-nums text-right text-h13-2xl`}>
								{!parseFloat(jsonReport.final_balance_credit_horizontal_total[currentFx]) ? '--' : Math.round(parseFloat(jsonReport.final_balance_credit_horizontal_total[currentFx])) < 0 ? `${numberFormat===0 ?'(':''}${Math.round(parseFloat(jsonReport.final_balance_credit_horizontal_total[currentFx])*(numberFormat===0?-1:1)).toLocaleString('en-US')}${numberFormat===0 ?')':''}`: Math.round(Math.abs(parseFloat(jsonReport.final_balance_credit_horizontal_total[currentFx]))).toLocaleString('en-US')==='0' ? '--' : Math.round(parseFloat(jsonReport.final_balance_credit_horizontal_total[currentFx])).toLocaleString('en-US')}
							</td>
						</tr>} 
					</tbody>
					</table>
				</div>}
				<div className='absolute bottom-[40px] right-[40px] z-[101]'>
					{errorSign ?  <MessageToast type="error" title={Idioma.transacciones.mensajeError[language]} message={errorMessage} setSign={setErrorSign}/> : null}
					{showTransactionsUpdated ?  <MessageToast type='success' setSign={setShowTransactionsUpdated} title={Idioma.transacciones.transActualizadas[language]} message={updatedTransactions} /> : null}
					{showNeedUpdate ?  <MessageToast type='warning' setSign={setShowNeedUpdate} title={Idioma.planeacion.cambiosPendientes[language]} message={Idioma.planeacion.favorActualizar[language]} /> : null}
				</div>
        </div>
    )
}



export default ReportPlanning