import React from 'react'

const CleaningLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Enmascarar_grupo_33" data-name="Enmascarar grupo 33" transform="translate(-493 -392)" clip-path="url(#clip-path)">
            <g id="Grupo_630" data-name="Grupo 630" transform="translate(493 393)">
            <g id="Grupo_629" data-name="Grupo 629" clip-path="url(#clip-path-2)">
                <g id="Grupo_1104" data-name="Grupo 1104" transform="translate(3.868 0)">
                <g id="Grupo_1103" data-name="Grupo 1103" clip-path="url(#clip-path-3)">
                    <path id="Trazado_1323" data-name="Trazado 1323" d="M30.006,209.337v.134l.012-.074a.087.087,0,0,0-.012-.059m17.626-4.426h-16.7a.928.928,0,0,0-.928.928v2.783H48.56v-2.783a.928.928,0,0,0-.928-.928" transform="translate(-28.15 -192.238)" fill={props.fill || "#adadad"}/>
                    <path id="Trazado_1324" data-name="Trazado 1324" d="M135.006,13.111V2.783a2.783,2.783,0,0,1,5.566,0V13.111Z" transform="translate(-126.657)" fill={props.fill || "#adadad"}/>
                    <path id="Trazado_1325" data-name="Trazado 1325" d="M1.831,256.539a.129.129,0,0,0,.025-.074v-.079Zm20.422,10.906-1.675-10.052H1.688L.013,267.445a.928.928,0,0,0,.915,1.08H5.567v-6.494a.928.928,0,1,1,1.855,0v6.494h2.783v-6.494a.928.928,0,1,1,1.855,0v6.494h2.783v-6.494a.928.928,0,1,1,1.855,0v6.494h4.638a.928.928,0,0,0,.915-1.08" transform="translate(0 -240.531)" fill={props.fill || "#adadad"}/>
                </g>
                </g>
            </g>
            </g>
        </g>
    </svg>

  )
}

export default CleaningLogo