import React from 'react'

const CopyScenarioLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
        <g id="Enmascarar_grupo_86" data-name="Enmascarar grupo 86" transform="translate(-815 -294)" clip-path="url(#clip-path)">
            <g id="Grupo_1069" data-name="Grupo 1069" transform="translate(815.907 296.085)">
                <g id="Grupo_1068" data-name="Grupo 1068" clip-path="url(#clip-path-2)">
                    <path id="Trazado_1253" data-name="Trazado 1253" d="M133.889,109.708c1.037,0,2.076-.04,3.111.009a2.842,2.842,0,0,1,2.749,2.872q.033,2.992,0,5.985a2.859,2.859,0,0,1-2.868,2.857c-1.984.019-3.968,0-5.952.007a2.949,2.949,0,0,1-2.914-2.928c.008-1.62,0-3.241.006-4.862,0-.454-.013-.911.025-1.362a2.881,2.881,0,0,1,2.684-2.567c1.052-.034,2.106-.007,3.16-.007v0" transform="translate(-111.579 -95.61)" fill={props.fill || "#000"}/>
                    <path id="Trazado_1254" data-name="Trazado 1254" d="M52.833,7.461c-.11-.282-.211-.567-.33-.845a6.788,6.788,0,0,0-4.8-4.06,7.06,7.06,0,0,0-8.743,6.89c0,.406.01.814-.008,1.219a1.1,1.1,0,0,1-1.187,1.078,1.115,1.115,0,0,1-1.137-1.1,13.207,13.207,0,0,1,.278-3.553,9.37,9.37,0,0,1,2.381-4.2A9.308,9.308,0,0,1,44.187.19a9.22,9.22,0,0,1,5.052.384,8.907,8.907,0,0,1,3.686,2.452,8.906,8.906,0,0,1,1.991,3.369c.143.473.251.957.379,1.454.038-.032.082-.065.121-.1.129-.128.253-.262.385-.386a1.151,1.151,0,0,1,1.714,1.517,1.476,1.476,0,0,1-.145.172Q56.2,10.222,55.021,11.4a1.083,1.083,0,0,1-1.586.008c-.759-.754-1.51-1.516-2.265-2.273a1.241,1.241,0,0,1-.446-.925,1.142,1.142,0,0,1,.726-1.063,1.1,1.1,0,0,1,1.266.267.392.392,0,0,1,.058.1Z" transform="translate(-31.922 0)" fill={props.fill || "#000"}/>
                    <path id="Trazado_1255" data-name="Trazado 1255" d="M5.874,109.707c1.037,0,2.076-.04,3.111.009a2.842,2.842,0,0,1,2.749,2.872q.033,2.992,0,5.985a2.859,2.859,0,0,1-2.868,2.857c-1.984.019-3.968,0-5.952.007A2.949,2.949,0,0,1,0,118.509c.008-1.621,0-3.241.006-4.862,0-.454-.013-.911.025-1.362a2.881,2.881,0,0,1,2.684-2.567c1.052-.034,2.106-.007,3.16-.007v0m0,2.334v.014c-.995,0-1.99,0-2.985,0a.509.509,0,0,0-.542.53c0,.064,0,.128,0,.193q0,2.809.007,5.618a1.389,1.389,0,0,0,.022.255.467.467,0,0,0,.367.4,1.88,1.88,0,0,0,.413.039q2.8,0,5.6,0c.459,0,.647-.194.646-.66q0-1.894,0-3.788,0-.987,0-1.974a.525.525,0,0,0-.227-.516.989.989,0,0,0-.44-.107c-.952-.009-1.9,0-2.856,0" transform="translate(0 -95.609)" fill={props.fill || "#000"}/>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default CopyScenarioLogo