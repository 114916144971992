import { Player } from "@lottiefiles/react-lottie-player";
import Error from '../animations/404.json';

const FourOFour = () => {
    return(
        <div className="w-full h-[80%] flex justify-center items-center">
            <div className="flex flex-col justify-center">
                <Player
                    className="w-[50%]"
                    autoplay
                    loop
                    src={Error}
                ></Player>
                <div className="flex flex-col items-center pr-16">
                    <h2 className="font-bold text-3xl text-md-black-text text-center">¡Lo sentimos!</h2>
                    <p className="w-72 text-center text-lg font-bold">La Página que estás buscando no esta disponible</p>
                </div>
            </div>
        </div>
    );
}   

export default FourOFour;