import React from 'react'

const ExcelExportLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_32" data-name="Enmascarar grupo 32" transform="translate(-815 -294)" clip-path="url(#clip-path)">
        <g id="Grupo_483" data-name="Grupo 483" transform="translate(815 294.997)">
          <g id="Grupo_482" data-name="Grupo 482" transform="translate(0 0.003)" clip-path="url(#clip-path-2)">
            <path id="Trazado_712" data-name="Trazado 712" d="M15.174,274.748a1.391,1.391,0,0,0,.256-.8V261.293a1.389,1.389,0,0,0-1.39-1.39H1.39A1.389,1.389,0,0,0,0,261.293v12.651a1.39,1.39,0,0,0,1.39,1.39H14.041a1.388,1.388,0,0,0,1.134-.586" transform="translate(0 -253.462)" fill="#0c6b35"/>
            <rect id="Rectángulo_449" data-name="Rectángulo 449" width="11.194" height="6.923" transform="translate(18.806 14.156)" fill="#107c41"/>
            <rect id="Rectángulo_450" data-name="Rectángulo 450" width="11.194" height="6.958" transform="translate(18.806 7.199)" fill="#21a366"/>
            <path id="Trazado_713" data-name="Trazado 713" d="M768.4,0h-9.553V7.2h11.194V1.644A1.641,1.641,0,0,0,768.4,0" transform="translate(-740.045 -0.003)" fill="#33c481"/>
            <path id="Trazado_714" data-name="Trazado 714" d="M291.919,238.862V224.009a1.632,1.632,0,0,0-1.632-1.632h-8.15v.93h7.049a1.389,1.389,0,0,1,1.39,1.39v12.651a1.39,1.39,0,0,1-1.39,1.39h-7.049v1.757h8.15a1.632,1.632,0,0,0,1.632-1.632" transform="translate(-275.145 -216.866)" fill="#124228"/>
            <path id="Trazado_715" data-name="Trazado 715" d="M291.919,850.584v.917a1.632,1.632,0,0,1-1.632,1.632h-8.15v2.94a1.641,1.641,0,0,0,1.641,1.641H303.5a1.641,1.641,0,0,0,1.641-1.641v-5.489H291.919Z" transform="translate(-275.145 -829.505)" fill="#185c37"/>
            <rect id="Rectángulo_451" data-name="Rectángulo 451" width="2.032" height="6.923" transform="translate(16.774 14.156)" fill="#185c37"/>
            <rect id="Rectángulo_452" data-name="Rectángulo 452" width="2.032" height="6.958" transform="translate(16.774 7.199)" fill="#107c41"/>
            <path id="Trazado_716" data-name="Trazado 716" d="M291.919,7.147V7.2h2.032V0H283.778a1.641,1.641,0,0,0-1.641,1.641v3.87h8.15a1.632,1.632,0,0,1,1.632,1.632" transform="translate(-275.145 -0.004)" fill="#21a366"/>
            <path id="Trazado_717" data-name="Trazado 717" d="M168.661,400.276h-2.125l-1.594,2.542-1.595-2.542h-2.125l2.632,4.277-2.632,4.2h2.227l1.493-2.427,1.493,2.427h2.227l-2.632-4.2Z" transform="translate(-157.226 -390.357)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ExcelExportLogo
