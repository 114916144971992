import React from 'react'

const PPSect3Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_115" data-name="Enmascarar grupo 115" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1170" data-name="Grupo 1170" transform="translate(425.548 226)">
        <path id="Trazado_1449" data-name="Trazado 1449" d="M132.172,62.344h6.25a1.172,1.172,0,0,0,0-2.344h-6.25a1.172,1.172,0,1,0,0,2.344Z" transform="translate(-55.313 -55.313)"/>
        <path id="Trazado_1450" data-name="Trazado 1450" d="M132.172,122.344h6.25a1.172,1.172,0,0,0,0-2.344h-6.25a1.172,1.172,0,1,0,0,2.344Z" transform="translate(-55.313 -110.625)"/>
        <path id="Trazado_1451" data-name="Trazado 1451" d="M132.172,182.344h12.5a1.172,1.172,0,0,0,0-2.344h-12.5a1.172,1.172,0,0,0,0,2.344Z" transform="translate(-55.313 -165.938)"/>
        <path id="Trazado_1452" data-name="Trazado 1452" d="M145.844,241.172A1.172,1.172,0,0,0,144.672,240h-12.5a1.172,1.172,0,0,0,0,2.344h12.5A1.172,1.172,0,0,0,145.844,241.172Z" transform="translate(-55.313 -221.25)"/>
        <path id="Trazado_1453" data-name="Trazado 1453" d="M139.984,300h-7.812a1.172,1.172,0,0,0,0,2.344h7.812a1.172,1.172,0,0,0,0-2.344Z" transform="translate(-55.313 -276.563)"/>
        <path id="Trazado_1454" data-name="Trazado 1454" d="M96.781,14.085V9.955a3.492,3.492,0,0,0-1.03-2.486L89.312,1.03A3.492,3.492,0,0,0,86.826,0H74.516A3.52,3.52,0,0,0,71,3.516V26.953a3.52,3.52,0,0,0,3.516,3.516h4.305a8.912,8.912,0,0,0,2.335,5.661v2.7A1.172,1.172,0,0,0,82.328,40H96.391a1.172,1.172,0,0,0,1.172-1.172v-2.7a8.92,8.92,0,0,0,2.344-6.052v-12.5A3.52,3.52,0,0,0,96.781,14.085ZM88.969,4,92.78,7.812h-2.64a1.173,1.173,0,0,1-1.172-1.172ZM74.516,28.125a1.173,1.173,0,0,1-1.172-1.172V3.516a1.173,1.173,0,0,1,1.172-1.172H86.625v4.3a3.52,3.52,0,0,0,3.516,3.516h4.3v4.5a3.515,3.515,0,0,0-1.562,2.922v7.558a5.1,5.1,0,0,0-3.515,2.989Zm23.047,1.953a6.6,6.6,0,0,1-1.944,4.7l-.056.055a1.172,1.172,0,0,0-.344.829v2H83.5v-2a1.172,1.172,0,0,0-.344-.829l-.055-.055a6.592,6.592,0,0,1-1.933-4.3h7.8V33.2a1.172,1.172,0,1,0,2.344,0V30.078a2.737,2.737,0,0,1,2.734-2.734,1.172,1.172,0,0,0,1.172-1.172V17.578a1.172,1.172,0,1,1,2.344,0Z"/>
        </g>
    </g>
    </svg>
  )
}

export default PPSect3Logo