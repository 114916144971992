import React from 'react'

const ErrorLogo = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
  <g id="Grupo_1049" data-name="Grupo 1049" transform="translate(-1523 -883)">
    <path id="Trazado_1134" data-name="Trazado 1134" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1523 883)" fill={props.fill || "#fff"}/>
    <g id="Grupo_927" data-name="Grupo 927" transform="translate(1531.5 892.5)">
      <line id="Línea_63" data-name="Línea 63" x2="13" y2="11" fill="none" stroke={props.stroke || "#fff"} stroke-linecap="round" stroke-width="2"/>
      <line id="Línea_64" data-name="Línea 64" x1="13" y2="11" fill="none" stroke={props.stroke || "#fff"} stroke-linecap="round" stroke-width="2"/>
    </g>
  </g>
</svg>
  )
}

export default ErrorLogo