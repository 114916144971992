import React from 'react'

const InfoLogo = (props) => {
  return (
<svg id="Grupo_1059" data-name="Grupo 1059" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30" stroke={props.stroke || "#fff"} fill={props.fill || "#fff"}>
  <g id="Grupo_1058" data-name="Grupo 1058" clip-path="url(#clip-path)">
    <path id="Trazado_1140" data-name="Trazado 1140" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0m1.226,23.626a2.12,2.12,0,0,1-.723.729,2.032,2.032,0,0,1-2.467-3.189,2.031,2.031,0,0,1,2.872,0,2.02,2.02,0,0,1,.318,2.461m3.849-10.845a4.784,4.784,0,0,1-.914,1.512,6.183,6.183,0,0,1-1.394,1.15,4.923,4.923,0,0,0-1.087.888,2.661,2.661,0,0,0-.552,1.032,6.458,6.458,0,0,0-.2,1.633v.595H12.881v-.6a9.068,9.068,0,0,1,.367-2.75,4.455,4.455,0,0,1,1.04-1.753,7.986,7.986,0,0,1,1.528-1.175,3.911,3.911,0,0,0,.8-.645,2.751,2.751,0,0,0,.537-.826,2.645,2.645,0,0,0,.195-1.033,2.082,2.082,0,0,0-1.166-1.948,2.816,2.816,0,0,0-2.374-.033,2.1,2.1,0,0,0-.877.742,2.707,2.707,0,0,0-.414,1.376l-.032.466H9.346l.024-.524a5.3,5.3,0,0,1,.838-2.733A4.979,4.979,0,0,1,12.222,6.4a6.206,6.206,0,0,1,2.727-.6,5.964,5.964,0,0,1,2.849.657,4.812,4.812,0,0,1,1.916,1.824,5.112,5.112,0,0,1,.678,2.622,5.324,5.324,0,0,1-.317,1.872"/>
  </g>
</svg>


  )
}

export default InfoLogo