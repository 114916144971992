import { msalInstance } from "./index";
import { protectedResources } from "./authConfig";

export const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        account: account,
        scopes: protectedResources.apiTodoList.scopes.read
    });
    
    return response.accessToken;
}
