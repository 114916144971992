import React from 'react'

const MinimizeIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="4" viewBox="0 0 21 4">
      <line id="Línea_131" data-name="Línea 131" x1="17" transform="translate(2 2)" fill="none" stroke={props.stroke || "#fff"} stroke-linecap="round" stroke-width="4"/>
    </svg>
  )
}

export default MinimizeIcon