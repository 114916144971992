import React from 'react'

const TyCSect21Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
        <g id="Grupo_1142" data-name="Grupo 1142" transform="translate(-491 -226)" clip-path="url(#clip-path)">
            <g id="Grupo_1147" data-name="Grupo 1147" transform="translate(434.767 226)">
            <g id="Grupo_1144" data-name="Grupo 1144" transform="translate(60.999)">
                <g id="Grupo_1143" data-name="Grupo 1143" transform="translate(0)">
                <path id="Trazado_1436" data-name="Trazado 1436" d="M91.466,8.2a3.519,3.519,0,0,0-3.515-3.515H84.234A3.521,3.521,0,0,0,80.92,2.344H79.547a3.516,3.516,0,0,0-6.629,0H71.545a3.521,3.521,0,0,0-3.314,2.344H64.514A3.519,3.519,0,0,0,61,8.2v28.28A3.519,3.519,0,0,0,64.514,40H87.951a3.514,3.514,0,0,0,3.515-3.5ZM70.374,5.859a1.173,1.173,0,0,1,1.172-1.172h2.344a1.172,1.172,0,0,0,1.172-1.172,1.172,1.172,0,1,1,2.344,0,1.172,1.172,0,0,0,1.172,1.172H80.92a1.173,1.173,0,0,1,1.172,1.172V7.031H70.374Zm17.577,31.8H64.514a1.173,1.173,0,0,1-1.172-1.172V8.2a1.173,1.173,0,0,1,1.172-1.172H68.03V8.2A1.172,1.172,0,0,0,69.2,9.374H83.263A1.172,1.172,0,0,0,84.435,8.2V7.031h3.515A1.173,1.173,0,0,1,89.123,8.2V36.494A1.168,1.168,0,0,1,87.951,37.654Z" transform="translate(-60.999)"/>
                </g>
            </g>
            <g id="Grupo_1146" data-name="Grupo 1146" transform="translate(65.686 14.218)">
                <g id="Grupo_1145" data-name="Grupo 1145" transform="translate(0)">
                <path id="Trazado_1437" data-name="Trazado 1437" d="M142.033,189.645a1.172,1.172,0,0,0-.945-.8l-5.867-.856-2.625-5.338a1.172,1.172,0,0,0-2.1,0l-2.625,5.338-5.867.856a1.172,1.172,0,0,0-.65,2L125.6,195l-1,5.87a1.172,1.172,0,0,0,1.7,1.234l5.247-2.769,5.247,2.769a1.172,1.172,0,0,0,1.7-1.234l-1-5.87,4.248-4.156A1.172,1.172,0,0,0,142.033,189.645Zm-6.621,4.111a1.172,1.172,0,0,0-.336,1.035l.706,4.132-3.691-1.948a1.171,1.171,0,0,0-1.094,0l-3.691,1.948.706-4.132a1.172,1.172,0,0,0-.336-1.035l-3-2.93,4.135-.6a1.172,1.172,0,0,0,.882-.642l1.845-3.753,1.845,3.753a1.172,1.172,0,0,0,.882.642l4.135.6Z" transform="translate(-120.998 -182)"/>
                </g>
            </g>
            </g>
        </g>
    </svg>

  )
}

export default TyCSect21Logo