import React from 'react'

const PPSect7Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Grupo_119" data-name="Grupo 119" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1173" data-name="Grupo 1173" transform="translate(448.644 226)">
        <path id="Trazado_1468" data-name="Trazado 1468" d="M77.527,4.347A29.033,29.033,0,0,1,62.918.158a1.172,1.172,0,0,0-1.177,0A28.791,28.791,0,0,1,47.188,4.347a1.172,1.172,0,0,0-1.242,1.17V18.906c0,7.011,2.4,13.291,8.1,17.469A20.218,20.218,0,0,0,62.356,40a19.921,19.921,0,0,0,8.314-3.636c5.624-4.138,8.1-10.384,8.1-17.536V5.517a1.174,1.174,0,0,0-1.24-1.17Zm-1.1,14.481c0,6.857-2.377,12.1-7.064,15.59a18.78,18.78,0,0,1-7,3.217,18.84,18.84,0,0,1-7-3.208c-4.687-3.475-7.063-8.7-7.063-15.521V6.686A31.952,31.952,0,0,0,62.332,2.52,32.218,32.218,0,0,0,76.423,6.686V18.828Z"/>
        <path id="Trazado_1469" data-name="Trazado 1469" d="M153.294,111.859V114.2h-1.172a1.172,1.172,0,0,0-1.172,1.172v9.375a4.693,4.693,0,0,0,4.687,4.687h7.031a4.693,4.693,0,0,0,4.687-4.687v-9.375a1.172,1.172,0,0,0-1.172-1.172h-1.172v-2.344a5.859,5.859,0,1,0-11.719,0Zm11.719,12.891a2.346,2.346,0,0,1-2.344,2.344h-7.031a2.346,2.346,0,0,1-2.344-2.344v-8.2h11.719Zm-5.859-16.406a3.52,3.52,0,0,1,3.516,3.516V114.2h-7.031v-2.344A3.52,3.52,0,0,1,159.153,108.344Z" transform="translate(-96.801 -97.719)"/>
        <path id="Trazado_1470" data-name="Trazado 1470" d="M203.324,263.343l-3.859,3.859-1.515-1.515a1.172,1.172,0,1,0-1.657,1.657l2.344,2.344a1.172,1.172,0,0,0,1.657,0L204.981,265a1.172,1.172,0,1,0-1.657-1.657Z" transform="translate(-138.285 -242.453)"/>
        </g>
    </g>
    </svg>

  )
}

export default PPSect7Logo