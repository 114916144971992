import React from 'react'

const TyCSect17Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
        <g id="Enmascarar_grupo_112" data-name="Enmascarar grupo 112" transform="translate(-491 -226)" clip-path="url(#clip-path)">
            <g id="Grupo_1134" data-name="Grupo 1134" transform="translate(488.716 226)">
            <path id="Trazado_1417" data-name="Trazado 1417" d="M99.8,91a3.516,3.516,0,1,0,3.516,3.516A3.52,3.52,0,0,0,99.8,91Zm0,4.687a1.172,1.172,0,1,1,1.172-1.172A1.173,1.173,0,0,1,99.8,95.687Z" transform="translate(-86.481 -83.891)"/>
            <path id="Trazado_1418" data-name="Trazado 1418" d="M24.167,13.091l-2.576-2.006a8.24,8.24,0,0,0,0-.921l2.576-2.006L20.882,2.467,17.857,3.694a8.259,8.259,0,0,0-.8-.462L16.609,0H10.037L9.588,3.233a8.259,8.259,0,0,0-.8.462L5.765,2.467,2.479,8.158l2.576,2.006a8.24,8.24,0,0,0,0,.921L2.479,13.091l3.286,5.691,3.024-1.228a8.259,8.259,0,0,0,.8.462l.449,3.232h6.572l.449-3.232a8.259,8.259,0,0,0,.8-.462l3.024,1.228Zm-6.655,1.794a7.453,7.453,0,0,1-1.966,1.246l-.628.254-.35,2.52H12.078l-.35-2.52-.628-.254a7.457,7.457,0,0,1-1.966-1.246l-2.36.958L5.529,13.687l2.008-1.564a10.623,10.623,0,0,1-.151-1.5,10.625,10.625,0,0,1,.151-1.5L5.529,7.563,6.774,5.406l2.36.958A7.453,7.453,0,0,1,11.1,5.118l.628-.254.35-2.52h2.49l.35,2.52.628.254a7.457,7.457,0,0,1,1.966,1.246l2.36-.958,1.245,2.157L19.109,9.126a10.624,10.624,0,0,1,.151,1.5,10.626,10.626,0,0,1-.151,1.5l2.008,1.564-1.245,2.157Z"/>
            <path id="Trazado_1419" data-name="Trazado 1419" d="M324.016,324.714a3.516,3.516,0,1,0,3.516,3.516A3.52,3.52,0,0,0,324.016,324.714Zm0,4.687a1.172,1.172,0,1,1,1.172-1.172A1.173,1.173,0,0,1,324.016,329.4Z" transform="translate(-293.176 -299.346)"/>
            <path id="Trazado_1420" data-name="Trazado 1420" d="M241.324,240.466q0-.251-.015-.5l2.675-2.083-3.385-5.863-3.14,1.275a8.578,8.578,0,0,0-.875-.506l-.466-3.356h-6.77l-.466,3.356a8.585,8.585,0,0,0-.875.506l-3.14-1.275-3.385,5.863,2.675,2.083a8.531,8.531,0,0,0,0,1.01l-2.675,2.083,3.385,5.863,3.14-1.275a8.591,8.591,0,0,0,.875.506l.466,3.356h6.77l.466-3.356a8.584,8.584,0,0,0,.875-.506l3.14,1.275,3.385-5.863-2.675-2.083C241.319,240.8,241.324,240.633,241.324,240.466Zm-2.4-.872a7.8,7.8,0,0,1-.093,2.413l2.107,1.641-1.344,2.328-2.477-1.005a7.719,7.719,0,0,1-2.041,1.289l-.628.254-.367,2.645h-2.688l-.367-2.645-.628-.254a7.722,7.722,0,0,1-2.041-1.289l-2.477,1.005-1.344-2.328,2.107-1.641a7.793,7.793,0,0,1,0-3.084l-2.107-1.641,1.344-2.328,2.477,1.005a7.719,7.719,0,0,1,2.041-1.289l.628-.254.367-2.645h2.688l.367,2.645.628.254a7.721,7.721,0,0,1,2.041,1.289l2.477-1.005,1.344,2.328-2.107,1.641Z" transform="translate(-201.893 -211.504)"/>
            <path id="Trazado_1421" data-name="Trazado 1421" d="M49.34,334.08l1.023,1.03H49.241a3.527,3.527,0,0,1-3.523-3.523V328H43.375v3.587a5.873,5.873,0,0,0,5.866,5.866h1.122l-1.023,1.03L51,340.134l3.828-3.853L51,332.428Z" transform="translate(-37.701 -302.376)"/>
            <path id="Trazado_1422" data-name="Trazado 1422" d="M331.471,34.732l-1.023-1.03h1.122a3.527,3.527,0,0,1,3.523,3.523v3.587h2.344V37.225a5.873,5.873,0,0,0-5.866-5.866h-1.122l1.023-1.03-1.663-1.652L325.98,32.53l3.828,3.853Z" transform="translate(-298.228 -26.437)"/>
            </g>
        </g>
    </svg>

  )
}

export default TyCSect17Logo