import React from 'react'

const LapizLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.801" height="15.778" viewBox="0 0 15.801 15.778">
      <path id="Trazado_115" data-name="Trazado 115" d="M0,15.692a3.869,3.869,0,0,1,.111-.372q.669-1.638,1.346-3.273a3.266,3.266,0,0,1,.717-1.067L12.2.953A1.009,1.009,0,0,1,13.749.946q.845.842,1.687,1.687a1.012,1.012,0,0,1,0,1.559l-9.75,9.75a3.633,3.633,0,0,1-1.1.759q-1.749.777-3.5,1.555a1.441,1.441,0,0,1-.273.092A.653.653,0,0,1,0,15.692m4.9-2.44,8.322-8.324L11.462,3.171,3.139,11.494,4.9,13.252M2.443,12.3,1.311,15.052,4,13.858,2.443,12.3M12.993,1.653l-.753.752,1.754,1.755.75-.748L12.993,1.653" transform="translate(0 -0.589)" fill={props.fill}/>
    </svg>
  )
}

export default LapizLogo