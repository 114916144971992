import React from 'react'

const TyCSect12Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_107" data-name="Enmascarar grupo 107" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1129" data-name="Grupo 1129" transform="translate(491 226.001)">
        <path id="Trazado_1369" data-name="Trazado 1369" d="M81.5,195.144H94.055a1.172,1.172,0,1,0,0-2.344H81.5a1.172,1.172,0,1,0,0,2.344Zm0,0" transform="translate(-74.056 -177.739)"/>
        <path id="Trazado_1370" data-name="Trazado 1370" d="M89.035,257.066H81.5a1.172,1.172,0,0,0,0,2.344h7.531a1.172,1.172,0,1,0,0-2.344Zm0,0" transform="translate(-74.056 -236.984)"/>
        <path id="Trazado_1371" data-name="Trazado 1371" d="M89.035,321.332H81.5a1.172,1.172,0,0,0,0,2.344h7.531a1.172,1.172,0,1,0,0-2.344Zm0,0" transform="translate(-74.056 -296.229)"/>
        <path id="Trazado_1372" data-name="Trazado 1372" d="M89.035,385.6H81.5a1.172,1.172,0,0,0,0,2.344h7.531a1.172,1.172,0,1,0,0-2.344Zm0,0" transform="translate(-74.056 -355.478)"/>
        <path id="Trazado_1373" data-name="Trazado 1373" d="M29.957,17.633V3.682A3.684,3.684,0,0,0,26.275,0H8.7a1.171,1.171,0,0,0-.829.343L.343,7.874A1.171,1.171,0,0,0,0,8.7V36.316A3.686,3.686,0,0,0,3.682,40h25.1a11.213,11.213,0,0,0,1.172-22.365ZM8.786,2.746v4.7A1.34,1.34,0,0,1,7.447,8.786h-4.7Zm-5.1,34.909a1.34,1.34,0,0,1-1.338-1.338V11.13h5.1A3.687,3.687,0,0,0,11.13,7.448v-5.1H26.275a1.339,1.339,0,0,1,1.339,1.338V17.633a11.209,11.209,0,0,0-5.68,20.021Zm25.1,0a8.869,8.869,0,1,1,8.869-8.869A8.879,8.879,0,0,1,28.785,37.654Zm0,0"/>
        <path id="Trazado_1374" data-name="Trazado 1374" d="M358.406,294.22h-2.594v-3.849a1.172,1.172,0,0,0-2.344,0v5.021a1.172,1.172,0,0,0,1.172,1.172h3.765a1.172,1.172,0,1,0,0-2.344Zm0,0" transform="translate(-325.855 -266.607)"/>
        </g>
    </g>
    </svg>

  )
}

export default TyCSect12Logo