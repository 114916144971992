const fiscalWeeks = {
    "2020": {
        "1": {
            "es": "del 30 de diciembre al 5 de enero",
            "en": "from december 30 to january 5"
        },
        "2": {
            "es": "del 6 de enero al 12 de enero",
            "en": "from january 6 to january 12"
        },
        "3": {
            "es": "del 13 de enero al 19 de enero",
            "en": "from january 13 to january 19"
        },
        "4": {
            "es": "del 20 de enero al 26 de enero",
            "en": "from january 20 to january 26"
        },
        "5": {
            "es": "del 27 de enero al 2 de febrero",
            "en": "from january 27 to february 2"
        },
        "6": {
            "es": "del 3 de febrero al 9 de febrero",
            "en": "from february 3 to february 9"
        },
        "7": {
            "es": "del 10 de febrero al 16 de febrero",
            "en": "from february 10 to february 16"
        },
        "8": {
            "es": "del 17 de febrero al 23 de febrero",
            "en": "from february 17 to february 23"
        },
        "9": {
            "es": "del 24 de febrero al 1 de marzo",
            "en": "from february 24 to march 1"
        },
        "10": {
            "es": "del 2 de marzo al 8 de marzo",
            "en": "from march 2 to march 8"
        },
        "11": {
            "es": "del 9 de marzo al 15 de marzo",
            "en": "from march 9 to march 15"
        },
        "12": {
            "es": "del 16 de marzo al 22 de marzo",
            "en": "from march 16 to march 22"
        },
        "13": {
            "es": "del 23 de marzo al 29 de marzo",
            "en": "from march 23 to march 29"
        },
        "14": {
            "es": "del 30 de marzo al 5 de abril",
            "en": "from march 30 to april 5"
        },
        "15": {
            "es": "del 6 de abril al 12 de abril",
            "en": "from april 6 to april 12"
        },
        "16": {
            "es": "del 13 de abril al 19 de abril",
            "en": "from april 13 to april 19"
        },
        "17": {
            "es": "del 20 de abril al 26 de abril",
            "en": "from april 20 to april 26"
        },
        "18": {
            "es": "del 27 de abril al 3 de mayo",
            "en": "from april 27 to may 3"
        },
        "19": {
            "es": "del 4 de mayo al 10 de mayo",
            "en": "from may 4 to may 10"
        },
        "20": {
            "es": "del 11 de mayo al 17 de mayo",
            "en": "from may 11 to may 17"
        },
        "21": {
            "es": "del 18 de mayo al 24 de mayo",
            "en": "from may 18 to may 24"
        },
        "22": {
            "es": "del 25 de mayo al 31 de mayo",
            "en": "from may 25 to may 31"
        },
        "23": {
            "es": "del 1 de junio al 7 de junio",
            "en": "from june 1 to june 7"
        },
        "24": {
            "es": "del 8 de junio al 14 de junio",
            "en": "from june 8 to june 14"
        },
        "25": {
            "es": "del 15 de junio al 21 de junio",
            "en": "from june 15 to june 21"
        },
        "26": {
            "es": "del 22 de junio al 28 de junio",
            "en": "from june 22 to june 28"
        },
        "27": {
            "es": "del 29 de junio al 5 de julio",
            "en": "from june 29 to july 5"
        },
        "28": {
            "es": "del 6 de julio al 12 de julio",
            "en": "from july 6 to july 12"
        },
        "29": {
            "es": "del 13 de julio al 19 de julio",
            "en": "from july 13 to july 19"
        },
        "30": {
            "es": "del 20 de julio al 26 de julio",
            "en": "from july 20 to july 26"
        },
        "31": {
            "es": "del 27 de julio al 2 de agosto",
            "en": "from july 27 to august 2"
        },
        "32": {
            "es": "del 3 de agosto al 9 de agosto",
            "en": "from august 3 to august 9"
        },
        "33": {
            "es": "del 10 de agosto al 16 de agosto",
            "en": "from august 10 to august 16"
        },
        "34": {
            "es": "del 17 de agosto al 23 de agosto",
            "en": "from august 17 to august 23"
        },
        "35": {
            "es": "del 24 de agosto al 30 de agosto",
            "en": "from august 24 to august 30"
        },
        "36": {
            "es": "del 31 de agosto al 6 de septiembre",
            "en": "from august 31 to september 6"
        },
        "37": {
            "es": "del 7 de septiembre al 13 de septiembre",
            "en": "from september 7 to september 13"
        },
        "38": {
            "es": "del 14 de septiembre al 20 de septiembre",
            "en": "from september 14 to september 20"
        },
        "39": {
            "es": "del 21 de septiembre al 27 de septiembre",
            "en": "from september 21 to september 27"
        },
        "40": {
            "es": "del 28 de septiembre al 4 de octubre",
            "en": "from september 28 to october 4"
        },
        "41": {
            "es": "del 5 de octubre al 11 de octubre",
            "en": "from october 5 to october 11"
        },
        "42": {
            "es": "del 12 de octubre al 18 de octubre",
            "en": "from october 12 to october 18"
        },
        "43": {
            "es": "del 19 de octubre al 25 de octubre",
            "en": "from october 19 to october 25"
        },
        "44": {
            "es": "del 26 de octubre al 1 de noviembre",
            "en": "from october 26 to november 1"
        },
        "45": {
            "es": "del 2 de noviembre al 8 de noviembre",
            "en": "from november 2 to november 8"
        },
        "46": {
            "es": "del 9 de noviembre al 15 de noviembre",
            "en": "from november 9 to november 15"
        },
        "47": {
            "es": "del 16 de noviembre al 22 de noviembre",
            "en": "from november 16 to november 22"
        },
        "48": {
            "es": "del 23 de noviembre al 29 de noviembre",
            "en": "from november 23 to november 29"
        },
        "49": {
            "es": "del 30 de noviembre al 6 de diciembre",
            "en": "from november 30 to december 6"
        },
        "50": {
            "es": "del 7 de diciembre al 13 de diciembre",
            "en": "from december 7 to december 13"
        },
        "51": {
            "es": "del 14 de diciembre al 20 de diciembre",
            "en": "from december 14 to december 20"
        },
        "52": {
            "es": "del 21 de diciembre al 27 de diciembre",
            "en": "from december 21 to december 27"
        },
        "53": {
            "es": "del 28 de diciembre al 3 de enero",
            "en": "from december 28 to january 3"
        }
    },
    "2021": {
        "1": {
            "es": "del 4 de enero al 10 de enero",
            "en": "from january 4 to january 10"
        },
        "2": {
            "es": "del 11 de enero al 17 de enero",
            "en": "from january 11 to january 17"
        },
        "3": {
            "es": "del 18 de enero al 24 de enero",
            "en": "from january 18 to january 24"
        },
        "4": {
            "es": "del 25 de enero al 31 de enero",
            "en": "from january 25 to january 31"
        },
        "5": {
            "es": "del 1 de febrero al 7 de febrero",
            "en": "from february 1 to february 7"
        },
        "6": {
            "es": "del 8 de febrero al 14 de febrero",
            "en": "from february 8 to february 14"
        },
        "7": {
            "es": "del 15 de febrero al 21 de febrero",
            "en": "from february 15 to february 21"
        },
        "8": {
            "es": "del 22 de febrero al 28 de febrero",
            "en": "from february 22 to february 28"
        },
        "9": {
            "es": "del 1 de marzo al 7 de marzo",
            "en": "from march 1 to march 7"
        },
        "10": {
            "es": "del 8 de marzo al 14 de marzo",
            "en": "from march 8 to march 14"
        },
        "11": {
            "es": "del 15 de marzo al 21 de marzo",
            "en": "from march 15 to march 21"
        },
        "12": {
            "es": "del 22 de marzo al 28 de marzo",
            "en": "from march 22 to march 28"
        },
        "13": {
            "es": "del 29 de marzo al 4 de abril",
            "en": "from march 29 to april 4"
        },
        "14": {
            "es": "del 5 de abril al 11 de abril",
            "en": "from april 5 to april 11"
        },
        "15": {
            "es": "del 12 de abril al 18 de abril",
            "en": "from april 12 to april 18"
        },
        "16": {
            "es": "del 19 de abril al 25 de abril",
            "en": "from april 19 to april 25"
        },
        "17": {
            "es": "del 26 de abril al 2 de mayo",
            "en": "from april 26 to may 2"
        },
        "18": {
            "es": "del 3 de mayo al 9 de mayo",
            "en": "from may 3 to may 9"
        },
        "19": {
            "es": "del 10 de mayo al 16 de mayo",
            "en": "from may 10 to may 16"
        },
        "20": {
            "es": "del 17 de mayo al 23 de mayo",
            "en": "from may 17 to may 23"
        },
        "21": {
            "es": "del 24 de mayo al 30 de mayo",
            "en": "from may 24 to may 30"
        },
        "22": {
            "es": "del 31 de mayo al 6 de junio",
            "en": "from may 31 to june 6"
        },
        "23": {
            "es": "del 7 de junio al 13 de junio",
            "en": "from june 7 to june 13"
        },
        "24": {
            "es": "del 14 de junio al 20 de junio",
            "en": "from june 14 to june 20"
        },
        "25": {
            "es": "del 21 de junio al 27 de junio",
            "en": "from june 21 to june 27"
        },
        "26": {
            "es": "del 28 de junio al 4 de julio",
            "en": "from june 28 to july 4"
        },
        "27": {
            "es": "del 5 de julio al 11 de julio",
            "en": "from july 5 to july 11"
        },
        "28": {
            "es": "del 12 de julio al 18 de julio",
            "en": "from july 12 to july 18"
        },
        "29": {
            "es": "del 19 de julio al 25 de julio",
            "en": "from july 19 to july 25"
        },
        "30": {
            "es": "del 26 de julio al 1 de agosto",
            "en": "from july 26 to august 1"
        },
        "31": {
            "es": "del 2 de agosto al 8 de agosto",
            "en": "from august 2 to august 8"
        },
        "32": {
            "es": "del 9 de agosto al 15 de agosto",
            "en": "from august 9 to august 15"
        },
        "33": {
            "es": "del 16 de agosto al 22 de agosto",
            "en": "from august 16 to august 22"
        },
        "34": {
            "es": "del 23 de agosto al 29 de agosto",
            "en": "from august 23 to august 29"
        },
        "35": {
            "es": "del 30 de agosto al 5 de septiembre",
            "en": "from august 30 to september 5"
        },
        "36": {
            "es": "del 6 de septiembre al 12 de septiembre",
            "en": "from september 6 to september 12"
        },
        "37": {
            "es": "del 13 de septiembre al 19 de septiembre",
            "en": "from september 13 to september 19"
        },
        "38": {
            "es": "del 20 de septiembre al 26 de septiembre",
            "en": "from september 20 to september 26"
        },
        "39": {
            "es": "del 27 de septiembre al 3 de octubre",
            "en": "from september 27 to october 3"
        },
        "40": {
            "es": "del 4 de octubre al 10 de octubre",
            "en": "from october 4 to october 10"
        },
        "41": {
            "es": "del 11 de octubre al 17 de octubre",
            "en": "from october 11 to october 17"
        },
        "42": {
            "es": "del 18 de octubre al 24 de octubre",
            "en": "from october 18 to october 24"
        },
        "43": {
            "es": "del 25 de octubre al 31 de octubre",
            "en": "from october 25 to october 31"
        },
        "44": {
            "es": "del 1 de noviembre al 7 de noviembre",
            "en": "from november 1 to november 7"
        },
        "45": {
            "es": "del 8 de noviembre al 14 de noviembre",
            "en": "from november 8 to november 14"
        },
        "46": {
            "es": "del 15 de noviembre al 21 de noviembre",
            "en": "from november 15 to november 21"
        },
        "47": {
            "es": "del 22 de noviembre al 28 de noviembre",
            "en": "from november 22 to november 28"
        },
        "48": {
            "es": "del 29 de noviembre al 5 de diciembre",
            "en": "from november 29 to december 5"
        },
        "49": {
            "es": "del 6 de diciembre al 12 de diciembre",
            "en": "from december 6 to december 12"
        },
        "50": {
            "es": "del 13 de diciembre al 19 de diciembre",
            "en": "from december 13 to december 19"
        },
        "51": {
            "es": "del 20 de diciembre al 26 de diciembre",
            "en": "from december 20 to december 26"
        },
        "52": {
            "es": "del 27 de diciembre al 2 de enero",
            "en": "from december 27 to january 2"
        }
    },
    "2022": {
        "1": {
            "es": "del 3 de enero al 9 de enero",
            "en": "from january 3 to january 9"
        },
        "2": {
            "es": "del 10 de enero al 16 de enero",
            "en": "from january 10 to january 16"
        },
        "3": {
            "es": "del 17 de enero al 23 de enero",
            "en": "from january 17 to january 23"
        },
        "4": {
            "es": "del 24 de enero al 30 de enero",
            "en": "from january 24 to january 30"
        },
        "5": {
            "es": "del 31 de enero al 6 de febrero",
            "en": "from january 31 to february 6"
        },
        "6": {
            "es": "del 7 de febrero al 13 de febrero",
            "en": "from february 7 to february 13"
        },
        "7": {
            "es": "del 14 de febrero al 20 de febrero",
            "en": "from february 14 to february 20"
        },
        "8": {
            "es": "del 21 de febrero al 27 de febrero",
            "en": "from february 21 to february 27"
        },
        "9": {
            "es": "del 28 de febrero al 6 de marzo",
            "en": "from february 28 to march 6"
        },
        "10": {
            "es": "del 7 de marzo al 13 de marzo",
            "en": "from march 7 to march 13"
        },
        "11": {
            "es": "del 14 de marzo al 20 de marzo",
            "en": "from march 14 to march 20"
        },
        "12": {
            "es": "del 21 de marzo al 27 de marzo",
            "en": "from march 21 to march 27"
        },
        "13": {
            "es": "del 28 de marzo al 3 de abril",
            "en": "from march 28 to april 3"
        },
        "14": {
            "es": "del 4 de abril al 10 de abril",
            "en": "from april 4 to april 10"
        },
        "15": {
            "es": "del 11 de abril al 17 de abril",
            "en": "from april 11 to april 17"
        },
        "16": {
            "es": "del 18 de abril al 24 de abril",
            "en": "from april 18 to april 24"
        },
        "17": {
            "es": "del 25 de abril al 1 de mayo",
            "en": "from april 25 to may 1"
        },
        "18": {
            "es": "del 2 de mayo al 8 de mayo",
            "en": "from may 2 to may 8"
        },
        "19": {
            "es": "del 9 de mayo al 15 de mayo",
            "en": "from may 9 to may 15"
        },
        "20": {
            "es": "del 16 de mayo al 22 de mayo",
            "en": "from may 16 to may 22"
        },
        "21": {
            "es": "del 23 de mayo al 29 de mayo",
            "en": "from may 23 to may 29"
        },
        "22": {
            "es": "del 30 de mayo al 5 de junio",
            "en": "from may 30 to june 5"
        },
        "23": {
            "es": "del 6 de junio al 12 de junio",
            "en": "from june 6 to june 12"
        },
        "24": {
            "es": "del 13 de junio al 19 de junio",
            "en": "from june 13 to june 19"
        },
        "25": {
            "es": "del 20 de junio al 26 de junio",
            "en": "from june 20 to june 26"
        },
        "26": {
            "es": "del 27 de junio al 3 de julio",
            "en": "from june 27 to july 3"
        },
        "27": {
            "es": "del 4 de julio al 10 de julio",
            "en": "from july 4 to july 10"
        },
        "28": {
            "es": "del 11 de julio al 17 de julio",
            "en": "from july 11 to july 17"
        },
        "29": {
            "es": "del 18 de julio al 24 de julio",
            "en": "from july 18 to july 24"
        },
        "30": {
            "es": "del 25 de julio al 31 de julio",
            "en": "from july 25 to july 31"
        },
        "31": {
            "es": "del 1 de agosto al 7 de agosto",
            "en": "from august 1 to august 7"
        },
        "32": {
            "es": "del 8 de agosto al 14 de agosto",
            "en": "from august 8 to august 14"
        },
        "33": {
            "es": "del 15 de agosto al 21 de agosto",
            "en": "from august 15 to august 21"
        },
        "34": {
            "es": "del 22 de agosto al 28 de agosto",
            "en": "from august 22 to august 28"
        },
        "35": {
            "es": "del 29 de agosto al 4 de septiembre",
            "en": "from august 29 to september 4"
        },
        "36": {
            "es": "del 5 de septiembre al 11 de septiembre",
            "en": "from september 5 to september 11"
        },
        "37": {
            "es": "del 12 de septiembre al 18 de septiembre",
            "en": "from september 12 to september 18"
        },
        "38": {
            "es": "del 19 de septiembre al 25 de septiembre",
            "en": "from september 19 to september 25"
        },
        "39": {
            "es": "del 26 de septiembre al 2 de octubre",
            "en": "from september 26 to october 2"
        },
        "40": {
            "es": "del 3 de octubre al 9 de octubre",
            "en": "from october 3 to october 9"
        },
        "41": {
            "es": "del 10 de octubre al 16 de octubre",
            "en": "from october 10 to october 16"
        },
        "42": {
            "es": "del 17 de octubre al 23 de octubre",
            "en": "from october 17 to october 23"
        },
        "43": {
            "es": "del 24 de octubre al 30 de octubre",
            "en": "from october 24 to october 30"
        },
        "44": {
            "es": "del 31 de octubre al 6 de noviembre",
            "en": "from october 31 to november 6"
        },
        "45": {
            "es": "del 7 de noviembre al 13 de noviembre",
            "en": "from november 7 to november 13"
        },
        "46": {
            "es": "del 14 de noviembre al 20 de noviembre",
            "en": "from november 14 to november 20"
        },
        "47": {
            "es": "del 21 de noviembre al 27 de noviembre",
            "en": "from november 21 to november 27"
        },
        "48": {
            "es": "del 28 de noviembre al 4 de diciembre",
            "en": "from november 28 to december 4"
        },
        "49": {
            "es": "del 5 de diciembre al 11 de diciembre",
            "en": "from december 5 to december 11"
        },
        "50": {
            "es": "del 12 de diciembre al 18 de diciembre",
            "en": "from december 12 to december 18"
        },
        "51": {
            "es": "del 19 de diciembre al 25 de diciembre",
            "en": "from december 19 to december 25"
        },
        "52": {
            "es": "del 26 de diciembre al 1 de enero",
            "en": "from december 26 to january 1"
        }
    },
    "2023": {
        "1": {
            "es": "del 2 de enero al 8 de enero",
            "en": "from january 2 to january 8"
        },
        "2": {
            "es": "del 9 de enero al 15 de enero",
            "en": "from january 9 to january 15"
        },
        "3": {
            "es": "del 16 de enero al 22 de enero",
            "en": "from january 16 to january 22"
        },
        "4": {
            "es": "del 23 de enero al 29 de enero",
            "en": "from january 23 to january 29"
        },
        "5": {
            "es": "del 30 de enero al 5 de febrero",
            "en": "from january 30 to february 5"
        },
        "6": {
            "es": "del 6 de febrero al 12 de febrero",
            "en": "from february 6 to february 12"
        },
        "7": {
            "es": "del 13 de febrero al 19 de febrero",
            "en": "from february 13 to february 19"
        },
        "8": {
            "es": "del 20 de febrero al 26 de febrero",
            "en": "from february 20 to february 26"
        },
        "9": {
            "es": "del 27 de febrero al 5 de marzo",
            "en": "from february 27 to march 5"
        },
        "10": {
            "es": "del 6 de marzo al 12 de marzo",
            "en": "from march 6 to march 12"
        },
        "11": {
            "es": "del 13 de marzo al 19 de marzo",
            "en": "from march 13 to march 19"
        },
        "12": {
            "es": "del 20 de marzo al 26 de marzo",
            "en": "from march 20 to march 26"
        },
        "13": {
            "es": "del 27 de marzo al 2 de abril",
            "en": "from march 27 to april 2"
        },
        "14": {
            "es": "del 3 de abril al 9 de abril",
            "en": "from april 3 to april 9"
        },
        "15": {
            "es": "del 10 de abril al 16 de abril",
            "en": "from april 10 to april 16"
        },
        "16": {
            "es": "del 17 de abril al 23 de abril",
            "en": "from april 17 to april 23"
        },
        "17": {
            "es": "del 24 de abril al 30 de abril",
            "en": "from april 24 to april 30"
        },
        "18": {
            "es": "del 1 de mayo al 7 de mayo",
            "en": "from may 1 to may 7"
        },
        "19": {
            "es": "del 8 de mayo al 14 de mayo",
            "en": "from may 8 to may 14"
        },
        "20": {
            "es": "del 15 de mayo al 21 de mayo",
            "en": "from may 15 to may 21"
        },
        "21": {
            "es": "del 22 de mayo al 28 de mayo",
            "en": "from may 22 to may 28"
        },
        "22": {
            "es": "del 29 de mayo al 4 de junio",
            "en": "from may 29 to june 4"
        },
        "23": {
            "es": "del 5 de junio al 11 de junio",
            "en": "from june 5 to june 11"
        },
        "24": {
            "es": "del 12 de junio al 18 de junio",
            "en": "from june 12 to june 18"
        },
        "25": {
            "es": "del 19 de junio al 25 de junio",
            "en": "from june 19 to june 25"
        },
        "26": {
            "es": "del 26 de junio al 2 de julio",
            "en": "from june 26 to july 2"
        },
        "27": {
            "es": "del 3 de julio al 9 de julio",
            "en": "from july 3 to july 9"
        },
        "28": {
            "es": "del 10 de julio al 16 de julio",
            "en": "from july 10 to july 16"
        },
        "29": {
            "es": "del 17 de julio al 23 de julio",
            "en": "from july 17 to july 23"
        },
        "30": {
            "es": "del 24 de julio al 30 de julio",
            "en": "from july 24 to july 30"
        },
        "31": {
            "es": "del 31 de julio al 6 de agosto",
            "en": "from july 31 to august 6"
        },
        "32": {
            "es": "del 7 de agosto al 13 de agosto",
            "en": "from august 7 to august 13"
        },
        "33": {
            "es": "del 14 de agosto al 20 de agosto",
            "en": "from august 14 to august 20"
        },
        "34": {
            "es": "del 21 de agosto al 27 de agosto",
            "en": "from august 21 to august 27"
        },
        "35": {
            "es": "del 28 de agosto al 3 de septiembre",
            "en": "from august 28 to september 3"
        },
        "36": {
            "es": "del 4 de septiembre al 10 de septiembre",
            "en": "from september 4 to september 10"
        },
        "37": {
            "es": "del 11 de septiembre al 17 de septiembre",
            "en": "from september 11 to september 17"
        },
        "38": {
            "es": "del 18 de septiembre al 24 de septiembre",
            "en": "from september 18 to september 24"
        },
        "39": {
            "es": "del 25 de septiembre al 1 de octubre",
            "en": "from september 25 to october 1"
        },
        "40": {
            "es": "del 2 de octubre al 8 de octubre",
            "en": "from october 2 to october 8"
        },
        "41": {
            "es": "del 9 de octubre al 15 de octubre",
            "en": "from october 9 to october 15"
        },
        "42": {
            "es": "del 16 de octubre al 22 de octubre",
            "en": "from october 16 to october 22"
        },
        "43": {
            "es": "del 23 de octubre al 29 de octubre",
            "en": "from october 23 to october 29"
        },
        "44": {
            "es": "del 30 de octubre al 5 de noviembre",
            "en": "from october 30 to november 5"
        },
        "45": {
            "es": "del 6 de noviembre al 12 de noviembre",
            "en": "from november 6 to november 12"
        },
        "46": {
            "es": "del 13 de noviembre al 19 de noviembre",
            "en": "from november 13 to november 19"
        },
        "47": {
            "es": "del 20 de noviembre al 26 de noviembre",
            "en": "from november 20 to november 26"
        },
        "48": {
            "es": "del 27 de noviembre al 3 de diciembre",
            "en": "from november 27 to december 3"
        },
        "49": {
            "es": "del 4 de diciembre al 10 de diciembre",
            "en": "from december 4 to december 10"
        },
        "50": {
            "es": "del 11 de diciembre al 17 de diciembre",
            "en": "from december 11 to december 17"
        },
        "51": {
            "es": "del 18 de diciembre al 24 de diciembre",
            "en": "from december 18 to december 24"
        },
        "52": {
            "es": "del 25 de diciembre al 31 de diciembre",
            "en": "from december 25 to december 31"
        }
    },
    "2024": {
        "1": {
            "es": "del 1 de enero al 7 de enero",
            "en": "from january 1 to january 7"
        },
        "2": {
            "es": "del 8 de enero al 14 de enero",
            "en": "from january 8 to january 14"
        },
        "3": {
            "es": "del 15 de enero al 21 de enero",
            "en": "from january 15 to january 21"
        },
        "4": {
            "es": "del 22 de enero al 28 de enero",
            "en": "from january 22 to january 28"
        },
        "5": {
            "es": "del 29 de enero al 4 de febrero",
            "en": "from january 29 to february 4"
        },
        "6": {
            "es": "del 5 de febrero al 11 de febrero",
            "en": "from february 5 to february 11"
        },
        "7": {
            "es": "del 12 de febrero al 18 de febrero",
            "en": "from february 12 to february 18"
        },
        "8": {
            "es": "del 19 de febrero al 25 de febrero",
            "en": "from february 19 to february 25"
        },
        "9": {
            "es": "del 26 de febrero al 3 de marzo",
            "en": "from february 26 to march 3"
        },
        "10": {
            "es": "del 4 de marzo al 10 de marzo",
            "en": "from march 4 to march 10"
        },
        "11": {
            "es": "del 11 de marzo al 17 de marzo",
            "en": "from march 11 to march 17"
        },
        "12": {
            "es": "del 18 de marzo al 24 de marzo",
            "en": "from march 18 to march 24"
        },
        "13": {
            "es": "del 25 de marzo al 31 de marzo",
            "en": "from march 25 to march 31"
        },
        "14": {
            "es": "del 1 de abril al 7 de abril",
            "en": "from april 1 to april 7"
        },
        "15": {
            "es": "del 8 de abril al 14 de abril",
            "en": "from april 8 to april 14"
        },
        "16": {
            "es": "del 15 de abril al 21 de abril",
            "en": "from april 15 to april 21"
        },
        "17": {
            "es": "del 22 de abril al 28 de abril",
            "en": "from april 22 to april 28"
        },
        "18": {
            "es": "del 29 de abril al 5 de mayo",
            "en": "from april 29 to may 5"
        },
        "19": {
            "es": "del 6 de mayo al 12 de mayo",
            "en": "from may 6 to may 12"
        },
        "20": {
            "es": "del 13 de mayo al 19 de mayo",
            "en": "from may 13 to may 19"
        },
        "21": {
            "es": "del 20 de mayo al 26 de mayo",
            "en": "from may 20 to may 26"
        },
        "22": {
            "es": "del 27 de mayo al 2 de junio",
            "en": "from may 27 to june 2"
        },
        "23": {
            "es": "del 3 de junio al 9 de junio",
            "en": "from june 3 to june 9"
        },
        "24": {
            "es": "del 10 de junio al 16 de junio",
            "en": "from june 10 to june 16"
        },
        "25": {
            "es": "del 17 de junio al 23 de junio",
            "en": "from june 17 to june 23"
        },
        "26": {
            "es": "del 24 de junio al 30 de junio",
            "en": "from june 24 to june 30"
        },
        "27": {
            "es": "del 1 de julio al 7 de julio",
            "en": "from july 1 to july 7"
        },
        "28": {
            "es": "del 8 de julio al 14 de julio",
            "en": "from july 8 to july 14"
        },
        "29": {
            "es": "del 15 de julio al 21 de julio",
            "en": "from july 15 to july 21"
        },
        "30": {
            "es": "del 22 de julio al 28 de julio",
            "en": "from july 22 to july 28"
        },
        "31": {
            "es": "del 29 de julio al 4 de agosto",
            "en": "from july 29 to august 4"
        },
        "32": {
            "es": "del 5 de agosto al 11 de agosto",
            "en": "from august 5 to august 11"
        },
        "33": {
            "es": "del 12 de agosto al 18 de agosto",
            "en": "from august 12 to august 18"
        },
        "34": {
            "es": "del 19 de agosto al 25 de agosto",
            "en": "from august 19 to august 25"
        },
        "35": {
            "es": "del 26 de agosto al 1 de septiembre",
            "en": "from august 26 to september 1"
        },
        "36": {
            "es": "del 2 de septiembre al 8 de septiembre",
            "en": "from september 2 to september 8"
        },
        "37": {
            "es": "del 9 de septiembre al 15 de septiembre",
            "en": "from september 9 to september 15"
        },
        "38": {
            "es": "del 16 de septiembre al 22 de septiembre",
            "en": "from september 16 to september 22"
        },
        "39": {
            "es": "del 23 de septiembre al 29 de septiembre",
            "en": "from september 23 to september 29"
        },
        "40": {
            "es": "del 30 de septiembre al 6 de octubre",
            "en": "from september 30 to october 6"
        },
        "41": {
            "es": "del 7 de octubre al 13 de octubre",
            "en": "from october 7 to october 13"
        },
        "42": {
            "es": "del 14 de octubre al 20 de octubre",
            "en": "from october 14 to october 20"
        },
        "43": {
            "es": "del 21 de octubre al 27 de octubre",
            "en": "from october 21 to october 27"
        },
        "44": {
            "es": "del 28 de octubre al 3 de noviembre",
            "en": "from october 28 to november 3"
        },
        "45": {
            "es": "del 4 de noviembre al 10 de noviembre",
            "en": "from november 4 to november 10"
        },
        "46": {
            "es": "del 11 de noviembre al 17 de noviembre",
            "en": "from november 11 to november 17"
        },
        "47": {
            "es": "del 18 de noviembre al 24 de noviembre",
            "en": "from november 18 to november 24"
        },
        "48": {
            "es": "del 25 de noviembre al 1 de diciembre",
            "en": "from november 25 to december 1"
        },
        "49": {
            "es": "del 2 de diciembre al 8 de diciembre",
            "en": "from december 2 to december 8"
        },
        "50": {
            "es": "del 9 de diciembre al 15 de diciembre",
            "en": "from december 9 to december 15"
        },
        "51": {
            "es": "del 16 de diciembre al 22 de diciembre",
            "en": "from december 16 to december 22"
        },
        "52": {
            "es": "del 23 de diciembre al 29 de diciembre",
            "en": "from december 23 to december 29"
        }
    },
    "2025": {
        "1": {
            "es": "del 30 de diciembre al 5 de enero",
            "en": "from december 30 to january 5"
        },
        "2": {
            "es": "del 6 de enero al 12 de enero",
            "en": "from january 6 to january 12"
        },
        "3": {
            "es": "del 13 de enero al 19 de enero",
            "en": "from january 13 to january 19"
        },
        "4": {
            "es": "del 20 de enero al 26 de enero",
            "en": "from january 20 to january 26"
        },
        "5": {
            "es": "del 27 de enero al 2 de febrero",
            "en": "from january 27 to february 2"
        },
        "6": {
            "es": "del 3 de febrero al 9 de febrero",
            "en": "from february 3 to february 9"
        },
        "7": {
            "es": "del 10 de febrero al 16 de febrero",
            "en": "from february 10 to february 16"
        },
        "8": {
            "es": "del 17 de febrero al 23 de febrero",
            "en": "from february 17 to february 23"
        },
        "9": {
            "es": "del 24 de febrero al 2 de marzo",
            "en": "from february 24 to march 2"
        },
        "10": {
            "es": "del 3 de marzo al 9 de marzo",
            "en": "from march 3 to march 9"
        },
        "11": {
            "es": "del 10 de marzo al 16 de marzo",
            "en": "from march 10 to march 16"
        },
        "12": {
            "es": "del 17 de marzo al 23 de marzo",
            "en": "from march 17 to march 23"
        },
        "13": {
            "es": "del 24 de marzo al 30 de marzo",
            "en": "from march 24 to march 30"
        },
        "14": {
            "es": "del 31 de marzo al 6 de abril",
            "en": "from march 31 to april 6"
        },
        "15": {
            "es": "del 7 de abril al 13 de abril",
            "en": "from april 7 to april 13"
        },
        "16": {
            "es": "del 14 de abril al 20 de abril",
            "en": "from april 14 to april 20"
        },
        "17": {
            "es": "del 21 de abril al 27 de abril",
            "en": "from april 21 to april 27"
        },
        "18": {
            "es": "del 28 de abril al 4 de mayo",
            "en": "from april 28 to may 4"
        },
        "19": {
            "es": "del 5 de mayo al 11 de mayo",
            "en": "from may 5 to may 11"
        },
        "20": {
            "es": "del 12 de mayo al 18 de mayo",
            "en": "from may 12 to may 18"
        },
        "21": {
            "es": "del 19 de mayo al 25 de mayo",
            "en": "from may 19 to may 25"
        },
        "22": {
            "es": "del 26 de mayo al 1 de junio",
            "en": "from may 26 to june 1"
        },
        "23": {
            "es": "del 2 de junio al 8 de junio",
            "en": "from june 2 to june 8"
        },
        "24": {
            "es": "del 9 de junio al 15 de junio",
            "en": "from june 9 to june 15"
        },
        "25": {
            "es": "del 16 de junio al 22 de junio",
            "en": "from june 16 to june 22"
        },
        "26": {
            "es": "del 23 de junio al 29 de junio",
            "en": "from june 23 to june 29"
        },
        "27": {
            "es": "del 30 de junio al 6 de julio",
            "en": "from june 30 to july 6"
        },
        "28": {
            "es": "del 7 de julio al 13 de julio",
            "en": "from july 7 to july 13"
        },
        "29": {
            "es": "del 14 de julio al 20 de julio",
            "en": "from july 14 to july 20"
        },
        "30": {
            "es": "del 21 de julio al 27 de julio",
            "en": "from july 21 to july 27"
        },
        "31": {
            "es": "del 28 de julio al 3 de agosto",
            "en": "from july 28 to august 3"
        },
        "32": {
            "es": "del 4 de agosto al 10 de agosto",
            "en": "from august 4 to august 10"
        },
        "33": {
            "es": "del 11 de agosto al 17 de agosto",
            "en": "from august 11 to august 17"
        },
        "34": {
            "es": "del 18 de agosto al 24 de agosto",
            "en": "from august 18 to august 24"
        },
        "35": {
            "es": "del 25 de agosto al 31 de agosto",
            "en": "from august 25 to august 31"
        },
        "36": {
            "es": "del 1 de septiembre al 7 de septiembre",
            "en": "from september 1 to september 7"
        },
        "37": {
            "es": "del 8 de septiembre al 14 de septiembre",
            "en": "from september 8 to september 14"
        },
        "38": {
            "es": "del 15 de septiembre al 21 de septiembre",
            "en": "from september 15 to september 21"
        },
        "39": {
            "es": "del 22 de septiembre al 28 de septiembre",
            "en": "from september 22 to september 28"
        },
        "40": {
            "es": "del 29 de septiembre al 5 de octubre",
            "en": "from september 29 to october 5"
        },
        "41": {
            "es": "del 6 de octubre al 12 de octubre",
            "en": "from october 6 to october 12"
        },
        "42": {
            "es": "del 13 de octubre al 19 de octubre",
            "en": "from october 13 to october 19"
        },
        "43": {
            "es": "del 20 de octubre al 26 de octubre",
            "en": "from october 20 to october 26"
        },
        "44": {
            "es": "del 27 de octubre al 2 de noviembre",
            "en": "from october 27 to november 2"
        },
        "45": {
            "es": "del 3 de noviembre al 9 de noviembre",
            "en": "from november 3 to november 9"
        },
        "46": {
            "es": "del 10 de noviembre al 16 de noviembre",
            "en": "from november 10 to november 16"
        },
        "47": {
            "es": "del 17 de noviembre al 23 de noviembre",
            "en": "from november 17 to november 23"
        },
        "48": {
            "es": "del 24 de noviembre al 30 de noviembre",
            "en": "from november 24 to november 30"
        },
        "49": {
            "es": "del 1 de diciembre al 7 de diciembre",
            "en": "from december 1 to december 7"
        },
        "50": {
            "es": "del 8 de diciembre al 14 de diciembre",
            "en": "from december 8 to december 14"
        },
        "51": {
            "es": "del 15 de diciembre al 21 de diciembre",
            "en": "from december 15 to december 21"
        },
        "52": {
            "es": "del 22 de diciembre al 28 de diciembre",
            "en": "from december 22 to december 28"
        }
    },
    "2026": {
        "1": {
            "es": "del 29 de diciembre al 4 de enero",
            "en": "from december 29 to january 4"
        },
        "2": {
            "es": "del 5 de enero al 11 de enero",
            "en": "from january 5 to january 11"
        },
        "3": {
            "es": "del 12 de enero al 18 de enero",
            "en": "from january 12 to january 18"
        },
        "4": {
            "es": "del 19 de enero al 25 de enero",
            "en": "from january 19 to january 25"
        },
        "5": {
            "es": "del 26 de enero al 1 de febrero",
            "en": "from january 26 to february 1"
        },
        "6": {
            "es": "del 2 de febrero al 8 de febrero",
            "en": "from february 2 to february 8"
        },
        "7": {
            "es": "del 9 de febrero al 15 de febrero",
            "en": "from february 9 to february 15"
        },
        "8": {
            "es": "del 16 de febrero al 22 de febrero",
            "en": "from february 16 to february 22"
        },
        "9": {
            "es": "del 23 de febrero al 1 de marzo",
            "en": "from february 23 to march 1"
        },
        "10": {
            "es": "del 2 de marzo al 8 de marzo",
            "en": "from march 2 to march 8"
        },
        "11": {
            "es": "del 9 de marzo al 15 de marzo",
            "en": "from march 9 to march 15"
        },
        "12": {
            "es": "del 16 de marzo al 22 de marzo",
            "en": "from march 16 to march 22"
        },
        "13": {
            "es": "del 23 de marzo al 29 de marzo",
            "en": "from march 23 to march 29"
        },
        "14": {
            "es": "del 30 de marzo al 5 de abril",
            "en": "from march 30 to april 5"
        },
        "15": {
            "es": "del 6 de abril al 12 de abril",
            "en": "from april 6 to april 12"
        },
        "16": {
            "es": "del 13 de abril al 19 de abril",
            "en": "from april 13 to april 19"
        },
        "17": {
            "es": "del 20 de abril al 26 de abril",
            "en": "from april 20 to april 26"
        },
        "18": {
            "es": "del 27 de abril al 3 de mayo",
            "en": "from april 27 to may 3"
        },
        "19": {
            "es": "del 4 de mayo al 10 de mayo",
            "en": "from may 4 to may 10"
        },
        "20": {
            "es": "del 11 de mayo al 17 de mayo",
            "en": "from may 11 to may 17"
        },
        "21": {
            "es": "del 18 de mayo al 24 de mayo",
            "en": "from may 18 to may 24"
        },
        "22": {
            "es": "del 25 de mayo al 31 de mayo",
            "en": "from may 25 to may 31"
        },
        "23": {
            "es": "del 1 de junio al 7 de junio",
            "en": "from june 1 to june 7"
        },
        "24": {
            "es": "del 8 de junio al 14 de junio",
            "en": "from june 8 to june 14"
        },
        "25": {
            "es": "del 15 de junio al 21 de junio",
            "en": "from june 15 to june 21"
        },
        "26": {
            "es": "del 22 de junio al 28 de junio",
            "en": "from june 22 to june 28"
        },
        "27": {
            "es": "del 29 de junio al 5 de julio",
            "en": "from june 29 to july 5"
        },
        "28": {
            "es": "del 6 de julio al 12 de julio",
            "en": "from july 6 to july 12"
        },
        "29": {
            "es": "del 13 de julio al 19 de julio",
            "en": "from july 13 to july 19"
        },
        "30": {
            "es": "del 20 de julio al 26 de julio",
            "en": "from july 20 to july 26"
        },
        "31": {
            "es": "del 27 de julio al 2 de agosto",
            "en": "from july 27 to august 2"
        },
        "32": {
            "es": "del 3 de agosto al 9 de agosto",
            "en": "from august 3 to august 9"
        },
        "33": {
            "es": "del 10 de agosto al 16 de agosto",
            "en": "from august 10 to august 16"
        },
        "34": {
            "es": "del 17 de agosto al 23 de agosto",
            "en": "from august 17 to august 23"
        },
        "35": {
            "es": "del 24 de agosto al 30 de agosto",
            "en": "from august 24 to august 30"
        },
        "36": {
            "es": "del 31 de agosto al 6 de septiembre",
            "en": "from august 31 to september 6"
        },
        "37": {
            "es": "del 7 de septiembre al 13 de septiembre",
            "en": "from september 7 to september 13"
        },
        "38": {
            "es": "del 14 de septiembre al 20 de septiembre",
            "en": "from september 14 to september 20"
        },
        "39": {
            "es": "del 21 de septiembre al 27 de septiembre",
            "en": "from september 21 to september 27"
        },
        "40": {
            "es": "del 28 de septiembre al 4 de octubre",
            "en": "from september 28 to october 4"
        },
        "41": {
            "es": "del 5 de octubre al 11 de octubre",
            "en": "from october 5 to october 11"
        },
        "42": {
            "es": "del 12 de octubre al 18 de octubre",
            "en": "from october 12 to october 18"
        },
        "43": {
            "es": "del 19 de octubre al 25 de octubre",
            "en": "from october 19 to october 25"
        },
        "44": {
            "es": "del 26 de octubre al 1 de noviembre",
            "en": "from october 26 to november 1"
        },
        "45": {
            "es": "del 2 de noviembre al 8 de noviembre",
            "en": "from november 2 to november 8"
        },
        "46": {
            "es": "del 9 de noviembre al 15 de noviembre",
            "en": "from november 9 to november 15"
        },
        "47": {
            "es": "del 16 de noviembre al 22 de noviembre",
            "en": "from november 16 to november 22"
        },
        "48": {
            "es": "del 23 de noviembre al 29 de noviembre",
            "en": "from november 23 to november 29"
        },
        "49": {
            "es": "del 30 de noviembre al 6 de diciembre",
            "en": "from november 30 to december 6"
        },
        "50": {
            "es": "del 7 de diciembre al 13 de diciembre",
            "en": "from december 7 to december 13"
        },
        "51": {
            "es": "del 14 de diciembre al 20 de diciembre",
            "en": "from december 14 to december 20"
        },
        "52": {
            "es": "del 21 de diciembre al 27 de diciembre",
            "en": "from december 21 to december 27"
        },
        "53": {
            "es": "del 28 de diciembre al 3 de enero",
            "en": "from december 28 to january 3"
        }
    },
    "2027": {
        "1": {
            "es": "del 4 de enero al 10 de enero",
            "en": "from january 4 to january 10"
        },
        "2": {
            "es": "del 11 de enero al 17 de enero",
            "en": "from january 11 to january 17"
        },
        "3": {
            "es": "del 18 de enero al 24 de enero",
            "en": "from january 18 to january 24"
        },
        "4": {
            "es": "del 25 de enero al 31 de enero",
            "en": "from january 25 to january 31"
        },
        "5": {
            "es": "del 1 de febrero al 7 de febrero",
            "en": "from february 1 to february 7"
        },
        "6": {
            "es": "del 8 de febrero al 14 de febrero",
            "en": "from february 8 to february 14"
        },
        "7": {
            "es": "del 15 de febrero al 21 de febrero",
            "en": "from february 15 to february 21"
        },
        "8": {
            "es": "del 22 de febrero al 28 de febrero",
            "en": "from february 22 to february 28"
        },
        "9": {
            "es": "del 1 de marzo al 7 de marzo",
            "en": "from march 1 to march 7"
        },
        "10": {
            "es": "del 8 de marzo al 14 de marzo",
            "en": "from march 8 to march 14"
        },
        "11": {
            "es": "del 15 de marzo al 21 de marzo",
            "en": "from march 15 to march 21"
        },
        "12": {
            "es": "del 22 de marzo al 28 de marzo",
            "en": "from march 22 to march 28"
        },
        "13": {
            "es": "del 29 de marzo al 4 de abril",
            "en": "from march 29 to april 4"
        },
        "14": {
            "es": "del 5 de abril al 11 de abril",
            "en": "from april 5 to april 11"
        },
        "15": {
            "es": "del 12 de abril al 18 de abril",
            "en": "from april 12 to april 18"
        },
        "16": {
            "es": "del 19 de abril al 25 de abril",
            "en": "from april 19 to april 25"
        },
        "17": {
            "es": "del 26 de abril al 2 de mayo",
            "en": "from april 26 to may 2"
        },
        "18": {
            "es": "del 3 de mayo al 9 de mayo",
            "en": "from may 3 to may 9"
        },
        "19": {
            "es": "del 10 de mayo al 16 de mayo",
            "en": "from may 10 to may 16"
        },
        "20": {
            "es": "del 17 de mayo al 23 de mayo",
            "en": "from may 17 to may 23"
        },
        "21": {
            "es": "del 24 de mayo al 30 de mayo",
            "en": "from may 24 to may 30"
        },
        "22": {
            "es": "del 31 de mayo al 6 de junio",
            "en": "from may 31 to june 6"
        },
        "23": {
            "es": "del 7 de junio al 13 de junio",
            "en": "from june 7 to june 13"
        },
        "24": {
            "es": "del 14 de junio al 20 de junio",
            "en": "from june 14 to june 20"
        },
        "25": {
            "es": "del 21 de junio al 27 de junio",
            "en": "from june 21 to june 27"
        },
        "26": {
            "es": "del 28 de junio al 4 de julio",
            "en": "from june 28 to july 4"
        },
        "27": {
            "es": "del 5 de julio al 11 de julio",
            "en": "from july 5 to july 11"
        },
        "28": {
            "es": "del 12 de julio al 18 de julio",
            "en": "from july 12 to july 18"
        },
        "29": {
            "es": "del 19 de julio al 25 de julio",
            "en": "from july 19 to july 25"
        },
        "30": {
            "es": "del 26 de julio al 1 de agosto",
            "en": "from july 26 to august 1"
        },
        "31": {
            "es": "del 2 de agosto al 8 de agosto",
            "en": "from august 2 to august 8"
        },
        "32": {
            "es": "del 9 de agosto al 15 de agosto",
            "en": "from august 9 to august 15"
        },
        "33": {
            "es": "del 16 de agosto al 22 de agosto",
            "en": "from august 16 to august 22"
        },
        "34": {
            "es": "del 23 de agosto al 29 de agosto",
            "en": "from august 23 to august 29"
        },
        "35": {
            "es": "del 30 de agosto al 5 de septiembre",
            "en": "from august 30 to september 5"
        },
        "36": {
            "es": "del 6 de septiembre al 12 de septiembre",
            "en": "from september 6 to september 12"
        },
        "37": {
            "es": "del 13 de septiembre al 19 de septiembre",
            "en": "from september 13 to september 19"
        },
        "38": {
            "es": "del 20 de septiembre al 26 de septiembre",
            "en": "from september 20 to september 26"
        },
        "39": {
            "es": "del 27 de septiembre al 3 de octubre",
            "en": "from september 27 to october 3"
        },
        "40": {
            "es": "del 4 de octubre al 10 de octubre",
            "en": "from october 4 to october 10"
        },
        "41": {
            "es": "del 11 de octubre al 17 de octubre",
            "en": "from october 11 to october 17"
        },
        "42": {
            "es": "del 18 de octubre al 24 de octubre",
            "en": "from october 18 to october 24"
        },
        "43": {
            "es": "del 25 de octubre al 31 de octubre",
            "en": "from october 25 to october 31"
        },
        "44": {
            "es": "del 1 de noviembre al 7 de noviembre",
            "en": "from november 1 to november 7"
        },
        "45": {
            "es": "del 8 de noviembre al 14 de noviembre",
            "en": "from november 8 to november 14"
        },
        "46": {
            "es": "del 15 de noviembre al 21 de noviembre",
            "en": "from november 15 to november 21"
        },
        "47": {
            "es": "del 22 de noviembre al 28 de noviembre",
            "en": "from november 22 to november 28"
        },
        "48": {
            "es": "del 29 de noviembre al 5 de diciembre",
            "en": "from november 29 to december 5"
        },
        "49": {
            "es": "del 6 de diciembre al 12 de diciembre",
            "en": "from december 6 to december 12"
        },
        "50": {
            "es": "del 13 de diciembre al 19 de diciembre",
            "en": "from december 13 to december 19"
        },
        "51": {
            "es": "del 20 de diciembre al 26 de diciembre",
            "en": "from december 20 to december 26"
        },
        "52": {
            "es": "del 27 de diciembre al 2 de enero",
            "en": "from december 27 to january 2"
        }
    },
    "2028": {
        "1": {
            "es": "del 3 de enero al 9 de enero",
            "en": "from january 3 to january 9"
        },
        "2": {
            "es": "del 10 de enero al 16 de enero",
            "en": "from january 10 to january 16"
        },
        "3": {
            "es": "del 17 de enero al 23 de enero",
            "en": "from january 17 to january 23"
        },
        "4": {
            "es": "del 24 de enero al 30 de enero",
            "en": "from january 24 to january 30"
        },
        "5": {
            "es": "del 31 de enero al 6 de febrero",
            "en": "from january 31 to february 6"
        },
        "6": {
            "es": "del 7 de febrero al 13 de febrero",
            "en": "from february 7 to february 13"
        },
        "7": {
            "es": "del 14 de febrero al 20 de febrero",
            "en": "from february 14 to february 20"
        },
        "8": {
            "es": "del 21 de febrero al 27 de febrero",
            "en": "from february 21 to february 27"
        },
        "9": {
            "es": "del 28 de febrero al 5 de marzo",
            "en": "from february 28 to march 5"
        },
        "10": {
            "es": "del 6 de marzo al 12 de marzo",
            "en": "from march 6 to march 12"
        },
        "11": {
            "es": "del 13 de marzo al 19 de marzo",
            "en": "from march 13 to march 19"
        },
        "12": {
            "es": "del 20 de marzo al 26 de marzo",
            "en": "from march 20 to march 26"
        },
        "13": {
            "es": "del 27 de marzo al 2 de abril",
            "en": "from march 27 to april 2"
        },
        "14": {
            "es": "del 3 de abril al 9 de abril",
            "en": "from april 3 to april 9"
        },
        "15": {
            "es": "del 10 de abril al 16 de abril",
            "en": "from april 10 to april 16"
        },
        "16": {
            "es": "del 17 de abril al 23 de abril",
            "en": "from april 17 to april 23"
        },
        "17": {
            "es": "del 24 de abril al 30 de abril",
            "en": "from april 24 to april 30"
        },
        "18": {
            "es": "del 1 de mayo al 7 de mayo",
            "en": "from may 1 to may 7"
        },
        "19": {
            "es": "del 8 de mayo al 14 de mayo",
            "en": "from may 8 to may 14"
        },
        "20": {
            "es": "del 15 de mayo al 21 de mayo",
            "en": "from may 15 to may 21"
        },
        "21": {
            "es": "del 22 de mayo al 28 de mayo",
            "en": "from may 22 to may 28"
        },
        "22": {
            "es": "del 29 de mayo al 4 de junio",
            "en": "from may 29 to june 4"
        },
        "23": {
            "es": "del 5 de junio al 11 de junio",
            "en": "from june 5 to june 11"
        },
        "24": {
            "es": "del 12 de junio al 18 de junio",
            "en": "from june 12 to june 18"
        },
        "25": {
            "es": "del 19 de junio al 25 de junio",
            "en": "from june 19 to june 25"
        },
        "26": {
            "es": "del 26 de junio al 2 de julio",
            "en": "from june 26 to july 2"
        },
        "27": {
            "es": "del 3 de julio al 9 de julio",
            "en": "from july 3 to july 9"
        },
        "28": {
            "es": "del 10 de julio al 16 de julio",
            "en": "from july 10 to july 16"
        },
        "29": {
            "es": "del 17 de julio al 23 de julio",
            "en": "from july 17 to july 23"
        },
        "30": {
            "es": "del 24 de julio al 30 de julio",
            "en": "from july 24 to july 30"
        },
        "31": {
            "es": "del 31 de julio al 6 de agosto",
            "en": "from july 31 to august 6"
        },
        "32": {
            "es": "del 7 de agosto al 13 de agosto",
            "en": "from august 7 to august 13"
        },
        "33": {
            "es": "del 14 de agosto al 20 de agosto",
            "en": "from august 14 to august 20"
        },
        "34": {
            "es": "del 21 de agosto al 27 de agosto",
            "en": "from august 21 to august 27"
        },
        "35": {
            "es": "del 28 de agosto al 3 de septiembre",
            "en": "from august 28 to september 3"
        },
        "36": {
            "es": "del 4 de septiembre al 10 de septiembre",
            "en": "from september 4 to september 10"
        },
        "37": {
            "es": "del 11 de septiembre al 17 de septiembre",
            "en": "from september 11 to september 17"
        },
        "38": {
            "es": "del 18 de septiembre al 24 de septiembre",
            "en": "from september 18 to september 24"
        },
        "39": {
            "es": "del 25 de septiembre al 1 de octubre",
            "en": "from september 25 to october 1"
        },
        "40": {
            "es": "del 2 de octubre al 8 de octubre",
            "en": "from october 2 to october 8"
        },
        "41": {
            "es": "del 9 de octubre al 15 de octubre",
            "en": "from october 9 to october 15"
        },
        "42": {
            "es": "del 16 de octubre al 22 de octubre",
            "en": "from october 16 to october 22"
        },
        "43": {
            "es": "del 23 de octubre al 29 de octubre",
            "en": "from october 23 to october 29"
        },
        "44": {
            "es": "del 30 de octubre al 5 de noviembre",
            "en": "from october 30 to november 5"
        },
        "45": {
            "es": "del 6 de noviembre al 12 de noviembre",
            "en": "from november 6 to november 12"
        },
        "46": {
            "es": "del 13 de noviembre al 19 de noviembre",
            "en": "from november 13 to november 19"
        },
        "47": {
            "es": "del 20 de noviembre al 26 de noviembre",
            "en": "from november 20 to november 26"
        },
        "48": {
            "es": "del 27 de noviembre al 3 de diciembre",
            "en": "from november 27 to december 3"
        },
        "49": {
            "es": "del 4 de diciembre al 10 de diciembre",
            "en": "from december 4 to december 10"
        },
        "50": {
            "es": "del 11 de diciembre al 17 de diciembre",
            "en": "from december 11 to december 17"
        },
        "51": {
            "es": "del 18 de diciembre al 24 de diciembre",
            "en": "from december 18 to december 24"
        },
        "52": {
            "es": "del 25 de diciembre al 31 de diciembre",
            "en": "from december 25 to december 31"
        }
    },
    "2029": {
        "1": {
            "es": "del 1 de enero al 7 de enero",
            "en": "from january 1 to january 7"
        },
        "2": {
            "es": "del 8 de enero al 14 de enero",
            "en": "from january 8 to january 14"
        },
        "3": {
            "es": "del 15 de enero al 21 de enero",
            "en": "from january 15 to january 21"
        },
        "4": {
            "es": "del 22 de enero al 28 de enero",
            "en": "from january 22 to january 28"
        },
        "5": {
            "es": "del 29 de enero al 4 de febrero",
            "en": "from january 29 to february 4"
        },
        "6": {
            "es": "del 5 de febrero al 11 de febrero",
            "en": "from february 5 to february 11"
        },
        "7": {
            "es": "del 12 de febrero al 18 de febrero",
            "en": "from february 12 to february 18"
        },
        "8": {
            "es": "del 19 de febrero al 25 de febrero",
            "en": "from february 19 to february 25"
        },
        "9": {
            "es": "del 26 de febrero al 4 de marzo",
            "en": "from february 26 to march 4"
        },
        "10": {
            "es": "del 5 de marzo al 11 de marzo",
            "en": "from march 5 to march 11"
        },
        "11": {
            "es": "del 12 de marzo al 18 de marzo",
            "en": "from march 12 to march 18"
        },
        "12": {
            "es": "del 19 de marzo al 25 de marzo",
            "en": "from march 19 to march 25"
        },
        "13": {
            "es": "del 26 de marzo al 1 de abril",
            "en": "from march 26 to april 1"
        },
        "14": {
            "es": "del 2 de abril al 8 de abril",
            "en": "from april 2 to april 8"
        },
        "15": {
            "es": "del 9 de abril al 15 de abril",
            "en": "from april 9 to april 15"
        },
        "16": {
            "es": "del 16 de abril al 22 de abril",
            "en": "from april 16 to april 22"
        },
        "17": {
            "es": "del 23 de abril al 29 de abril",
            "en": "from april 23 to april 29"
        },
        "18": {
            "es": "del 30 de abril al 6 de mayo",
            "en": "from april 30 to may 6"
        },
        "19": {
            "es": "del 7 de mayo al 13 de mayo",
            "en": "from may 7 to may 13"
        },
        "20": {
            "es": "del 14 de mayo al 20 de mayo",
            "en": "from may 14 to may 20"
        },
        "21": {
            "es": "del 21 de mayo al 27 de mayo",
            "en": "from may 21 to may 27"
        },
        "22": {
            "es": "del 28 de mayo al 3 de junio",
            "en": "from may 28 to june 3"
        },
        "23": {
            "es": "del 4 de junio al 10 de junio",
            "en": "from june 4 to june 10"
        },
        "24": {
            "es": "del 11 de junio al 17 de junio",
            "en": "from june 11 to june 17"
        },
        "25": {
            "es": "del 18 de junio al 24 de junio",
            "en": "from june 18 to june 24"
        },
        "26": {
            "es": "del 25 de junio al 1 de julio",
            "en": "from june 25 to july 1"
        },
        "27": {
            "es": "del 2 de julio al 8 de julio",
            "en": "from july 2 to july 8"
        },
        "28": {
            "es": "del 9 de julio al 15 de julio",
            "en": "from july 9 to july 15"
        },
        "29": {
            "es": "del 16 de julio al 22 de julio",
            "en": "from july 16 to july 22"
        },
        "30": {
            "es": "del 23 de julio al 29 de julio",
            "en": "from july 23 to july 29"
        },
        "31": {
            "es": "del 30 de julio al 5 de agosto",
            "en": "from july 30 to august 5"
        },
        "32": {
            "es": "del 6 de agosto al 12 de agosto",
            "en": "from august 6 to august 12"
        },
        "33": {
            "es": "del 13 de agosto al 19 de agosto",
            "en": "from august 13 to august 19"
        },
        "34": {
            "es": "del 20 de agosto al 26 de agosto",
            "en": "from august 20 to august 26"
        },
        "35": {
            "es": "del 27 de agosto al 2 de septiembre",
            "en": "from august 27 to september 2"
        },
        "36": {
            "es": "del 3 de septiembre al 9 de septiembre",
            "en": "from september 3 to september 9"
        },
        "37": {
            "es": "del 10 de septiembre al 16 de septiembre",
            "en": "from september 10 to september 16"
        },
        "38": {
            "es": "del 17 de septiembre al 23 de septiembre",
            "en": "from september 17 to september 23"
        },
        "39": {
            "es": "del 24 de septiembre al 30 de septiembre",
            "en": "from september 24 to september 30"
        },
        "40": {
            "es": "del 1 de octubre al 7 de octubre",
            "en": "from october 1 to october 7"
        },
        "41": {
            "es": "del 8 de octubre al 14 de octubre",
            "en": "from october 8 to october 14"
        },
        "42": {
            "es": "del 15 de octubre al 21 de octubre",
            "en": "from october 15 to october 21"
        },
        "43": {
            "es": "del 22 de octubre al 28 de octubre",
            "en": "from october 22 to october 28"
        },
        "44": {
            "es": "del 29 de octubre al 4 de noviembre",
            "en": "from october 29 to november 4"
        },
        "45": {
            "es": "del 5 de noviembre al 11 de noviembre",
            "en": "from november 5 to november 11"
        },
        "46": {
            "es": "del 12 de noviembre al 18 de noviembre",
            "en": "from november 12 to november 18"
        },
        "47": {
            "es": "del 19 de noviembre al 25 de noviembre",
            "en": "from november 19 to november 25"
        },
        "48": {
            "es": "del 26 de noviembre al 2 de diciembre",
            "en": "from november 26 to december 2"
        },
        "49": {
            "es": "del 3 de diciembre al 9 de diciembre",
            "en": "from december 3 to december 9"
        },
        "50": {
            "es": "del 10 de diciembre al 16 de diciembre",
            "en": "from december 10 to december 16"
        },
        "51": {
            "es": "del 17 de diciembre al 23 de diciembre",
            "en": "from december 17 to december 23"
        },
        "52": {
            "es": "del 24 de diciembre al 30 de diciembre",
            "en": "from december 24 to december 30"
        }
    },
    "2030": {
        "1": {
            "es": "del 31 de diciembre al 6 de enero",
            "en": "from december 31 to january 6"
        },
        "2": {
            "es": "del 7 de enero al 13 de enero",
            "en": "from january 7 to january 13"
        },
        "3": {
            "es": "del 14 de enero al 20 de enero",
            "en": "from january 14 to january 20"
        },
        "4": {
            "es": "del 21 de enero al 27 de enero",
            "en": "from january 21 to january 27"
        },
        "5": {
            "es": "del 28 de enero al 3 de febrero",
            "en": "from january 28 to february 3"
        },
        "6": {
            "es": "del 4 de febrero al 10 de febrero",
            "en": "from february 4 to february 10"
        },
        "7": {
            "es": "del 11 de febrero al 17 de febrero",
            "en": "from february 11 to february 17"
        },
        "8": {
            "es": "del 18 de febrero al 24 de febrero",
            "en": "from february 18 to february 24"
        },
        "9": {
            "es": "del 25 de febrero al 3 de marzo",
            "en": "from february 25 to march 3"
        },
        "10": {
            "es": "del 4 de marzo al 10 de marzo",
            "en": "from march 4 to march 10"
        },
        "11": {
            "es": "del 11 de marzo al 17 de marzo",
            "en": "from march 11 to march 17"
        },
        "12": {
            "es": "del 18 de marzo al 24 de marzo",
            "en": "from march 18 to march 24"
        },
        "13": {
            "es": "del 25 de marzo al 31 de marzo",
            "en": "from march 25 to march 31"
        },
        "14": {
            "es": "del 1 de abril al 7 de abril",
            "en": "from april 1 to april 7"
        },
        "15": {
            "es": "del 8 de abril al 14 de abril",
            "en": "from april 8 to april 14"
        },
        "16": {
            "es": "del 15 de abril al 21 de abril",
            "en": "from april 15 to april 21"
        },
        "17": {
            "es": "del 22 de abril al 28 de abril",
            "en": "from april 22 to april 28"
        },
        "18": {
            "es": "del 29 de abril al 5 de mayo",
            "en": "from april 29 to may 5"
        },
        "19": {
            "es": "del 6 de mayo al 12 de mayo",
            "en": "from may 6 to may 12"
        },
        "20": {
            "es": "del 13 de mayo al 19 de mayo",
            "en": "from may 13 to may 19"
        },
        "21": {
            "es": "del 20 de mayo al 26 de mayo",
            "en": "from may 20 to may 26"
        },
        "22": {
            "es": "del 27 de mayo al 2 de junio",
            "en": "from may 27 to june 2"
        },
        "23": {
            "es": "del 3 de junio al 9 de junio",
            "en": "from june 3 to june 9"
        },
        "24": {
            "es": "del 10 de junio al 16 de junio",
            "en": "from june 10 to june 16"
        },
        "25": {
            "es": "del 17 de junio al 23 de junio",
            "en": "from june 17 to june 23"
        },
        "26": {
            "es": "del 24 de junio al 30 de junio",
            "en": "from june 24 to june 30"
        },
        "27": {
            "es": "del 1 de julio al 7 de julio",
            "en": "from july 1 to july 7"
        },
        "28": {
            "es": "del 8 de julio al 14 de julio",
            "en": "from july 8 to july 14"
        },
        "29": {
            "es": "del 15 de julio al 21 de julio",
            "en": "from july 15 to july 21"
        },
        "30": {
            "es": "del 22 de julio al 28 de julio",
            "en": "from july 22 to july 28"
        },
        "31": {
            "es": "del 29 de julio al 4 de agosto",
            "en": "from july 29 to august 4"
        },
        "32": {
            "es": "del 5 de agosto al 11 de agosto",
            "en": "from august 5 to august 11"
        },
        "33": {
            "es": "del 12 de agosto al 18 de agosto",
            "en": "from august 12 to august 18"
        },
        "34": {
            "es": "del 19 de agosto al 25 de agosto",
            "en": "from august 19 to august 25"
        },
        "35": {
            "es": "del 26 de agosto al 1 de septiembre",
            "en": "from august 26 to september 1"
        },
        "36": {
            "es": "del 2 de septiembre al 8 de septiembre",
            "en": "from september 2 to september 8"
        },
        "37": {
            "es": "del 9 de septiembre al 15 de septiembre",
            "en": "from september 9 to september 15"
        },
        "38": {
            "es": "del 16 de septiembre al 22 de septiembre",
            "en": "from september 16 to september 22"
        },
        "39": {
            "es": "del 23 de septiembre al 29 de septiembre",
            "en": "from september 23 to september 29"
        },
        "40": {
            "es": "del 30 de septiembre al 6 de octubre",
            "en": "from september 30 to october 6"
        },
        "41": {
            "es": "del 7 de octubre al 13 de octubre",
            "en": "from october 7 to october 13"
        },
        "42": {
            "es": "del 14 de octubre al 20 de octubre",
            "en": "from october 14 to october 20"
        },
        "43": {
            "es": "del 21 de octubre al 27 de octubre",
            "en": "from october 21 to october 27"
        },
        "44": {
            "es": "del 28 de octubre al 3 de noviembre",
            "en": "from october 28 to november 3"
        },
        "45": {
            "es": "del 4 de noviembre al 10 de noviembre",
            "en": "from november 4 to november 10"
        },
        "46": {
            "es": "del 11 de noviembre al 17 de noviembre",
            "en": "from november 11 to november 17"
        },
        "47": {
            "es": "del 18 de noviembre al 24 de noviembre",
            "en": "from november 18 to november 24"
        },
        "48": {
            "es": "del 25 de noviembre al 1 de diciembre",
            "en": "from november 25 to december 1"
        },
        "49": {
            "es": "del 2 de diciembre al 8 de diciembre",
            "en": "from december 2 to december 8"
        },
        "50": {
            "es": "del 9 de diciembre al 15 de diciembre",
            "en": "from december 9 to december 15"
        },
        "51": {
            "es": "del 16 de diciembre al 22 de diciembre",
            "en": "from december 16 to december 22"
        },
        "52": {
            "es": "del 23 de diciembre al 29 de diciembre",
            "en": "from december 23 to december 29"
        }
    },
    "2031": {
        "1": {
            "es": "del 30 de diciembre al 5 de enero",
            "en": "from december 30 to january 5"
        }
    }
}

export default fiscalWeeks;