import React from 'react'

const UpdateReportLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30"  fill={props.fill || "#fff"}>
  <g id="Enmascarar_grupo_1" data-name="Enmascarar grupo 1" transform="translate(-139 -11554)" clip-path="url(#clip-path)">
    <g id="Grupo_773" data-name="Grupo 773" transform="translate(139 11554)" clip-path="url(#clip-path-2)">
      <path id="Trazado_943" data-name="Trazado 943" d="M15.009,0c2.334,0,4.667-.011,7,0a7.813,7.813,0,0,1,5.042,1.755,8.187,8.187,0,0,1,1.981,2.4,7.456,7.456,0,0,1,.942,3.651c.01,4.843.063,9.687-.017,14.529a7.481,7.481,0,0,1-4,6.652A8.124,8.124,0,0,1,21.758,30q-6.737-.026-13.474,0A8.438,8.438,0,0,1,4.8,29.378,7.821,7.821,0,0,1,.386,24.57a6.887,6.887,0,0,1-.378-2.226Q0,15.062.008,7.78A7.616,7.616,0,0,1,2.153,2.506,7.74,7.74,0,0,1,6.608.122a8.041,8.041,0,0,1,1.4-.113c2.334-.011,4.667,0,7,0m-1.43,17.022H6v7.983H8.665V22.222a.822.822,0,0,1,.109.058c.236.183.464.377.706.551A9.486,9.486,0,0,0,14.53,24.66a9.478,9.478,0,0,0,8.592-4.351c.424-.676.742-1.42,1.111-2.131.061-.118-.023-.141-.1-.176l-1.49-.71-.784-.375c-.037.076-.064.133-.093.189-.3.578-.548,1.185-.9,1.728a6.624,6.624,0,0,1-5.491,3.148,6.684,6.684,0,0,1-4.938-1.793c-.168-.15-.335-.3-.5-.452l.043-.052h3.6ZM8.364,13.153l.111-.206c.261-.491.487-1,.788-1.469A6.7,6.7,0,0,1,15.176,8.22a6.635,6.635,0,0,1,4.433,1.7.407.407,0,0,1,.052.085H16.291v2.651h7.362V5.036a.449.449,0,0,0-.076-.018c-.821,0-1.642,0-2.463-.006-.107,0-.122.047-.122.141,0,.745,0,1.489-.006,2.234,0,.049-.006.1-.01.157a.357.357,0,0,1-.068-.025,9.293,9.293,0,0,0-4.972-1.93,9.5,9.5,0,0,0-4.522.684A9.782,9.782,0,0,0,8.021,8.66,13.455,13.455,0,0,0,5.992,11.9l2.372,1.25" transform="translate(0 0)"/>
    </g>
  </g>
</svg>
  )
}

export default UpdateReportLogo