import { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Bar, Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import { urlBase } from "../../authConfig";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { Player } from "@lottiefiles/react-lottie-player";
import Loading from "../../animations/Loading.json";
import PlusIcon from "../../assets/PlusIcon";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { 
    PieChart,
    LineChart, 
    BarChart
} from 'echarts/charts';
import {
    DatasetComponent,
    TooltipComponent,
    TitleComponent,
    GridComponent,
    TransformComponent,
    ToolboxComponent,
    LegendComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

Chart.defaults.font.family = 'Century Gothic';

echarts.use([
    TitleComponent, 
    TooltipComponent, 
    PieChart, 
    CanvasRenderer, 
    GridComponent, 
    LineChart, 
    DatasetComponent, 
    TransformComponent, 
    BarChart,
    ToolboxComponent,
    LegendComponent, 
]);

export const WaterFallData = ({ start, end, idRS = '' }) => {
    const {idClient, accessToken} = useStateContext();
    const [chartOption, setChartOption] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [divider, setDivider] = useState();
    const [newjson, setNewJson] = useState();

    const splitWords = (value) => {
        let words = value.split(' ');

        if (words.length <= 2) {
            return words.join('\n'); // Si hay dos o menos palabras, simplemente únelas con saltos de línea.
        }

        // Para más de dos palabras, une cada palabra con un salto de línea.
        return words.join('\n');
    }

    useEffect(() => {

        if( start !== null && end !== null ){
            getData();
        }
    }, [start, end, idRS]);

    const getData = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            "clientID": idClient, 
            "startDate": start, 
            "endDate": end
        }

        if( idRS ) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/chart/waterFallData`, {
            method:"POST",
            headers:headers,
            body: JSON.stringify(body)
        }).then( res => res.json())
        .then(json => {
            setNewJson(json);
            let div = json.divider;
            let arrAba = json.arrAba;
            let arrSup = json.arrSup
            setDivider(json.divider);

            const barColors = json.colorBySign.map( val => val === 'Positive' ? '#6844D8' : (val === 'Total' ? '#3C0383' : '#9191F4'));

            const adjustLabelSize = () => {
                const charSize = window.innerWidth;
                
                return charSize <= 800 ? 6 : charSize <= 1200 ? 8 : charSize <= 1400 ? 9 : charSize <= 1600 ? 10 : 12;
            }

            const option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (params) {

                            let dataIndex = params[0].dataIndex;

                            let trueValues = json.trueValues[dataIndex];

                            if( trueValues === 0 ) {
                                return params[0].name + '<br/>' + '>0' + div;
                            }

                            let amount =  new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 2, style: 'currency', currencySign: 'accounting'}).format(trueValues);

                            let valueWithoutSign;
                            valueWithoutSign = amount.replace(/\$/g, '');
                            

                            return params[0].name + '<br/>' + valueWithoutSign + div;
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        splitLine: { show: false },
                        data: json.labels,
                        axisLabel: {
                            fontSize: adjustLabelSize(),
                            interval: 0,
                            rotate: 0, 
                            formatter: function( value ) {
                                return splitWords( value );
                            }, 
                            fontFamily: 'Inter, Arial, sans-serif',
                        }
                    },
                    yAxis: {
                        type: 'value',  
                        axisLabel: {
                            formatter: function( value ) {
                                let amount = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD', 
                                    currencySign: 'accounting',
                                    minimumFractionDigits: 0, 
                                    maximumFractionDigits: 0
                                }).format(value);
    
                                let valueWithoutSign = amount.replace(/\$/g, '');
    
                                return valueWithoutSign;
                            }, 
                            fontFamily: 'Inter, Arial, sans-serif',
                        }                  
                    },
                    series: [
                        {
                        name: 'Placeholder',
                        type: 'bar',
                        stack: 'Total',
                        itemStyle: {
                            borderColor: 'transparent',
                            color: 'transparent'
                        },
                        emphasis: {
                            itemStyle: {
                            borderColor: 'transparent',
                            color: 'transparent'
                            }
                        },
                        data: json.arrBase
                        },
                        {
                            name: 'Life Cost',
                            type: 'bar',
                            stack: 'Total',
                            label: {
                                show: true,
                                position: 'top',
                                formatter: function(params) {
                                    if(Math.round(json.trueValues[params.dataIndex]) === 0) {
                                        return '>0' + div;
                                    }
                                    return arrSup[params.dataIndex] !== "" ? new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 0, style: 'currency', currencySign: 'accounting'}).format(Math.round(json.trueValues[params.dataIndex])).replace(/\$/, '') + div : '';
                                },
                            },
                            itemStyle: {
                                color: function(params) {
                                    return barColors[params.dataIndex];
                                }
                            },
                            data: json.arrPositive 
                        },      
                        {
                            name: 'Life Cost',
                            type: 'bar',
                            stack: 'Total',
                            label: {
                                show: true,
                                position: 'bottom', 
                                formatter: function( params ) {
                                    return arrAba[params.dataIndex] !== "" ? new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 0, style: 'currency', currencySign: 'accounting'}).format(Math.round(json.trueValues[params.dataIndex])).replace(/\$/g, '') + div : '';
                                },
                            },
                            itemStyle: {
                                color: function(params) {
                                    return barColors[params.dataIndex];
                                }
                            },
                            data: json.arrNegative
                        }
                    ]
            }

            setChartOption(option);

        });
    }
    //     const adjustLabelSize = () => {
    //         const charSize = window.innerWidth;

    //         return charSize < 1400 ? 10 : charSize < 600 ? 7 : 12;
    //     }

    //     const barColors = newjson.colorBySign.map( val => val === 'Positive' ? '#6844D8' : (val === 'Total' ? '#3C0383' : '#9191F4'));
    //     let div = newjson.divider;
    //     let arrAba = newjson.arrAba;
    //     let arrSup = newjson.arrSup

    //     const option = {
    //         tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //                 type: 'shadow'
    //             },
    //             formatter: function (params) {
    //                 let dataIndex = params[0].dataIndex;

    //                 let trueValues = newjson.trueValues[dataIndex];

    //                 let amount =  new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 2, style: 'currency', currencySign: 'accounting'}).format(trueValues);

    //                 let valueWithoutSign;
    //                 valueWithoutSign = amount.replace(/\$/g, '');
                    

    //                 return params[0].name + '<br/>' + valueWithoutSign + div;
    //             }
    //         },
    //         grid: {
    //             left: '3%',
    //             right: '4%',
    //             bottom: '3%',
    //             containLabel: true
    //         },
    //         xAxis: {
    //             type: 'category',
    //             splitLine: { show: false },
    //             data: newjson.labels,
    //             axisLabel: {
    //                 fontSize: adjustLabelSize(),
    //                 interval: 0,
    //                 rotate: 0, 
    //                 formatter: function( value ) {
    //                     return splitWords( value );
    //                 }
    //             }
    //         },
    //         yAxis: {
    //             type: 'value',  
    //             axisLabel: {
    //                 formatter: function( value ) {
    //                     let amount = new Intl.NumberFormat('en-US', {
    //                         style: 'currency',
    //                         currency: 'USD', 
    //                         currencySign: 'accounting',
    //                         minimumFractionDigits: 0, 
    //                         maximumFractionDigits: 0
    //                     }).format(value);

    //                     let valueWithoutSign = amount.replace(/\$/g, '');

    //                     return valueWithoutSign;
    //                 }
    //             }                  
    //         },
    //         series: [
    //             {
    //             name: 'Placeholder',
    //             type: 'bar',
    //             stack: 'Total',
    //             itemStyle: {
    //                 borderColor: 'transparent',
    //                 color: 'transparent'
    //             },
    //             emphasis: {
    //                 itemStyle: {
    //                 borderColor: 'transparent',
    //                 color: 'transparent'
    //                 }
    //             },
    //             data: newjson.arrBase
    //             },
    //             {
    //                 name: 'Life Cost',
    //                 type: 'bar',
    //                 stack: 'Total',
    //                 label: {
    //                     show: true,
    //                     position: 'top',
    //                     formatter: function(params) {
    //                         return arrSup[params.dataIndex] !== "" ? new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 0, style: 'currency', currencySign: 'accounting'}).format(newjson.trueValues[params.dataIndex]).replace(/\$/, '') + div : '';
    //                     }
    //                 },
    //                 itemStyle: {
    //                     color: function(params) {
    //                         return barColors[params.dataIndex];
    //                     }
    //                 },
    //                 data: newjson.arrPositive 
    //             },      
    //             {
    //                 name: 'Life Cost',
    //                 type: 'bar',
    //                 stack: 'Total',
    //                 label: {
    //                     show: true,
    //                     position: 'bottom', 
    //                     formatter: function( params ) {
    //                         return arrAba[params.dataIndex] !== "" ? new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 0, style: 'currency', currencySign: 'accounting'}).format(newjson.trueValues[params.dataIndex]).replace(/\$/g, '') + div : '';
    //                     }
    //                 },
    //                 itemStyle: {
    //                     color: function(params) {
    //                         return barColors[params.dataIndex];
    //                     }
    //                 },
    //                 data: newjson.arrNegative
    //             }
    //         ]
    // }

    // setChartOption(option);
    // }

    // window.addEventListener('resize', () => {
    //     updateChart();
    // });

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const Modal = ({ onClose, children }) => {
        return(
            <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                    <div className="flex justify-between flex-wrap">
                        <h2 className="font-bold text-xl">Ingresos vs egresos operativos</h2>
                        <button onClick={toggleModal}>
                            <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 h-[20%] mb-5">
            <div className="flex justify-between">
                <div className="flex relative flex-col">
                    <h2 className="font-bold text-xl">Flujo de efectivo del periodo</h2>
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={chartOption}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
                    <div onClick={toggleModal} className='fixed top-0 left-[15%] bottom-0 w-screen z-20 h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={chartOption}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
};

export const RainfallEvaporationChart = ({start, end, prestart, preend, groupby, comparison, none, idRS = ''}) => {
    const [option, setOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone ] = useState(false);
    const [openHover, setOpenHover] = useState(false);

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null) {
            getData();
        }
    },[start, end, prestart, preend, groupby, idRS])
    
    const getData = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            "clientID":idClient,
            "startDate":start,
            "endDate":end,
            "startPreDate":prestart,
            "endPreDate":preend,
            "groupBy":groupby
        }
        if( idRS ) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/chart/InflowVsOutflow`, {
            method:"POST",
            headers:headers,
            body:JSON.stringify(body)
        }).then(res => res.json())
        .then(json => {
            setTotalThis(json.totalThis);
            setTotalPrev(json.totalPrev);
            setPercentage(json.percentage);
            setDivider(json.divider);

            if(none === true) {
                setIsNone(true);
            }else {
                setIsNone(false);
            }

            setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function(params) {
                        let index = params[0].dataIndex || params[0].axisValue;
                    
                        if (typeof index !== 'number') {
                            index = json.dayListLabels.findIndex(month => month.startsWith(index));
                        }
                    
                        let monthLabel = (typeof index === 'number' && json.dayListLabels[index]) ? json.dayListLabels[index] : 'Índice no válido';
                    
                        let result = `${monthLabel} <br/>`;
                        params.forEach(param => {
                            let amount = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD', 
                                currencySign: 'accounting',
                                minimumFractionDigits: 1, 
                                maximumFractionDigits: 1
                            }).format(param.value);
                            let valueWithoutSign = amount.replace(/\$/g, '');
                            result += `${param.marker} ${param.seriesName}: ${valueWithoutSign}${json.divider}<br/>`;
                        });
                        return result;
                    }
                },
                legend: {
                    data: ['Ingresos', 'Egresos']
                },

                calculable: true,
                xAxis: [
                    groupby === 1 || groupby === 2 ?
                        {
                            type: 'category',
                            data: Object.keys(json['dayList']).map(key => json['dayList'][key]),
                            axisLabel: {
                                interval: function (index, value) {
                                    var total = Object.keys(json['dayList']).length;
                                    return index === 0 || index === total - 1;
                                }
                            },
                            axisTick: {
                                show: true
                            },
                        }
                    :
                    {
                        type: 'category',
                        data: Object.keys(json['dayList']).map(key => json['dayList'][key]),
                        axisLabel: {
                            interval: 0
                        },
                        axisTick: {
                            show: true
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: { show: false},
                        axisLabel: {
                            formatter: function( value ) {
                                if( value < 0 ){
                                    return '(' + Math.abs( value ) + ')';
                                } else {
                                    return value;
                                }
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: 'Ingresos',
                        type: 'bar',
                        data: Object.keys(json['ThisPeriod']).map(key => json['ThisPeriod'][key]),
                        color: '#9191F4'
                    },
                    {
                        name: 'Egresos',
                        type: 'bar',
                        data: Object.keys(json['previousPeriod']).map(key => json['previousPeriod'][key]),
                        color: '#3C0383'
                    }
                ]
            });
        });
    }
    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    const Modal = ({ onClose, children }) => {
        return(
            <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                <div className="flex justify-between">
        <h2 className="font-bold text-xl">Ingresos vs egresos operativos</h2>
        <button onClick={toggleModal}>
            <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
        </button>
    </div>
    <div className="mt-2 flex justify-between">
        <div className="flex">
            <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
            {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
        </div>
        <div>
            {percentage > 0 && !isNone?
                <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                    <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                </div>
            :   percentage < 0 && !isNone ?
                <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                    <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                </div>
            : 
                null
            }
        </div>
    </div>
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Ingresos vs egresos operativos</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                                <p>
                                    Del {formatDate(prestart)} al {formatDate(preend)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    :
                        null
                    }
                </div>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
                    <div onClick={toggleModal} className='fixed top-0 left-[15%] bottom-0 w-screen z-20 h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    )
};

export const MixLineBar = ({start, end, prestart, preend, groupby, comparison, none, idRS = ''}) => {
    const [option, setOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone ] = useState(false);
    const [openHover, setOpenHover] = useState(false);

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null) {
            getData();
        }
    },[start, end, prestart, preend, groupby, idRS]);

    const getData = () => {
        let headers = new Headers();
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            startDate: start,
            endDate: end,
            startPreDate: prestart,
            endPreDate: preend,
            groupBy: groupby
        }
        if(idRS) {
            body.idRS = idRS.toString(); 
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/chart/NetCashFlow`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(json => {
            setTotalThis(json.totalThis);
            setTotalPrev(json.totalPrev);
            setPercentage(json.percentage);
            setDivider(json.divider);
            
            if(none === true) {
                setIsNone(true);
            }else {
                setIsNone(false);
            }

            setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross', crossStyle: { color: '#999' } },
                    formatter: function(params) {
                        let result = ``; 
                        params.forEach(param => {
                            if (param.seriesName !== 'Periodo anterior' || json['previousPeriod'] !== '-') {
                                let monthLabel = param.seriesName === 'Periodo actual' ? json['dayListThis'][param.dataIndex] : json['dayListPast'][param.dataIndex];
                                let amount = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD', 
                                    currencySign: 'accounting',
                                    minimumFractionDigits: 1, 
                                    maximumFractionDigits: 1
                                }).format(param.value);
                                let valueWithoutSign = amount.replace(/\$/g, '');
                                result += `${param.marker} ${monthLabel}: ${valueWithoutSign}${json.divider}<br/>`;
                            }
                        });
                        return result;
                    }
                },
                legend: {
                    show: true,
                    data: ['Periodo actual'].concat(json['previousPeriod'] !== '-' ? ['Periodo anterior'] : []),
                    orient: 'horizontal', 
                    top: 'top', 
                    left: 'center'
                },
                xAxis: [
                    groupby === 1 || groupby === 2 ? 
                    {
                    type: 'category',
                    data: json['dayList'],
                    axisPointer: { type: 'shadow' },
                    axisLabel: {
                        interval: function (index, value) {
                            var total = json['dayList'].length;
                            return index === 0 || index === total - 1;
                        }
                    },
                    axisTick: {
                        show: true
                    },
                    axisLine: { show: false },
                    splitLine: { show: false }
                }
                :
                {
                    type: 'category',
                    data: json['dayList'],
                    axisPointer: { type: 'shadow' }, 
                    axisLabel: {
                        interval: 0
                    },
                    axisTick: {
                        show: true
                    },
                    axisLine: { show: false },
                    splitLine: { show: false }
                }
                ],
                yAxis: [
                    { 
                        type: 'value',
                        name: '',
                        axisLine: { show: false },
                        splitLine: { show: false },
                        axisLabel: {
                            formatter: function(value) {
                                let amount = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD', 
                                    currencySign: 'accounting',
                                    minimumFractionDigits: 0, 
                                    maximumFractionDigits: 0
                                }).format(value);

                                let valueWithoutSign = amount.replace(/\$/g, '');

                                return valueWithoutSign;
                            }
                        }
                    },
                    { 
                        type: 'value', 
                        name: '',
                        axisLine: { show: false },
                        splitLine: { show: false },
                        axisLabel: {
                            formatter: function(value) {
                                if (value < 0) {
                                    // Format negative values with parentheses
                                    return '(' + Math.abs(value) + ')';
                                } else {
                                    // Return positive values as is
                                    return value;
                                }
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: 'Periodo actual',
                        type: 'bar',
                        data: json['ThisPeriod'],
                        color: '#4E00AF',
                    },
                    json['previousPeriod'][0] !== '-' && {
                        name: 'Periodo anterior',
                        type: 'line',
                        data: json['previousPeriod'],
                        color: '#B4B4B4',
                    }
                ].filter(Boolean)
            });
        });
    };

    
    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    const Modal = ({ onClose, children }) => {
                return(
                    <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                        <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                        <div className="flex justify-between">
                <h2 className="font-bold text-xl">Flujo neto de efectivo</h2>
                
                <button onClick={toggleModal}>
                    <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex">
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0  && !isNone?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    :  percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    :
                        null
                    }
                </div>
            </div>
                            {children}
                        </div>
                    </div>
                );
            }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 h-[20%] mb-5">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Flujo neto de efectivo</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                                <p>
                                    Del {formatDate(prestart)} al {formatDate(preend)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ? 
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    : 
                        null
                    }
                </div>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
}

export const LineChartComponent = ({start, end, prestart, preend, groupby, comparison, none, idRS = ''}) => {
    const [chartOption, setChartOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone ] = useState(false);
    const [openHover, setOpenHover] = useState(false);

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null) {
            getData();
        }
    }, [start, end, prestart, preend, groupby, idRS]);

    const getData = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            "clientID":idClient,
            "startDate":start,
            "endDate":end,
            "startPre":prestart,
            "endPre":preend,
            "groupBy":groupby
        }
        if(idRS) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/chart/chartFlowPeriod`, {
            method:"POST",
            headers:headers,
            body:JSON.stringify(body)
        }).then(res => res.json())
        .then(aux => {
            setTotalThis(aux.totalThis);
            setTotalPrev(aux.totalPrevious);
            setPercentage(aux.percentage);
            setDivider(aux.divider);

            if(none === true) {
                setIsNone(true);
            }else {
                setIsNone(false);
            }

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function(params) {
                        let index = params[0].dataIndex || params[0].axisValue;
                    
                        if (typeof index !== 'number') {
                            index = aux.dayListLabels.findIndex(month => month.startsWith(index));
                        }
                    
                        let monthLabel = (typeof index === 'number' && aux.dayListLabels[index]) ? aux.dayListLabels[index] : 'Índice no válido';
                    
                        let result = `${monthLabel} <br/>`;
                        params.forEach(param => {
                            let amount = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD', 
                                currencySign: 'accounting',
                                minimumFractionDigits: 1, 
                                maximumFractionDigits: 1
                            }).format(param.value);
                            let valueWithoutSign = amount.replace(/\$/g, '');
                            result += `${param.marker} ${param.seriesName}: ${valueWithoutSign}${aux.divider}<br/>`;
                        });
                        return result;
                    }
                },
                legend: {  
                    show: true,
                    data: ['Flujo operativo', 'Flujo inversion', 'Flujo financiamiento', 'Flujo neto'],
                    orient: 'horizontal',
                    top: 'top',
                    left: 'center' 
                },
                xAxis: [
                    groupby === 1 || groupby === 2 ? 
                    {
                        type: 'category',
                        data: aux['dayList'],
                        axisLabel: {
                            // Utiliza una función para determinar si una etiqueta debe mostrarse o no
                            interval: function (index, value) {
                                // Obtén la cantidad total de datos
                                var total = aux['dayList'].length;
                                // Muestra solo la primera y la última etiqueta
                                return index === 0 || index === total - 1;
                            }
                        },
                        axisTick: {
                            show: true
                        }
                    }
                :
                    {
                        type: 'category',
                        data: aux['dayList'],
                        axisLabel: {
                            interval: 0
                        },
                        axisTick: {
                            show: true
                        }
                    },
                ],
                yAxis: {
                    name: '',
                    splitLine: { show: false},
                    axisLabel: {
                        formatter: function( value ) {
                            let amount = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD', 
                                currencySign: 'accounting',
                                minimumFractionDigits: 0, 
                                maximumFractionDigits: 0
                            }).format(value);

                            let valueWithoutSign = amount.replace(/\$/g, '');

                            return valueWithoutSign;
                        }
                    }
                },
                series: [
                    {
                        name: 'Flujo operativo',
                        type: 'line',
                        data: aux['flujoOperativo'],
                        color: '#D3D3D3'
                    },
                    {
                        name: 'Flujo inversion',
                        type: 'line',
                        data: aux['flujoInversion'],
                        color: '#9191FB'
                    },
                    {
                        name: 'Flujo financiamiento',
                        type: 'line',
                        data: aux['flujoFinance'],
                        color: '#5600B6'
                    },
                    {
                        name: 'Flujo neto',
                        type: 'line',
                        data: aux['flujoNeto'],
                        color: '#E2E2FF'
                    },
                ]
            };
    
            setChartOption(option);
        });
    }
    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    const Modal = ({ onClose, children }) => {
                return(
                    <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                        <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                        <div className="flex justify-between">
                <h2 className="font-bold text-xl">Flujo de efectivo</h2>
                <button onClick={toggleModal}>
                    <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex">
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    :
                        null
                    }
                </div>
            </div>
                            {children}
                        </div>
                    </div>
                );
            }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Flujo de efectivo</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                                <p>
                                    Del {formatDate(prestart)} al {formatDate(preend)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    :
                        null
                    }
                </div>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={chartOption}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={chartOption}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
};

export const OperativesInflows = ({start, end, prestart, preend, groupby, comparison, none, top = 3, idRS = ''}) => {
    const [chartOption, setChartOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone ] = useState(false);
    const [openHover, setOpenHover] = useState(false);

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null && accessToken) {
            getData();
        }
    }, [start, end, prestart, preend, groupby, idRS]);

    const getData = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            "clientID": idClient,
            "startDate": start,
            "endDate": end,
            "startPre": prestart, 
            "endPre": preend,
            "groupBy": groupby,
            "inOut": '+',
            "topV": top
        }
        if(idRS){
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }
        fetch(`${urlBase}/chart/OperativeInflows`, {
            method: 'POST', 
            headers: headers, 
            body: JSON.stringify(body)
        }).then(res => res.json())
        .then(aux => {
            setTotalThis(aux.totalThis);
            setTotalPrev(aux.totalPrev);
            setPercentage(aux.percentage);
            setDivider(aux.divider);

            if(none === true) {
                setIsNone(true);
            }else {
                setIsNone(false);
            }

            const data = {
                dayList: aux['dayList'],
                top1: aux['top1'],
                top2: aux['top2'],
                top3: aux['top3'],
                others: aux['another'],
                labels: aux['labels']
            };
            
            const totals = aux.dayList.map((_, index) => (
                data.top1[index] + data.top2[index] + data.top3[index] + data.others[index]
            ));

            let isTop1Visible = true;
            let isTop2Visible = true;
            let isTop3Visible = true;
            let isOthersVisible = true;

            const getLabelSettings = (seriesIndex, params) => {
                let showLabel = false;
                switch (seriesIndex) {
                    case 0: // top1
                        showLabel = isTop1Visible && (!isTop2Visible || !isTop3Visible || !isOthersVisible);
                        break;
                    case 1: // top2
                        showLabel = isTop2Visible && (!isTop3Visible || !isOthersVisible);
                        break;
                    case 2: // top3
                        showLabel = isTop3Visible && !isOthersVisible;
                        break;
                    case 3: // otros
                        showLabel = isOthersVisible;
                        break;
                }
        
                if (showLabel) {
                    const value = totals[params.dataIndex];
                    if (value === 0) {
                        return ''; // No mostrar el valor si es 0
                    }
                    let valueNew = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        currency: 'USD',
                        currencySign: 'accounting'
                    }).format(value);
                    return valueNew.replace(/\$/g, '') + aux.divider;
                }
                return '';
            };
    
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function(params) {
                        let index = params[0].dataIndex;
                    
                        if (typeof index !== 'number') {
                            index = aux.dayListLabel.findIndex(month => month.startsWith(index));
                        }
                    
                        let monthLabel = (typeof index === 'number' && aux.dayListLabel[index]) ? aux.dayListLabel[index] : 'Índice no válido';
                        let result = `${monthLabel} <br/>`;
                        params.forEach(param => {
                            let amount = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD', 
                                currencySign: 'accounting',
                                minimumFractionDigits: 1, 
                                maximumFractionDigits: 1
                            }).format(param.value);
                            let valueWithoutSign = amount.replace(/\$/g, '');
                            result += `${param.marker} ${param.seriesName}: ${valueWithoutSign}${aux.divider}<br/>`;
                        });
                        return result;
                    }
                },
                legend: {
                    data: data['labels'] === null ? '' : data['labels'],
                    selectedMode: false
                },
                xAxis: [
                    groupby === 1 || groupby === 2 ?
                    {
                        type: 'category',
                        data: data['dayList'],
                        axisLabel: {
                            // Utiliza una función para determinar si una etiqueta debe mostrarse o no
                            interval: function (index, value) {
                                // Obtén la cantidad total de datos
                                var total = data['dayList'].length;
                                // Muestra solo la primera y la última etiqueta
                                return index === 0 || index === total - 1;
                            }
                        },
                        axisTick: {
                            show: true
                        },
                    }
                :
                    {
                        type: 'category',
                        data: data['dayList'],
                        axisLabel: {
                            interval: 0
                        },
                        axisTick: {
                            show: true
                        },
                    }
                ],
                yAxis: {
                    name: '',
                    splitLine: { show: false}
                },
                series: [
                    {
                        name: data['labels'][0] ? data['labels'][0] : '',
                        type: 'bar',
                        stack: 'total',
                        color: '#6844D8',
                        data: data['top1'],
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => getLabelSettings(0, params),
                            color: '#000'
                        }
                    },
                    {
                        name: data['labels'][1] ? data['labels'][1] : '',
                        type: 'bar',
                        stack: 'total',
                        data: data['top2'],
                        color: '#3C0383',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => getLabelSettings(1, params),
                            color: '#000'
                        }
                    },
                    {
                        name: data['labels'][2] ? data['labels'][2] : '',
                        type: 'bar',
                        stack: 'total',
                        data: data['top3'],
                        color: '#9191F4',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => getLabelSettings(2, params),
                            color: '#000'
                        }
                    },
                    {
                        name: data['labels'][3] ? data['labels'][3] : '',
                        type: 'bar',
                        stack: 'total',
                        data: data['others'],
                        color: '#C9C9FF',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => getLabelSettings(3, params),
                            color: '#000'
                        }
                    }
                ]
            };
    
            setChartOption(option);
        });
    }
    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    const Modal = ({ onClose, children }) => {
                return(
                    <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                        <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                        <div className="flex justify-between">
                <h2 className="font-bold text-xl">Ingresos operativos</h2>
                <button onClick={toggleModal}>
                    <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex">
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    : 
                        null
                    }
                </div>
            </div>
                            {children}
                        </div>
                    </div>
                );
            }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Ingresos operativos</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                                <p>
                                    Del {formatDate(prestart)} al {formatDate(preend)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    : 
                        null
                    }
                </div>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={chartOption}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={chartOption}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
}

export const OperativesOutflows = ({start, end, prestart, preend, groupby, comparison, none, top = 3, idRS=''}) => {   
    const [chartOption, setChartOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone ] = useState(false);
    const [openHover, setOpenHover] = useState(false);

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null && accessToken) {
            getData();
        }
    }, [start, end, prestart, preend, groupby, idRS]);
    
    const getData = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        let body = {
            "clientID": idClient,
            "startDate": start,
            "endDate": end,
            "startPre": prestart, 
            "endPre": preend,
            "groupBy": groupby, 
            "inOut": '-',
            "topV": top
        }
        if(idRS){
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/chart/OperativeOutflows`, {
            method: 'POST', 
            headers: headers, 
            body: JSON.stringify(body)
        }).then(res => res.json())
        .then(aux => {
            setTotalThis(aux.totalThis);
            setTotalPrev(aux.totalPrev);
            setPercentage(aux.percentage);
            setDivider(aux.divider);

            if(none === true) {
                setIsNone(true);
            }else {
                setIsNone(false);
            }

            const data = {
                dayList: aux['dayList'],
                top1: aux['top1'],
                top2: aux['top2'],
                top3: aux['top3'],
                others: aux['another'],
                labels: aux['labels']
            };
            
            const totals = aux.dayList.map((_, index) => (
                data.top1[index] + data.top2[index] + data.top3[index] + data.others[index]
            ));
    
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function(params) {
                        let index = params[0].dataIndex;
                    
                        if (typeof index !== 'number') {
                            index = aux.dayListLabel.findIndex(month => month.startsWith(index));
                        }
                    
                        let monthLabel = (typeof index === 'number' && aux.dayListLabel[index]) ? aux.dayListLabel[index] : 'Índice no válido';
                        let result = `${monthLabel} <br/>`;
                        params.forEach(param => {
                            let amount = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD', 
                                currencySign: 'accounting',
                                minimumFractionDigits: 1, 
                                maximumFractionDigits: 1
                            }).format(param.value);
                            let valueWithoutSign = amount.replace(/\$/g, '');
                            result += `${param.marker} ${param.seriesName}: ${valueWithoutSign}${aux.divider}<br/>`;
                        });
                        return result;
                    }
                },
                legend: {
                    data: data['labels'] === null ? '' : data['labels'],
                    selectedMode: false
                },
                xAxis: [
                    groupby === 1 || groupby === 2 ?
                    {
                        type: 'category',
                        data: data['dayList'],
                        axisLabel: {
                            // Utiliza una función para determinar si una etiqueta debe mostrarse o no
                            interval: function (index, value) {
                                // Obtén la cantidad total de datos
                                var total = data['dayList'].length;
                                // Muestra solo la primera y la última etiqueta
                                return index === 0 || index === total - 1;
                            }
                        },
                        axisTick: {
                            show: true
                        },
                    }
                :
                    {
                        type: 'category',
                        data: data['dayList'],
                        axisLabel: {
                            interval: 0
                        },
                        axisTick: {
                            show: true
                        },
                    }
                ],
                yAxis: {
                    name: '',
                    splitLine: { show: false}
                },
                series: [
                    {
                        name: data['labels'][0] ? data['labels'][0] : '',
                        type: 'bar',
                        stack: 'total',
                        color: '#6844D8',
                        data: data['top1'], 
                    },
                    {
                        name: data['labels'][1] ? data['labels'][1] : '',
                        type: 'bar',
                        stack: 'total',
                        data: data['top2'],
                        color: '#3C0383'
                    },
                    {
                        name: data['labels'][2] ? data['labels'][2] : '',
                        type: 'bar',
                        stack: 'total',
                        data: data['top3'],
                        color: '#9191F4'
                    },
                    {
                        name: data['labels'][3] ? data['labels'][3] : '',
                        type: 'bar',
                        stack: 'total',
                        data: data['others'],
                        color: '#C9C9FF',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => {
                                const value = totals[params.dataIndex];
                                if(value === 0) {
                                    return '';
                                }
                                let valueNew = new Intl.NumberFormat('en-US', {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: 'USD', currencySign: 'accounting'}).format(value);
                                let valueWithoutSign = valueNew.replace(/\$/g, '');
                                return Number.isFinite(value) ? valueWithoutSign + aux.divider : '0';
                            },
                            color: '#000'
                        }
                    }
                ]
            };
    
            setChartOption(option);
        });
    }

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    const Modal = ({ onClose, children }) => {
        return(
            <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                <div className="flex justify-between">
        <h2 className="font-bold text-xl">Ingresos operativos</h2>
        <button onClick={toggleModal}>
            <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
        </button>
    </div>
    <div className="mt-2 flex justify-between">
        <div className="flex">
            <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
            {isNone ? 
            <></>
            : 
            <>
                <p className="text-sm mr-2 leading-8">vs</p>
                <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
            </>
            }
        </div>
        <div>
            {percentage > 0 && !isNone ?
                <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                    <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                </div>
            : percentage < 0 && !isNone ? 
                <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                    <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                </div>
            :
                null
            }
        </div>
    </div>
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Egresos operativos</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                                <p>
                                    Del {formatDate(prestart)} al {formatDate(preend)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} en el {comparison === 'ninguno' ? 'año anterior' : comparison}</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    :   percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    :
                        null
                    }
                </div>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={chartOption}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={chartOption}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
}

export const FinalBalanceCash = ({start, end, prestart, preend, groupby, comparison, none, idRS = ''}) => {
    const [option, setOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone ] = useState(false);
    const [openHover, setOpenHover] = useState(false);

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null) {
            getData();
        }
    }, [start, end, prestart, preend, groupby, idRS]);

    const getData = () => {
        let headers = new Headers();
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            startDate: start,
            endDate: end,
            startPreDate: prestart,
            endPreDate: preend,
            groupBy: groupby
        }
        if(idRS) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/chart/CashBalance`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(json => {
            setTotalThis(json.totalThis);
            setTotalPrev(json.average);
            setPercentage(json.percentage);
            setDivider(json.divider);
            if(none === true) {
                setIsNone(true);
            }else {
                setIsNone(false);
            }

            setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross', crossStyle: { color: '#999' } },
                    formatter: function(params) {
                        let index = params[0].dataIndex;
                    
                        if (typeof index !== 'number') {
                            index = json.Days .findIndex(month => month.startsWith(index));
                        }
                    
                        let monthLabel = (typeof index === 'number' && json.Days[index]) ? json.Days[index] : 'Índice no válido';
                    
                        let result = `${monthLabel} <br/>`;
                        params.forEach(param => {
                            let amount = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD', 
                                currencySign: 'accounting',
                                minimumFractionDigits: 1, 
                                maximumFractionDigits: 1
                            }).format(param.value);
                            let valueWithoutSign = amount.replace(/\$/g, '');
                            result += `${param.marker} ${param.seriesName}: ${valueWithoutSign}${json.divider}<br/>`;
                        });
                        return result;
                    }
                },
                xAxis: [
                    groupby === 1 || groupby === 2 ?
                    {
                        type: 'category',
                        data: json['dayList'],
                        axisLabel: {
                            formatter: function (value, index) {
                                var total = json['dayList'].length;
                                return index === 0 || index === total - 1 ? value : '';
                            }
                        },
                        axisTick: {
                            show: true
                        },
                        axisPointer: { type: 'shadow' },
                        axisLine: { show: false },
                        splitLine: { show: false }
                    }
                    :
                    {
                        type: 'category',
                        data: json['dayList'],
                        axisLabel: {
                            interval: 0,
                        },
                        axisTick: {
                            show: true
                        },
                        axisPointer: { type: 'shadow' },
                        axisLine: { show: false },
                        splitLine: { show: false }
                    }
                ],
                yAxis: [
                    {
                        type: 'value', 
                        name: '',
                        axisLine: { show: false },
                        splitLine: { show: false }
                    },
                    {
                        type: 'value', 
                        name: '',
                        axisLine: { show: false },
                        splitLine: { show: false } 
                    }
                ],
                series: [
                    {
                        name: 'Periodo actual',
                        type: 'line',
                        color: '#4E00AF',
                        data: json['ThisPeriod']
                    },
                    {
                        name: 'Promedio',
                        type: 'line',
                        color: '#C9C9FF',
                        data: json['arrayAverage']
                    }
                ]
            });
        });
    };

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    const Modal = ({ onClose, children }) => {
                return(
                    <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                        <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                            <div className="flex justify-between">
                                <h2 className="font-bold text-xl">Balance de efectivo</h2>
                                <button onClick={toggleModal}>
                                    <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                                </button>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="flex">
                                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                                    {isNone === true ?
                                    <></>
                                    : 
                                    <>
                                        <p className="text-sm mr-2 leading-8">vs</p>
                                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} promedio en el periodo</h4>
                                    </>
                                    }
                                </div>
                                <div>
                                    {percentage > 0 && !isNone ?
                                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                                        </div>
                                    :   percentage < 0 && !isNone ?
                                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            </div>
                                {children}
                        </div>
                    </div>
                );
    }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Balance de efectivo</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                                <p>
                                    Del {formatDate(prestart)} al {formatDate(preend)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                        <p className="text-sm mr-2 leading-8">vs</p>
                        <h4 className="text-sm leading-8">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalPrev)} {divider} promedio en el periodo</h4>
                    </>
                    }
                </div>
                <div>
                    {percentage > 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-green-300">
                            <p className="text-green-500 font-bold">+{Math.round(percentage)}%</p>
                        </div>
                    : percentage < 0 && !isNone ?
                        <div className="w-20 h-8 rounded-lg flex justify-center items-center bg-red-300">
                            <p className="text-red-500 font-bold">({Math.abs(Math.round(percentage))}%)</p>
                        </div>
                    :
                        null
                    }
                </div>
            </div>
            <ReactEChartsCore 
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={true}
                        theme="theme_name"
            />
            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
}

export const TopTenInflowChart = ({start, end, prestart, preend, groupby, comparison, none, top = 9, idRS = ''}) => { 
    const [option, setOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone] = useState(false);
    const [openHover, setOpenHover] = useState(false);
    const [lenthTop, setLengthTop] = useState();


    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null){
            getData();
        }
    }, [start, end, prestart, preend, groupby, top, idRS]);

    const getData = () => {
        let headers = new Headers();
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            startDate: start,
            endDate: end,
            startPre: prestart,
            endPre: preend,
            groupBy: groupby,
            TopVal: top, 
            inOut: '+',
        }
        if(idRS) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/chart/OperativeInflowsNew`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        }).then( res => res.json())
            .then(result => {
                result.tops.sort((a, b) => b.value - a.value);
                setLengthTop(result.length);
                setTotalThis(result.totalThis);
                setTotalPrev(result.totalPrev);
                setPercentage(result.porcentage);
                setDivider(result.divider)

                if(none === true) {
                    setIsNone(true);
                }else {
                    setIsNone(false);
                }

                const colors = ['#DDDDF2', '#BBBBEA', '#9898EA', '#6F6FDD', '#4343D6', '#292987', '#1C1C4F', '#3D3DA3', '#6E5BAA', '#907FD6', '#BFB7ED', '#D6D3F2', '#9796AA', '#D3D3D3', '#C1C1DB'];

                let tops = result.tops
                let options = {
                    color: colors,
                    tooltip: {
                        trigger: 'item',
                        formatter: function(params) {
                            return params.name + ':<br/>' 
                                    + (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(params.value)).replace(/\$/g, '') + result.divider + '<br/>' 
                                    + params.percent + '%';
                        }
                    }, 
                    legend: {
                        top: 'center',
                        left: 'left', 
                        padding: -1,
                        selectedMode: false,
                        orient: 'vertical'
                    },
                    series: [
                        {
                            name: function( value ) {
                                return value
                            }, 
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: true,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false, 
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                    fontSize: 20, 
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show:false
                            },
                            data: tops
                        }
                    ]
                }

                setOption(options);
            });
    }

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const Modal = ({ onClose, children }) => {
                return(
                    <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                        <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                            <div className="flex justify-between">
                                <h2 className="font-bold text-xl">Top Ingresos</h2>
                                <button onClick={toggleModal}>
                                    <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                                </button>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="flex">
                                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                                    {isNone === true ?
                                    <></>
                                    : 
                                    <>
                                    </>
                                    }
                                </div>
                            </div>
                                {children}
                        </div>
                    </div>
                );
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Top Ingresos</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                    </>
                    }
                </div>
            </div>
            <div className="w-full ml-10">
                <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                />
            </div>
            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }

        </div>
    );
}

export const TopTenOutflowChart = ( {start, end, prestart, preend, groupby, comparison, none, top = 9, idRS = ''} ) => {
    const [option, setOption] = useState({});
    const {idClient, accessToken} = useStateContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [totalThis, setTotalThis] = useState('');
    const [totalPrev, setTotalPrev] = useState('');
    const [percentage, setPercentage] = useState();
    const [divider, setDivider] = useState();
    const [ isNone, setIsNone] = useState(false);
    const [openHover, setOpenHover] = useState(false);
    const [lenthTop, setLengthTop] = useState();

    useEffect(() => {
        if(start !== null && end !== null && prestart !== null && preend !== null && groupby !== null){
            getData();
        }
    }, [start, end, prestart, preend, groupby, top, idRS]);

    const getData = () => {
        let headers = new Headers();
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            startDate: start,
            endDate: end,
            startPre: prestart,
            endPre: preend,
            groupBy: groupby,
            topVal: top, 
            inOut: '-'
        }
        if(idRS) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }
        fetch(`${urlBase}/chart/OperativeOutflowsNew`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        }).then( res => res.json())
            .then(result => {
                setLengthTop(result.length);
                setTotalThis(result.totalThis);
                setTotalPrev(result.totalPrev);
                setPercentage(result.porcentage);
                setDivider(result.divider)

                if(none === true) {
                    setIsNone(true);
                }else {
                    setIsNone(false);
                }

                const colors = ['#DDDDF2', '#BBBBEA', '#9898EA', '#6F6FDD', '#4343D6', '#292987', '#1C1C4F', '#3D3DA3', '#6E5BAA', '#907FD6', '#BFB7ED', '#D6D3F2', '#9796AA', '#D3D3D3', '#C1C1DB'];

                let tops = result.tops
                let options = {
                    color: colors, 
                    tooltip: {
                        trigger: 'item',
                        formatter: function(params) {
                            return params.name + ':<br/>' 
                                    + (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(params.value)).replace(/\$/g, '') + result.divider + '<br/>' 
                                    + params.percent + '%';
                        }
                    }, 
                    legend: {
                        top: 'center',
                        left: 'left', 
                        padding: -1,
                        selectedMode: false,
                        orient: 'vertical'
                    },
                    series: [
                        {
                            name: function( value ) {
                                return value
                            }, 
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: true,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false, 
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                    fontSize: 20, 
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show:false
                            },
                            data: tops
                        }
                    ]
                }

                setOption(options);
            });
    }

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    }

    const formatDate = ( date ) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8)

        return `${day}/${month}/${year}`;
    }


    const Modal = ({ onClose, children }) => {
                return(
                    <div className="fixed inset-0 flex items-center justify-center z-[100] px-4 py-6 sm:px-6 lg:px-8" onClick={onClose}>
                        <div className="bg-white p-4 rounded-md shadow-xl mx-auto max-w-3xl w-full h-1/2 sm:h-auto sm:max-h-[90vh] overflow-auto z-[100]" onClick={e => e.stopPropagation()}>
                            <div className="flex justify-between">
                                <h2 className="font-bold text-xl">Top Egresos</h2>
                                <button onClick={toggleModal}>
                                    <XCircleIcon className="w-7 h-7 text-ligth-md-purple"/>
                                </button>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="flex">
                                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                                    {isNone === true ?
                                    <></>
                                    : 
                                    <>
                                    </>
                                    }
                                </div>
                            </div>
                                {children}
                        </div>
                    </div>
                );
    }

    return (
        <div className="w-full bg-white shadow-lg rounded-lg px-3 mb-5 h-[20%]">
            <div className="flex justify-between">
                <div className="flex relative">
                    <h2 className="font-bold text-xl">Top Egresos</h2>
                    {openHover &&
                        <div className="absolute top-14 mt-2 w-96 p-4 bg-white border border-gray-300 rounded shadow text-left z-10">
                            <div className="flex flex-col">
                                <p className="text-ligth-md-purple font-bold">
                                    Del {formatDate(start)} al {formatDate(end)}: {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <button onClick={toggleModal}>
                    <PlusIcon/>
                </button>
            </div>
            <div className="mt-2 flex justify-between">
                <div className="flex">
                    <h2 className="text-ligth-md-purple font-bold text-xl mr-2" onMouseEnter={() => {
                    setOpenHover(true);
                }} 
                onMouseLeave={() => {
                    setOpenHover(false);
                }}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 1, currencySign: 'accounting'}).format(totalThis)} {divider}</h2>
                    {isNone ? 
                    <></>
                    : 
                    <>
                    </>
                    }
                </div>
            </div>
            <div className="w-full ml-10">
                <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                />
            </div>

            {isModalOpen && 
                <div className="z-[1000]">
                    <div onClick={toggleModal} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-[99] h-screen opacity-50 bg-md-gray'></div>
                    <Modal onClose={toggleModal}>
                        <ReactEChartsCore 
                            echarts={echarts}
                            option={option}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                        />
                    </Modal>
                </div>
            }
        </div>
    );
}
