
import React from 'react'

const MultiTransactionLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="31.081" height="31.34" viewBox="0 0 31.081 31.34">
      <g id="Grupo_249" data-name="Grupo 249" transform="matrix(0.719, 0.695, -0.695, 0.719, 18.975, 0)">
        <g id="Grupo_248" data-name="Grupo 248" transform="translate(0 0)" >
          <path id="Trazado_174" data-name="Trazado 174" d="M.01,16.7c0-2.918.019-5.837-.01-8.756A2.838,2.838,0,0,1,1.147,5.53C3.222,3.874,5.263,2.174,7.322.5a1.469,1.469,0,0,1,2.2-.008c2.083,1.7,4.152,3.416,6.246,5.1a2.687,2.687,0,0,1,1.066,2.254c-.015,4.8-.007,9.609-.007,14.413,0,1.148.006,2.3,0,3.443-.007,1.108-.521,1.613-1.638,1.614q-6.763,0-13.527,0c-1.135,0-1.642-.51-1.643-1.659Q.006,21.179.01,16.7M9.893,6.646A1.475,1.475,0,0,0,8.4,5.2a1.473,1.473,0,1,0,1.491,1.45" transform="translate(0 0)" fill={props.fill || "#b4b4b4"}/>
        </g>
      </g>
    </svg>
  )
}

export default MultiTransactionLogo