import React from 'react'

const PPSect6Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Enmascarar_grupo_117" data-name="Enmascarar grupo 117" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1171" data-name="Grupo 1171" transform="translate(493.422 226)">
        <path id="Trazado_1462" data-name="Trazado 1462" d="M100.547,167a10.547,10.547,0,1,0,10.547,10.547A10.559,10.559,0,0,0,100.547,167Zm0,18.75a8.2,8.2,0,1,1,8.2-8.2A8.212,8.212,0,0,1,100.547,185.75Zm0,0" transform="translate(-82.969 -153.953)"/>
        <path id="Trazado_1463" data-name="Trazado 1463" d="M169.646,238.359l-3.315,3.315-3.315-3.315-1.657,1.657,3.315,3.314-3.315,3.315,1.657,1.657,3.315-3.315,3.315,3.315,1.657-1.657-3.315-3.315,3.315-3.314Zm0,0" transform="translate(-148.753 -219.738)"/>
        <path id="Trazado_1464" data-name="Trazado 1464" d="M35.156,0H0V40H35.156ZM32.813,37.656H2.344V2.344H5.859V5A2.344,2.344,0,1,0,8.2,5V2.344h4.688V5a2.344,2.344,0,1,0,2.344,0V2.344h4.688V5a2.344,2.344,0,1,0,2.344,0V2.344h4.688V5A2.344,2.344,0,1,0,29.3,5V2.344h3.516Zm0,0"/>
        </g>
    </g>
    </svg>
  )
}

export default PPSect6Logo