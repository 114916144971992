import React from 'react'

const PPSect10Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Grupo_1209" data-name="Grupo 1209" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1220" data-name="Grupo 1220" transform="translate(490.952 226)">
        <g id="Grupo_1211" data-name="Grupo 1211" transform="translate(0.053)">
            <g id="Grupo_1210" data-name="Grupo 1210">
            <path id="Trazado_1480" data-name="Trazado 1480" d="M39.017,17.3a3.521,3.521,0,0,0-4.975,0l-1.176,1.184V11.6a3.492,3.492,0,0,0-1.03-2.486L28.521,5.8a3.569,3.569,0,0,0-.343-.3V1.172A1.172,1.172,0,0,0,27.006,0H1.225A1.172,1.172,0,0,0,.053,1.172V34.063a1.172,1.172,0,0,0,1.172,1.172H4.74v3.594A1.172,1.172,0,0,0,5.912,40H31.694a1.172,1.172,0,0,0,1.172-1.172V28.461l6.151-6.193A3.52,3.52,0,0,0,39.017,17.3ZM25.834,7.109h.2a1.164,1.164,0,0,1,.829.343l3.315,3.315a1.164,1.164,0,0,1,.343.829v.2H25.834ZM4.74,5.938V32.891H2.4V2.344H25.834V4.766H5.912A1.172,1.172,0,0,0,4.74,5.938ZM30.522,37.656H7.084V7.109H23.49v5.859a1.172,1.172,0,0,0,1.172,1.172h5.859v6.7l-8,8.057a1.172,1.172,0,0,0-.309.555l-1.032,4.336A1.173,1.173,0,0,0,22.587,35.2l4.347-1.022a1.173,1.173,0,0,0,.563-.315l3.025-3.046v6.835Zm-4-6.134-.451.454-2.172.511.516-2.169.448-.451a3.508,3.508,0,0,1,1.659,1.655ZM28.232,29.8a5.853,5.853,0,0,0-1.661-1.654l6.653-6.7,1.657,1.657Zm9.124-9.186-.823.829-1.657-1.657.826-.831a1.172,1.172,0,1,1,1.654,1.66Z" transform="translate(-0.053)"/>
            </g>
        </g>
        <g id="Grupo_1213" data-name="Grupo 1213" transform="translate(9.428 9.453)">
            <g id="Grupo_1212" data-name="Grupo 1212">
            <path id="Trazado_1481" data-name="Trazado 1481" d="M130.6,121h-9.375a1.172,1.172,0,0,0,0,2.344H130.6a1.172,1.172,0,0,0,0-2.344Z" transform="translate(-120.053 -121)"/>
            </g>
        </g>
        <g id="Grupo_1215" data-name="Grupo 1215" transform="translate(9.428 14.141)">
            <g id="Grupo_1214" data-name="Grupo 1214">
            <path id="Trazado_1482" data-name="Trazado 1482" d="M130.6,181h-9.375a1.172,1.172,0,1,0,0,2.344H130.6a1.172,1.172,0,1,0,0-2.344Z" transform="translate(-120.053 -181)"/>
            </g>
        </g>
        <g id="Grupo_1217" data-name="Grupo 1217" transform="translate(9.428 18.828)">
            <g id="Grupo_1216" data-name="Grupo 1216">
            <path id="Trazado_1483" data-name="Trazado 1483" d="M137.631,241H121.225a1.172,1.172,0,1,0,0,2.344h16.406a1.172,1.172,0,1,0,0-2.344Z" transform="translate(-120.053 -241)"/>
            </g>
        </g>
        <g id="Grupo_1219" data-name="Grupo 1219" transform="translate(9.428 25.859)">
            <g id="Grupo_1218" data-name="Grupo 1218">
            <path id="Trazado_1484" data-name="Trazado 1484" d="M131.428,331.343a1.172,1.172,0,0,0-1.657,0l-6.2,6.2-1.515-1.515a1.172,1.172,0,1,0-1.657,1.657l2.344,2.344a1.172,1.172,0,0,0,1.657,0L131.428,333A1.172,1.172,0,0,0,131.428,331.343Z" transform="translate(-120.053 -331)"/>
            </g>
        </g>
        </g>
    </g>
    </svg>

  )
}

export default PPSect10Logo