import React from 'react'

const ControlCenterLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
            <g id="Grupo_137" data-name="Grupo 137" transform="translate(29.999 -0.239) rotate(90)">
                <g id="Grupo_136" data-name="Grupo 136" transform="translate(0.239 -0.001)" clip-path="url(#clip-path)">
                <g id="Ikon" transform="translate(3.106 0.014)">
                    <path id="Trazado_1491" data-name="Trazado 1491" d="M24.508,12.655v18.26a1.129,1.129,0,0,1-2.254,0V12.655A2.715,2.715,0,0,1,20,10.032a2.715,2.715,0,0,1,2.254-2.623V3.071a1.129,1.129,0,0,1,2.254,0V7.409a2.715,2.715,0,0,1,2.254,2.623,2.715,2.715,0,0,1-2.254,2.623Z" transform="translate(-4.221 -2)" fill={props.fill || "#1d0039"}/>
                    <path id="Trazado_1492" data-name="Trazado 1492" d="M19.763,22.883a2.719,2.719,0,0,1-2.254,2.623v5.409a1.129,1.129,0,0,1-2.254,0V25.506a2.653,2.653,0,0,1,0-5.246V3.071a1.129,1.129,0,0,1,2.254,0V20.26a2.719,2.719,0,0,1,2.254,2.623Z" transform="translate(-5.111 -2)" fill={props.fill || "#1d0039"}/>
                    <path id="Trazado_1493" data-name="Trazado 1493" d="M12.763,14.316a2.719,2.719,0,0,1-2.254,2.623V30.915a1.129,1.129,0,0,1-2.254,0V16.939a2.653,2.653,0,0,1,0-5.246V3.071a1.129,1.129,0,0,1,2.254,0v8.622a2.719,2.719,0,0,1,2.254,2.623Z" transform="translate(-6 -2)" fill={props.fill || "#1d0039"}/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default ControlCenterLogo