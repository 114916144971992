import React from 'react'

const FileUploadLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
    <g id="Enmascarar_grupo_47" data-name="Enmascarar grupo 47" transform="translate(-693 -514)" clip-path="url(#clip-path)">
      <g id="Grupo_956" data-name="Grupo 956" transform="translate(694 514)">
        <g id="Grupo_133" data-name="Grupo 133" clip-path="url(#clip-path-2)">
          <path id="Trazado_605" data-name="Trazado 605" d="M0,14.974Q0,9.55,0,4.127A3.963,3.963,0,0,1,4.129,0q9.5,0,19,0a3.964,3.964,0,0,1,4.124,4.128q0,8.548.006,17.1a1.253,1.253,0,0,1-.382.966c-2.5,2.489-4.989,4.993-7.5,7.474a1.2,1.2,0,0,1-.764.315c-4.9.018-9.806.029-14.709.006A3.945,3.945,0,0,1,0,25.995C0,22.321,0,18.648,0,14.974m19.663,9.087a19.7,19.7,0,0,0-.031,2.9L24.3,22.321" transform="translate(0 -0.001)" fill="#d1d1d1"/>
          <path id="Trazado_609" data-name="Trazado 609" d="M230.125,256.525l-4.663,4.639a19.7,19.7,0,0,1,.031-2.9,2,2,0,0,1,1.874-1.719,26.333,26.333,0,0,1,2.758-.021" transform="translate(-205.829 -234.204)" fill="#9e9e9e"/>
          <path id="Trazado_610" data-name="Trazado 610" d="M145.309,152.606v-4.18a10.02,10.02,0,0,1,1.577.029c.177.029.418.389.428.607.047.967.03,1.937.015,2.905a.575.575,0,0,1-.537.63c-.485.033-.973.008-1.484.008" transform="translate(-132.68 -135.51)" fill="#d1d1d1"/>
          <path id="Trazado_611" data-name="Trazado 611" d="M71.051,148.379c.473,0,.917-.007,1.361,0a.584.584,0,0,1,.626.58.6.6,0,0,1-.577.676c-.456.031-.916.007-1.41.007Z" transform="translate(-64.876 -135.481)" fill="#d1d1d1"/>
          <text id="_.XLX" data-name=".XLX" transform="translate(10.631 12.522)" fill="#9e9e9e" font-size="6" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="-6" y="0">.XLX</tspan></text>
        </g>
      </g>
    </g>
  </svg>
  
  )
}

export default FileUploadLogo