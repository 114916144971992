import React from 'react'

const DotLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
        <circle id="Elipse_67" data-name="Elipse 67" cx="4.5" cy="4.5" r="4.5" fill={props.fill || "#adadad"}/>
    </svg>

  )
}

export default DotLogo