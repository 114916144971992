import React, {useState, useEffect} from 'react'
import {useTable} from 'react-table'
import { Dropdown,Loading } from '../components';
import DatePicker from 'react-datepicker'
import { PlusCircleIcon, XMarkIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import 'moment/locale/es';
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import { useStateContext } from '../contexts/ContextProvider';
import { getToken } from '../fetch';

import { urlBase } from '../authConfig';
moment.locale('es');
const BanksEditionScreen = () => {
    const {idClient,accessToken,setToken} = useStateContext();
    const navigate = useNavigate();
    const [itemToDelete, setItemToDelete] = useState();
    const [optionsModal, setOptionsModal] = useState();
    const isAuthenticated = useIsAuthenticated();
    const [banco,setBanco] = useState({name:'Banco'});
    const [bancos,setBancos]=useState([]);
    const [opcionBanco,setOpcionBanco] = useState({name:'Banco'});
    const [opcionBank,setOpcionBank] = useState('');
    const [opcionesBancos,setOpcionesBancos] = useState([]);
    const [accountData,setAccountData] = useState([]);
    const [accountName, setAccountName] = useState('');
    const [currentyType, setCurrencyType] = useState('');
    const [idUpdate, setIdUpdate] = useState();

    const [forexData,setForexData] = useState([]);

    const [data,setData] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [newDate, setnewDate] = useState();
    const [forexDate, setForexDate] = useState(new Date());
    const [dateUpload, setDateUpload] = useState();
    const [newDateUpload, setNewDateUpload] = useState();
    
    const [moneda,setMoneda] = useState({name: "Moneda"});
    const [monedaUpload,setMonedaUpload] = useState({});
    const [monedas, setMonedas] = useState([{name:'MXN'},{name:'USD'}])

    const [typeBank, setTypeBank] = useState([{name: 'Debito'}, {name: 'Credito'}]);
    const [bankType, setBankType] = useState( {name: 'Tipo de cuenta'});
    const [bankTypeUpload, setBankTypeUpload] = useState({});
    const [bankTypeReal, setBankTypeReal] = useState();

    const [initialValue, setInitialValue] = useState(0);
    const [currentValue, setCurrentValue] = useState(0);

    const [account, setAccount] = useState();
    
    const [selectedAccountId,setSelectedAccountId] = useState({})

    const [forexValue,setForexValue] = useState();

    const [disableNewForex,setDisableNewForex] = useState(true)
    
    const [selectedDate,setSelectedDate] = useState()

    const [showAlert,setShowAlert] = useState(false)
    const [alertError,setAlertError] = useState(false)

    const [saveAccountLoading,setSaveAccountLoading] = useState(false)
    const [accountTableActive,setAccountTableActive] = useState(false)

    const[forexModal,setForexModal] = useState(false)
    const [AddAccount, SetAddAccount] = useState(false);
    const [ accountDataMXN, setAccountDataMXN] = useState([]);
    const [ accountDataUSD, setAccountDataUSD] = useState([]);
    const [ DeleteConfirmation, setDeleteConfirmation] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [modalAddCount, setModalAddCount] = useState('');
    const [defaultAccount, setDefaultAccount] = useState('');
    
    useEffect(() => {
        if(!isAuthenticated){
            navigate('/')
            return
        }
        getToken().then((token)=>{setToken(token)})

        getData();

        return () => {
         
        }
    },[])

    useEffect(() => {
        if(newDate === null || newDate === undefined){
            let dateNow = new Date();
            const year = dateNow.getFullYear();
            const month = (dateNow.getMonth() + 1).toString().padStart(2, '0');
            const day = dateNow.getDate().toString().padStart(2, '0');
            let aux = `${year}-${month}-${day}`;
            setnewDate(aux);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [reloadData]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let aux = `${year}-${month}-${day}`;
        setnewDate(aux);
    }

    const formatDateUpload = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let aux = `${year}-${month}-${day}`;
        setNewDateUpload(aux);
    }

    const getData = async () => {
        

        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        fetch(`${urlBase}/getAccounts`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            "clientID": idClient
        })
        })
          .then((response) => response.json())
          .then((aux) => {
            let newData=[]
            for (const property in aux) {
                newData[property] = aux[property]
            }
            let aux2 = Object.getOwnPropertyNames(aux);
            let aux3 = []
            aux3[0] = {name: 'Selecciona un Banco'}
            for(let i=1;i<aux2.length+1;i++){
                aux3[i] = {'name':aux2[i-1]}
            }
            setBancos(aux3)
            setBanco(aux3[0])
            let aux4 = [];
            let count= 0;
            for(let i=0;i<aux2.length;i++){
                for(let j=0;j<newData[aux2[i]].accounts.length;j++){
                    aux4[count] = {
                        id:newData[aux2[i]].accounts[j].accountID,
                        bank:newData[aux2[i]].bank,
                        account:newData[aux2[i]].accounts[j].accountNumber,
                        currency:newData[aux2[i]].accounts[j].currency,
                        initialValue:newData[aux2[i]].accounts[j].initialValue.toLocaleString('en-us'),
                        initialDate:newData[aux2[i]].accounts[j].initialDate.slice(0,10),
                        total: newData[aux2[i]].accounts[j].total
                    }
                    count++;
                }
            }
            setAccountData(aux4);
            let auxUSD = [];
            let auxMXN = [];
            for(let i = 0; i < aux4.length; i++) {
                if(aux4[i].currency === 'USD'){
                    auxUSD.push(aux4[i]);
                }else {
                    auxMXN.push(aux4[i]);
                }
            }

            setAccountDataMXN(auxMXN);
            setAccountDataUSD(auxUSD);

            setData(newData)
            if(aux3.length>1){
                setAccountTableActive(true)
            }
        });
        
        fetch(`${urlBase}/getBanks`,{
          method: 'GET',
          headers: headers
        })
          .then((response) => response.json())
          .then((aux) => {
            let aux2=[]
            for(let i=0;i<aux.length;i++){
                aux2[i] = {
                    'name':aux[i].bank,
                    'id':aux[i].idBank
                }
            }
            setOpcionesBancos(aux2);
        });
        setReloadData(false);
    }

    const handleOpcionBancoChange = (value) => {
        setOpcionBanco(value);
    }

    const handleBankChange = (value) => {
        setBanco(value)
        setSelectedAccountId('')
        setDisableNewForex(true)
        setForexData([])
        let aux = []
        if(value.name == 'Todos'){
            let aux2 = Object.getOwnPropertyNames(data);
            aux2.shift()
            let count=0
            for(let i=0;i<aux2.length;i++){
                for(let j=0;j<data[aux2[i]].accounts.length;j++){
                    aux[count] = {
                        id:data[aux2[i]].accounts[j].accountID,
                        bank:data[aux2[i]].bank,
                        account:data[aux2[i]].accounts[j].accountNumber,
                        currency:data[aux2[i]].accounts[j].currency,
                        initialValue:data[aux2[i]].accounts[j].initialValue.toLocaleString('en-us'),
                        initialDate:data[aux2[i]].accounts[j].initialDate.slice(0,10)
                    }
                    count++;
                }
            }
        }else{
            for(let i=0;i<data[value.name].accounts.length;i++){
                aux[i] = {
                    id:data[value.name].accounts[i].accountID,
                    bank:data[value.name].bank,
                    account:data[value.name].accounts[i].accountNumber,
                    currency:data[value.name].accounts[i].currency,
                    initialValue:data[value.name].accounts[i].initialValue.toLocaleString('en-us'),
                    initialDate:data[value.name].accounts[i].initialDate.slice(0,10)
                }
            }
        }
        
        setAccountData(aux)
    }

    const handleMonedaChange = (value) => {
        setMoneda(value)
    }

    const handleTipoBanco = (value) => {
        if(value === 'Debito'){
            setBankType(value);
            setBankTypeReal('D');
        }else {
            setBankType(value);
            setBankTypeReal('C');
        }

    }

    const getForexData = async (accountID) => {
        

        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        fetch(`${urlBase}/getForex`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "clientID": idClient
            })
            })
            .then((response) => response.json())
            .then((aux) => {
                let newData=[]
                for(let i = 0;i<aux.length;i++){
                    newData[i] = {
                        update: aux[i].update_d.slice(0,10),
                        mxnValue: aux[i].MXN
                    }
                }
                setForexData(newData)
            });
    }

    const updateForex = async () => {
        

        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let year = forexDate.getFullYear().toString()
        let month=forexDate.getMonth()+1
        let day = forexDate.getDate()
        if(day<10){
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }
        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fecha = year+'-'+month+'-'+day
        fetch(`${urlBase}/updateForex`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "clientID": idClient,
                'accountID':selectedAccountId,
                'updateDate':fecha,
                'mxn':forexValue,
            })
        })
            .then((response) => response.json())
            .then((aux) => {
                setForexDate(new Date())
                setForexValue('')
                getForexData(selectedAccountId)
        });
    }

    
     const accountRowSelect = (row) => {
        if(row.original.id == selectedAccountId){
            setSelectedAccountId('')
            setDisableNewForex(true)
            setForexData([])
            setSelectedDate()
        }else if(row.original.currency != 'MXN'){
            setSelectedAccountId(row.original.id)
            setSelectedDate()
            getForexData(row.original.id)
            setDisableNewForex(false)
            setForexModal(true)
        }
    }

    const closeModal = () => {
        setSelectedAccountId('')
        setDisableNewForex(true)
        setForexData([])
        setSelectedDate()
        setForexModal(false)
    }

    const setSelectedForex = (row) => {
        if(selectedDate == row.original.update){
            setSelectedDate()
            setForexValue('')
        }else{
            let month = row.original.update.slice(5,7)
            month = parseInt(month)
            month--
            let day = row.original.update.slice(8,10)
            let date = new Date(row.original.update.slice(0,4),month,day)
            setSelectedDate(row.original.update)
            setForexValue(row.original.mxnValue)
            setForexDate(date)
        }
    
    }
    

    const accountColumns = React.useMemo(
        () => [
          {
            Header: 'Banco',
            accessor: 'bank', // accessor is the "key" in the data
          },
          {
            Header: 'Cuenta',
            accessor: 'account',
          },
          {
            Header: 'Moneda',
            accessor: 'currency',
          },
          {
            Header: 'Valor Inicial',
            accessor: 'initialValue',
          },
          {
            Header: 'Fecha Inicio',
            accessor: 'initialDate',
          },
        ],
        []
    )


    const forexColumns = React.useMemo(
            () => [
                {
                Header: 'Actualización',
                accessor: 'update', // accessor is the "key" in the data
                },
                {
                Header: 'Valor en MXN',
                accessor: 'mxnValue',
                },
            ],
            []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns:accountColumns, data:accountData })

    const {
        getTableProps:getForexTableProps,
        getTableBodyProps:getForexTableBodyProps,
        headerGroups:forexHeaderGroups,
        rows:forexRows,
        prepareRow:forexPrepareRow,
    } = useTable({ columns:forexColumns, data:forexData })

    const disableNewForexNew = selectedDate || !selectedAccountId
    const disableNewForexSave = !selectedDate || !selectedAccountId
    const accountTableDisabled = accountTableActive ? '' : 'opacity-50';

    const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 

    if(forexModal){
        document.body.classList.add('overflow-y-hidden')
    }else{
        document.body.classList.remove('overflow-y-hidden')
    }

    const createAccount = async() => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json');
        fetch(`${urlBase}/createAccount`,  {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'bankID': opcionBanco['id'],
                'account': account,
                'currency': moneda['name'],
                'initialValue': initialValue,
                "date": newDate,
                "accountType": bankTypeReal
            })
        }).then(res => {
                if(res.ok){
                    setModalAddCount('success');
                }else {
                    setModalAddCount('failed');
                }
                setReloadData(true);
                setTimeout(() => {
                    SetAddAccount(false);
                }, 1000);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const updateAccount = () => {
        let headers = new Headers();

        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/updateAccount`, {
            method: 'POST', 
            headers: headers, 
            body: JSON.stringify({
                'clientID': parseInt(idClient),
                'accountID': idUpdate,
                'bankID': opcionBank['id'], 
                'account': defaultAccount,
                'currency': monedaUpload['name'],
                'initialValue': currentValue,
                'date': newDateUpload,
                'accountType': bankTypeReal
            })
        })
        .then(aux => {
            setReloadData(true);
            setTimeout(() => {
                setOptionsModal(false);
            }, 1500);
            setReloadData(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    const verifyTransactions = (total) => {
        if(total === 0 || total === null) {
            return false;
        }else {
            return true;
        }
    }

    const handleDeleteAccounts = async() => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        fetch(`${urlBase}/deleteAccount`, {
            method: 'POST', 
            headers: headers, 
            body: JSON.stringify({
                "clientID": idClient,
                "accountID": itemToDelete
            })
        }).then(res => {
            setReloadData(true);
            setDeleteConfirmation(false)
        });
    }

    const handleChangeBankUpload = (value) => {
        setOpcionBank(value);
    }

    const formatValue = (value) => {
        return value.replace(',', '');
    }

    const getPrevValues = (currency, id) => {
        if(currency === 'MXN'){
            accountDataMXN.map((item) => {
                if(item.id === id) {
                    handleChangeBankUpload(item.bank);
                    setDefaultAccount(item.account);
                    setMonedaUpload({name: item.currency});
                    setCurrentValue(parseFloat(formatValue(item.initialValue)));
                    setDateUpload(new Date(item.initialDate));
                    setIdUpdate(item.id);
                }
            })
            setOptionsModal(true);
        }else {
            accountDataUSD.map((item) => {
                if(item.id === id){
                    handleChangeBankUpload(item.bank);
                    setDefaultAccount(item.account);
                    setMonedaUpload({name: item.currency});
                    setCurrentValue(parseFloat(formatValue(item.initialValue)));
                    setDateUpload(new Date(item.initialDate));
                    setIdUpdate(item.id);
                }
            })
            setOptionsModal(true);
        }
    }

    const dateFormate = (date) => {
        let newDate = date.split("-");
        let year = newDate[0];
        let month = newDate[1];
        let day = newDate[2];
        let format = `${moment(`${month}-${day}-${year}`).locale('es').format('MMM D, YYYY')}`;
        let formatterDate = format.replace('.', '');
        formatterDate = formatterDate.charAt(0).toUpperCase() + formatterDate.slice(1);
        return formatterDate;
    }

    return(
        <div className='ml-20'>
                {AddAccount &&
                    <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
                        <div onClick={() => SetAddAccount(false)} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'></div>
                        <div className='bg-md-table-bg z-10 absolute top-[40%] left-[50%] px-[14px] mt-10 py-[28px] max-w-[600px] min-w-[600px] rounded-lg -translate-x-[50%] -translate-y-[50%]'>
                            <div className='flex justify-between'>
                                <div className='mr-16'>
                                    <h2 className='text-md-gray-subtitle text-3xl'>Agregar Cuenta</h2>
                                </div>
                                <div>
                                    <button onClick={() => {SetAddAccount(false)}}>
                                        <XMarkIcon className='h-7 w-7 text-md-gray-subtitle font-bold'/>
                                    </button>
                                </div>
                            </div>
                                {modalAddCount === 'success' &&
                                    <div className='mt-2 bg-green-500 w-full h-12'>
                                        <h3 className='text-center text-2xl text-md-white my-auto'>¡Registro de cuenta exitoso!</h3>
                                    </div>
                                }  
                                {modalAddCount === 'failed' &&
                                    <div className='mt-2 bg-red-600 w-full h-12'>
                                        <h3 className='text-center text-2xl text-md-white my-auto'>Fallo el registro de la cuenta</h3>
                                    </div>
                                }
                            <hr className='my-2 border-md-gray-subtitle'/>
                            <div className='my-2'>
                                <div>
                                    <h3 className='mt-3 text-xl text-md-gray font-bold text-left mb-1'>Banco:</h3>
                                    <Dropdown selected={opcionBanco} setSelected={handleOpcionBancoChange} options={opcionesBancos} className='bg-main-bg border-1  w-[100%] text-md-gray rounded-lg ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='text-xl text-md-gray font-bold text-left mb-1'>Numero de cuenta:</h3>
                                    <input type="text" onChange={(e) => setAccount(e.target.value)} value={account} className='bg-main-bg w-[100%] rounded-lg border-1 pl-2 py-2 mb-2 ml-2' placeholder='Numero de cuenta'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='mt-3 text-xl text-md-gray font-bold text-left mb-1'>Moneda:</h3>
                                    <Dropdown selected={moneda} setSelected={handleMonedaChange} options={monedas} className='bg-main-bg border-1  w-[100%] text-md-gray rounded-lg ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='text-xl text-md-gray font-bold text-left mb-1'>Valor Inicial</h3>
                                    <input type='number' onChange={(e) => setInitialValue(e.target.value)} value={initialValue} className='bg-main-bg w-[100%] rounded-lg border-1 pl-2 py-2 mb-2 ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='text-xl text-md-gray font-bold text-left mb-1'>Fecha Inicial</h3>
                                    <DatePicker selected={startDate} onChange={(date) => {
                                        formatDate(date);
                                        setStartDate(date);
                                        }} className='bg-main-bg w-[100%] rounded-lg border-1 pl-2 py-2 mb-2 ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='mt-3 text-xl text-md-gray font-bold text-left mb-1'>Tipo de cuenta</h3>
                                    <Dropdown selected={bankType} setSelected={handleTipoBanco} options={typeBank} className='bg-main-bg border-1  w-[60%] text-md-gray rounded-lg ml-2'/>
                                </div>
                            </div>
                            <button className='rounded-full bg-md-blue p-2 mt-5' onClick={createAccount}>
                                <p className='text-md-table-bg'>Agregar Cuenta</p>
                            </button>
                        </div>
                    </div>
                }
                {DeleteConfirmation &&
                    <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
                        <div onClick={() => setDeleteConfirmation(false)} className='fixed top-0 right-0 left-0 bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'></div>
                        <div className='bg-md-table-bg z-10 absolute top-[40%] left-[50%] px-[14px] mt-10 py-[28px] max-w-[600px] min-w-[600px] rounded-lg -translate-x-[50%] -translate-y-[50%]'>
                            <p className='text-center text-xl'>¿Estas seguro que quieres borrar el registro?</p>
                            <div className='flex justify-between px-10 mt-5'>
                                <button className='w-48 bg-md-gray text-md-white border-md-gray rounded-xl h-12 text-xl' onClick={() => setDeleteConfirmation(false)}>
                                    Cancelar
                                </button>
                                <button className='w-48 bg-md-blue text-md-white border-md-blue rounded-xl h-12 text-xl' onClick={() => handleDeleteAccounts()}>
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {optionsModal &&
                    <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
                        <div onClick={() => setOptionsModal(false)} className='fixed top-0 right-0 left-0 bottom-0 w-screen z -10 h-screen opacity-50 bg-md-gray'></div>
                        <div className='bg-md-table-bg z-10 absolute top-[40%] left-[50%] px-[14px] mt-10 py-[28px] max-w-[600px] min-w-[600px] rounded-lg -translate-x-[50%] -translate-y-[50%]'>
                            <div className='flex justify-between'>
                                <div className='mr-16'>
                                    <h2 className='text-md-gray-subtitle text-3xl'>Modificar cuentas</h2>
                                </div>
                                <div>
                                    <button onClick={() => setOptionsModal(false)}>
                                        <XMarkIcon className='h-7 w-7 text-md-gray-subtitle font-bold'/>
                                    </button>
                                </div>
                            </div>
                            <hr className='my-2 border-md-gray-subtitle'/>
                            <div className='my-2'>
                                <div>
                                    <h3 className='mt-3 text-xl text-md-gray font-bold text-left mb-1'>Banco:</h3>
                                    <Dropdown selected={opcionBank} setSelected={handleChangeBankUpload} options={opcionesBancos} className='bg-main-bg border-1  w-[100%] text-md-gray rounded-lg ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='text-xl text-md-gray font-bold text-left mb-1'>Numero de cuenta:</h3>
                                    <input type="text" onChange={(e) => setDefaultAccount(e.target.value)} value={defaultAccount} className='bg-main-bg w-[100%] rounded-lg border-1 pl-2 py-2 mb-2 ml-2' placeholder='Numero de cuenta'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='mt-3 text-xl text-md-gray font-bold text-left mb-1'>Moneda:</h3>
                                    <Dropdown selected={monedaUpload} setSelected={handleMonedaChange} options={monedas} className='bg-main-bg border-1  w-[100%] text-md-gray rounded-lg ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='text-xl text-md-gray font-bold text-left mb-1'>Valor Inicial</h3>
                                    <input type='number' onChange={(e) => setCurrentValue(e.target.value)} value={currentValue} className='bg-main-bg w-[100%] rounded-lg border-1 pl-2 py-2 mb-2 ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='text-xl text-md-gray font-bold text-left mb-1'>Fecha Inicial</h3>
                                    <DatePicker selected={dateUpload} onChange={(date) => {
                                        formatDateUpload(date);
                                        setDateUpload(date);
                                        }} className='bg-main-bg w-[100%] rounded-lg border-1 pl-2 py-2 mb-2 ml-2'/>
                                </div>
                                <div className='my-2'>
                                    <h3 className='mt-3 text-xl text-md-gray font-bold text-left mb-1'>Tipo de cuenta</h3>
                                    <Dropdown selected={bankType} setSelected={handleTipoBanco} options={typeBank} className='bg-main-bg border-1  w-[60%] text-md-gray rounded-lg ml-2'/>
                                </div>
                            </div>
                            <div>
                                <button className='rounded-full bg-md-blue p-2 mt-5' onClick={updateAccount}>
                                    <p className='text-md-table-bg'>Actualizar Datos</p>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            <div className='mt-5 mr-10 flex'>
                <div className='w-[90%]'>
                    <div className='mt-10 mb-5'>
                        <div className='pt-3 text-left bg-md-table-bg rounded-t-xl'>
                            <h3 className='text-3xl pl-4'>Cuentas en moneda nacional</h3>
                        </div>
                        <table className='w-full text-md-blue'>
                            <thead className='rounded-xl shadow-x'>
                                <tr className="pt-3 pb-3 text-left shadow-lg rounded-xl">
                                    <th className='w-[15%] text-2xl rounded-bl-xl pl-4 bg-md-table-bg'>Banco</th>
                                    <th className='w-[15%] text-2xl bg-md-table-bg text-right pr-10'>Cuenta</th>
                                    <th className='w-[15%] text-2xl bg-md-table-bg text-right pr-10'>Moneda</th>
                                    <th className='w-[17%] text-2xl bg-md-table-bg text-right pr-10'>Valor Inicial</th>
                                    <th className='w-[20%] text-2xl bg-md-table-bg rounded-br-xl text-right pr-10'>Fecha de Inicio</th>
                                    <th className='w-[5%] text-2xl bg-md-table-bg'></th>
                                    <th className='w-[5%] text-2xl bg-md-table-bg rounded-br-xl'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountDataMXN.map((item) => (
                                    <tr>
                                        <td className='pl-4 text-xl'>{item.bank}</td>
                                        <td className='text-right pr-10 text-xl'>{item.account}</td>
                                        <td className='text-right pr-10 text-xl'>{item.currency}</td>
                                        <td className='text-right pr-10 text-xl'>${item.initialValue}</td>
                                        <td className='text-right pr-10 text-xl'>{dateFormate(item.initialDate)}</td>
                                        <td>
                                            <button disabled={verifyTransactions(item.total)} onClick={()=>{
                                                setDeleteConfirmation(true)
                                                setItemToDelete(item.id);
                                            }} title='Editar'>
                                                <TrashIcon className={verifyTransactions(item.total) ? 'w-7 text-gray-400' : 'w-7 text-md-blue'} title='Eliminar'/>
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                getPrevValues(item.currency, item.id);
                                            }}>
                                                <PencilSquareIcon className='w-7 text-md-blue'/>
                                            </button>
                                        </td>
                                    </tr>
                                    
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-10 mb-5'>
                        <div className='pt-3 text-left bg-md-table-bg rounded-t-xl'>
                            <h3 className='text-3xl pl-4'>Cuentas en moneda extranjera</h3>
                        </div>
                        <table className='w-full text-md-blue'>
                            <thead className='rounded-xl shadow-x'>
                                <tr className="pt-3 pb-3 text-left shadow-lg rounded-xl">
                                    <th className='w-[15%] text-2xl rounded-bl-xl pl-4 bg-md-table-bg'>Banco</th>
                                    <th className='w-[15%] text-2xl bg-md-table-bg text-right pr-10'>Cuenta</th>
                                    <th className='w-[15%] text-2xl bg-md-table-bg text-right pr-10'>Moneda</th>
                                    <th className='w-[17%] text-2xl bg-md-table-bg text-right pr-10'>Valor Inicial</th>
                                    <th className='w-[20%] text-2xl bg-md-table-bg text-right pr-10'>Fecha de Inicio</th>
                                    <th className='w-[5%] text-2xl bg-md-table-bg'></th>
                                    <th className='w-[5%] text-2xl bg-md-table-bg rounded-br-xl'></th>
                                </tr>
                            </thead> 
                            <tbody>
                                {accountDataUSD.map((item) => (
                                    <tr>
                                        <td className='pl-4 text-xl'>{item.bank}</td>
                                        <td className='text-right pr-10 text-xl'>{item.account}</td>
                                        <td className='text-right pr-10 text-xl'>{item.currency}</td>
                                        <td className='text-right pr-10 text-xl'>${item.initialValue}</td>
                                        <td className='text-right pr-10 text-xl'>{dateFormate(item.initialDate)}</td>
                                        <td>
                                            <button disabled={verifyTransactions(item.total)} onClick={()=>{
                                                setDeleteConfirmation(true)
                                                setItemToDelete(item.id);
                                            }}>
                                                <TrashIcon className={verifyTransactions(item.total) ? 'w-7 text-gray-400' : 'w-7 text-md-blue'}/>
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                getPrevValues(item.currency, item.id);
                                            }}>
                                                <PencilSquareIcon className='w-7 text-md-blue'/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button className='flex mt-5 rounded-full p-1' onClick={() => {SetAddAccount(true)}}>
                        <PlusCircleIcon
                            className="h-7 w-7 text-md-blue font-bold"
                        />
                        <p className='text-xl text-md-blue'>Agregar Cuenta</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BanksEditionScreen