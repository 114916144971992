import React from 'react'

const AIChatLogo = (props) => {
  return (
    <svg id="Grupo_1330" data-name="Grupo 1330" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Grupo_1328" data-name="Grupo 1328" clip-path="url(#clip-path)">
        <path id="Trazado_1607" data-name="Trazado 1607" d="M28.16,7.4h0a1.626,1.626,0,0,0-.425.058V1.617A1.535,1.535,0,0,0,26.3,0H3.419A1.536,1.536,0,0,0,1.981,1.617V7.4c-.047,0-.094-.008-.142-.008A1.963,1.963,0,0,0,0,9.464V13.6a1.963,1.963,0,0,0,1.839,2.068c.048,0,.1,0,.142-.008v5.787a1.536,1.536,0,0,0,1.438,1.617H8.34L5.519,30,18.39,23.063H26.3a1.535,1.535,0,0,0,1.438-1.617V15.609a1.626,1.626,0,0,0,.425.058A1.964,1.964,0,0,0,30,13.6V9.464A1.964,1.964,0,0,0,28.16,7.4" fill={props.fill || "#e8e8ff"}/>
        <path id="Trazado_1608" data-name="Trazado 1608" d="M19.069,17.292H10.931a5.857,5.857,0,1,1,0-11.714h8.138a5.857,5.857,0,1,1,0,11.714" fill={props.fillcenter || "#5300ba"}/>
        <path id="Trazado_1609" data-name="Trazado 1609" d="M12.217,11.422a1.8,1.8,0,1,1-1.8-1.8,1.8,1.8,0,0,1,1.8,1.8" fill={props.fill || "#e8e8ff"}/>
        <path id="Trazado_1610" data-name="Trazado 1610" d="M21.226,11.422a1.8,1.8,0,1,1-1.8-1.8,1.8,1.8,0,0,1,1.8,1.8" fill={props.fill || "#e8e8ff"}/>
      </g>
    </svg>
  )
}

export default AIChatLogo