const PlusIcon = (props) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 30 30">
            <g id="Grupo_29" data-name="Grupo 29" transform="translate(-893 -142)">
                <g id="Elipse_1" data-name="Elipse 1" transform="translate(893 142)" fill="none" stroke={props.fill || "#9191f4"} stroke-width="2">
                    <circle cx="15" cy="15" r="15" stroke="none"/>
                    <circle cx="15" cy="15" r="14" fill="none"/>
                </g>
                <line id="Línea_3" data-name="Línea 3" y2="15" transform="translate(908.5 149.5)" fill="none" stroke={props.fill || "#9191f4"} stroke-width="2"/>
                <line id="Línea_4" data-name="Línea 4" x2="13" transform="translate(902 157)" fill="none" stroke={props.fill || "#9191f4"} stroke-width="2"/>
            </g>
        </svg>
    );
}

export default PlusIcon;

