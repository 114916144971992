

import React from 'react'

const XMark = () => {
  return (
    <svg id="icono_cierre" data-name="icono cierre" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_41" data-name="Enmascarar grupo 41" transform="translate(-1587 -260)" clip-path="url(#clip-path)">
        <g id="Grupo_72" data-name="Grupo 72" transform="translate(1587.219 260.219)">
          <g id="Grupo_72-2" data-name="Grupo 72" clip-path="url(#clip-path-2)">
            <path id="Trazado_36" data-name="Trazado 36" d="M0,14.891A14.89,14.89,0,1,1,14.9,29.779,14.891,14.891,0,0,1,0,14.891M14.9.83A14.061,14.061,0,1,0,28.936,14.9,14.1,14.1,0,0,0,14.9.83" transform="translate(0 0)"/>
            <path id="Trazado_37" data-name="Trazado 37" d="M108.009,107.268c-.868-.865-1.672-1.666-2.474-2.467s-1.587-1.585-2.378-2.38c-.225-.226-.562-.465-.231-.806s.579-.028.81.2l4.478,4.474c.1.1.21.2.442.422a2.138,2.138,0,0,1,.26-.434q2.213-2.231,4.441-4.449c.094-.094.182-.243.291-.261a.76.76,0,0,1,.544.069c.088.074.062.344.013.5-.039.126-.189.219-.293.324l-4.863,4.86a2.035,2.035,0,0,1,.446.253q2.232,2.213,4.448,4.441c.1.1.248.2.286.329.044.147.052.392-.035.469a.7.7,0,0,1-.507.089c-.109-.016-.2-.161-.3-.254q-2.223-2.221-4.443-4.447a4.628,4.628,0,0,1-.33-.424c-.178.169-.293.273-.4.382l-4.477,4.475c-.094.094-.184.238-.294.258a.659.659,0,0,1-.507-.066c-.093-.1-.076-.343-.039-.508.025-.112.166-.2.26-.293q2.222-2.223,4.447-4.443a4.556,4.556,0,0,1,.408-.321" transform="translate(-93.669 -92.464)"/>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default XMark;