import React from 'react'

const SelectConsolidatedLogo = () => {
  return (
    <svg id="Grupo_1273" data-name="Grupo 1273" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="46" height="46" viewBox="0 0 46 46">
        <g id="Grupo_38" data-name="Grupo 38" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Trazado_1557" data-name="Trazado 1557" d="M9.9,9.9V3.58A1.58,1.58,0,0,1,11.482,2H32.025a1.58,1.58,0,0,1,1.58,1.58V24.124a1.58,1.58,0,0,1-1.58,1.58H25.7v6.31a1.59,1.59,0,0,1-1.591,1.591H3.591A1.59,1.59,0,0,1,2,32.014L2,11.493A1.59,1.59,0,0,1,3.6,9.9Zm3.161,0H24.113A1.59,1.59,0,0,1,25.7,11.493V22.544h4.741V5.161H13.062Zm-.785,17.383,8.938-8.94L18.98,16.11l-6.7,6.705L8.922,19.462,6.687,21.7Z" transform="translate(5.197 5.197)" fill="#d3d3d3"/>
        </g>
        </svg>

  )
}

export default SelectConsolidatedLogo