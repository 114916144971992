import React from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { useStateContext } from '../contexts/ContextProvider'
import Idioma from './Idioma'

const PlanningExcelExport = ({excelData, fileName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF=8'
    const fileExtension = '.xlsx'
    const cols = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O']
    const {language} = useStateContext()

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData)
        if(!ws["!rows"]) ws["!rows"] = []
        for(let i=0;i<cols.length;i++){
            for(let j=0;j<excelData.length+1;j++){
                let auxBody = {
                    font: {
                    name: 'Century Gothic',
                    sz: 8,
                  },
                }
                if(j===0){
                    auxBody.font.bold = true
                    if(!ws["!rows"][j+1]) ws["!rows"][j+1] = {hpt: 15};
                }
                if(i>=1){
                    auxBody.alignment = {
                        horizontal:'right'
                    }
                }
                if(i===6 || i===7 || i===11){
                    auxBody.numFmt = "#,##0.00_);(#,##0.00);--_)"
                }
                
                ws[`${cols[i]}${j+1}`].s = auxBody
            }
        }
        const wb = { Sheets: {'data': ws}, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }
   

    return (
        <>
            <button className='rounded-button text-center text-white p-2 bg-purple-1 font-bold text-p8-2xl' onClick={(e)=>exportToExcel(fileName)}>
                {Idioma.transacciones.exportarExcel[language]}
            </button>
        </>
    )
}

export default PlanningExcelExport
