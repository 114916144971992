import React from 'react'

const AddSingleTransactionLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_26" data-name="Enmascarar grupo 26" transform="translate(-523 -296)" clip-path="url(#clip-path)">
        <g id="Grupo_521" data-name="Grupo 521" transform="translate(524 297)">
          <g id="Grupo_520" data-name="Grupo 520" clip-path="url(#clip-path-2)">
            <path id="Trazado_741" data-name="Trazado 741" d="M13.985,27.958A13.979,13.979,0,1,1,27.945,13.95,13.958,13.958,0,0,1,13.985,27.958m-.007-3.288A10.69,10.69,0,1,0,3.287,14,10.686,10.686,0,0,0,13.978,24.67" transform="translate(0 0)" fill={props.fill || "000"}/>
            <path id="Trazado_742" data-name="Trazado 742" d="M65.754,69.251h-4.93V65.977h4.9v-4.9h3.27v4.847h4.89v3.314H69.045v4.907H65.754Z" transform="translate(-53.402 -53.627)" fill={props.fill || "000"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AddSingleTransactionLogo