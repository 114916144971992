
import React from 'react'

const transaccionesLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_18" data-name="Enmascarar grupo 18" transform="translate(-78 -374)" clip-path="url(#clip-path)">
        <g id="Grupo_219" data-name="Grupo 219" transform="translate(80 375)">
          <g id="Grupo_218" data-name="Grupo 218" transform="translate(0 0)" clip-path="url(#clip-path-2)">
            <path id="Trazado_152" data-name="Trazado 152" d="M30.484,7.023q0,2.87,0,5.738a1.072,1.072,0,0,1-1.213,1.233q-5.778.007-11.558,0a1.111,1.111,0,0,1-1.218-1.217q-.012-5.758,0-11.517A1.152,1.152,0,0,1,17.808,0C19.884,0,21.96,0,24.035,0q2.523,0,5.047,0c1.007,0,1.4.4,1.4,1.405q0,2.808,0,5.616" transform="translate(-4.849 0)" fill={props.fill || "#fff"}/>
            <path id="Trazado_153" data-name="Trazado 153" d="M6.995,34.961a7.01,7.01,0,1,1,7.048-7.015,7.024,7.024,0,0,1-7.048,7.015" transform="translate(0 -6.159)" fill={props.fill || "#fff"}/>
            <path id="Trazado_154" data-name="Trazado 154" d="M27.828,29.933h-.515q-2.014,0-4.027,0c-.582,0-.676-.1-.678-.69,0-.339,0-.678,0-1.017,0-.589.1-.691.68-.691h4.068c.129,0,.258-.012.479-.023-.661-.659-1.263-1.256-1.863-1.856-.43-.429-.429-.575,0-1.006.277-.279.559-.553.833-.835a.48.48,0,0,1,.775-.02q2.264,2.282,4.525,4.564a.474.474,0,0,1,.006.748q-2.261,2.283-4.524,4.564a.48.48,0,0,1-.775-.006c-.314-.318-.637-.629-.947-.951-.28-.29-.276-.5.018-.8.533-.541,1.074-1.074,1.609-1.612.092-.092.178-.191.338-.363" transform="translate(-6.648 -6.942)" fill={props.fill || "#fff"}/>
            <path id="Trazado_155" data-name="Trazado 155" d="M4.525,6.629H5q2.094,0,4.19,0c.457,0,.58.122.586.576.006.407,0,.814,0,1.221-.006.429-.126.552-.561.553-1.369,0-2.739,0-4.108,0H4.584c.118.137.186.228.266.308.555.558,1.115,1.11,1.666,1.671a.485.485,0,0,1,.008.805c-.31.323-.631.635-.948.95a.485.485,0,0,1-.777-.006Q2.528,10.435.256,8.163a.484.484,0,0,1,.008-.776Q2.51,5.146,4.753,2.9a.494.494,0,0,1,.806,0c.3.292.6.59.892.891.323.331.325.541,0,.867C5.923,5.2,5.383,5.738,4.847,6.276c-.091.091-.174.19-.322.352" transform="translate(-0.019 -0.793)" fill={props.fill || "#fff"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default transaccionesLogo

