import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { urlBase } from "../authConfig";

const stripePromise = loadStripe('pk_test_51Pr0drI3uw0UqnHM4hfJW96TnAmCQLtl9LqqzZGGSUbHrOedr1BiaCGHNFvhkjdqmYgPU6hHXwowe4qn4zOuRj1e00qrV13Aog');

const Payment = () => {
    const { accessToken } = useStateContext();
    const [isOpen, setIsOpen] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);

    const getData = useCallback(async () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        try {
            const response = await fetch(`${urlBase}/PaymentSub`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({})
            });
            const data = await response.json();
            return data.clientSecret;
        } catch (error) {
            console.error('Error al obtener clientSecret:', error);
        }
    }, [accessToken]);

    useEffect(() => {
        if (isOpen) {
            getData().then(clientSecret => {
                if (typeof clientSecret === 'string') {
                    setClientSecret(clientSecret);
                } else {
                    console.error('Error: clientSecret no es una cadena válida', clientSecret);
                }
            });
        }
    }, [isOpen, getData]);

    const handleCheckoutClick = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const options = clientSecret ? { clientSecret } : null;

    return (
        <>
            <button className="rounded-md bg-purple-3 p-5 text-white" onClick={handleCheckoutClick}>
                Open
            </button>
            {isOpen && clientSecret && ( // Renderiza solo si isOpen es true y clientSecret está disponible
                <div className="fixed rounded-sm top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
                    <div onClick={() => handleCloseModal} className="fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6"></div>
                    <div className="bg-neutral-6 z-10 absolute top-[40%] left-[40%] max-w-[442px] min-w-[442px] mt-14 -translate-x-[50%] -translate-y-[50%] shadow-high-elevation rounded-md">
                        <div id="checkout">
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={options}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Payment;
