import React from 'react'

const TyCSect22Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
    <g id="Grupo_1153" data-name="Grupo 1153" transform="translate(-491 -226)" clip-path="url(#clip-path)">
        <g id="Grupo_1156" data-name="Grupo 1156" transform="translate(491.001 207.277)">
        <g id="Grupo_1154" data-name="Grupo 1154" transform="translate(19.255 45.836)">
            <path id="Trazado_1438" data-name="Trazado 1438" d="M264.2,353.156a.859.859,0,0,1-.859-.859v-2.421a1.115,1.115,0,0,0-1.114-1.114H249.305a1.115,1.115,0,0,0-1.114,1.114V352.3a.859.859,0,0,1-1.719,0v-2.421a2.836,2.836,0,0,1,2.832-2.832h12.918a2.836,2.836,0,0,1,2.832,2.832V352.3A.859.859,0,0,1,264.2,353.156Z" transform="translate(-246.473 -347.044)" fill="#1d0039"/>
        </g>
        <g id="Grupo_1155" data-name="Grupo 1155" transform="translate(0 20.31)">
            <path id="Trazado_1439" data-name="Trazado 1439" d="M240.282,408.286h-20.04a1.434,1.434,0,0,0-1.432,1.432v3.651a1.434,1.434,0,0,0,1.432,1.432h20.04a1.434,1.434,0,0,0,1.433-1.432v-3.651A1.434,1.434,0,0,0,240.282,408.286Zm-.286,4.8H220.529V410H240Z" transform="translate(-201.716 -377.976)" fill="#1d0039"/>
            <path id="Trazado_1440" data-name="Trazado 1440" d="M185.531,276.245l2.648.781a.859.859,0,1,0,.486-1.648l-2.648-.781a.859.859,0,1,0-.486,1.648Z" transform="translate(-170.469 -254.698)" fill="#1d0039"/>
            <path id="Trazado_1441" data-name="Trazado 1441" d="M189.246,326.951a.859.859,0,0,0-1.067-.581l-2.648.781a.859.859,0,1,0,.486,1.648l2.648-.781A.859.859,0,0,0,189.246,326.951Z" transform="translate(-170.468 -302.427)" fill="#1d0039"/>
            <path id="Trazado_1442" data-name="Trazado 1442" d="M22.093,36.555a1.642,1.642,0,0,0-1.447,1.628v2.959a1.642,1.642,0,0,0,1.641,1.641H34.807a1.642,1.642,0,0,0,1.641-1.641V38.183A1.642,1.642,0,0,0,35,36.555V26.538a1.642,1.642,0,0,0,1.447-1.628V21.951a1.642,1.642,0,0,0-1.641-1.641H22.286a1.642,1.642,0,0,0-1.641,1.641V24.91a1.642,1.642,0,0,0,1.447,1.628v1.505H3.5a3.5,3.5,0,1,0,0,7.007h18.59Zm11.188-10v9.992h-9.47V26.55Zm1.447,14.514H22.365v-2.8H34.729ZM22.365,22.029H34.729v2.8H22.365ZM3.5,33.331a1.785,1.785,0,0,1,0-3.569h18.59v3.569Z" transform="translate(0 -20.31)" fill="#1d0039"/>
        </g>
        </g>
    </g>
    </svg>

  )
}

export default TyCSect22Logo