import React from 'react'

const AjustesLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
      <g id="Enmascarar_grupo_22" data-name="Enmascarar grupo 22" transform="translate(-78 -869)" clip-path="url(#clip-path)">
        <g id="Grupo_806" data-name="Grupo 806" transform="translate(79 875)">
          <g id="Grupo_805" data-name="Grupo 805" clip-path="url(#clip-path-2)">
            <path id="Trazado_983" data-name="Trazado 983" d="M121.129,17.673c-.008-.147-.023-.286-.023-.426q0-3.98,0-7.959c0-1.337,0-2.674,0-4.01,0-.13-.079-.1-.145-.1-.893,0-1.786-.006-2.679-.01-.093,0-.185-.015-.293-.024V4.981q0-1.739,0-3.479c0-.181.027-.216.215-.223.282-.01.564-.012.846-.012q3,0,6,0c.374,0,.375,0,.375.372q0,3.016,0,6.033c0,.381,0,.375-.384.372-1.191-.007-2.382-.008-3.572-.011a.64.64,0,0,0-.084.018c0,.24,0,.488.006.736,0,.022.087.058.134.059.313,0,.627,0,.94,0l4.215,0c.457,0,.457,0,.457.45v8.1c.228,0,.44,0,.652-.005.026,0,.07-.077.071-.118q.009-.987.007-1.974,0-3.557-.006-7.114c0-.041,0-.081-.006-.146h-1.067l-.485,0c-.118,0-.169-.062-.167-.176,0-.037,0-.073,0-.11q0-3.722,0-7.443c0-.052-.016-.116.008-.154a.6.6,0,0,1,.164-.153c.025-.017.071,0,.108,0h6.534c.259,0,.3.039.3.3q0,8.509,0,17.017c0,.362,0,.364-.359.365q-2.915,0-5.829,0t-5.829-.006c-.03,0-.06,0-.1-.009m6.4-14.556h1.363V1.752h-1.363Zm0,3.023h1.359V4.783h-1.359ZM130.5,7.878c0,.432,0,.853,0,1.274,0,.03.063.083.1.084q.587.009,1.173,0c.033,0,.093-.054.093-.083.006-.421,0-.842,0-1.275Zm1.372-4.771c0-.44,0-.856-.005-1.273,0-.033-.078-.09-.121-.091-.376-.007-.752,0-1.127-.006-.091,0-.118.042-.118.124,0,.381,0,.762,0,1.143,0,.035.052.1.081.1.422.006.844,0,1.289,0M130.5,6.138h1.369c0-.43,0-.851-.005-1.272,0-.028-.073-.078-.113-.079-.276-.006-.553,0-.829,0-.418,0-.419,0-.421.421,0,.306,0,.613,0,.93m-7.007,6.78c-.326,0-.638,0-.95,0-.092,0-.117.037-.115.12,0,.182,0,.364.006.546,0,.394,0,.393.4.389.217,0,.434,0,.66,0Zm0-2.372h-1.06c0,.311.005.612,0,.914,0,.117.043.14.148.136.256-.009.512-.007.767-.011.049,0,.1-.01.148-.015Zm2.3,2.384h-1.027v1.029H125.8Zm-1.032-1.356H125.8V10.548h-1.036Z" transform="translate(-109.834 0)" fill={props.fill || "#fff"}/>
            <path id="Trazado_984" data-name="Trazado 984" d="M72.708,93.736q0,2.772,0,5.545c0,.232-.018.253-.246.253-1.822,0-3.644,0-5.467,0-.13,0-.164-.052-.165-.162,0-.433-.012-.866-.012-1.3q0-4.629,0-9.257c0-.219,0-.439,0-.658,0-.184.034-.223.218-.224q1.073-.006,2.146-.006h3.258c.25,0,.274.025.274.28q0,2.765,0,5.529h0m-4.663,2.258h1.043V94.912H68.044Zm2.318-1.088v1.087h1.009c.015-.026.024-.035.024-.044,0-.323.011-.645.008-.968,0-.025-.059-.071-.091-.072-.312-.005-.624,0-.951,0m0-3.68c.329,0,.65,0,.972,0,.025,0,.07-.051.07-.078.005-.316,0-.632,0-.964H70.36ZM71.4,93.614c0-.341,0-.662,0-.984,0-.023-.053-.064-.082-.064-.316,0-.632,0-.956,0v1.051Zm-2.316-3.435c-.326,0-.638,0-.95,0a.139.139,0,0,0-.1.1c-.008.286-.008.573,0,.859,0,.03.049.084.076.085.321.006.643,0,.973,0Zm0,2.385h-.98a.223.223,0,0,0-.054.013c-.008.016-.016.025-.016.034,0,.213,0,.427,0,.64,0,.353,0,.355.352.361.232,0,.464,0,.7,0Z" transform="translate(-62.194 -81.848)" fill={props.fill || "#fff"}/>
            <path id="Trazado_985" data-name="Trazado 985" d="M372.065,200.178c0-.23-.008-.46,0-.689.005-.11-.06-.114-.131-.134a1.109,1.109,0,0,1-.9-1.033,5.964,5.964,0,0,1,.044-1.389,1.093,1.093,0,0,1,1.328-.826,1.147,1.147,0,0,1,.975,1.224,3.382,3.382,0,0,0,0,.774,1.156,1.156,0,0,1-.89,1.233c-.114.026-.147.067-.144.186.009.449,0,.9,0,1.347,0,.114-.033.156-.151.159s-.147-.057-.144-.163c0-.23,0-.459,0-.689h.005" transform="translate(-345.367 -182.532)" fill={props.fill || "#fff"}/>
            <path id="Trazado_986" data-name="Trazado 986" d="M1.012,201c0-.4-.016-.8.005-1.2.016-.284-.026-.452-.34-.573a1.125,1.125,0,0,1-.643-1.347.81.81,0,0,0,0-.334A1.189,1.189,0,0,1,.471,196.3a1.193,1.193,0,0,1,1.34-.032,1.142,1.142,0,0,1,.507,1.194,2.09,2.09,0,0,0,.005.525,1.187,1.187,0,0,1-.9,1.343c-.108.023-.129.07-.128.169,0,.428,0,.856,0,1.285,0,.248-.007.255-.281.219" transform="translate(0 -182.521)" fill={props.fill || "#fff"}/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AjustesLogo

