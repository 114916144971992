import React from 'react'

const MiniLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="61.581" height="14.828" viewBox="0 0 61.581 14.828">
        <g id="Grupo_804" data-name="Grupo 804" transform="translate(0 0)">
            <path id="Trazado_733" data-name="Trazado 733" d="M8.421,6.795,0,.041V14.832H1.869V3.936L8.492,9.248l7.437-6.715V.015Z" transform="translate(0 -0.01)" fill="#1d0039"/>
            <g id="Grupo_507" data-name="Grupo 507" transform="translate(0 0)">
            <g id="Grupo_506" data-name="Grupo 506" clip-path="url(#clip-path)">
                <path id="Trazado_734" data-name="Trazado 734" d="M59.438,14.828V12.959c3.822,0,6.931-2.487,6.931-5.545a5.01,5.01,0,0,0-1.963-3.861,7.8,7.8,0,0,0-4.968-1.684V0a9.663,9.663,0,0,1,6.156,2.112,6.837,6.837,0,0,1,2.644,5.3c0,4.088-3.947,7.414-8.8,7.414" transform="translate(-41.64 0)" fill="#1d0039"/>
                <path id="Trazado_735" data-name="Trazado 735" d="M115.813,18.026h-1.53a2.346,2.346,0,0,0-.329-.864,2.417,2.417,0,0,0-.589-.633,2.548,2.548,0,0,0-.777-.393,3.071,3.071,0,0,0-.91-.132,2.824,2.824,0,0,0-1.553.437,2.955,2.955,0,0,0-1.079,1.28,4.845,4.845,0,0,0-.395,2.06,4.871,4.871,0,0,0,.395,2.07,2.915,2.915,0,0,0,1.082,1.275,2.842,2.842,0,0,0,1.545.432,3.1,3.1,0,0,0,.9-.13,2.556,2.556,0,0,0,.778-.385,2.4,2.4,0,0,0,.591-.626,2.355,2.355,0,0,0,.336-.851l1.53,0a4.1,4.1,0,0,1-.473,1.362,3.767,3.767,0,0,1-.9,1.069,4.068,4.068,0,0,1-1.248.694,4.612,4.612,0,0,1-1.531.246,4.384,4.384,0,0,1-2.325-.621,4.294,4.294,0,0,1-1.606-1.778,6.049,6.049,0,0,1-.586-2.762,6.017,6.017,0,0,1,.589-2.764,4.311,4.311,0,0,1,1.609-1.776,4.375,4.375,0,0,1,2.32-.621,4.757,4.757,0,0,1,1.494.228,4.016,4.016,0,0,1,1.248.667,3.716,3.716,0,0,1,.917,1.069,4.159,4.159,0,0,1,.49,1.44" transform="translate(-75.062 -10.243)" fill="#1d0039"/>
                <path id="Trazado_736" data-name="Trazado 736" d="M140.361,30.941a3.05,3.05,0,0,1-1.3-.267,2.147,2.147,0,0,1-.915-.778,2.224,2.224,0,0,1-.336-1.251,2.028,2.028,0,0,1,.245-1.05,1.807,1.807,0,0,1,.662-.652,3.47,3.47,0,0,1,.932-.363,9.359,9.359,0,0,1,1.05-.186q.677-.079,1.1-.13a1.588,1.588,0,0,0,.613-.169.414.414,0,0,0,.191-.382v-.034a1.33,1.33,0,0,0-.361-1,1.474,1.474,0,0,0-1.072-.353,1.866,1.866,0,0,0-1.165.326,1.762,1.762,0,0,0-.586.723l-1.379-.314a2.657,2.657,0,0,1,.719-1.111,2.925,2.925,0,0,1,1.091-.618,4.324,4.324,0,0,1,1.3-.194,4.721,4.721,0,0,1,.959.105,2.867,2.867,0,0,1,.952.385,2.126,2.126,0,0,1,.728.8,2.77,2.77,0,0,1,.285,1.341v5h-1.433v-1.03h-.059a2.1,2.1,0,0,1-.427.559,2.267,2.267,0,0,1-.731.456,2.843,2.843,0,0,1-1.069.181m.319-1.177a2.12,2.12,0,0,0,1.042-.24,1.7,1.7,0,0,0,.662-.63,1.628,1.628,0,0,0,.228-.836v-.971a.746.746,0,0,1-.3.145,4.084,4.084,0,0,1-.508.113q-.284.047-.554.083l-.451.061a3.6,3.6,0,0,0-.778.182,1.328,1.328,0,0,0-.559.365.921.921,0,0,0-.209.63.933.933,0,0,0,.4.822,1.769,1.769,0,0,0,1.026.277" transform="translate(-96.548 -16.212)" fill="#1d0039"/>
                <path id="Trazado_737" data-name="Trazado 737" d="M167.353,25.078l-1.329.235a1.613,1.613,0,0,0-.262-.486,1.339,1.339,0,0,0-.484-.378,1.742,1.742,0,0,0-.76-.147,1.839,1.839,0,0,0-1.04.277.83.83,0,0,0-.417.714.749.749,0,0,0,.28.608,2.261,2.261,0,0,0,.9.378l1.2.275a3.21,3.21,0,0,1,1.55.741,1.74,1.74,0,0,1,.51,1.3,1.972,1.972,0,0,1-.39,1.2,2.584,2.584,0,0,1-1.084.826,4.031,4.031,0,0,1-1.607.3,3.608,3.608,0,0,1-2.065-.542,2.272,2.272,0,0,1-.982-1.543l1.418-.216a1.346,1.346,0,0,0,.544.836,1.863,1.863,0,0,0,1.074.282,1.976,1.976,0,0,0,1.153-.3.883.883,0,0,0,.432-.738.774.774,0,0,0-.263-.593,1.761,1.761,0,0,0-.8-.363l-1.276-.279A3.049,3.049,0,0,1,162.1,26.7a1.844,1.844,0,0,1-.5-1.33,1.9,1.9,0,0,1,.373-1.167,2.458,2.458,0,0,1,1.03-.782,3.79,3.79,0,0,1,1.506-.282,3.134,3.134,0,0,1,1.923.527,2.478,2.478,0,0,1,.927,1.411" transform="translate(-113.052 -16.211)" fill="#1d0039"/>
                <path id="Trazado_738" data-name="Trazado 738" d="M185.909,20.654v4.474h-1.467V15.081h1.447v3.738h.093a2.163,2.163,0,0,1,.809-.966,2.546,2.546,0,0,1,1.423-.358,2.776,2.776,0,0,1,1.356.319,2.179,2.179,0,0,1,.9.949,3.443,3.443,0,0,1,.321,1.572v4.792h-1.467V20.511a1.818,1.818,0,0,0-.427-1.287,1.541,1.541,0,0,0-1.187-.459,1.925,1.925,0,0,0-.929.221,1.577,1.577,0,0,0-.643.645,2.1,2.1,0,0,0-.233,1.023" transform="translate(-129.214 -10.565)" fill="#1d0039"/>
            </g>
            </g>
        </g>
    </svg>

  )
}

export default MiniLogo